import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import {
    Box,
    Divider,
    Grid,
    Typography,
    withWidth,
} from '@material-ui/core';
import { withRouter, useLocation } from 'react-router-dom';
import {
    IHeadRowTicketsReport,
    headTicketsReportColumnsDefault,
    headTicketsReportSelectableColumns,
    betStatusFilterList,
    payoutStatusFilterList,
} from 'data';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import ColumnChooser from 'components/ColumnChooser/ColumnChooser';
import NoResultsFound from 'components/NoResultsFound';
import SearchbyNumber from 'components/SearchByNumber';
import TicketsReportFilters from 'pages/Reports/Tickets/TicketsReportFilters/TicketsReportFilters';
import TicketsReportTable from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTable';
import { PAGINATION_ACTIONS } from 'components/TablePaginationActions/TablePaginationActionsDumb';
import { getPagesCount } from 'components/TablePaginationActions/TablePaginationActions';
import DateRangeLegend from 'components/DateRangeLegend';
import ButtonWithLinearProgress from 'components/Buttons/ButtonWithLinearProgress';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';

import { GlobalContext } from 'context/globalContext';

import { ITicketsFullSearchResponse, IBetTypeFilterItem, IAlertMessage } from 'api/models/reports';
import Api from 'api/Api';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

import { EXPORT_REPORT_TYPE } from 'api/models/reportsExport';

import {
    Order,
    rowsPerPageOptions,
    TICKETS_SEARCH_BY_DATES,
    TICKETS_SEARCH_BY_DATES_SORTING_COLUMNS,
    SHOW_PAGINATION_INFO_RANGE_MONTH,
    EXCEL_EXPORT_ERROR_ENUM,
    REPORTS_NAMES,
    BET_TYPE,
    BET_TYPE_NAMES,
    initialAlertMessage
} from 'const';

import formatStr from 'utils/formatStr';

import {
    getLastDateOnPage,
} from 'utils/formatDate';

import {
    ITicketsReportBaseFilterList,
    getDuplicatedTicketIds,
    getShowPaginationRangeMs,
    ticketIdSearchValidator,
    ticketsReportFilterListInitial as filterListInitial,
    toggleColumns,
    ITicketsReportInitState,
    ITicketsReportFilterList,
} from 'pages/Reports/business';

import {
    generateActiveColumsForExcel,
    generateTicketsSearchPayload,
    getAllBetshops,
    getPointsOfSale,
} from 'pages/Reports/networkHelpers';

import {
    startOfDay,
    endOfDay,
} from 'utils/formatDate';

import { setLanguageCookie } from 'utils/cookieMethods';

import { mqttClient, initMqttConnection, subscribe as subscribeToMqtt } from 'api/mqttClient';
import { onMessageExcelHandler, ExportToExcelContext, state as mqttState, setState as setMqttState } from 'context/exportToExcel';

import { GlobalContextModel } from 'api/models/general';

const SHOW_PAGINATION_INFO_RANGE_MS = getShowPaginationRangeMs(SHOW_PAGINATION_INFO_RANGE_MONTH);

import useStyles from './styles';

const apiRequests = {
    getAllBetshops: null,
    getPointsOfSale: null,
    getTicketsList: null,
    getTicketsCount: null,
};

let pendingGetExport = false;
let lastSearchResultDateReportingApi;

const TicketsReport = (props) => {
    const { state: initState } = useLocation<ITicketsReportInitState>();
    const classes = useStyles({});
    const { globalSettings, translations, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);

    const isMobileView = props.width === 'xs';

    const ticketsReportMaxRangeInDays = globalSettings?.settings?.ExcelExportSettings?.TicketsReportMaxRangeDays != null
        ? globalSettings?.settings?.ExcelExportSettings?.TicketsReportMaxRangeDays
        : globalSettings?.settings?.ExcelExportSettings?.DefaultReportMaxRangeDays;
    const ticketsReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * ticketsReportMaxRangeInDays;

    const operators = globalSettings?.user?.DomainMappings || {};
    const isMultitenancyOperators = Object.keys(operators).length > 0;

    const betTypeFilterList: Array<IBetTypeFilterItem> = [
        { id: BET_TYPE.SINGLE, name: BET_TYPE_NAMES.SINGLE },
        { id: BET_TYPE.COMBO, name: BET_TYPE_NAMES.COMBO },
        { id: BET_TYPE.YOUR_BET, name: BET_TYPE_NAMES.YOUR_BET },
        { id: BET_TYPE.SYSTEM, name: BET_TYPE_NAMES.SYSTEM },
        isMultitenancyOperators &&
        { id: BET_TYPE.MULTIPLES, name: BET_TYPE_NAMES.MULTIPLES }, // aggregated betType
        isMultitenancyOperators &&
        { id: BET_TYPE.TEASER, name: BET_TYPE_NAMES.TEASER },
        !isMultitenancyOperators &&
        { id: BET_TYPE.MULTIWAY, name: BET_TYPE_NAMES.MULTIWAY },
        ...(isMultitenancyOperators
            ? [
                { id: BET_TYPE.SGPX_PARLAY, name: BET_TYPE_NAMES.SGPX_PARLAY },
                { id: BET_TYPE.SGPX_ROBINS, name: BET_TYPE_NAMES.SGPX_ROBINS },
                { id: BET_TYPE.SGPX_MULTIPLES, name: BET_TYPE_NAMES.SGPX_MULTIPLES }, ]
            : []
        ),
        { id: BET_TYPE.PROGRESSIVE_PARLAY, name: BET_TYPE_NAMES.PROGRESSIVE_PARLAY }
    ].filter(Boolean);

    const { reportTicketsExportPermission } = permissions;

    const filtersListsInitial: ITicketsReportBaseFilterList = {
        betShops: [],
        pointsOfSale: [],
        betStatuses: [...betStatusFilterList],
        payoutStatuses: [...payoutStatusFilterList],
        betTypes: [...betTypeFilterList],
    };

    const [filtersLists, setFiltersLists] = useState(initState?.filtersLists ?? { ...filtersListsInitial });

    const dateInTimeZone = new Date(new Date().toLocaleString('en', { timeZone }));
    const fromDate = startOfDay(dateInTimeZone);
    const toDate = endOfDay(dateInTimeZone);
    const filterListInitialWithDate = { ...filterListInitial, fromDate, toDate };

    // applied filters (as reference values when closing filters drawer for resetting if it is not applied)
    const [appliedFilters, setAppliedFilters] = useState(initState?.appliedFilters ?? { ...filterListInitialWithDate });

    const [isAscendingOrder, setIsAscendingOrder] = useState(initState?.isAscendingOrder ?? false);
    const orderBy: keyof typeof TICKETS_SEARCH_BY_DATES = TICKETS_SEARCH_BY_DATES_SORTING_COLUMNS[TICKETS_SEARCH_BY_DATES[appliedFilters?.searchByDate]];

    const [areTicketsLoaded, setTicketsLoaded] = useState(false);
    const [areFiltersListsLoaded, setFiltersListsLoaded] = useState(false);
    const isLoaded = areTicketsLoaded && areFiltersListsLoaded;

    const isReadyToShowRef = useRef(false);

    isLoaded && (isReadyToShowRef.current = true);

    const [rows, setRows] = useState(initState?.rows ?? []);
    const [activeColumns, setActiveColumns]: [
        Array<IHeadRowTicketsReport>,
        React.Dispatch<React.SetStateAction<Array<IHeadRowTicketsReport>>>
    ] = useState(JSON.parse(localStorage.getItem('activeTicketsReportColumns')) || [...headTicketsReportColumnsDefault]);

    const isFirstPageRelativeCountRef = useRef(initState?.isFirstPageRelativeCount ?? true);
    // page count can be relative to newest/last newee (isFirstPageRelativeCountRef.current === true/false)
    const [relativePage, setRelativePage] = useState<number>(initState?.relativePage ?? 0);
    // relativePage is positive/negative if page count relative to first/last page
    const [isFirstPage, setIsFirstPage] = useState(initState?.isFirstPage ?? true);
    const [isLastPage, setIsLastPage] = useState(initState?.isLastPage ?? false);
    const newestTicketDateInRangeRef = useRef<string>(initState?.newestTicketDateInRange); // used to limit toDate if filter toDate is in future
    const previousRowsPerPageRef= useRef(rowsPerPageOptions[0]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(initState?.rowsPerPage ?? previousRowsPerPageRef.current);
    const scrollDownRef = useRef(false);

    const showPaginationInfoRef = useRef(true);
    const [rowsCount, setRowsCount] = useState<number>(initState?.rowsCount ?? -1); // -1 means that rowsCount is not loaded yet
    const isRowsCountLoaded = rowsCount !== -1;
    const page = isFirstPageRelativeCountRef.current
        ? relativePage
        : getPagesCount(rowsCount, rowsPerPage) + relativePage;

    const [searchText, setSearchText] = useState<string>(initState?.searchText ?? '');
    const [searchResetKey, setSearchResetKey] = useState(Math.random()); // used to reset searchByNumber field state
    const [invalidSearchValue, setInvalidSearchValue] = useState(false);
    const [isSearchedByNumber, setIsSearchedByNumber] = useState(false);
    const [ticketIdFromSearch, setTicketIdFromSearch] = useState<string>(initState?.searchText ?? null);

    const [alertMessage, setAlertMessage] = useState<IAlertMessage>({ ...initialAlertMessage });

    const ExportToExcelState = useContext(ExportToExcelContext);
    const {
        percentage: valueExportToExcel,
        isLoading: isLoadingExportToExcel,
        isFailed: isFailedExportToExcel,
        errorCode: excelExportError
    } = ExportToExcelState[REPORTS_NAMES.tickets];

    isFailedExportToExcel && setAlertMessage({
        show: true,
        message: excelExportError === EXCEL_EXPORT_ERROR_ENUM.NoData
            ? translations['emp-transaction-nodata-error']
            : translations['general-error']
    });

    const fullSearchHandler = async ({
        paginationAction,
        newFilters = appliedFilters,
        newFilters: {
            fromDate,
            toDate,
            searchByDate,
        } = appliedFilters,
        newRowsPerPage = rowsPerPage,
        newIsAscendingOrder = isAscendingOrder,
    }: {
        paginationAction?: PAGINATION_ACTIONS;
        newFilters?: ITicketsReportFilterList;
        newRowsPerPage?: number;
        newIsAscendingOrder?: boolean;
    } = {}) => {
        const newOrderBy: keyof typeof TICKETS_SEARCH_BY_DATES = TICKETS_SEARCH_BY_DATES_SORTING_COLUMNS[TICKETS_SEARCH_BY_DATES[searchByDate]];

        const areFiltersApplied = paginationAction === undefined;

        if (areFiltersApplied) { // true if: new filters applied, changed rowsPerPage, changed sorting
            paginationAction = PAGINATION_ACTIONS.FIRST;
            setRelativePage(0);
            isFirstPageRelativeCountRef.current = true;
            setRowsCount(-1);
            setIsFirstPage(true);
            setIsLastPage(true);
            newestTicketDateInRangeRef.current = null;

            setSearchText('');
            setSearchResetKey(Math.random());
            setInvalidSearchValue(false);
        }

        setTicketsLoaded(false);

        const firstPageNav = paginationAction === PAGINATION_ACTIONS.FIRST;
        const prevPageNav = paginationAction === PAGINATION_ACTIONS.PREV;
        const lastPageNav = paginationAction === PAGINATION_ACTIONS.LAST;
        const nextPageNav = paginationAction === PAGINATION_ACTIONS.NEXT;
        const lastDateOnPage: string = (firstPageNav || lastPageNav) ? null : getLastDateOnPage(rows, newOrderBy, prevPageNav);
        const reverseOrder = (lastPageNav || prevPageNav) && !firstPageNav;

        // last tickets with same lastDateOnPage will be duplicated in the next page request response
        const duplicatedTicketIds: Array<string> = firstPageNav ? [] : getDuplicatedTicketIds(rows, lastDateOnPage, newOrderBy);
        // ticketsInResponse:
        //  newRowsPerPage: tickets to show on a page
        //  +1: to see if it is a single page at all
        //  +duplicatedTicketIds.length: because duplicated tickets will be excluded from search
        const ticketsInResponse: number = newRowsPerPage + 1 + duplicatedTicketIds.length;

        const ticketsListPayload = generateTicketsSearchPayload(
            {
                ...newFilters,
                toDate: firstPageNav ? newFilters.toDate : newestTicketDateInRangeRef.current as unknown as Date,
            },
            ticketsInResponse,
            lastDateOnPage,
            lastSearchResultDateReportingApi,
            null,
            reverseOrder ? !newIsAscendingOrder : newIsAscendingOrder,
            timeZone
        );

        apiRequests.getTicketsList = Api.TicketsReport.GetTicketsList(ticketsListPayload);

        showPaginationInfoRef.current = (toDate.getTime() - fromDate.getTime() < SHOW_PAGINATION_INFO_RANGE_MS);

        const shouldUpdateTicketsCount = showPaginationInfoRef.current && firstPageNav;
        let ticketsList;

        try {
            const responseTicketsList: ITicketsFullSearchResponse = await apiRequests.getTicketsList;

            lastSearchResultDateReportingApi = responseTicketsList.lastSearchResultDate;
            ticketsList = responseTicketsList.ticketsList.filter((row) => !duplicatedTicketIds.includes(row.ticketId));

            reverseOrder && ticketsList.reverse();

            if (prevPageNav && ticketsList.length <= newRowsPerPage) {
                // navigated from last page to first by only clicking "Previous Page" arrow in pagination
                console.log('firstPage');
                setRelativePage(0);
                isFirstPageRelativeCountRef.current = true;
                setIsFirstPage(true);
                setIsLastPage(false);

                cancelRequests(apiRequests);

                return fullSearchHandler({ paginationAction: PAGINATION_ACTIONS.FIRST });
            } else if (nextPageNav && ticketsList.length <= newRowsPerPage) {
                // navigated from first page to last by only clicking "Next Page" arrow in pagination
                console.log('lastPage');
                setRelativePage(0);
                isFirstPageRelativeCountRef.current = false;
                setIsFirstPage(false);
                setIsLastPage(true);
            }

            if (firstPageNav) {
                newestTicketDateInRangeRef.current = newIsAscendingOrder ? responseTicketsList.newestTicketDateInRange : ticketsList[0]?.[newOrderBy];
            }

            let rowsOnPage: number = newRowsPerPage;

            if (isRowsCountLoaded && (nextPageNav || lastPageNav) && ticketsList.length <= newRowsPerPage) {
                rowsOnPage = ticketsList.length;
            }

            if (isRowsCountLoaded && lastPageNav && ticketsList.length > newRowsPerPage) {
                rowsOnPage = rowsCount % newRowsPerPage;
            }

            setRows(reverseOrder ? ticketsList.slice(-rowsOnPage) : ticketsList.slice(0, rowsOnPage));

            if (firstPageNav && ticketsList.length > newRowsPerPage) {
                // enable next/last navigation if not a single page
                setIsLastPage(false);
            }

            setIsSearchedByNumber(false);
            setTicketsLoaded(true);

            if (shouldUpdateTicketsCount && ticketsList.length <= newRowsPerPage) {
                // no need in count request if a single page
                setRowsCount(ticketsList.length);
            }
        } catch (error) {
            throwNetworkError(error);
        }

        try {
            if (!shouldUpdateTicketsCount ||
                shouldUpdateTicketsCount && ticketsList.length <= newRowsPerPage || // avoid count request if a single page, see above
                !newestTicketDateInRangeRef.current) return;

            setRowsCount(-1);
            apiRequests.getTicketsCount?.cancel();

            const ticketsCountPayload = generateTicketsSearchPayload(
                {
                    ...newFilters,
                    toDate: newestTicketDateInRangeRef.current as unknown as Date,
                },
                1,
                null,
                null,
                null,
                false,
                timeZone
            );

            apiRequests.getTicketsCount = Api.TicketsReport.GetTicketsCount(ticketsCountPayload);

            const ticketsCount = await apiRequests.getTicketsCount;

            if (!isNaN(ticketsCount)) {
                setRowsCount(ticketsCount);
            }
        } catch (error) {
            throwNetworkError(error);
        }
    };

    const searchByIdHandler = async (ticketId: string) => {
        setTicketsLoaded(false);

        cancelRequests(apiRequests);
        apiRequests.getTicketsList = Api.TicketsReport.GetTicketsListById({ ticketId, betShopIds: [] });

        try {
            const ticketsList = await apiRequests.getTicketsList;

            setRelativePage(0);
            setIsFirstPage(true);
            setRowsCount(ticketsList.length);
            setRows(ticketsList);
            setIsSearchedByNumber(true);
            setTicketsLoaded(true);
            setTicketIdFromSearch(ticketId);
        } catch (error) {
            throwNetworkError(error);
        }
    };

    useLayoutEffect(() => {
        if (scrollDownRef.current) {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;

            scrollDownRef.current = false;
        }
    }, [rows]);

    useEffect(() => {
        if (initState?.rows?.length) {
            setFiltersListsLoaded(true);
            setTicketsLoaded(true);

            if (!isRowsCountLoaded) {
                const payload = generateTicketsSearchPayload(
                    {
                        ...appliedFilters,
                        toDate: newestTicketDateInRangeRef.current as unknown as Date,
                    },
                    1,
                    null,
                    null,
                    null,
                    false,
                    timeZone
                );

                apiRequests.getTicketsCount = Api.TicketsReport.GetTicketsCount(payload);
                apiRequests.getTicketsCount.then((count) => setRowsCount(count));
            }
        } else {
            getAllBetshops(apiRequests, setFiltersLists, globalSettings?.user?.ClientId)
                .then(() =>
                    getPointsOfSale(apiRequests, setFiltersLists)
                )
                .then(() => {
                    setFiltersListsLoaded(true);
                    fullSearchHandler();
                }).catch(throwNetworkError);
        }

        return () => cancelRequests(apiRequests);
    }, []);

    useEffect(() => {
        scrollDownRef.current = previousRowsPerPageRef.current !== rowsPerPage;
        previousRowsPerPageRef.current = rowsPerPage;
    }, [rowsPerPage]);

    useEffect(() => {
        const filtersListsInitial: ITicketsReportBaseFilterList = {
            betShops: [],
            pointsOfSale: [],
            betStatuses: betStatusFilterList.map(({ id, name }) => ({ id, name: translations[name] })),
            payoutStatuses: payoutStatusFilterList.map(({ id, name }) => ({ id, name: translations[name] })),
            betTypes: betTypeFilterList.map(({ id, name }) => ({ id, name: translations[name] })),
        };

        setFiltersLists({ ...filtersListsInitial });
    }, [translations]);

    const paginationNavEffect = (paginationAction: PAGINATION_ACTIONS) => {
        cancelRequests({ ...apiRequests, getTicketsCount: undefined });
        fullSearchHandler({ paginationAction });
    };

    const handleSort = () => {
        setIsAscendingOrder((isAscendingOrder) => !isAscendingOrder);

        cancelRequests(apiRequests);
        fullSearchHandler({ newIsAscendingOrder: !isAscendingOrder });
    };

    const handleColumnChoose = (event, id: string) => {
        const activeColumnsUpdated: Array<IHeadRowTicketsReport> = toggleColumns(activeColumns, id);

        localStorage.setItem('activeTicketsReportColumns', JSON.stringify(activeColumnsUpdated));
        setActiveColumns(activeColumnsUpdated);
    };

    const searchTextSubmitHandler = (value: string) => {
        if (value.length) {
            setAppliedFilters({ ...filterListInitialWithDate });

            const isValid = ticketIdSearchValidator(value);

            setInvalidSearchValue(!isValid);

            if (isValid) {
                setSearchText(value);
                searchByIdHandler(value);
            } else {
                setRows([]);
            }
        } else {
            setInvalidSearchValue(false);
            setIsSearchedByNumber(false);
        }
    };

    const handleChangePage = (event, paginationAction: PAGINATION_ACTIONS) => {
        let actualPaginationAction: PAGINATION_ACTIONS;

        switch (paginationAction) {
            // PREV/FIRST page navigation
            case PAGINATION_ACTIONS.PREV:
                if (relativePage !== 1) {
                    setRelativePage(relativePage - 1);
                    setIsLastPage(false);
                    actualPaginationAction = PAGINATION_ACTIONS.PREV;
                    break;
                }

            case PAGINATION_ACTIONS.FIRST:
                setRelativePage(0);
                isFirstPageRelativeCountRef.current = true;
                setIsFirstPage(true);
                setIsLastPage(false);
                actualPaginationAction = PAGINATION_ACTIONS.FIRST;
                break;

            // NEXT/LAST page navigation
            case PAGINATION_ACTIONS.NEXT:
                if (relativePage !== -1) {
                    setRelativePage(relativePage + 1);
                    setIsFirstPage(false);
                    actualPaginationAction = PAGINATION_ACTIONS.NEXT;
                    break;
                }

            case PAGINATION_ACTIONS.LAST:
                setRelativePage(0);
                isFirstPageRelativeCountRef.current = false;
                setIsFirstPage(false);
                setIsLastPage(true);
                actualPaginationAction = PAGINATION_ACTIONS.LAST;
                break;
        }

        paginationNavEffect(actualPaginationAction);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);

        cancelRequests(apiRequests);
        fullSearchHandler({ newRowsPerPage: event.target.value });
    };

    const handleFiltersApply = (filters) => {
        cancelRequests(apiRequests);

        setAlertMessage({ ...alertMessage, show: false });
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.tickets]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });
        setAppliedFilters(filters);

        fullSearchHandler({ newFilters: { ...filters } });
    };

    const resetToDefault = () => {
        handleFiltersApply({ ...filterListInitialWithDate });
        setTicketIdFromSearch(null);
    };

    const handleRedirect = (ticketId: string) => {
        const state: ITicketsReportInitState = {
            filtersLists,
            isSearchedByNumber,
            appliedFilters,
            isAscendingOrder,
            rowsPerPage,
            rows,
            rowsCount,
            ticketId,
            relativePage,
            isFirstPageRelativeCount: isFirstPageRelativeCountRef.current,
            isFirstPage,
            isLastPage,
            newestTicketDateInRange: newestTicketDateInRangeRef.current,
            searchText,
        };

        props.history.replace({
            pathname: '/reports/tickets',
            state
        });
        props.history.push({
            pathname: `/reports/tickets/${ticketId}`,
            state
        });
    };

    const handleLoadingExcel = async () => {
        if (rows.length == 0){
            setAlertMessage({ show: true, message: translations['emp-transaction-nodata-error'] });
        } else if (appliedFilters.toDate.getTime() - appliedFilters.fromDate.getTime() > ticketsReportMaxRangeInMilliseconds) {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-transaction-export-to-excel-error-days'],
                    { 'days': ticketsReportMaxRangeInDays }
                )}`
            });
        } else {
            let activeColumnsExport = generateActiveColumsForExcel(activeColumns);

            const payload = generateTicketsSearchPayload(
                {
                    ...appliedFilters
                },
                0,
                null,
                lastSearchResultDateReportingApi,
                activeColumnsExport,
                isAscendingOrder,
                timeZone,
                ticketIdFromSearch ?? ticketIdFromSearch,
            );

            if (!pendingGetExport) {
                pendingGetExport = true;
                Api.TicketsReport.GetExport(
                    setLanguageCookie({ ...payload, reportType: EXPORT_REPORT_TYPE.Tickets }, globalSettings.languages, 'languageId'))
                    .then(res => {
                        const {
                            jwtToken,
                            reportId
                        } = res;

                        if (jwtToken) {
                            !mqttClient?.connected && initMqttConnection(jwtToken, globalSettings.settings.MqttSettigs.BrokerKongUrl);

                            subscribeToMqtt({
                                topic: globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                onMessageHandler: (response) => onMessageExcelHandler(
                                    response,
                                    reportId,
                                    globalSettings.settings.MqttSettigs.ReportsExportStatusTopic,
                                    REPORTS_NAMES.tickets)
                            });
                        }
                    }).catch((error) => {
                        setMqttState({
                            ...mqttState,
                            [REPORTS_NAMES.tickets]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: null
                            }
                        });
                        throwNetworkError(error);
                    }).finally(() => pendingGetExport = false);
            }
        }
    };

    const handleHideAlert = () => {
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.tickets]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });
        setAlertMessage({ ...alertMessage, show: false });
    };

    const TicketsReportFiltersWithParams = (
        <TicketsReportFilters
            filtersLists={filtersLists}
            appliedFilters={appliedFilters}
            setAppliedFilters={handleFiltersApply}
            filterListInitialWithDate={filterListInitialWithDate}
        />
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.searchWrap}>
                    <SearchbyNumber
                        initValue={searchText}
                        searchResetKey={searchResetKey}
                        placeholder={translations['emp-reports-ticket-search']}
                        onSubmit={searchTextSubmitHandler}
                        onReset={resetToDefault}
                        searchValidator={ticketIdSearchValidator}
                    />
                </div>
                <div className={classes.titleWrap}>
                    <Typography className={classes.title}
                        component="h1"
                        noWrap
                    >
                        {translations['gen-tickets']}
                    </Typography>
                    {isMobileView &&
                        <div className={classes.actions}>
                            {TicketsReportFiltersWithParams}
                            {reportTicketsExportPermission &&
                                <div style={{ marginLeft: '8px' }}>
                                    <ButtonWithLinearProgress
                                        label={translations['emp-transaction-export-to-excel'] as string}
                                        value={valueExportToExcel}
                                        handleLoading={handleLoadingExcel}
                                        loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                {isMobileView && (
                    <DateRangeLegend
                        translationKey="emp-reports-results-range-title"
                        fromDate={appliedFilters.fromDate}
                        toDate={appliedFilters.toDate}
                    />)}
            </div>
            {<Divider className={classes.divider} />}
            <div className={classes.paper}>
                <BackdropSpinner open={!isLoaded} />
                {isReadyToShowRef.current && (
                    <>
                        {!isMobileView && (
                            <div className={classes.resultsHeader}>
                                {!isSearchedByNumber && (
                                    <DateRangeLegend
                                        translationKey="emp-reports-results-range-title"
                                        fromDate={appliedFilters.fromDate}
                                        toDate={appliedFilters.toDate}
                                    />)}
                                <div className={classes.options}>
                                    {TicketsReportFiltersWithParams}
                                    <ColumnChooser
                                        activeColumns={activeColumns}
                                        allColumns={headTicketsReportSelectableColumns.filter(
                                            item => item.hasOwnProperty('permission')
                                                ? globalSettings.settings.TicketsReportSettings?.[item.permission]
                                                : true)}
                                        onHandleClick={handleColumnChoose}
                                    />
                                    {reportTicketsExportPermission &&
                                        <div style={{ marginLeft: '8px' }}>
                                            <ButtonWithLinearProgress
                                                label={translations['emp-transaction-export-to-excel'] as string}
                                                value={valueExportToExcel}
                                                handleLoading={handleLoadingExcel}
                                                loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className={classes.alertWrapper}>
                            <AlertMessageFullWidth
                                label={alertMessage.message}
                                show={alertMessage.show}
                                handleHideAlert={handleHideAlert}
                            >
                                {
                                    translations['gen-dismiss']
                                }
                            </AlertMessageFullWidth>
                        </div>
                        <Box className={classes.resultsWrapper}>
                            {(rows.length && !invalidSearchValue)
                                ? (
                                    <TicketsReportTable
                                        page={page}
                                        isFirstPage={isFirstPage}
                                        isLastPage={isLastPage}
                                        rows={rows}
                                        rowsCount={rowsCount}
                                        hideSorting={isSearchedByNumber}
                                        hidePagination={isSearchedByNumber}
                                        showPaginationInfo={showPaginationInfoRef.current}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        activeColumns={activeColumns}
                                        handleSort={handleSort}
                                        order={isAscendingOrder ? Order.ascending : Order.descending}
                                        orderBy={orderBy}
                                        isMobileView={isMobileView}
                                        handleRedirect={handleRedirect}
                                    />)
                                : (
                                    <Box paddingTop={12}>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <NoResultsFound
                                                    onResetClick={resetToDefault}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default withWidth()(withRouter(TicketsReport));
