import BaseApi from './_BaseApi';
import { IEposListItemModel, IAdditionalData } from 'api/models/epos';
import { EmpEndpoint } from 'api/Endpoint';
import { COMMAND_TYPES, COMMAND_SUBTYPES } from 'const';
import { emulateOptions } from 'utils/emulateOptions';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class EposApi extends BaseApi {
    // Do not remove! Used for testings big amount of terminals
    GetList(): Promise<Array<IEposListItemModel>> {
        let options =
            emulateOptions.useMock
                ? {
                    mockCount: emulateOptions.mockCount
                }
                : {};

        return this.QueryEmpBff(handleCookieToPayload(options, 'showTestAccounts', 'showTestAccounts'), EmpEndpoint.Epos.GetList);
    }

    GetEposAdditionalData(id: string, machineId: string): Promise<IAdditionalData> {
        let options = {
            CustomerId: id,
            MachineId: machineId
        };

        return this.QueryEmpBff(options, EmpEndpoint.Epos.GetEposAdditionalData);
    }

    BulkAction(machineId: Array<string>, commandTypeId: number | string, parameters: Array<string> = []): Promise<boolean> {
        let params = {
            MachineId: machineId,
            CommandTypeId: parseInt(commandTypeId as string),
            Arguments: parameters,
        };

        let path = '';

        switch (commandTypeId) {
            case COMMAND_TYPES.RestartPC:
                path = EmpEndpoint.Command.RestartPC;
                break;

            case COMMAND_TYPES.RestartShell:
                path = EmpEndpoint.Command.RestartShell;
                break;

            case COMMAND_TYPES.RestartPrinter:
                path = EmpEndpoint.Command.RestartPrinter;
                break;

            case COMMAND_TYPES.ReInitBillAcceptor:
                path = EmpEndpoint.Command.ReInitBillAcceptor;
                break;

            case COMMAND_TYPES.EnableMaintenanceMode:
                path = EmpEndpoint.Command.EnableMaintenanceMode;
                break;

            case COMMAND_TYPES.DisableMaintenanceMode:
                path = EmpEndpoint.Command.DisableMaintenanceMode;
                params.CommandTypeId = COMMAND_TYPES.EnableMaintenanceMode;

                if (!parameters) {
                    params.Arguments = [];
                }

                break;

            case COMMAND_TYPES.ShellUpdate:
                path = EmpEndpoint.Command.UpdateShell;
                break;

            case COMMAND_TYPES.ReloadShellSettings:
                path = EmpEndpoint.Command.ReloadShellSettings;
                break;

            case COMMAND_SUBTYPES.SwitchEnviromentProduction:
                path = EmpEndpoint.Command.SwitchToProduction;
                break;

            case COMMAND_SUBTYPES.SwitchEnviromentCanary:
                path = EmpEndpoint.Command.SwitchToCanary;
                break;

            default:
                path = 'undefined';
                break;
        }

        return this.QueryEmpBff(params, path);
    }
}
