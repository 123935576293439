import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IReportingApiBaseRequest,
    IReportCardTransactionModel,
    IPointsOfSaleModel,
    ICardTransactionsFullSearchPayload,
    ICardTransactionsSearchResponse,
    ICardTransactionsSearchByIdPayload,
} from 'api/models/reports';

import {
    ICardTransactionsReportExportPayload,
    IReportsExportModel,
} from 'api/models/reportsExport';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class CardTransactionsReportApi extends BaseApi {
    GetCashiersList(payload: IReportingApiBaseRequest) {
        return this.QueryEmpBff<IPointsOfSaleModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.CardPaymentTransactionsReport.CashiersList, this.QueryMethods.POST);
    }

    GetTransactionsPage(payload: ICardTransactionsFullSearchPayload) {
        return this.QueryEmpBff<ICardTransactionsSearchResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.CardPaymentTransactionsReport.Search, this.QueryMethods.POST);
    }

    GetTransactionsPageByTransactionRRNId(payload: ICardTransactionsSearchByIdPayload) {
        return this.QueryEmpBff<Array<IReportCardTransactionModel>>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.CardPaymentTransactionsReport.SearchByTransactionId,
            this.QueryMethods.POST
        );
    }

    GetExport(payload: ICardTransactionsReportExportPayload) {
        return this.QueryEmpBff<IReportsExportModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.CardTransactionReportExport.GetExport,
            this.QueryMethods.POST
        );
    }
}
