import { defaultTimezoneIANA } from 'const';

import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

export const getTimezoneName: (sTz: string) => string = (sTz) => {
    if (sTz) {
        try {
            Intl.DateTimeFormat('en-GB', { timeZone: `${sTz}` });

            return sTz;
        } catch (e) {
            console.error(`Invalid IANA timezone name!\nRecieved "${sTz}" value, default value "${defaultTimezoneIANA}" is applied.`);

            return defaultTimezoneIANA;
        }
    }
};

export const formatDateByPattern = (date: string | number | Date, formatStr: string, timeZone: string) =>
    // If backend will send us as earlier dateString in local time (YYYY-MM-DDTHH:mm:ss.sss):
    // formatInTimeZone(zonedTimeToUtc(date, timeZone), timeZone, formatStr);
    // or just formatToIsoDateString(dateString) each dateString before Date object creation from it/
    // If backend will send dateString in ISO format in UTC (YYYY-MM-DDTHH:mm:ss.sssZ):
    formatInTimeZone(date, timeZone, formatStr);

const PeriodsShortNames = {
    days: 'd',
    hours: 'h',
    minutes: 'm'
};

function countTimePeriod(timeRange: number, period: number) {
    return Math.trunc(Math.abs(timeRange / period));
}

export const transformDate = (date) => {
    if (date) {
        const DayInMillisec = 86400000;
        const HourInMillisec = 3600000;
        const MinuteInMillisec = 60000;

        const nowDate = Date.now();
        const transformDate = new Date(date).getTime();
        const timeRange = nowDate - transformDate;
        const days = countTimePeriod(timeRange, DayInMillisec);
        const hours = countTimePeriod((timeRange - days * DayInMillisec), HourInMillisec);
        const minutes = days ? 0 : countTimePeriod((timeRange - hours * HourInMillisec), MinuteInMillisec);

        return days
            ? `${days}${PeriodsShortNames.days} ${hours}${PeriodsShortNames.hours}`
            : `${hours}${PeriodsShortNames.hours} ${minutes}${PeriodsShortNames.minutes}`;
    }

    return '';
};

export function lastErrorDate(arr) {
    const nowDate = Date.now();

    return Math.min(...arr.map((e) => nowDate - new Date(e.errorDateTime).getTime()));
}

export const isValid = (date: Date) => date && !isNaN(date.getTime());

export const startOfDay = (date: Date) => {
    const copiedDate = new Date(date.getTime());

    copiedDate.setHours(0, 0, 0, 0);

    return copiedDate;
};

export const endOfDay = (date: Date) => {
    const copiedDate = new Date(date.getTime());

    copiedDate.setHours(23, 59, 59, 999);

    return copiedDate;
};

export const addMinutes = (date: Date, minutes: number) => {
    const copiedDate = new Date(date.getTime());

    copiedDate.setTime(copiedDate.getTime() + minutes*60000);

    return copiedDate;
};

export const addHours = (date: Date, hours: number) => {
    const copiedDate = new Date(date.getTime());

    copiedDate.setTime(copiedDate.getTime() + hours*60*60000);

    return copiedDate;
};

export const pickerDateToUtc = (date: string | number | Date, timeZone: string) => zonedTimeToUtc(date, timeZone);

export const addYears = (date: Date, count: number) => {
    const copiedDate = new Date(date.getTime());

    copiedDate.setFullYear(copiedDate.getFullYear() + count);

    return copiedDate;
};

export const addMonths = (date: Date, months: number) => {
    const copiedDate = new Date(date.getTime());

    const dayOfMonth = copiedDate.getDate();

    copiedDate.setMonth(copiedDate.getMonth() + months);

    if (copiedDate.getDate() != dayOfMonth) {
        copiedDate.setDate(0);
    }

    return copiedDate;
};

export const utcDateFormat = (date: Date) => {
    const offset = date.getTimezoneOffset();

    return addMinutes(date, -offset);
};

export const formatDateFactory = (locale: string | string[], options?: Intl.DateTimeFormatOptions) => {
    let formatter = new Intl.DateTimeFormat(locale, options);

    return (date: Date) => formatter.format(date);
};

export const isValidDate: (date: Date) => boolean = (date) => date instanceof Date && !isNaN(date.getTime());

export const isIsoDateString: (dateString: string) => boolean = (dateString) => {
    const date = new Date(dateString);
    const lastChar = dateString[dateString.length - 1];

    return isValidDate(date) && lastChar === 'Z';
};

export const formatToIsoDateString = (date: string | Date) => (date !== null)
    ? (typeof date === 'string')
        ? `${date}${isIsoDateString(date) ? '' : 'Z'}`
        : date
    : null;

export const getDateRangeString = (fromDateString: string, toDateSring: string, delimiter: string = '-') => {
    if (fromDateString === toDateSring) {
        return fromDateString;
    } else {
        return `${fromDateString} ${delimiter} ${toDateSring}`;
    }
};

const getFirstRowDate = (rows, orderBy) => rows.length ? rows[0]?.[orderBy] : null;
const getLastRowDate = (rows, orderBy) => rows.length ? rows[rows.length - 1]?.[orderBy] : null;

export const getLastDateOnPage = (rows, orderBy: string, prevPageNav?: boolean) => prevPageNav
    ? getFirstRowDate(rows, orderBy)
    : getLastRowDate(rows, orderBy);
