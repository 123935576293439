import { IBetshopNumberInputData } from 'api/models/network-management';

import formatStr from 'utils/formatStr';

export const emailValidate = (email) => {
    const pattern = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return pattern.test(email);
};

export const passwordValidate = (password) => {
    const validReg = /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?!.*[^\x00-\x7F]).{8,}/;

    return validReg.test(password);
};

export const phoneValidate = (phone: string) => {
    const validReg = /^[0-9-+() ]*$/;

    return validReg.test(phone);
};

export interface ICustomErrorMessageKeys {
    required?: string;
    isNumber?: string;
    isInteger?: string;
    min?: string;
}

export const defaultErrorMessageKeys: Required<ICustomErrorMessageKeys> = {
    required: 'gen-valid-msg-empty-field',
    isNumber: 'gen-valid-msg-number-field',
    isInteger: 'gen-valid-msg-integer-field',
    min: 'gen-valid-msg-number-min',
};

export type INumberValidationProps = Pick<IBetshopNumberInputData, 'isInteger' | 'min'>

export function getDetailsFieldErrorData (
    {
        customErrorMessageKeys,
        translations,
    }: {
        customErrorMessageKeys?: ICustomErrorMessageKeys;
        translations: {[key: string]: string};
    },
    value: string | Array<number>,
    required: boolean
) {
    const isNotEmpty = Boolean(
        typeof value === 'string'
            ? value.trim().length
            : value.length
    );

    const error = !isNotEmpty && required;
    const errorMessageKey = error
        ? (customErrorMessageKeys?.required ?? defaultErrorMessageKeys.required)
        : '';

    return { error, errorMessage: translations[errorMessageKey] };
}

export function getNumberFieldErrorData(
    {
        customErrorMessageKeys,
        translations,
    }: {
        customErrorMessageKeys?: ICustomErrorMessageKeys;
        translations: {[key: string]: string};
    },
    value: string,
    required: boolean,
    { isInteger = false, min }: INumberValidationProps = {},
) {
    const isNotEmpty = Boolean(value.length);
    const isValidNumber = isNotEmpty && !isNaN(Number(value));
    const isValidInteger = isValidNumber && !Boolean(Number(value) % 1);
    const minIsDefined = (typeof min === 'number');
    const isValidMin = minIsDefined && isValidNumber && Number(value) >= min;

    let errorMessage = '';

    if (!isValidMin && minIsDefined) {
        errorMessage = formatStr(
            translations[customErrorMessageKeys?.min ?? defaultErrorMessageKeys.min],
            { min: `${min}` }
        );
    }

    if (!isValidInteger && isInteger) {
        errorMessage = translations[customErrorMessageKeys?.isInteger ?? defaultErrorMessageKeys.isInteger];
    }

    if (!isValidNumber) {
        errorMessage = translations[customErrorMessageKeys?.isNumber ?? defaultErrorMessageKeys.isNumber];
    }

    if (!isNotEmpty && required) {
        errorMessage = translations[customErrorMessageKeys?.required ?? defaultErrorMessageKeys.required];
    }

    const error = !isValidMin && minIsDefined || !isValidInteger && isInteger || !isValidNumber || !isNotEmpty && required;

    return { error, errorMessage };
}
