import * as React from 'react';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.05),
            },
            marginLeft: 0,
            width: '100%',
        },
        searchIcon: {
            width: theme.spacing(4),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            width: '100%',
            padding: theme.spacing(0.8, 0.5, 0.8, 4),
            fontSize: '0.875rem',
        },
        searchButton: {
            width: theme.spacing(4),
            paddingLeft: 0,
            pointerEvents: 'all'
        },
        closeButton: {
            zIndex: 1,
            right: `-${theme.spacing(1)}px`,
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer'
        }
    }),
);

export default function SearchField(
    props: {
        onValueChange: (value: string) => void;
        widthCloseButton?: boolean;
        handleClose?: React.MouseEventHandler;
        value: string;
    }) {
    const classes = useStyles({});
    const { onValueChange, handleClose, widthCloseButton = true, value = '' } = props;
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <IconButton classes={{ root: classes.searchButton }} size="small" className={classes.searchIcon}>
                    <SearchIcon />
                </IconButton>
            </div>
            <InputBase
                placeholder={translations['hm-search']}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                autoFocus
                inputProps={{ 'aria-label': 'search', 'data-a': 'search-field' }}
                onChange={(event) => onValueChange(event.target.value)}
                value={value}
            />
            {widthCloseButton &&
                <div className={clsx(classes.searchIcon, classes.closeButton)} onClick={handleClose}>
                    <IconButton
                        classes={{ root: classes.searchButton }}
                        size="small"
                        className={classes.closeButton}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            }
        </div>
    );
}
