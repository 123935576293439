import * as React from 'react';
import { Button, Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
    show: boolean;
    label: string;
    type?: 'error'
        | 'info'
        | 'success'
        | 'warning';
    children: React.ReactNode;
    handleHideAlert: Function;
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%'
    },
}));

const AlertMessageFullWidth = ({ show, label, type='error', children, handleHideAlert }: IProps) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(show);

    React.useEffect(() => {
        setOpen(show);
    }, [show]);

    const handleClick = () => {
        handleHideAlert();
    };

    return (
        <div className={classes.root} data-a="alert-message">
            <Collapse in={open}>
                <Alert
                    severity={type}
                    action={
                        <Button color="inherit" size="small" onClick={handleClick} data-a="alert-message-button">
                            {children}
                        </Button>
                    }
                >
                    {label}
                </Alert>
            </Collapse>
        </div>
    );
};

export default AlertMessageFullWidth;
