import { KPI_TYPES } from 'const';
import { formatNumberToCurrencyString } from 'utils/formatNumber';

export const dashboardValue = (kpiType: string, value: number | string, numberStyles, currencySign, currencyFormat) => {
    if (kpiType === KPI_TYPES.SportGGR || kpiType === KPI_TYPES.SportTurnover) {
        return formatNumberToCurrencyString(value, numberStyles, currencySign, currencyFormat);
    }

    if (kpiType === KPI_TYPES.Margin) {
        return `${value}%`;
    }

    return value;
};
