import React, { useContext } from 'react';
import clsx from 'clsx';
import { useClasses, useStyles } from './styles';
import { Chip } from '@material-ui/core';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

function TestLabel({
    type = 'short',
    className,
}: {
    className?: string;
    type?: 'short' | 'long';
}) {
    const classes = useClasses({});
    const styles = useStyles({});
    const { translations }: GlobalContextModel = useContext(GlobalContext);
    const isLong = type === 'long';
    const label = isLong ? translations['gen-test'] : 'T';

    return (
        <Chip
            className={
                clsx(
                    className,
                    isLong ? styles.rootLong : styles.rootShort)
            }
            classes={classes}
            label={label}
            size="small"
            component={'span'}
        />
    );
}

export default TestLabel;
