import * as React from 'react';
import clsx from 'clsx';

import { Divider, Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { palette } from 'theme';
import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

interface IComponentProps {
    errors: number;
    normal: number;
    offline: number;
    all: number;
    pendingMaintenance: number;
    maintenance: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrap: {
            padding: `0 ${theme.spacing(6)}px`,

            [theme.breakpoints.down('md')]: {
                padding: `0 ${theme.spacing(2)}px`,
            },
            [theme.breakpoints.down('xs')]: {
                padding: `0 ${theme.spacing(1.5)}px`,
            }
        },
        statusBar: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${theme.spacing(2)}px 0`,
            [theme.breakpoints.down('xs')]: {
                padding: `${theme.spacing(1)}px 0`
            }
        },
        button: {
            width: '30%',
            minWidth: 'auto',
            height: theme.spacing(1.5),
            border: 'none',
            borderRadius: '4px',
            marginLeft: '3px',
            marginRight: '3px',
            cursor: 'pointer',
            outline: 'none',
            '&:first-child': {
                marginLeft: '0'
            },
            '&:last-child': {
                marginRight: '0'
            },
            [theme.breakpoints.down('xs')]: {
                height: theme.spacing(1)
            }
        },
        warningButton: (props: IComponentProps) => ({
            width: `${(props.errors / props.all) * 100}%`,
            backgroundColor: palette.error.main,
            '&:hover': {
                backgroundColor: 'rgba(224, 78, 80, 0.7)'
            }
        }),
        onlineButton: (props: IComponentProps) => ({
            width: `${(props.normal / props.all) * 100}%`,
            backgroundColor: palette.secondary.main,
            '&:hover': {
                backgroundColor: palette.secondary.light
            }
        }),
        offlineButton: (props: IComponentProps) => ({
            width: `${(props.offline / props.all) * 100}%`,
            backgroundColor: palette.text.secondary,
            '&:hover': {
                backgroundColor: 'grey'
            }
        }),
        maintenanceButton: (props: IComponentProps) => ({
            width: `${(props.maintenance / props.all) * 100}%`,
            backgroundColor: theme.palette.grey[400],
            '&:hover': {
                backgroundColor: theme.palette.grey[300]
            }
        }),
        pendingMaintenanceButton: (props: IComponentProps) => ({
            width: `${(props.pendingMaintenance / props.all) * 100}%`,
            backgroundColor: '#B88A00',
            '&:hover': {
                backgroundColor: '#c0a041'
            }
        }),
        tab: {
            display: 'flex',
            position: 'relative',
            minWidth: 'auto',
            minHeight: '40px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: '4px',
            '& > span': {
                textTransform: 'none',
            },
            '&::before': {
                content: '\'\'',
                width: '9px',
                height: '8px',
                marginRight: '8px',
                borderRadius: '2px'
            },
            '&:hover': {
                color: palette.primary.main,
                backgroundColor: palette.primary.light,
                borderRadius: '4px 4px 0 0'
            },

            [theme.breakpoints.down('xs')]: {
                minHeight: '30px',
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1)
            }
        },
        allTab: {
            '&::before': {
                display: 'none'
            }
        },
        warningTab: {
            '&::before': {
                backgroundColor: palette.error.main
            }
        },
        onlineTab: {
            '&::before': {
                backgroundColor: palette.secondary.main
            }
        },
        offlineTab: {
            '&::before': {
                backgroundColor: palette.text.secondary
            }
        },
        maintenance: {
            '&::before': {
                backgroundColor: theme.palette.grey[400]
            }
        },
        pendingMaintenance: {
            '&::before': {
                backgroundColor: '#B88A00'
            }
        }
    })
);

export enum eposStatuses {
    all = 0,
    errors = 1,
    normal = 2,
    offline = 3,
    maintenance = 4,
    pendingMaintenance = 5,
}

type eposStatusesProps = { [key in eposStatuses]: eposStatuses };

const StatusBar = (props: { eposStatusesCount: eposStatusesProps; activeStatus: number; onStatusBarFiltering(num): void }) => {
    const eposStatusesCount = props.eposStatusesCount;
    const all = eposStatusesCount[eposStatuses.all];
    const errors = eposStatusesCount[eposStatuses.errors];
    const normal = eposStatusesCount[eposStatuses.normal];
    const offline = eposStatusesCount[eposStatuses.offline];
    const maintenance = eposStatusesCount[eposStatuses.maintenance];
    const pendingMaintenance = eposStatusesCount[eposStatuses.pendingMaintenance];

    const classes = useStyles({ maintenance, errors, normal, offline, pendingMaintenance, all });
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    function onStatusBarClick(id: number | string) {
        props.onStatusBarFiltering(id);
    }

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        props.onStatusBarFiltering(newValue);
    }

    return (
        <>
            {all
                ? (
                    <div className={classes.wrap}>
                        <div className={classes.statusBar}>
                            {errors
                                ? (
                                    <button
                                        className={clsx(classes.button, classes.warningButton)}
                                        onClick={() => onStatusBarClick(eposStatuses.errors)}
                                    />)
                                : null
                            }
                            {normal
                                ? (
                                    <button
                                        className={clsx(classes.button, classes.onlineButton)}
                                        onClick={() => onStatusBarClick(eposStatuses.normal)}
                                    />)
                                : null
                            }
                            {offline
                                ? (
                                    <button
                                        className={clsx(classes.button, classes.offlineButton)}
                                        onClick={() => onStatusBarClick(eposStatuses.offline)}
                                    />)
                                : null
                            }
                            {maintenance
                                ? (
                                    <button
                                        className={clsx(classes.button, classes.maintenanceButton)}
                                        onClick={() => onStatusBarClick(eposStatuses.maintenance)}
                                    />)
                                : null
                            }
                            {pendingMaintenance
                                ? (
                                    <button
                                        className={clsx(classes.button, classes.pendingMaintenanceButton)}
                                        onClick={() => onStatusBarClick(eposStatuses.pendingMaintenance)}
                                    />)
                                : null
                            }
                        </div>
                        <div>
                            <Tabs
                                value={props.activeStatus}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="off"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab className={clsx(classes.tab, classes.allTab)} label={`${translations['hm-filter-all']} ${all}`} />
                                {errors &&
                                    <Tab
                                        className={clsx(classes.tab, classes.warningTab)}
                                        label={`${translations['hm-filter-warning']} ${errors}`}
                                        value={eposStatuses.errors}
                                    />}
                                {normal &&
                                    <Tab
                                        className={clsx(classes.tab, classes.onlineTab)}
                                        label={`${translations['hm-filter-normal']} ${normal}`}
                                        value={eposStatuses.normal}
                                    />}
                                {offline &&
                                    <Tab
                                        className={clsx(classes.tab, classes.offlineTab)}
                                        label={`${translations['hm-filter-offline']} ${offline}`}
                                        value={eposStatuses.offline}
                                    />}
                                {maintenance &&
                                    <Tab
                                        className={clsx(classes.tab, classes.maintenance)}
                                        label={`${translations['hm-filter-under-maintenance']} ${maintenance}`}
                                        value={eposStatuses.maintenance}
                                    />}
                                {pendingMaintenance &&
                                    <Tab
                                        className={clsx(classes.tab, classes.pendingMaintenance)}
                                        label={`${translations['hm-filter-pending-maintenance']} ${pendingMaintenance}`}
                                        value={eposStatuses.pendingMaintenance}
                                    />}
                            </Tabs>
                        </div>
                    </div>)
                : null}
            <Divider />
        </>
    );
};

export default StatusBar;
