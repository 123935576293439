import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IReportingApiBaseRequest,
    IReportSupervisorModel,
    ISupervisorsListModel,
    ISupervisorApprovalsFullSearchPayload,
    ISupervisorApprovalsSearchResponse,
    ISupervisorApprovalsSearchByIdPayload,
} from 'api/models/reports';

import {
    ISupervisorReportExportPayload,
    IReportsExportModel
} from 'api/models/reportsExport';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class SupervisorReportApi extends BaseApi {
    GetSupervisorsList(payload: IReportingApiBaseRequest) {
        return this.QueryEmpBff<ISupervisorsListModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.SupervisorAuditReport.UsersList, this.QueryMethods.POST);
    }

    GetApprovalsPage(payload: ISupervisorApprovalsFullSearchPayload) {
        return this.QueryEmpBff<ISupervisorApprovalsSearchResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.SupervisorAuditReport.Search, this.QueryMethods.POST);
    }

    GetApprovalsPageByDocumentId(payload: ISupervisorApprovalsSearchByIdPayload) {
        return this.QueryEmpBff<Array<IReportSupervisorModel>>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.SupervisorAuditReport.SearchByDocumentId, this.QueryMethods.POST);
    }

    GetExport(payload: ISupervisorReportExportPayload) {
        return this.QueryEmpBff<IReportsExportModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.SupervisorReportExport.GetExport,
            this.QueryMethods.POST
        );
    }
}
