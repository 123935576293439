import { isSearchValueAcceptable } from 'components/SearchField/business';
import { AUDIT_ACTION_KEYS } from 'const';

export const getActionTypeTranslations = (translations) => Object.keys(AUDIT_ACTION_KEYS)
    .reduce((actionTypeTranslations, type) => {
        actionTypeTranslations[type] = translations[AUDIT_ACTION_KEYS[type]];

        return actionTypeTranslations;
    }, {}
    );

export const getLogActionTitle = (log, translations) => (
    translations[log.actionTypeId] +
        (log.description
            ? ` - ${log.description}`
            : ''
        )
);

export const filterBySearch = (data, text) => {
    return data.filter(({ userName, action }) => {
        return isSearchValueAcceptable([userName, action], text);
    });
};

export const sortByDate = (a, b) => {
    return b.actionDateTime.localeCompare(a.actionDateTime);
};
