import React from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DataItemsWithChipPopover, { DataItem } from 'components/DataItemsWithChipPopover/DataItemsWithChipPopover';

import clsx from 'clsx';
import useStyles from './styles';

export default function DataItemsCellContent ({
    className = '',
    data,
    id = '',
    isMobileView = false,
    accordionTitle = '',
    handleRedirect,
    url = '',
}: {
    className?: string;
    data: Array<string>;
    id: string;
    isMobileView?: boolean;
    accordionTitle: string;
    handleRedirect?: (id: string) => void;
    url?: string;
}) {
    const classes = useStyles();

    return data.length > 0 && (isMobileView
        ? (
            <Accordion className={clsx(className, classes.accordion)}
                elevation={0}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`approval-${id}-tickets`}
                    id={`approval-${id}-tickets`}
                >
                    <Typography variant="subtitle2">
                        {accordionTitle}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    {data.map((dataItem) => (
                        <Typography className={classes.dataItem} key={dataItem}
                            variant="body2"
                        >
                            <DataItem className={classes.dataLink}
                                dataItem={dataItem}
                                url={url}
                                handleRedirect={handleRedirect}
                            />
                        </Typography>
                    ))}
                </AccordionDetails>
            </Accordion>
        )
        : (
            <DataItemsWithChipPopover className={className}
                data={data}
                hoverable={false}
                hideFixedItemsIfLimitExceeded
                url={url}
                handleRedirect={handleRedirect}
            />
        )
    );
}
