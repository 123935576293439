import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ColumnChooserButton from './ColumnChooserButton/ColumnChooserButton';

import { IHeadRowBase, IHeadRowTicketsReport, IHeadRowVouchersReport } from 'data';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { palette } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        columnChooser: {
            display: 'flex',
            marginLeft: theme.spacing(1),

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        }
    }),
);

export default function ColumnChooser (props: {
    readonly disabled?: boolean;
    readonly activeColumns: Array<IHeadRowBase> | Array<IHeadRowTicketsReport> | Array<IHeadRowVouchersReport>;
    readonly allColumns: Array<IHeadRowBase> | Array<IHeadRowTicketsReport> | Array<IHeadRowVouchersReport>;
    readonly onHandleClick: (event, id: string) => void;
}) {
    const classes = useStyles({});
    const { disabled = false, activeColumns, allColumns, onHandleClick } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function isChecked(id: string): boolean {
        return activeColumns.some((e) => e.id === id);
    }

    return (
        <div className={classes.columnChooser}>
            <ColumnChooserButton aria-controls="simple-menu" aria-haspopup="true" handleClick={handleClick} disabled={disabled} />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={palette.elevation}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div data-a="column-chooser">
                    {allColumns.map((item, i) => {
                        const show = item.hasOwnProperty('permission') ? item.permission : true;

                        return (show &&
                            <MenuItem
                                key={i}
                                onClick={(event) => {
                                    event.preventDefault();
                                    onHandleClick(event, item.id);
                                }}
                                data-a={`column-chooser-item-${item.id}`}
                            >
                                <FormControlLabel
                                    value={item.id}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onClick={(event) => event.preventDefault()}
                                            checked={isChecked(item.id)}
                                        />
                                    }
                                    label={translations[item.label]}
                                />
                            </MenuItem>
                        );
                    })}
                </div>
            </Menu>
        </div>
    );
}
