import { makeStyles } from '@material-ui/core';

export const useClasses = makeStyles((theme) => ({
    root: {
        height: theme.spacing(2),
    },
    label: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
}));

export const useStyles = makeStyles((theme) => ({
    rootShort: {
        width: theme.spacing(2),
        '& .MuiChip-label': {
            fontSize: '0.5rem',
            overflow: 'inherit',
        }
    },
    rootLong: {
        '& .MuiChip-label': {
            fontSize: '0.75rem',
        }
    },
}));
