import * as React from 'react';
import clsx from 'clsx';

import { Button, Icon } from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { ERROR_TYPES } from 'const';

import useStyles from './styles';

const ErrorsMainButton = (props) => {
    const classes = useStyles({});
    const { isOpen, handleIsOpen, selectedErrors } = props;
    const selectedErrorsArr = Object.keys(selectedErrors).filter(e => selectedErrors[e]);
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <div className={classes.main} onClick={handleIsOpen}>
            <Button
                className={clsx(classes.button, classes.mainButtonData, selectedErrorsArr.length && classes.active)}
                variant="outlined"
                component="span"
            >
                {
                    selectedErrorsArr.length === 1
                        ? (
                            <span className={classes.filteredButton}>
                                <Icon color={'error'} className={classes.mobileTitleIcon} >{ERROR_TYPES[selectedErrorsArr[0]].icon}</Icon>
                                <span>{translations[ERROR_TYPES[selectedErrorsArr[0]].key]}</span>
                            </span>)
                        : selectedErrorsArr.length > 1
                            ? `${selectedErrorsArr.length} ${translations['hm-table-warnings-selected']}`
                            : `${translations['hm-table-filter-warnings']}`
                }
            </Button>
            <Button
                className={clsx(classes.toggleButton, selectedErrorsArr.length && classes.active)}
                variant="outlined"
                component="span"
            >
                {isOpen
                    ? <ArrowDropUpIcon />
                    : <ArrowDropDownIcon />
                }
            </Button>
        </div>
    );
};

export default ErrorsMainButton;
