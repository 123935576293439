import * as React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import Widget from './components/Widget/Widget';
import Chart from './components/Chart/Chart';

import { GRID_COLUMNS as columns } from 'const';

import { IChartData } from './components/Chart/types';
import { ITileData } from './components/Widget/types';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { KpiContext } from 'context/kpi';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
    widgetsContainer: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
            paddingTop: '0.7rem'
        }
    },
    widgetWrap: {
        '&.MuiGrid-root': {
            [theme.breakpoints.down('xs')]: {
                padding: '0px'
            }
        }
    },
    title: {
        margin: `${theme.spacing(1)}px 0`,
        fontSize: '1.5rem',
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            fontSize: '2em',
            fontWeight: 500
        }
    }
}));

const Dashboard = () => {
    const classes = useStyles({});
    const dashboardData = React.useContext(KpiContext) || [];
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const spacing = 3;
    const hasData = Boolean(dashboardData?.chartModels?.length || dashboardData?.tileModels?.length);

    return (
        <Box className={classes.root}>
            <BackdropSpinner open={!hasData} />
            <h1 className={classes.title}>
                {translations['gen-dashboard']}
            </h1>
            <Grid className={classes.widgetsContainer} container spacing={spacing} >
                {hasData && (dashboardData.tileModels.map((tileData: ITileData, key: number) => {
                    return (
                        <Grid
                            className={classes.widgetWrap}
                            key={`tile${key}`}
                            item lg={columns.col3}
                            xs={columns.col6}
                            sm={columns.col6}
                        >
                            <Widget data={tileData} parentSelector={classes.root} />
                        </Grid>
                    );
                }))}
            </Grid>
            <Grid container spacing={spacing} >
                {hasData && (dashboardData.chartModels.map((chartData: IChartData, key: number) => {
                    return (
                        <Grid item key={`chart${key}`} lg={columns.col6} xs={columns.col12} sm={columns.col12}>
                            <Chart data={chartData} />
                        </Grid>
                    );
                }))}
            </Grid>
        </Box>
    );
};

export default Dashboard;
