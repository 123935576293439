import { OPERATORS_SPECIFIC_USER_KEYS, IFilterListItem } from 'const';

import { GeneralResponseResult } from 'api/models/general';
import { INetworkLocationStateBase } from 'api/models/network-management';

export interface IUserList {
    id: string;
    email: string;
    emailHash: number;
    firstName: string;
    lastName: string;
    userName: string;
    position?: string;
    enabled: boolean;
    userTypes: Array<number>;
    isInternal: boolean;
    passwordUpdateDate: string;
}

export enum USER_TYPES {
    emp = 0,
    supervisor = 1,
    cashier = 2
}

export const USER_FILTERS_TITLES = {
    userTypes: 'users-user-types',
    dublicateEmails: 'users-dublicate-emails'
};

export const USER_FILTERS = {
    userTypes: 'userTypes',
    dublicateEmails: 'dublicateEmails'
};
export interface IUserSearchModel {
    firstName: string;
    lastName: string;
    userName: string;
}

export interface IFilterDublicateEmailItem {
    email: string;
    emailHash: string;
    count: number;
}
export interface IFilterList {
    userTypes: Array<IFilterListItem>;
    dublicateEmails: Array<IFilterDublicateEmailItem>;
}
export interface IUserLocationState extends INetworkLocationStateBase {
    usersPerPage: number;
    firstName: string;
    lastName: string;
    userName: string;
    position: string;
    enabled: boolean;
    isInternal: boolean;
    filterList: IFilterList;
}

export enum UserAddFields {
    firstName = 'firstName',
    lastName = 'lastName',
    userName = 'userName',
    position = 'position',
    email = 'email',
}

export interface IUserAdd {
    id: number;
    name: keyof typeof UserAddFields;
    label: string;
    helperTextKey?: string;
    required: boolean;
    disabled?: boolean;
    maxLength: number;
    type?: string;
    fullSize?: boolean;
}

export interface IUserPayload {
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    position: string;
    isTwoFactorEnabled: boolean;
    operatorSpecificProperties: IOperatorSpecificProperties;
}

export interface IEditUserPayload {
    id: string;
    firstName: string;
    lastName: string;
    position: string;
    email?: string;
    isTwoFactorEnabled: boolean;
    operatorSpecificProperties: IOperatorSpecificProperties;
}

export interface IResetCredentialPayload {
    id: string;
}
export interface IBlockUserPayload {
    id: string;
    enabled: boolean;
}

export interface IUserIdPayload {
    id: string;
}

const emp: unique symbol = Symbol(OPERATORS_SPECIFIC_USER_KEYS.emp);
const supervisor: unique symbol = Symbol(OPERATORS_SPECIFIC_USER_KEYS.supervisor);
const cashier: unique symbol = Symbol(OPERATORS_SPECIFIC_USER_KEYS.cashier);

interface ISpecificPropertiesPerOperator {
    [emp]: {
        roleId: string;
        roleName: string;
        betshops: Array<number>;
    };
    [supervisor]: {
        betshops: Array<number>;
    };
    [cashier]: {
        roleId: string;
        betshopId: number | null;
        hideShopData: boolean;
    };
}

export interface IOperatorSpecificProperties {
    [key: string]: ISpecificPropertiesPerOperator;
}

export interface IUserDetails extends GeneralResponseResult {
    id: string;
    enabled: boolean;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    position?: string;
    isTwoFactorEnabled: boolean;
    operatorSpecificProperties: IOperatorSpecificProperties;
    isInternal: boolean;
    passwordUpdateDate: string;
}

export interface IUserDownloadBarcode {
    barcode: string;
}

export interface ICheckUserPermission {
    data: Array<string>;
    status: number;
    message: string;
}

export interface ICheckUserParams {
    permissions: Array<string>;
}
