import React from 'react';import {
    Box,
    Grid,
    Typography,
    withWidth,
} from '@material-ui/core';

import ThresholdInput, { IThresholdInputProps } from 'components/ThresholdInput/ThresholdInput';

export interface ISupervisorThresholdProps {
    className?: string;
    title?: string;
    tieWord?: string;
    thresholdInputsProps: Array<IThresholdInputProps>;
    width: string;
}

const SupervisorThreshold: React.FunctionComponent<ISupervisorThresholdProps> = ({
    className = '',
    title = '',
    tieWord = '',
    thresholdInputsProps,
    width,
}) => {
    const isMobileView = width === 'xs';

    return (
        <Box className={className}
            mb={1.5}
        >
            <Grid
                container
                spacing={3}
            >
                {title.length
                    ? (
                        <Grid item xs={12}>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                    ) : null
                }
                {thresholdInputsProps.map((props, index) => (
                    <React.Fragment key={props.name}>
                        {tieWord.length && index !== 0
                            ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    mt={isMobileView ? 0 : 3.5}
                                    mx="auto"
                                >
                                    <Typography>{tieWord}</Typography>
                                </Box>
                            )
                            : null
                        }
                        <Grid item xs={12} sm>
                            <ThresholdInput {...props} fullWidth />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

export default withWidth()(SupervisorThreshold);
