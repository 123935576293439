import React, { useEffect, useState } from 'react';

import SearchFieldWithButton from 'components/SearchField/SearchFieldWithButton';

export default function SearchByNumber({
    initValue = '',
    searchResetKey,
    onReset = () => {},
    onSubmit = () => {},
    placeholder,
    searchValidator = () => true,
    maxSymbols = 999
}: {
    initValue?: string;
    searchResetKey: number;
    onReset?(): void;
    onSubmit?(value: string): void;
    placeholder?: string;
    searchValidator?: (value: string) => boolean;
    maxSymbols?: number;
}) {
    const [inputValue, setInputValue] = useState<string>(initValue);

    useEffect(() => {
        setInputValue(initValue);
    }, [initValue, searchResetKey]);

    const changeHandler = (value: string) => {
        setInputValue(value.replace(/\D/g, ''));
    };

    return (
        <SearchFieldWithButton
            onSubmit={onSubmit}
            onChange={changeHandler}
            onReset={onReset}
            value={inputValue}
            placeholder={placeholder}
            isValid={searchValidator(inputValue)}
            maxSymbols={maxSymbols}
        />
    );
}
