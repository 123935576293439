import * as React from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { Box, IconButton, Grid, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import useStyles from './styles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

interface IVoucherDetailsHeader {
    voucherId: number;
    isCMV: boolean;
}

const VoucherDetailsHeader = ({
    history,
    data
}: {
        history: RouteComponentProps['history'];
        data: IVoucherDetailsHeader;
    }) => {
    const { state } = useLocation();
    const { voucherId, isCMV } = data;
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const fromSupervisorReport = state && state['pathFrom'] === '/reports/supervisor';

    const backNavigationClickHandler = () => {
        if (fromSupervisorReport) {
            history.goBack();
        } else {
            history.push({ pathname: '/reports/vouchers', state });
        }
    };

    return (
        <Grid container className={classes.bodyWrapContainer}>
            <Grid item>
                <IconButton
                    className={classes.iconButton}
                    onClick={backNavigationClickHandler}
                    edge="start"
                    color="inherit"
                    size="small"
                    disableRipple
                    data-a="ticket-back-nav-button"
                >
                    <ArrowBack htmlColor="black" />
                    &nbsp;
                    <Typography variant="button" className={classes.iconLabel}>
                        {translations[
                            fromSupervisorReport
                                ? 'gen-supervisor-report-title'
                                : 'emp-reports-vouchers-details-report']
                        }
                    </Typography>
                </IconButton>
                <Box display="flex" alignItems="center">
                    <Typography variant="h5" component="h5" className={classes.title} data-a="voucher-id-label">{`#${voucherId}`}</Typography>
                    {isCMV &&
                        <Typography className={classes.badge}
                            component="span"
                            variant="subtitle2"
                            data-a="voucher-cmv"
                        >
                            {translations['emp-reports-vouchers-cmv']}
                        </Typography>
                    }
                </Box>

            </Grid>
        </Grid>
    );
};

export default VoucherDetailsHeader;
