import * as React from 'react';
import * as SignalR from '@microsoft/signalr';
import Api from 'api/Api';
import { IEposListItemModel } from 'api/models/epos';
import { IFilterList, filterListInitial, EPOS_PUSH_RESTART_TIMEOUT, EPOS_PUSH_THROTTLING_INTERVAL } from 'const';

import { GlobalContext } from 'context/globalContext';
import { GlobalContextModel } from 'api/models/general';

import { IInitialSettingsModel } from 'api/models/general';

declare const initialSettings: IInitialSettingsModel;

interface IState {
    data: Array<IEposListItemModel>;
    eposList: Array<IEposListItemModel>;
    terminalImages: {
        [key: string]: Array<string> | string;
    };
    filtersList: IFilterList;
    isLoaded: boolean;
}

const initialState: IState = {
    data: [],
    eposList: [],
    terminalImages: {},
    filtersList: filterListInitial,
    isLoaded: false
};

let eposState: IState, setEposState;

const getFilters = (eposList: Array<IEposListItemModel>) => {
    const filterList = {
        location: [],
        os: [],
        shell: [],
        monitoringAgentVersion: [],
        dataSetVersion: [],
        updateStatus: [],
        environmentType: [],
    };

    const uniqLocationArr = [];
    const uniqOsArr = [];
    const uniqShellArr = [];
    const uniqMonitoringAgentVersionArr = [];
    const uniqDataSetVersionArr = [];
    const uniqUpdateStatusArr = [];
    const uniqEnvironmentTypeArr = [];

    eposList?.forEach((epos) => {
        if (epos.location && !uniqLocationArr.includes(epos.location)) {
            filterList.location.push({ id: epos.location, name: epos.location });
            uniqLocationArr.push(epos.location);
        }

        if (epos.shell && !uniqShellArr.includes(epos.shell)) {
            filterList.shell.push({ id: epos.shell, name: epos.shell });
            uniqShellArr.push(epos.shell);
        }

        if (epos.os && !uniqOsArr.includes(epos.os)) {
            filterList.os.push({ id: epos.os, name: epos.os });
            uniqOsArr.push(epos.os);
        }

        if (epos.dataSetVersion && !uniqDataSetVersionArr.includes(epos.dataSetVersion)) {
            filterList.dataSetVersion.push({ id: epos.dataSetVersion, name: epos.dataSetVersion });
            uniqDataSetVersionArr.push(epos.dataSetVersion);
        }

        if (epos.monitoringAgentVersion && !uniqMonitoringAgentVersionArr.includes(epos.monitoringAgentVersion)) {
            filterList.monitoringAgentVersion.push({ id: epos.monitoringAgentVersion, name: epos.monitoringAgentVersion });
            uniqMonitoringAgentVersionArr.push(epos.monitoringAgentVersion);
        }

        if (epos.updateStatus && !uniqUpdateStatusArr.includes(epos.updateStatus)) {
            filterList.updateStatus.push({ id: epos.updateStatus, name: epos.updateStatus });
            uniqUpdateStatusArr.push(epos.updateStatus);
        }

        if (epos.environmentType && !uniqEnvironmentTypeArr.includes(epos.environmentType)) {
            filterList.environmentType.push({ id: epos.environmentType, name: epos.environmentType });
            uniqEnvironmentTypeArr.push(epos.environmentType);
        }

    });

    return filterList;
};

const loadData = async () => {
    const eposList = await Api.Epos.GetList();
    const filtersList = getFilters(eposList);

    setEposState({
        ...eposState,
        eposList,
        filtersList,
        isLoaded: true
    });
};

export { loadData as loadEposData };

const startPush = async (eposListIsUpToDate) => {
    let throttleTimer = null;

    let restartPush = () => {
        if (throttleTimer) {
            clearInterval(throttleTimer);
        }

        setTimeout(startPush, EPOS_PUSH_RESTART_TIMEOUT);
    };

    try {
        if (!eposListIsUpToDate) {
            await loadData();
            await startPush(true);
        } else {
            let throttle = () => {
                let eposListDiff: Array<IEposListItemModel> = [];

                throttleTimer = setInterval(() => {
                    if (eposListDiff.length) {
                        let newEposList = [...eposState.eposList];

                        eposListDiff.map(epos => {
                            let eposIndexToUpdate = newEposList.findIndex(element => element.id === epos.id && element.machineId === epos.machineId);

                            if (eposIndexToUpdate >= 0) {
                                newEposList[eposIndexToUpdate] = epos;
                            }
                        });

                        const filtersList = getFilters(newEposList);

                        setEposState({
                            isLoaded: true,
                            filtersList,
                            eposList: newEposList
                        });

                        eposListDiff = [];
                    }
                }, EPOS_PUSH_THROTTLING_INTERVAL);

                let handler = (epos: IEposListItemModel) => {
                    eposListDiff.push(epos);
                };

                return handler;
            };

            let connection = new SignalR.HubConnectionBuilder().withUrl(
                `${initialSettings.EmpBffUrl}/hub/epos`, {
                    withCredentials: false,
                    skipNegotiation: true,
                    transport: SignalR.HttpTransportType.WebSockets
                }
            ).build();

            connection.on('EposUpdate', throttle());
            connection.onclose(restartPush);

            await connection.start();
        }
    } catch {
        restartPush();
    }
};

export const getEposById = (id: number): IEposListItemModel => eposState.eposList.find((epos) => epos.id === id);

export const EposContext = React.createContext(initialState);

export const EposContextProvider = ({ children }) => {
    const { globalSettings }: GlobalContextModel = React.useContext(GlobalContext);

    [eposState, setEposState] = React.useState(initialState);

    React.useEffect(() => {
        if (globalSettings?.user?.Permissions?.includes('health-monitor:view')) {
            loadData().then(async () => {
                await startPush(true);
            });
        }
    }, [globalSettings]);

    return (
        <EposContext.Provider value={eposState}>
            {children}
        </EposContext.Provider>
    );
};

export { eposState, setEposState };
