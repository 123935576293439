import BaseApi from './_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import { AuditLog, AuditLogResponse } from 'api/models/auditlog';

export default class AuditLogApi extends BaseApi {
    GetLogs(fromDate: Date, toDate: Date, actionTypes: Array<number>): Promise<AuditLogResponse> {
        const options = {
            fromDate,
            toDate,
            actionTypes
        };

        return this.QueryEmpBff(options, EmpEndpoint.AuditLog.GetList);
    }

    SaveLog(auditLog: AuditLog): Promise<Object> {
        return this.QueryEmpBff(auditLog, EmpEndpoint.AuditLog.SaveLog);
    }
}
