import React, { useState, useEffect, useRef, useContext } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
    Box,
    Checkbox,
    IconButton,
    MenuItem,
    Typography,
    Accordion,
    AccordionDetails,
    FormControlLabel
} from '@material-ui/core';

import { WindowScroller, List } from 'react-virtualized';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

import SearchField from 'components/SearchField/SearchField';
import ShowMore from 'components/ShowMore/ShowMore';
import StyledAccordionSummary from '../StyledAccordionSummary/StyledAccordionSummary';

import { IFilterListItem } from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { sortingByClicked } from 'pages/Epos/utils/utils';
import { sortingByInitialCheckedWithSearch } from 'components/Modal/utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '1rem'
        },
        accordion: {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            '&.Mui-expanded': {
                margin: 0
            },
            boxShadow: 'none',
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: '0 auto',
            },
        },
        accordionSummary: {
            marginLeft: `-${theme.spacing(0.5)}px`,
            '&.Mui-focused': {
                backgroundColor: theme.palette.common.white
            }
        },
        accordionDetails: {
            display: 'flex',
            flexDirection: 'column',
            padding: `0 0 ${theme.spacing(1.5)}px`,
        },
        menuItem: {
            lineHeight: 1,
            padding: `0 ${theme.spacing(2)}px`,
            '& .MuiTypography-body1': {
                fontSize: '0.875rem'
            }
        },
        menuItemRow: {
            overflow: 'hidden',
            '& > .MuiTypography-root': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }
        },
        searchButton: {
            width: `${theme.spacing(4)}px`,
            paddingLeft: 0
        },
        searchIcon: {
            marginRight: `${theme.spacing(1)}px`
        }
    }),
);

const windowScrollersRefs = [];
const updateWindowScrollersPosition = () => {
    windowScrollersRefs.forEach((ref) => ref?.current?.updatePosition());
};

const transitionHandler = (event) => {
    if (event.propertyName === 'height') {
        updateWindowScrollersPosition();
    }
};

const defaultShowedItems = 5;

const FiltersWithSearch = (
    props: {
        label: string;
        title: string;
        expanded: boolean;
        data: Array<IFilterListItem>;
        activeFilterIds: Array<IFilterListItem>;
        appliedActiveFilters: Array<IFilterListItem>;
        handleFilterClick: (key: string, item: IFilterListItem) => void;
        searchText: string;
        isSearchFieldShown: boolean;
        handleExpanded: (key: string) => void;
        handleSearchInFilters: (key: string, text: string, isFieldOpen: boolean) => void;
        scrollElement?: HTMLDivElement;
    }
) => {
    const {
        label,
        title,
        data,
        expanded,
        activeFilterIds,
        appliedActiveFilters,
        handleFilterClick,
        searchText,
        isSearchFieldShown,
        handleExpanded,
        handleSearchInFilters,
        scrollElement
    } = props;

    const classes = useStyles({});

    const [defaultData, setDefaultData] = useState<Array<IFilterListItem>>([]);
    const [filteringData, setFilteringData] = useState<Array<IFilterListItem>>([]);
    const [displayedItemsCount, setDisplayedItemsCount] = useState<number>(defaultShowedItems);
    const [showedMore, setShowedMore] = useState<boolean>(false);
    const { translations }: GlobalContextModel = useContext(GlobalContext);

    const [cellWidth, setCellWidth] = useState(0);
    const [cellHeight, setCellHeight] = useState(0);

    const accordionDetailsRef = useRef<HTMLDivElement>(null);
    const collapseNodeRef = useRef<HTMLDivElement>(null);
    const windowScrollerRef = useRef(null);
    const listRef = useRef(null);
    const hiddenCellRef = useRef(null);

    const onResize = () => {
        updateWindowScrollersPosition();

        if (scrollElement) {
            listRef.current?.forceUpdateGrid();
            setCellHeight(hiddenCellRef.current?.offsetHeight ?? 0);
            setCellWidth(scrollElement?.offsetWidth ?? 0);
        }
    };

    useEffect(() => {
        windowScrollersRefs.push(windowScrollerRef);
        window.addEventListener('resize', onResize);

        return () => {
            windowScrollersRefs.splice(windowScrollersRefs.indexOf((ref) => ref === windowScrollerRef), 1);

            window.removeEventListener('resize', onResize);
            collapseNodeRef.current?.removeEventListener('transitioncancel', transitionHandler);
            collapseNodeRef.current?.removeEventListener('transitionend', transitionHandler);
        };
    }, []);

    useEffect(() => {
        if (scrollElement) {
            if (collapseNodeRef.current) {
                showedMore && updateWindowScrollersPosition();
            } else {
                (expanded || showedMore) && setTimeout(updateWindowScrollersPosition, 300);
            }
        }
    }, [expanded, showedMore]);

    useEffect(() => {
        if (props && data) {
            const filteringDataNew = sortingByClicked(data, appliedActiveFilters);

            setDefaultData(data);
            showedMore && setDisplayedItemsCount(filteringDataNew.length);

            if (searchText) {
                sortingBySearch(searchText);
            } else {
                setFilteringData(filteringDataNew);
            }
        }
    }, [props]);

    useEffect(() => {
        if (scrollElement) {
            !cellHeight && setCellHeight(hiddenCellRef.current?.offsetHeight ?? 0);
            !cellWidth && setCellWidth(scrollElement?.offsetWidth ?? 0);

            if (!collapseNodeRef.current && accordionDetailsRef.current) {
                collapseNodeRef.current = accordionDetailsRef.current.closest('.MuiCollapse-container');

                collapseNodeRef.current?.addEventListener('transitionend', transitionHandler);
                collapseNodeRef.current?.addEventListener('transitioncancel', transitionHandler);
            }
        }
    });

    const isChecked = (id: string | number): boolean => {
        return activeFilterIds?.some((e) => e.id === id);
    };

    const onExpansionSummaryClick = () => {
        !isSearchFieldShown && handleExpanded(label);
    };

    const openSearchField = (event) => {
        event.stopPropagation();
        !expanded && handleExpanded(label);
        handleSearchInFilters(label, '', true);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        handleSearchInFilters(label, '', false);
        setFilteringData(sortingByClicked(defaultData, appliedActiveFilters));
    };

    const textFilterChange = (searchValue: string) => {
        handleSearchInFilters(label, searchValue, true);
        sortingBySearch(searchValue);
    };

    const sortingBySearch = (searchValue: string) => {
        if (searchValue) {
            const filteredData = sortingByInitialCheckedWithSearch(defaultData, appliedActiveFilters.map(filter => filter.id), searchValue);

            setFilteringData(filteredData);

            if (filteredData.length <= defaultShowedItems) {
                setShowedMore(false);
                setDisplayedItemsCount(defaultShowedItems);
            }

        } else {
            setFilteringData(sortingByInitialCheckedWithSearch(defaultData, appliedActiveFilters.map(filter => filter.id), searchValue));
        }
    };

    const toggleShowMore = () => {
        showedMore ? setDisplayedItemsCount(defaultShowedItems) : setDisplayedItemsCount(filteringData.length);
        setShowedMore(!showedMore);
    };

    const rowRenderer = ({
        key,
        index,
        style,
        forwardRef,
    }) => {
        const item = filteringData[index];

        return (
            <MenuItem
                key={key}
                className={classes.menuItem}
                dense
                onClick={(event) => {
                    event.preventDefault();
                    handleFilterClick(label, item);
                }}
                style={style}
                ref={forwardRef}
            >
                <FormControlLabel
                    className={classes.menuItemRow}
                    value={item.name}
                    control={
                        <Checkbox
                            color="primary"
                            onClick={(event) => event.preventDefault()}
                            checked={isChecked(item.id)}
                        />
                    }
                    label={item.name}
                />
            </MenuItem>
        );
    };

    return (
        <Accordion
            className={classes.accordion}
            expanded={expanded || false}
            data-a={`filter-with-search-${label}`}
        >
            <StyledAccordionSummary
                className={classes.accordionSummary}
                expandIcon={!isSearchFieldShown && <ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={onExpansionSummaryClick}
            >
                {
                    isSearchFieldShown
                        ? (
                            <SearchField
                                handleClose={handleClose}
                                onValueChange={textFilterChange}
                                value={searchText}
                            />)
                        : (
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <IconButton
                                    classes={{ root: classes.searchButton }}
                                    size="small"
                                    className={classes.searchIcon}
                                    onClick={(event) => openSearchField(event)}
                                    data-a="filter-search-button"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <Typography className={classes.title} variant="h6">{translations[title]}</Typography>
                            </Box>)
                }
            </StyledAccordionSummary>
            <AccordionDetails
                className={classes.accordionDetails}
                ref={accordionDetailsRef}
            >
                <Box mb={1}>
                    {filteringData?.[0] && rowRenderer({
                        key: 'hiddenCell',
                        index: 0,
                        style: { visibility: 'hidden', position: 'absolute' },
                        forwardRef: hiddenCellRef,
                    })}
                    {scrollElement ? (
                        <WindowScroller
                            ref={windowScrollerRef}
                            scrollElement={scrollElement}
                        >
                            {({ height, scrollTop }) => (
                                <List
                                    ref={listRef}
                                    autoHeight
                                    width={cellWidth}
                                    height={height ?? 0}
                                    scrollTop={scrollTop}
                                    rowCount={Math.min(displayedItemsCount, filteringData.length)}
                                    rowHeight={cellHeight}
                                    rowRenderer={rowRenderer}
                                    overscanRowCount={1}
                                />
                            )}
                        </WindowScroller>)
                        : filteringData.map((item, i) => {
                            if (i < displayedItemsCount) {
                                return (
                                    <MenuItem
                                        className={classes.menuItem}
                                        dense
                                        key={item.id}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleFilterClick(label, item);
                                        }}
                                    >
                                        <FormControlLabel
                                            className={classes.menuItemRow}
                                            value={item.name}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    onClick={(event) => event.preventDefault()}
                                                    checked={isChecked(item.id)}
                                                />
                                            }
                                            label={item.name}
                                        />
                                    </MenuItem>
                                );
                            }
                        })
                    }
                </Box>
                <div>
                    {
                        filteringData &&
                            (filteringData.length > defaultShowedItems) &&
                                <ShowMore
                                    showMore={showedMore}
                                    qty={showedMore ? 0 : filteringData.length - defaultShowedItems}
                                    toggleShowMore={toggleShowMore}
                                />
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default React.memo(FiltersWithSearch);
