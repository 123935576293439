import React, {
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';

import {
    useLocation,
    useParams,
    withRouter,
    Prompt
} from 'react-router-dom';

import {
    Box,
    Button,
    Divider,
    TextField,
    Grid,
    IconButton,
    Typography,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import TestLabel from 'components/TestLabel/TestLabel';

import { ArrowBack } from '@material-ui/icons';

import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import ThresholdInput, { IThresholdInputProps } from 'components/ThresholdInput/ThresholdInput';
import SupervisorThreshold from './components/SupervisorThreshold';

import Api from 'api/Api';

import { GlobalContext } from 'context/globalContext';
import { GlobalContextModel } from 'api/models/general';

import {
    IBetshopDetailsResponse,
    IBetshopPayoutPurchaseLimits,
    IBetshopSupervisorThresholds,
    IBetshopVoucherExpiration,
    IBetshopAddPayload,
    IBetshopAddResponse,
    IBetshopDetailInputData,
    INetworkBetshopLocationState,
    IBetshopNumberInputData,
} from 'api/models/network-management';
import { CancelablePromise } from 'api/services/_BaseApi';
import {
    APPROVAL_TYPE_NAMES,
    betshopPayoutPurchaseLimitsNames,
    betshopSupervisorThresholdNames,
    licenseTypes,
    voucherExpirationSettingNames,
    voucherExpirationTimeUnit,
    voucherExpirationTimeUnitNames,
} from 'const';
import {
    betshopDetailInputs,
    bethopPayoutPurchaseLimitsInputs,
    bethopSupervisorThresholds,
    voucherExpirationInputs,
} from 'data';

import { uncapitalize } from 'utils/formatStr';
import {
    emailValidate,
    phoneValidate,
    getDetailsFieldErrorData,
    getNumberFieldErrorData,
} from 'utils/validation';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

import {
    IBetshopFieldsData,
    IBetshopValuesState,
    IBetshopErrorsState,
    IBetshopErrorMessagesState,
    errorDescriptionToFieldNameMapper,
    initBetshopDetailInputs,
    initPayoutPurchaseLimitsInputs,
    initSupervisorInputs,
    initVoucherExpirationInputs,
} from 'pages/Betshops/business';

import { useStyles } from './styles';

const apiRequests: {
    getBetshop: CancelablePromise<IBetshopDetailsResponse>;
    saveBetshop: CancelablePromise<IBetshopAddResponse>;
} = {
    getBetshop: null,
    saveBetshop: null,
};

const timeUnitsArray = Object.values(voucherExpirationTimeUnit).filter((unit) => typeof unit === 'string');

const BetshopAdd = ({ history }) => {
    const { state: initState } = useLocation<INetworkBetshopLocationState>();
    const { id: pageId } = useParams<{ id?: string }>();
    const { globalSettings, translations, permissions }: GlobalContextModel = useContext(GlobalContext);

    const payoutPurchaseLimitsEnabled = Boolean(globalSettings.settings.BetshopDefaultSettings?.PayoutPurchaseLimits?.Enabled);
    const supervisorSettingsEnabled = Boolean(globalSettings.settings.BetshopDefaultSettings?.SupervisorSettings?.Enabled);
    const voucherExpirationEnabled = Boolean(globalSettings.settings.BetshopDefaultSettings?.VoucherExpiration?.Enabled);

    const isAddPage = pageId === 'add';
    const isReadOnlyPage = !isAddPage && !permissions.networkManagementBetshopsEdit;

    const [betshopId, setBetshopId] = useState(isAddPage ? undefined : Number(pageId));

    const classes = useStyles({});

    const isFirstRenderRef = useRef(true);

    const valuesInitRef = useRef({} as IBetshopValuesState);
    const errorsInitRef = useRef({} as IBetshopErrorsState);
    const errorMessagesInitRef = useRef({} as IBetshopErrorMessagesState);

    const valuesInit = valuesInitRef.current;
    const errorsInit = errorsInitRef.current;
    const errorMessagesInit = errorMessagesInitRef.current;

    const initAllFields = useCallback((fieldsData: IBetshopFieldsData, betshopDetailsResponse?: IBetshopDetailsResponse) => {
        initBetshopDetailInputs(translations, fieldsData, betshopDetailsResponse);
        payoutPurchaseLimitsEnabled && initPayoutPurchaseLimitsInputs(translations, fieldsData, betshopDetailsResponse, globalSettings);
        supervisorSettingsEnabled && initSupervisorInputs(translations, fieldsData, betshopDetailsResponse, globalSettings);
        voucherExpirationEnabled && initVoucherExpirationInputs(translations, fieldsData, betshopDetailsResponse, globalSettings);
    }, [translations]);

    if (isFirstRenderRef.current) {
        initAllFields({ valuesInit, errorsInit, errorMessagesInit });
    }

    const [values, setValues] = useState(valuesInit);
    const [errors, setErrors] = useState(errorsInit);
    const [errorMessages, setErrorMessages] = useState(errorMessagesInit);
    const [betshopDetailsError, setBetshopDetailsError] = useState(false);

    const [firstSubmit, setFirstSubmit] = useState(true);
    const [partiallySaved, setPartiallySaved] = useState(false);
    const [submitState, setSubmitState] = useState(false);
    const [serverErrorMessages, setServerErrorMessages] = useState([]);

    React.useEffect(() => {
        initAllFields({ valuesInit, errorsInit, errorMessagesInit });
    }, [translations]);

    React.useEffect(() => {
        loadData();

        isFirstRenderRef.current = false;

        return () => cancelRequests(apiRequests);
    }, []);

    const loadData = () => {
        !isAddPage && loadBetshopDetails(betshopId);
    };

    const loadBetshopDetails = async (id) => {
        try {
            setSubmitState(true);
            apiRequests.getBetshop = Api.NetworkManagement.GetSingleBetshop(id);

            const betshopDetailsResponse = await apiRequests.getBetshop;

            initAllFields({ valuesInit, errorsInit, errorMessagesInit }, betshopDetailsResponse);

            setValues({ ...valuesInit });
            setErrors({ ...errorsInit });
            setErrorMessages({ ...errorMessagesInit });

            setSubmitState(false);
        } catch (error) {
            setBetshopDetailsError(true);
            throwNetworkError(error);
        }
    };

    const handleChange = (name, required) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        const { error, errorMessage } = getDetailsFieldErrorData({ translations }, value, required);

        setErrors({ ...errors, [name]: error });
        setErrorMessages({ ...errorMessages, [name]: errorMessage });
        setValues({ ...values, [name]: value });
    };

    const handleNumberChange = (name: string, required: boolean, { isInteger = false, min = undefined } = {}) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.replace(/\D/g, ''); // temporary allow to enter only digits

            const customErrorMessageKeys = {
                min: name === voucherExpirationSettingNames.expPeriod
                    ? 'betshops-valid-exp-data-min'
                    : undefined,
            };

            const { error, errorMessage } = getNumberFieldErrorData({ customErrorMessageKeys, translations }, value, required, { isInteger, min });

            setValues({ ...values, [name]: value });
            setErrors({ ...errors, [name]: error });
            setErrorMessages({ ...errorMessages, [name]: errorMessage });
        };

    const handleChecked = (name) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: event.target.checked });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let tempValues: IBetshopValuesState = { ...values };
        let newErrors = errors;
        let newErrorMessages = errorMessages;

        firstSubmit && setFirstSubmit(false);

        for (const key in tempValues) {
            tempValues[key] = typeof tempValues[key] === 'string' ? tempValues[key].trim() : tempValues[key];
        }

        const allRequiredFieldsAreValid = Object.values({
            ...newErrors,
            phoneNumber: false,
            email: false,
        }).every(field => !field);

        if (allRequiredFieldsAreValid) {
            if (tempValues.phoneNumber && !phoneValidate(tempValues.phoneNumber)) {
                newErrors.phoneNumber = true;
                newErrorMessages.phoneNumber = translations['gen-valid-msg-phone-number'];
            }

            if (tempValues.email && !emailValidate(tempValues.email)) {
                newErrors.email = true;
                newErrorMessages.email = translations['gen-valid-msg-email'];
            }

            if (!Object.values(newErrors).some(Boolean)) {
                submitForm({ ...tempValues });
            }
        }

        setValues({ ...tempValues });
        setErrors({ ...newErrors });
        setErrorMessages({ ...newErrorMessages });

        setServerErrorMessages([]);
    };

    const apiRequest = (payload: IBetshopAddPayload) => (!isAddPage || partiallySaved)
        ? Api.NetworkManagement.EditBetshop({ ...payload, id: betshopId })
        : Api.NetworkManagement.AddBetshop(payload);

    const submitForm = (tempValues: IBetshopValuesState) => {
        setSubmitState(true);

        const payload: IBetshopAddPayload = { ...tempValues };

        if (payoutPurchaseLimitsEnabled) {
            payload.settings = payload.settings ?? {};
            payload.settings.payoutPurchaseLimits = {} as IBetshopPayoutPurchaseLimits;

            Object.keys(betshopPayoutPurchaseLimitsNames).forEach(limitKey => {
                payload.settings.payoutPurchaseLimits[limitKey] = Number(tempValues[limitKey]);
                delete payload[limitKey];
            });
        }

        if (supervisorSettingsEnabled) {
            payload.settings = payload.settings ?? {};
            payload.settings.supervisorThresholds = {} as IBetshopSupervisorThresholds;

            Object.keys(betshopSupervisorThresholdNames).forEach(thresholdKey => {
                payload.settings.supervisorThresholds[thresholdKey] = Number(tempValues[thresholdKey]);
                delete payload[thresholdKey];
            });
        }

        if (voucherExpirationEnabled) {
            payload.settings = payload.settings ?? {};
            payload.settings.voucherExpiration = {} as IBetshopVoucherExpiration;

            Object.keys(voucherExpirationSettingNames).forEach(key => {
                payload.settings.voucherExpiration[key] = Number(tempValues[key]);
                delete payload[key];
            });
        }

        apiRequest(payload)
            .then((res) => {
                if (res.succeeded) {
                    setPartiallySaved(false);
                    clearValues();

                    if (isAddPage) {
                        //refresh token if user already has some betshop
                        const currentOperatorKey = globalSettings?.user?.ClientId;
                        const userHasBetshop = globalSettings?.user?.Betshops?.[currentOperatorKey].length;

                        if (userHasBetshop) {
                            Api.General.GetToken('PUT').finally(() => handleCancel());
                        } else {
                            handleCancel();
                        }
                    } else {
                        history.replace({ pathname: '/betshops', state: initState });
                    }
                } else {
                    const newErrorFields = errors;
                    const newErrorMessages = errorMessages;
                    const newServerErrorMessages = [];

                    setSubmitState(false);

                    res.errors.forEach(({ code, description }) => {
                        const fieldName = errorDescriptionToFieldNameMapper[description] ?? uncapitalize(description);

                        if (code && errors.hasOwnProperty(fieldName)) {
                            newErrorFields[fieldName] = true;
                            newErrorMessages[fieldName] = translations[code];
                        } else {
                            if (code && description === 'error-settings-not-saved') {
                                setPartiallySaved(true);

                                if (typeof res.betshopId === 'number') {
                                    setBetshopId(res.betshopId);
                                }
                            }

                            newServerErrorMessages.push(translations[description]);
                        }
                    });

                    setErrors(newErrorFields);
                    setErrorMessages(newErrorMessages);
                    setServerErrorMessages(newServerErrorMessages);
                }
            })
            .catch((error) => {
                setServerErrorMessages([translations['general-error']]);
                setSubmitState(false);
                throwNetworkError(error);
            });
    };

    const clearValues = () => {
        setValues({ ...valuesInit });
        setErrors({ ...errorsInit });
        setErrorMessages({ ...errorMessagesInit });
    };

    const isValueChanged = () => {
        return Object.entries(values).some(([key, value]) => (
            typeof valuesInit[key] !== 'object'
                ? valuesInit[key] !== value
                : JSON.stringify(valuesInit[key]) !== JSON.stringify(value)
        ));
    };

    const handleCancel = () => {
        history.push({ pathname: '/betshops', state: initState });
    };

    const numberInputDataMapper: (inputData: IBetshopNumberInputData) => IThresholdInputProps = ({
        name,
        label,
        isCurrency,
        isInteger,
        maxLength,
        min,
        required,
    }) => {
        const error: boolean = !firstSubmit && errors[name];
        const errorMessage = error ? translations[errorMessages[name]] : '';
        const value: string = values[name] ?? '';
        const isNotEmpty = Boolean(value.length);
        const isValidNumber = isNotEmpty && !isNaN(values[name]);

        return {
            name,
            inputLabel: translations[label],
            inputComparisonLabel: '>=',
            inputPrefix: isCurrency ? globalSettings.settings.CurrencySign : undefined,
            inputProps: { maxLength },
            disabled: isReadOnlyPage,
            formatValue: isCurrency && isNotEmpty && isValidNumber,
            error,
            helperText: errorMessage,
            value,
            onChange: handleNumberChange(name, required, { isInteger, min })
        };
    };

    const promptMessage = JSON.stringify({
        title: translations['gen-discard-changes-title'],
        msg: translations['gen-discard-changes-msg'],
        yes: translations['gen-yes'],
        no: translations['gen-no'],
    });

    const submitButtonTranslationKey = partiallySaved
        ? 'gen-update'
        : isAddPage
            ? 'users-add'
            : 'users-edit';

    return !betshopDetailsError && (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.bodyWrapContainer}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={handleCancel}
                        edge="start"
                        color="inherit"
                        size="small"
                        disableRipple
                        data-a="betshop-back-nav-button"
                    >
                        <ArrowBack htmlColor="black" />
                        &nbsp;
                        <Typography
                            noWrap
                            variant="button"
                            className={classes.iconLabel}
                        >
                            {translations['gen-betshops']}
                        </Typography>
                    </IconButton>
                    <Typography variant="h5" component="h5" noWrap className={classes.title}>
                        {isAddPage ? translations['betshops-add'] : valuesInit.betshopName}
                    </Typography>
                    {valuesInit.isTest &&
                        <TestLabel className={classes.testLabelLong}
                            type="long"
                        />
                    }
                    {!isAddPage
                        ? (
                            <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                className={classes.subTitle}
                                noWrap
                            >
                                {betshopId}
                            </Typography>
                        )
                        : null
                    }
                </div>
            </div>
            <Divider />
            <PageLayoutWrapper>
                <BackdropSpinner open={submitState} />
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item md={4} sm={12}>
                            <h2 className={classes.groupLabel}>{translations['betshops-betshop-details']}</h2>
                        </Grid>
                        <Grid item md={8} sm={12}>
                            <Grid container spacing={3} className={classes.container}>
                                {betshopDetailInputs
                                    .map(({ id, name, label, required = true, disabled = false, maxLength, type = 'text', col = {} }: IBetshopDetailInputData) => {
                                        const isLicenseField = name === 'licenseId' || name === 'licenseType';
                                        const licenseError = errors.licenseId || errors.licenseType;
                                        const licenseErrorMessage = errorMessages.licenseId || errorMessages.licenseType;
                                        const isError = isLicenseField ? licenseError : errors[name];
                                        const helperText = !firstSubmit && isError &&
                                            (isLicenseField ? licenseErrorMessage : errorMessages[name]) ||
                                            (required ? ' ' : `${translations['gen-optional']}`);

                                        const props = {
                                            className: classes.input,
                                            id: name,
                                            label: translations[label],
                                            type,
                                            value: values[name],
                                            error: !firstSubmit && isError,
                                            disabled: disabled || isReadOnlyPage,
                                            helperText: name !== 'licenseId' ? helperText : '',
                                            inputProps: { maxLength },
                                            onChange: handleChange(name, required),
                                        };

                                        const selectProps = name === 'licenseType'
                                            ? {
                                                select: true,
                                                children: licenseTypes.map(type => (
                                                    <MenuItem key={type} value={type}>{type}</MenuItem>
                                                ))
                                            }
                                            : {};

                                        return (
                                            <Grid
                                                item
                                                key={id}
                                                sm={col.sm || 6}
                                                xs={col.xs || 12}
                                                className={(name === 'licenseType' || name === 'licenseId') ? classes[name] : ''}
                                            >
                                                <TextField
                                                    {...props}
                                                    {...selectProps}
                                                    variant="outlined"
                                                    color="primary"
                                                />
                                            </Grid>
                                        );
                                    }
                                    )}
                            </Grid>
                            {permissions.networkManagementBetshopsSetTest && !valuesInit.isTest && (
                                <FormControlLabel
                                    classes={{ root: classes.checkboxRow }}
                                    control={
                                        <Checkbox
                                            checked={values.isTest}
                                            onChange={handleChecked('isTest')}
                                            name="is-test"
                                            color="primary"
                                        />
                                    }
                                    disabled={isReadOnlyPage}
                                    label={`${translations['gen-test']} ${translations['gen-betshop']}`}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {payoutPurchaseLimitsEnabled ? (
                        <>
                            <Divider />
                            <Grid container spacing={2} className={classes.row}>
                                <Grid item md={4} sm={12}>
                                    <h2 className={classes.groupLabel}>{translations['betshops-payout-purchase-limits']}</h2>
                                </Grid>
                                <Grid item md={8} sm={12}>
                                    <Box mb={1.5}>
                                        <Grid container spacing={3}>
                                            {bethopPayoutPurchaseLimitsInputs
                                                .map(numberInputDataMapper)
                                                .map((props) => (
                                                    <Grid item xs={12} sm={6} key={props.name}>
                                                        <ThresholdInput
                                                            {...props}
                                                            inputComparisonLabel={undefined}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    {supervisorSettingsEnabled ? (
                        <>
                            <Divider />
                            <Grid container spacing={2} className={classes.row}>
                                <Grid item md={4} sm={12}>
                                    <h2 className={classes.groupLabel}>{translations['betshops-supervisor-thresholds']}</h2>
                                </Grid>
                                <Grid item md={8} sm={12}>
                                    {bethopSupervisorThresholds.map(({ id, tieWord, inputs }) => {
                                        const inputProps = inputs.map(numberInputDataMapper);

                                        return (
                                            <SupervisorThreshold key={id}
                                                title={translations[APPROVAL_TYPE_NAMES[id]]}
                                                tieWord={tieWord?.length ? translations[tieWord] : ''}
                                                thresholdInputsProps={inputProps}
                                            />
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    {voucherExpirationEnabled ? (
                        <>
                            <Divider />
                            <Grid container spacing={2} className={classes.row}>
                                <Grid item xs={12} md={4}>
                                    <h2 className={classes.groupLabel}>{translations['betshops-voucher-expiration']}</h2>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Box mb={1.5}>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <ThresholdInput className={classes.expPeriod}
                                                    {...numberInputDataMapper(voucherExpirationInputs[0])}
                                                    inputComparisonLabel={undefined}
                                                    inputLabel={undefined}
                                                    helperTextNoWrap
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id={voucherExpirationInputs[1].name}
                                                    name={voucherExpirationInputs[1].name}
                                                    variant="outlined"
                                                    color="primary"
                                                    select
                                                    children={timeUnitsArray.map((timeUnit) => (
                                                        <MenuItem
                                                            key={timeUnit}
                                                            value={`${voucherExpirationTimeUnit[timeUnit]}`}
                                                        >
                                                            {translations[voucherExpirationTimeUnitNames[timeUnit]]}
                                                        </MenuItem>)
                                                    )}
                                                    value={values[voucherExpirationInputs[1].name]}
                                                    disabled={isReadOnlyPage}
                                                    inputProps={{
                                                        'data-a-value': values[voucherExpirationInputs[1].name],
                                                    }}
                                                    onChange={handleNumberChange(
                                                        voucherExpirationInputs[1].name,
                                                        voucherExpirationInputs[1].required,
                                                        { isInteger: voucherExpirationInputs[1].isInteger }
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    {serverErrorMessages.map(errorMessage => (
                        <p key={errorMessage} className={classes.serverError} data-a="betshops-server-error">{errorMessage}</p>
                    ))}
                    {(isValueChanged() || isAddPage) && !isReadOnlyPage &&
                        <footer className={classes.footer}>
                            <Button
                                className={classes.actionButton}
                                variant="outlined"
                                onClick={handleCancel}
                                data-a="betshop-button-cancel"
                            >
                                {translations['gen-cancel'] || ''}
                            </Button>
                            <Button
                                className={classes.actionButton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                data-a="betshop-button-submit"
                                data-a-translation-key={submitButtonTranslationKey}
                            >
                                {translations[submitButtonTranslationKey] || ''}
                            </Button>
                        </footer>
                    }
                </form>
            </PageLayoutWrapper>
            <Prompt
                when={isValueChanged()}
                message={promptMessage}
            />
        </div>
    );
};

export default withRouter(BetshopAdd);
