import React, { useContext } from 'react';

import { Typography } from '@material-ui/core';
import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import clsx from 'clsx';
import useStyles from './styles';

const VouchersReportTableCellContent = ({
    className = '',
    id,
    row,
}) => {
    const classes = useStyles();
    const { globalSettings, translations, dateTimeFormat, timeZone }: GlobalContextModel = useContext(GlobalContext);

    const rawValue = row[id];
    let child;

    switch (id) {
        case 'voucherId':
            child = (
                <span className={clsx(classes.voucherIdWrap, className)}>
                    <span className={classes.voucherId}>
                        {rawValue}
                    </span>
                    {row.isCMV &&
                        <Typography className={classes.badge}
                            component="span"
                            variant="subtitle2"
                            data-a="voucher-label"
                        >
                            {translations['emp-reports-vouchers-cmv']}
                        </Typography>
                    }
                </span>);
            break;
        case 'issueDate':
        case 'cashoutDate':
        case 'expirationDate':
            const formattedDate = rawValue?.length
                ? formatDateByPattern(new Date(formatToIsoDateString(rawValue)), dateTimeFormat, timeZone)
                : '';

            child = <span className={className}>{formattedDate}</span>;
            break;
        case 'cost':
            child =
                (
                    <span className={className}>
                        {formatNumberToCurrencyString(
                            rawValue,
                            numberStyles(globalSettings?.settings?.Currency),
                            globalSettings?.settings?.CurrencySign,
                            globalSettings?.settings?.CurrencyFormat
                        )}
                    </span>
                );
            break;
        default:
            child = <span className={className}>{rawValue}</span>;
    }

    return child;
};

export default VouchersReportTableCellContent;
