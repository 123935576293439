import * as React from 'react';
import clsx from 'clsx';
import { Button, ButtonGroup, LinearProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface IProps {
    label: string;
    value: number;
    loading: boolean;
    handleLoading: Function;
    contained?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonGroup: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            minWidth: '128px',
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: `${theme.palette.grey[100]}`,
        },
        buttonGroupContained: {
            border: 'none',
            alignItems: 'stretch'
        },
        button: {
            padding: theme.spacing(1, 2),
            border: 'none',
            textTransform: 'none',
            '& > .MuiButton-label': {
                lineHeight: 0.85,
                whiteSpace: 'nowrap'
            }
        },
        linearProgressWrap: {
            width: '100%',
            padding: theme.spacing(0, 2)
        },
        linearProgressWrapContained: {
            display: 'flex',
            alignItems: 'center'
        },
        linearProgress: {
            width: '100%'
        }
    }),
);

const ButtonWithLinearProgress = ({ label, value, loading, handleLoading, contained = false }: IProps) => {
    const classes = useStyles({ value });

    const handleButton = () => {
        handleLoading();
    };

    return (
        <ButtonGroup
            variant="outlined"
            className={clsx(classes.buttonGroup, contained && classes.buttonGroupContained)}
            aria-label="split button"
        >
            {
                loading
                    ? (
                        <div className={clsx(classes.linearProgressWrap, contained && classes.linearProgressWrapContained)}>
                            <LinearProgress className={classes.linearProgress} color="primary" value={value} variant="determinate" data-a="lianer-progress" />
                        </div>)
                    : (
                        <Button
                            className={classes.button}
                            onClick={handleButton}
                            data-a="excel-button"
                            variant={contained ? 'contained' : 'text'}
                            color={contained ? 'primary' : 'default'}
                        >
                            {label}
                        </Button>)
            }

        </ButtonGroup>
    );
};

export default ButtonWithLinearProgress;
