export const checkValidity = (stringValue: string) => {
    const numberValue = +stringValue;
    const isNumber = !isNaN(numberValue);

    return isNumber && numberValue >= 0 && !isMinusZero(numberValue);
};

export const checkZeroString = (stringValue: string) => (stringValue === '0' || stringValue === '');

export const isMinusZero = (value: string | number) => 1/+value === -Infinity;
