import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
    cell: {
        padding: spacing(2, 1),
    },
    hideCellMobile: {
        [breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}));

export default useStyles;
