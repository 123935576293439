import { ICardTransactionsFullSearchPayload, ISupervisorApprovalsFullSearchPayload, ITicketsFullSearchPayload, IVouchersFullSearchPayload } from 'api/models/reports';

export enum EXPORT_REPORT_TYPE {
    Tickets = 1,
    SupervisorApprovals = 2,
    CardTransactions = 3,
    Vouchers = 4,
    PlayerStatement = 5
}

export interface ICardTransactionsReportExportPayload extends ICardTransactionsFullSearchPayload {
    reportType: number;
}
export interface ISupervisorReportExportPayload extends ISupervisorApprovalsFullSearchPayload {
    reportType: number;
}
export interface ITicketsReportExportPayload extends ITicketsFullSearchPayload {
    reportType: number;
}

export interface IVouchersReportExportPayload extends IVouchersFullSearchPayload {
    reportType: number;
}

export interface IReportsExportModel {
    jwtToken: string;
    reportId: string;
}
export interface IPlayerStatementExportPayload {
    fromDate: Date | string;
    toDate: Date | string;
    loyaltyId: string;
    reportType: EXPORT_REPORT_TYPE;
    languageId?: number;
}
