import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SwitchLeft (props) {
    return (
        <SvgIcon {...props}>
            <path d="M8.5,8.62v6.76L5.12,12L8.5,8.62 M10,5l-7,7l7,7V5L10,5z M14,5v14l7-7L14,5z" />
        </SvgIcon>
    );
}
