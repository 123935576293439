import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bodyWrap: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing(3, 6, 0),

        [theme.breakpoints.down('md')]: {
            alignItems: 'normal',
            padding: theme.spacing(2, 2, 0),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 0, 0),
            minHeight: `calc(100% - ${theme.spacing(2)}px)`,
        },
    }
}));

const PageLayoutWrapper = ({ children }) => {
    const classes = useStyles({});

    return (
        <Paper className={classes.bodyWrap} elevation={0}>
            {children}
        </Paper>
    );
};

export default PageLayoutWrapper;
