import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }: Theme) => ({
    inlineTitle: {
        [breakpoints.down('xs')]: {
            marginRight: spacing(4/8),
            color: palette.grey[700],
        }
    },
    receiptId: {
        [breakpoints.down('xs')]: {
            fontWeight: typography.fontWeightMedium,
        }
    },
    transactionIdCell: {
        maxWidth: 'initial'
    },
}));

export default useStyles;
