import defaultSorting from './defaultEposSorting';

type Order = 'asc' | 'desc';

export function desc(a, b, orderBy) {
    const first = orderBy === 'deliveryTime'
        ? new Date(a[orderBy])
        : typeof a[orderBy] === 'string'
            ? a[orderBy].toLowerCase() : a[orderBy];

    const second = orderBy === 'deliveryTime'
        ? new Date(b[orderBy])
        : typeof b[orderBy] === 'string'
            ? b[orderBy].toLowerCase() : b[orderBy];

    if (orderBy === 'ip' || orderBy === 'monitoringAgentVersion') {
        return ipSorting(first, second);
    }

    if (second < first) {
        return -1;
    }

    if (second > first) {
        return 1;
    }

    return 0;
}

export function getSorting<T, K extends keyof T>(order: Order, orderBy: K): (a: T, b: T) => number {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function stableSort<T, K extends keyof T>(array: Array<T>, order: Order, orderBy: K | any) {
    if (orderBy === 'errors') {
        return defaultSorting(array, order);
    } else {
        return array.sort(getSorting(order, orderBy));
    }
}

export function ipSorting(a, b) {
    const arrA = a?.split('.') || [];
    const arrB = b?.split('.') || [];

    for (let i = 0; i < arrA.length; i++) {
        if (parseInt(arrA[i] || -1) < parseInt(arrB[i] || -1)) {
            return -1;
        } else if (parseInt(arrA[i] || -1) > parseInt(arrB[i] || -1)) {
            return 1;
        }
    }

    return 0;
}
