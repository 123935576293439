import getCookieValue from 'utils/cookieMethods';
import { LanguageIdCookie } from 'const';

export function getCurrentLanguage(languages) {
    const cookieValue = getCookieValue(LanguageIdCookie);

    return cookieValue
        ? languages.find(lang => lang.Id.toString() === cookieValue)
        : languages.find(lang => lang.IsDefault);
}
