import { IChartDataItem } from './types';

const formatData = (data: Array<IChartDataItem>, minValueToDisplay) => {
    return data.map((item) =>
        [
            new Date(item.axisXValue).getTime(),
            minValueToDisplay ? changeToVisibleValue(item.axisYValue, minValueToDisplay) : item.axisYValue
        ]
    );
};

const countMinValueToDisplay = (data) => {
    const valuesArr = data.map(e => e.axisYValue);
    const minValue = Math.min(...valuesArr);
    const maxValue = Math.max(...valuesArr);
    const range = maxValue - minValue;

    return Math.ceil(range / 100);
};

const changeToVisibleValue = (value: number, minValueToDisplay: number) => {
    if (Math.abs(value) > minValueToDisplay) {
        return value;
    } else {
        if (value > 0) {
            return Number((value + minValueToDisplay).toFixed(2));
        } else if (value < 0) {
            return Number((value - minValueToDisplay).toFixed(2));
        } else {
            return 0;
        }
    }
};

const revertToRealValue = (value: number, minValueToDisplay: number) => {
    if (value > 0) {
        return value - minValueToDisplay < minValueToDisplay
            ? +(value - minValueToDisplay).toFixed(2)
            : value;
    } else if (value < 0) {
        return Math.abs(value + minValueToDisplay) < minValueToDisplay
            ? +(value + minValueToDisplay).toFixed(2)
            : value;
    } else {
        return 0;
    }
};

export { formatData, countMinValueToDisplay, changeToVisibleValue, revertToRealValue };

