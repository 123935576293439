import BaseApi from './_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IRoleList,
    IRoleAddPayload,
    IRoleEditPayload,
    IRoleDetailsPayload,
    IRoleDetails,
    RoleIdsList
} from 'api/models/role';

import { GeneralResponseResult } from '../models/general';

export default class RoleApi extends BaseApi {
    List(): Promise<Array<IRoleList>> {
        return this.QueryEmpBff({}, EmpEndpoint.Role.List, this.QueryMethods.GET);
    }

    ListIdName(params = {}): Promise<RoleIdsList> {
        return this.QueryEmpBff(params, EmpEndpoint.Role.ListIdName, this.QueryMethods.GET);
    }

    Add(payload: IRoleAddPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, EmpEndpoint.Role.Add);
    }

    Edit(payload: IRoleEditPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, EmpEndpoint.Role.Edit);
    }

    Details(payload: IRoleDetailsPayload): Promise<IRoleDetails> {
        return this.QueryEmpBff(payload, EmpEndpoint.Role.Details, this.QueryMethods.GET);
    }
}
