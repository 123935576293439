import * as React from 'react';
import { DialogContent, DialogContentText, DialogActions, DialogTitle, Button } from '@material-ui/core';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import Modal from 'components/Modal/Modal';

export default function ConfirmModal (
    props: {
        id: number;
        isOpen: boolean;
        title: string;
        description: string;
        onClose: () => void;
        onConfirm: (id: number) => void;
    }) {
    const { id, isOpen, title, description, onClose, onConfirm } = props;
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    function handleClick() {
        onConfirm(id);
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} data-a={`modal-${id}`}>
            <DialogTitle id="alert-dialog-title">{title && translations[title]}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description && translations[description]}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" data-a="confirm-modal-button-no">
                    {translations['gen-no']}
                </Button>
                <Button onClick={handleClick} color="primary" autoFocus data-a="confirm-modal-button-yes">
                    {translations['gen-yes']}
                </Button>
            </DialogActions>
        </Modal>
    );
}
