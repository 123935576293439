import BaseApi from './_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import { IKpiPayload, IKpiDataModel } from 'api/models/kpi';

import getCookieValue, { handleCookieToPayload } from 'utils/cookieMethods';

export default class KpiApi extends BaseApi {
    GetAllData(payload: IKpiPayload): Promise<IKpiDataModel> {
        const filterKpiByEntityId = getCookieValue('filterKpiByEntityId');

        if (filterKpiByEntityId && typeof filterKpiByEntityId === 'number') {
            payload['filterKpiByEntityId'] = filterKpiByEntityId;
        }

        return this.QueryEmpBff(
            handleCookieToPayload(handleCookieToPayload(payload, 'filterKpiByEntityId', 'FilterKpiByEntityId'), 'showTestAccounts', 'showTestAccounts'), EmpEndpoint.Kpi.GetAllData);
    }
}
