
import JsBarcode from 'jsbarcode';
import Api from 'api/Api';

export const handleDownLoadBarcode = (id: string) => {
    Api.User.DownloadSupervisorBarcode({ id }).then(data => downloadBarcode('download-barcode', data.barcode));
};

export const downloadBarcode = (id: string, barcode: string) => {
    JsBarcode(document.getElementById(id), barcode);

    const canvas = document.createElement('canvas');
    const svg = document.getElementById(id);
    const base64 = btoa(svg.outerHTML);

    const w = svg.getAttribute('width');
    const h = svg.getAttribute('height');
    const img_to_download = document.createElement('img');

    img_to_download.src = 'data:image/svg+xml;base64,' + base64;

    img_to_download.onload = function () {
        canvas.setAttribute('width', w);
        canvas.setAttribute('height', h);

        const context = canvas.getContext('2d');

        context.drawImage(img_to_download, 0, 0, parseInt(w), parseInt(h));

        const dataURL = canvas.toDataURL('image/png');

        const a = document.createElement('a');
        const my_evt = new MouseEvent('click');

        a.download = 'barcode.png';
        a.href = dataURL;
        a.dispatchEvent(my_evt);
    };
};
