import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, spacing, typography, palette }: Theme) => ({
    voucherIdWrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',

        [breakpoints.down('xs')]: {
            marginRight: 'auto',
            minWidth: spacing(150/8),
            padding: 0,
            fontWeight: typography.fontWeightMedium,
        }
    },
    voucherId: {
        marginRight: spacing(12/8),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        [breakpoints.down('xs')]: {
            fontSize: '12px',
            marginRight: spacing(0.5),
            textTransform: 'initial'
        }
    },
    badge: {
        padding: spacing(0, '6px'),
        borderRadius: '10px',
        backgroundColor: palette.grey[500],
        color: palette.common.white,
        fontSize: '12px'
    }
}));

export default useStyles;
