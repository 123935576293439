import Api from 'api/Api';

// triggers logout if there were no events defined by validEvents during idleTimeout
let eventTimerId;

const RestartTimerPostMessage = new BroadcastChannel('RestartTimer');

const validEvents = ['click', 'touchend'];

export const initIdleness = (idleTimeout) => {
    RestartTimerPostMessage.addEventListener('message', () => {
        resetEventTimer(idleTimeout);
    });

    const startEventTimer = (time) => {
        return setTimeout(() => {
            Api.General.GetToken('DELETE');
        }, time);
    };

    const stopTimer = (timerId) => {
        timerId && clearInterval(timerId);
    };

    const resetEventTimer = (idleTimeout) => {
        stopTimer(eventTimerId);
        eventTimerId = startEventTimer(idleTimeout);
    };

    if (!eventTimerId) {
        eventTimerId = startEventTimer(idleTimeout);

        validEvents.map((e) => {
            document.addEventListener(e, () => {
                resetEventTimer(idleTimeout);
                RestartTimerPostMessage.postMessage('RestartTimerPostMessage');
            });
        });
    }
};
