import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import { IOddsSheetsPdfResponseModel, IReportingApiBaseRequest } from 'api/models/reports';

export default class OddsSheetsApi extends BaseApi {
    GetPdf(payload: IReportingApiBaseRequest, modelType: string) {
        return this.QueryEmpBff<IOddsSheetsPdfResponseModel>(payload
            , `${EmpEndpoint.OddsSheetsPdf.GetPdf}?SportsbookProgramType=${modelType}`
            , this.QueryMethods.GET);
    }
}
