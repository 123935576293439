import React, { useContext } from 'react';
import useStyles from './styles';
import { Divider, Button, withWidth } from '@material-ui/core';

import { Link } from 'react-router-dom';

import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';

import BetshopTable from './components/BetshopTable/BetshopTable';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const BetshopList = (props) => {
    const classes = useStyles({});
    const { permissions, translations }: GlobalContextModel = useContext(GlobalContext);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleWrap}>
                    <h1 className={classes.title}>
                        {translations['gen-betshops'] || ''}
                    </h1>
                    {permissions.networkManagementBetshopsAdd &&
                        <Button
                            component={Link}
                            to={'/betshops/add'}
                            className={classes.addButton}
                            variant="contained"
                            color="primary"
                            data-a="betshops-add-button"
                        >
                            {translations['betshops-add'] || ''}
                        </Button>
                    }
                </div>
            </div>
            <Divider />
            <PageLayoutWrapper>
                <BetshopTable isSuggestionFieldEnabled suggestionFieldWidth={props.width} />
            </PageLayoutWrapper>
        </div>
    );
};

export default withWidth()(BetshopList);
