import * as React from 'react';
import { Grid, Button, Menu, MenuList, MenuItem, Typography } from '@material-ui/core';

import CodeIcon from '@material-ui/icons/Code';
import BuildIcon from '@material-ui/icons/Build';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetApp from '@material-ui/icons/GetApp';
import CachedIcon from '@material-ui/icons/Cached';
import SwitchLeftIcon from 'components/icons-material/SwitchLeft'; // for mui v5 and later just replace "component" with "@mui" in path

import { MaintenanceModeDisable, MaintenanceModeEnable } from 'data';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useStyles from './styles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import {
    EPOS_ENVIRONMENT_TYPE,
    EPOS_STATUS_NAMES,
    AUDIT_ACTIONS,
    COMMAND_TYPES,
    COMMAND_SUBTYPES,
} from 'const';

const linkFinder = (arrayOfLinks, type) => {
    return arrayOfLinks.find((link) => link.protocolType === type);
};

const MoreActionsMenu = ({
    links,
    onOpenLinkClick,
    handleMaintenanceMode,
    openUpdateShellModal,
    isUnderMaintenance,
    statusName,
    permissions,
    onBulkActionTriggered,
    environmentType = '',
}) => {
    const classes = useStyles({});

    const {
        sshPermission,
        maintenancePermission,
        technicianPagePermission,
        updateShellPermission,
        reloadShellSettingsPermission,
        switchEnvironment,
    } = permissions;

    const tpLink = linkFinder(links, 'RDP');
    const sshLink = linkFinder(links, 'SSH');

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button aria-controls="more-actions-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <Typography className={classes.buttonLabel}>{translations['hmd-more-actions']}</Typography>
                <ArrowDropDownIcon />
            </Button>

            <Menu
                id="more-actions-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MenuList className={classes.menu}>
                    {maintenancePermission &&
                        <MenuItem onClick={handleMaintenanceMode} disabled={statusName === EPOS_STATUS_NAMES.OFFLINE}>
                            <BuildIcon />
                            <Typography className={classes.buttonLabel}>
                                {translations[isUnderMaintenance ? MaintenanceModeDisable.title : MaintenanceModeEnable.title]}
                            </Typography>
                        </MenuItem>
                    }
                    {sshPermission &&
                        <MenuItem onClick={() => onOpenLinkClick(sshLink?.guacamoleUrl, AUDIT_ACTIONS.SSH_CONNECT)} disabled={!sshLink}>
                            <CodeIcon />
                            <Typography className={classes.buttonLabel}>{translations['hmd-ssh-access']}</Typography>
                        </MenuItem>
                    }
                    {technicianPagePermission &&
                        <MenuItem onClick={() => onOpenLinkClick(tpLink?.guacamoleUrl, AUDIT_ACTIONS.TECHNICIAN_CONNECT)} disabled={!tpLink}>
                            <BuildIcon />
                            <Typography className={classes.buttonLabel}>{translations['hmd-technician-page']}</Typography>
                        </MenuItem>
                    }
                    {updateShellPermission &&
                        <MenuItem onClick={openUpdateShellModal}>
                            <GetApp />
                            <Typography className={classes.buttonLabel}>{translations['hmd-shell-update']}</Typography>
                        </MenuItem>
                    }
                    {reloadShellSettingsPermission &&
                        <MenuItem onClick={() => onBulkActionTriggered(COMMAND_TYPES.ReloadShellSettings)}>
                            <CachedIcon />
                            <Typography className={classes.buttonLabel}>{translations['hmd-reload-shell-settings']}</Typography>
                        </MenuItem>
                    }
                    {switchEnvironment
                        ? environmentType == EPOS_ENVIRONMENT_TYPE[EPOS_ENVIRONMENT_TYPE.Canary]
                            ? (
                                <MenuItem
                                    onClick={() => onBulkActionTriggered(COMMAND_SUBTYPES.SwitchEnviromentProduction)}
                                    data-a="action-switch-environment-production"
                                >
                                    <SwitchLeftIcon />
                                    <Typography className={classes.buttonLabel}>
                                        {translations['hm-bulk-action-switch-env-production']}
                                    </Typography>
                                </MenuItem>)
                            : (
                                <MenuItem disabled={environmentType?.length === 0}
                                    onClick={() => onBulkActionTriggered(COMMAND_SUBTYPES.SwitchEnviromentCanary)}
                                    data-a="action-switch-environment-canary"
                                >
                                    <SwitchLeftIcon />
                                    <Typography className={classes.buttonLabel}>
                                        {translations['hm-bulk-action-switch-env-canary']}
                                    </Typography>
                                </MenuItem>)
                        : null
                    }
                </MenuList>
            </Menu>
        </>
    );
};

const ActionPanel = (props) => {
    const {
        links,
        onOpenLinkClick,
        handleMaintenanceMode,
        openUpdateShellModal,
        isUnderMaintenance,
        statusName,
        permissions,
        environmentType,
        onBulkActionTriggered,
    } = props;

    const classes = useStyles({});
    const showMoreActionsMenu = useMediaQuery(useTheme().breakpoints.down('sm'));

    const {
        restartTerminalPermission,
        sshPermission,
        maintenancePermission,
        technicianPagePermission,
        updateShellPermission,
        reloadShellSettingsPermission,
        switchEnvironment,
    } = permissions;

    const tpLink = linkFinder(links, 'RDP');
    const sshLink = linkFinder(links, 'SSH');
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <Grid container spacing={2}>
            {restartTerminalPermission &&
                <Grid item>
                    <Button onClick={() => onBulkActionTriggered(COMMAND_TYPES.RestartPC)}>
                        <RefreshIcon />
                        <Typography className={classes.buttonLabel}>{translations['hmd-restart']}</Typography>
                    </Button>
                </Grid>
            }
            {showMoreActionsMenu
                ? (
                    <Grid item>
                        <MoreActionsMenu {...props} />
                    </Grid>)
                : (
                    <>
                        {sshPermission &&
                            <Grid item>
                                <Button disabled={!sshLink} onClick={() => onOpenLinkClick(sshLink?.guacamoleUrl, AUDIT_ACTIONS.SSH_CONNECT)}>
                                    <CodeIcon />
                                    <Typography className={classes.buttonLabel}>{translations['hmd-ssh-access']}</Typography>
                                </Button>
                            </Grid>}
                        {technicianPagePermission &&
                            <Grid item>
                                <Button disabled={!tpLink} onClick={() => onOpenLinkClick(tpLink?.guacamoleUrl, AUDIT_ACTIONS.TECHNICIAN_CONNECT)}>
                                    <BuildIcon />
                                    <Typography className={classes.buttonLabel}>{translations['hmd-technician-page']}</Typography>
                                </Button>
                            </Grid>}
                        {maintenancePermission &&
                            <Grid item>
                                <Button onClick={handleMaintenanceMode} disabled={statusName === EPOS_STATUS_NAMES.OFFLINE}>
                                    <BuildIcon />
                                    <Typography className={classes.buttonLabel}>
                                        {translations[isUnderMaintenance ? MaintenanceModeDisable.title : MaintenanceModeEnable.title]}
                                    </Typography>
                                </Button>
                            </Grid>}
                        {updateShellPermission &&
                            <Grid item>
                                <Button onClick={openUpdateShellModal} disabled={statusName === EPOS_STATUS_NAMES.OFFLINE}>
                                    <GetApp />
                                    <Typography className={classes.buttonLabel}>
                                        {translations['hmd-shell-update']}
                                    </Typography>
                                </Button>
                            </Grid>}
                        {reloadShellSettingsPermission &&
                            <Grid item>
                                <Button
                                    onClick={() => onBulkActionTriggered(COMMAND_TYPES.ReloadShellSettings)}
                                    disabled={statusName === EPOS_STATUS_NAMES.OFFLINE}
                                >
                                    <CachedIcon />
                                    <Typography className={classes.buttonLabel}>
                                        {translations['hmd-reload-shell-settings']}
                                    </Typography>
                                </Button>
                            </Grid>}
                        {switchEnvironment
                            ? environmentType == EPOS_ENVIRONMENT_TYPE[EPOS_ENVIRONMENT_TYPE.Canary]
                                ? (
                                    <Grid item>
                                        <Button
                                            onClick={() => onBulkActionTriggered(COMMAND_SUBTYPES.SwitchEnviromentProduction)}
                                            data-a="action-switch-environment-production"
                                        >
                                            <SwitchLeftIcon />
                                            <Typography className={classes.buttonLabel}>
                                                {translations['hm-bulk-action-switch-env-production']}
                                            </Typography>
                                        </Button>
                                    </Grid>)
                                : (
                                    <Grid item>
                                        <Button
                                            disabled={environmentType?.length === 0}
                                            onClick={() => onBulkActionTriggered(COMMAND_SUBTYPES.SwitchEnviromentCanary)}
                                            data-a="action-switch-environment-canary"
                                        >
                                            <SwitchLeftIcon />
                                            <Typography className={classes.buttonLabel}>
                                                {translations['hm-bulk-action-switch-env-canary']}
                                            </Typography>
                                        </Button>
                                    </Grid>)
                            : null
                        }
                    </>)
            }
        </Grid>
    );
};

export default ActionPanel;
