import * as React from 'react';
import clsx from 'clsx';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
    Box,
    Drawer,
    Divider,
    IconButton,
    Icon,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Collapse,
    withWidth,
    AppBar,
    Toolbar,
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext, setIsCommonMenuOpen } from 'context/globalContext';
import { EposContext } from 'context/epos';

import MenuUser from './MenuUser';
import MenuOperators from './MenuOperators';

import { menuItems } from 'data';

const drawerWidth = 260;

const pagesIdMap = {
    'home': 1,
    'dashboard': 1,
    'epos': 2,
    'users': 3
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: 0,
            color: '#000',
            background: '#fff',
        },
        toolbar: {
            position: 'relative',
            justifyContent: 'center'
        },
        menuButton: {
            marginRight: theme.spacing(4),
            position: 'absolute',
            left: '1rem',
            top: '0.3rem'
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap'
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(5) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(7) + 1,
            },
        },
        drawerPaper: {
            overflow: 'initial',
        },
        drawerToolbar: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        drawerButton: {
            position: 'absolute',
            top: '50%',
            right: '-15px',
            transform: 'translateY(-50%)',
            backgroundColor: theme.palette.common.white,
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.15)',
            '&:hover': {
                backgroundColor: '#fef5eb'
            }
        },
        menu: {
            flexGrow: 1,
            outline: 'none',
            overflow: 'hidden'
        },
        menuItem: {
            position: 'relative',
            '&.quantity': {
                flexGrow: 0,
                '& span': {
                    fontSize: '0.9rem'
                }
            }
        },
        subMenuItem: {
            padding: theme.spacing(0.5, 2, 0.5, 8)
        },
        subMenuIcon: {
            position: 'absolute',
            left: '0',
            width: '0.8em'
        },
        menuIcon: {
            marginRight: theme.spacing(2)
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: theme.spacing(1),
            overflow: 'hidden',
            '& img': {
                width: 150,
                marginLeft: '-8px'
            }
        },
        logoLink: {
            lineHeight: 1
        },
        logoMobile: {
            position: 'relative',
            top: '0.3rem',
            left: '-0.1rem',
            '& img': {
                width: '6.8rem'
            }
        },
        logoutForm: {
            display: 'none'
        }
    }),
);

interface IProps extends RouteComponentProps {
    width: string;
}

const MenuPanel = function({ history, location, width }: IProps) {
    const classes = useStyles({});
    const { globalSettings, permissions }: GlobalContextModel = React.useContext(GlobalContext);
    const { eposList } = React.useContext(EposContext) || { eposList: [] };
    const { translations } = React.useContext(GlobalContext) || { translations: {} };

    const { pathname } = location;
    const operators = globalSettings?.user?.DomainMappings || {};
    const isMultitenancyOperators = Object.keys(operators).length > 0;
    const quantity = eposList?.length;

    const activePage = pathname === '/'
        ? menuItems.find(menuItem => permissions[menuItem.permission])?.id
        : menuItems.map(e => e.isSubMenu ? e.subItems : e)
            .reduce((acc: Array<any>, val) => acc.concat(val), [])
            .filter((e) =>
                pathname.startsWith(e.path) ||
                e.subpath?.some(i => pathname.startsWith(i)) ||
                e.subItems?.some(i => pathname.startsWith(i.path))
            )[0]?.id;
    let activeSubMenu = menuItems.filter(menuItem => menuItem.isSubMenu)
        .find(subMenu => subMenu.subItems.find(item => item.id === activePage))?.id || 0;
    const isMobileView = (width === 'xs');
    const isTabletView = (width === 'sm');
    const [open, setOpen] = React.useState(!isMobileView && !isTabletView);
    const [expandedSubMenuId, setExpandedSubMenu] = React.useState(open ? activeSubMenu : 0);

    React.useEffect(() => {
        open && setExpandedSubMenu(activeSubMenu);
    }, [pathname]);

    function handleDrawerOpen() {
        setOpen(true);
        setIsCommonMenuOpen(true);
        setExpandedSubMenu(activeSubMenu);
    }

    function handleDrawerClose() {
        setOpen(false);
        setIsCommonMenuOpen(false);
        setExpandedSubMenu(0);
    }

    function handleSubmenuItemClick(path) {
        isMobileView && handleDrawerClose();
        history.push(path);
    }

    function handleListItemClick(path) {
        handleSubmenuItemClick(path);
        setExpandedSubMenu(0);
    }

    function toggleSubMenu(id) {
        id === expandedSubMenuId
            ? setExpandedSubMenu(0)
            : setExpandedSubMenu(id);
        !open && setOpen(true);
    }

    return (
        <React.Fragment>
            {isMobileView ? (
                <AppBar className={classes.appBar} position="relative">
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className={classes.logoMobile}>
                            <Link to="/" className={classes.logoLink}>
                                <img src="/img/logo.png" alt="draftkings" />
                            </Link>
                        </div>
                    </Toolbar>
                </AppBar>
            ) : null}
            <Drawer
                variant={isMobileView ? 'temporary' : 'permanent'}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                        [classes.drawerPaper]: true
                    }),
                }}
                open={open}
            >
                <div className={classes.drawerToolbar}>
                    <div className={classes.logo}>
                        <Link to="/" onClick={handleListItemClick} className={classes.logoLink}>
                            <img src="/img/logo.png" alt="draftkings" />
                        </Link>
                    </div>
                    <IconButton
                        size="small"
                        className={classes.drawerButton}
                        color="primary"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                    >
                        {
                            open ? <ChevronLeftIcon /> : <ChevronRightIcon />
                        }
                    </IconButton>
                </div>
                {
                    isMultitenancyOperators
                        ? (
                            <MenuOperators />
                        )
                        : (
                            <Divider />
                        )
                }
                <List className={classes.menu}>
                    {menuItems.map((menuItem) => (
                        (permissions[menuItem.permission] || menuItem.isSubMenu && menuItem.subItems.some(subItem => permissions[subItem.permission])) &&
                            <React.Fragment key={menuItem.id}>
                                <ListItem
                                    data-a={menuItem['data-a']}
                                    className={classes.menuItem}
                                    button
                                    component={MenuItem}
                                    onClick={()=> menuItem.isSubMenu
                                        ? toggleSubMenu(menuItem.id)
                                        : handleListItemClick(menuItem.path)
                                    }
                                    selected={menuItem.isSubMenu
                                        ? (open && menuItem.id === activeSubMenu && menuItem.id !== expandedSubMenuId) ||
                                        (!open && menuItem.id === activeSubMenu)
                                        : menuItem.id === activePage}
                                >
                                    <Icon className={classes.menuIcon} >
                                        {menuItem.icon}
                                        {menuItem.isSubMenu &&
                                        (expandedSubMenuId === menuItem.id
                                            ? <ArrowDropDownIcon className={classes.subMenuIcon} />
                                            : <ArrowRightIcon className={classes.subMenuIcon} />
                                        )
                                        }
                                    </Icon>
                                    <Box display="flex" justifyContent="space-between" flexGrow={1}>
                                        <ListItemText className={classes.menuItem} primary={translations[menuItem.text]} />
                                        {
                                            menuItem.id === pagesIdMap.epos && quantity ? (
                                                <ListItemText
                                                    className={clsx(classes.menuItem, 'quantity')}
                                                    primary={quantity}
                                                />
                                            ) : null
                                        }
                                    </Box>
                                </ListItem>
                                {menuItem.isSubMenu &&
                                    <Collapse in={menuItem.id === expandedSubMenuId} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {menuItem.subItems.map(subItem => (
                                                permissions[subItem.permission] &&
                                                    <ListItem
                                                        data-a={subItem['data-a']}
                                                        className={classes.subMenuItem}
                                                        button
                                                        key={subItem.id}
                                                        component={MenuItem}
                                                        onClick={() => handleSubmenuItemClick(subItem.path)}
                                                        selected={activePage === subItem.id}
                                                    >
                                                        <ListItemText
                                                            className={classes.menuItem}
                                                            primary={translations[subItem.text]}
                                                        />
                                                    </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                }
                            </React.Fragment>
                    ))}
                </List>
                <MenuUser />
            </Drawer>
        </React.Fragment>
    );
};

export default withWidth()(withRouter(MenuPanel));
