export const findNestedItem = (arr, name) => (
    arr.reduce((a, item) => {
        if (a) {
            return a;
        }

        if (item.name === name) {
            return item;
        }

        if (item.children?.length) {
            return findNestedItem(item.children, name);
        }
    }, null)
);
