import React, { useContext } from 'react';

import {
    IReportCardTransactionModel,
    IReportCardTransactionsColumnsModel,
} from 'api/models/reports';

import DataItemsCellContent from 'pages/Reports/components/DataItemsCellContent/DataItemsCellContent';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';
import {
    TRANSACTION_STATUS,
    TRANSACTION_STATUS_NAMES,
    TRANSACTION_TYPE,
    TRANSACTION_TYPE_DOCUMENT_URLS,
} from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import clsx from 'clsx';
import useStyles from 'pages/Reports/Supervisor/SupervisorReportTable/SupervisorReportTableCellContent/styles';

const CardTransactionsReportTableCellContent = ({
    className = '',
    id,
    isMobileView = false,
    handleRedirect,
    row,
}: {
    className?: string;
    id: keyof IReportCardTransactionsColumnsModel;
    isMobileView?: boolean;
    handleRedirect?: (href: string) => void;
    row: IReportCardTransactionModel;
}) => {
    const classes = useStyles();

    const { globalSettings, translations, dateTimeFormat, timeZone }: GlobalContextModel = useContext(GlobalContext);

    const rawValue = row[id];
    let child;

    switch (id) {
        case 'amount':
            child = (
                <span className={className}>
                    {formatNumberToCurrencyString(
                        row[id],
                        numberStyles(globalSettings?.settings?.Currency),
                        globalSettings?.settings?.CurrencySign,
                        globalSettings?.settings?.CurrencyFormat
                    )}
                </span>
            );
            break;
        case 'creationDate':
            const formattedDate = row[id]?.length
                ? formatDateByPattern(new Date(formatToIsoDateString(row[id])), dateTimeFormat, timeZone)
                : '';

            child = (
                <span className={clsx(classes.approvalDateWrap, className)}>
                    <span className={classes.approvalDate}>
                        {formattedDate}
                    </span>
                </span>);
            break;
        case 'itemIds':
            const accordionTitle = `${row[id].length} ${row[id].length > 1
                ? translations['gen-tickets']
                : translations['gen-ticket']
            }`;
            const url = TRANSACTION_TYPE_DOCUMENT_URLS[TRANSACTION_TYPE.cashierBetPlacement];

            child = (
                <DataItemsCellContent className={className}
                    accordionTitle={accordionTitle}
                    data={row[id]}
                    id={id}
                    isMobileView={isMobileView}
                    handleRedirect={handleRedirect}
                    url={url}
                />
            );
            break;
        case 'status':
            child = (
                <span className={className}>
                    {translations[TRANSACTION_STATUS_NAMES[TRANSACTION_STATUS[row[id]]]]}
                </span>);
            break;
        default:
            child = <span className={className}>{rawValue}</span>;
    }

    return child;
};

export default CardTransactionsReportTableCellContent;
