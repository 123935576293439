import React, { useContext } from 'react';
import { makeStyles, Typography, Theme } from '@material-ui/core';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { useFormatDate } from 'utils/customHooks';

import {
    resultsDateRangeFormatOptions as dateFormatOptions,
} from 'const';

import {
    getDateRangeString,
} from 'utils/formatDate';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
    resultsLegendWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',

        [breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            marginTop: spacing(2)
        }
    },
    resultsRangeTitle: {
        marginRight: spacing(0.5),
        fontWeight: 500,
    },
}));

const DateRangeLegend = ({ translationKey, fromDate, toDate }) => {
    const { translations }: GlobalContextModel = useContext(GlobalContext);
    const classes = useStyles({});

    const formatDate = useFormatDate(dateFormatOptions);
    const fromDateString = formatDate(fromDate);
    const toDateSring = formatDate(toDate);

    return (
        <div className={classes.resultsLegendWrapper}>
            <Typography className={classes.resultsRangeTitle}
                variant="body2"
            >
                {translations[translationKey]}
            </Typography>
            <Typography variant="body2" data-a="range-legend-dates">
                {getDateRangeString(fromDateString, toDateSring)}
            </Typography>
        </div>
    );
};

export default DateRangeLegend;
