export const sortingBySearch = (data, searchText) => {
    const filteredArr = data.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));

    return [...filteredArr.sort((a, b) => a.name.localeCompare(b.name))];
};

export const sortingByInitialCheckedWithSearch = (data, stillSelectedIds, searchText) => {
    const checkedItems = [];
    const uncheckedItems = [];

    data?.forEach(item => {
        if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
            stillSelectedIds.some(selectedId => selectedId === item.id)
                ? checkedItems.push(item)
                : uncheckedItems.push(item);
        }
    });

    return [...checkedItems.sort((a, b) => a.name.localeCompare(b.name)), ...uncheckedItems.sort((a, b) => a.name.localeCompare(b.name))];
};
