import React from 'react';
import useStyles from './styles';
import { Order, rowsPerPageOptions } from 'const';
import {
    Box,
    Table,
    TableBody,
    TablePagination,
} from '@material-ui/core';

import { IHeadRowVouchersReport } from 'data';
import { IReportVouchersModel } from 'api/models/reports';

import VouchersReportTableHead from 'pages/Reports/Vouchers/VouchersReportTable/VouchersReportTableHead/VouchersReportTableHead';
import VouchersReportTableRow from 'pages/Reports/Vouchers/VouchersReportTable/VouchersReportTableRow/VouchersReportTableRow';
import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';
import { PAGINATION_ACTIONS } from 'components/TablePaginationActions/TablePaginationActionsDumb';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { ORDER_BY_VOUCHERS_COLUMN } from 'const';

const VouchersReportTable = ({
    activeColumns,
    order,
    orderByColumn,
    page,
    rows,
    rowsCount,
    hidePagination,
    hideSorting,
    rowsPerPage,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    isMobileView,
    handleRedirect
}: {
    activeColumns: Array<IHeadRowVouchersReport>;
    order: Order;
    orderByColumn: ORDER_BY_VOUCHERS_COLUMN;
    page: number;
    rows: Array<IReportVouchersModel>;
    rowsCount: number;
    hidePagination?: boolean;
    hideSorting?: boolean;
    rowsPerPage: number;
    handleSort: (orderByColumn: ORDER_BY_VOUCHERS_COLUMN) => void;
    handleChangePage: (event, action: PAGINATION_ACTIONS) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isMobileView: boolean;
    handleRedirect: (voucherId: number) => void;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const classes = useStyles({});

    return (
        <React.Fragment>
            <Box className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    data-a="vouchers-report-table"
                >
                    <VouchersReportTableHead
                        classes={classes}
                        activeColumns={activeColumns}
                        order={order}
                        orderByColumn={orderByColumn}
                        onSort={handleSort}
                        hideSorting={hideSorting}
                    />
                    <TableBody>
                        {rows.map((row) => (
                            <VouchersReportTableRow
                                key={`${row.voucherId}${row.expirationDate}`}
                                row={row}
                                activeColumns={activeColumns}
                                isMobileView={isMobileView}
                                handleRedirect={handleRedirect}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {!hidePagination &&
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={rowsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage={translations['hm-table-rows-per-page']}
                    ActionsComponent={TablePaginationActions}
                />
            }
        </React.Fragment>
    );
};

export default VouchersReportTable;
