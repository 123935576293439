import * as React from 'react';
import clsx from 'clsx';
import { Checkbox, Paper, Typography } from '@material-ui/core';
import TestLabel from 'components/TestLabel/TestLabel';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import { IEposListItemModel } from 'api/models/epos';
import TerminalStatus from 'pages/Epos/components/TerminalStatus/TerminalStatus';

import TerminalsTilesImg from '../TerminalsTilesImg/TerminalsTilesImg';

import ErrorIcon from 'components/ErrorIcon/ErrorIcon';

import { EPOS_STATUS, transitionDuration, statusColors } from 'const';
import { bulkActions } from 'data';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { palette } from 'theme';

const styles = (theme: Theme) => (
    createStyles({
        wrap: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            borderRadius: theme.spacing(0.5),
            border: `1px solid ${theme.palette.grey[400]}`,
            cursor: 'pointer',
            transitionProperty: 'border-color, box-shadow',
            overflow: 'hidden',
            transitionDuration
        },
        wrapHover: {
            borderColor: theme.palette.grey[800]
        },
        wrapActive: {
            borderColor: theme.palette.primary.main
        },
        imageWrap: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: 112,
            background: `${theme.palette.grey[100]}`,
            lineHeight: 0,
            [theme.breakpoints.down('sm')]: {
                minHeight: 150
            },
            [theme.breakpoints.down('xs')]: {
                minHeight: 144
            }
        },
        image: {
            width: '100%'
        },
        selectBlock: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0,
            transitionProperty: 'backgroundColor, opacity',
            transitionDuration
        },
        selectBlockHover: {
            opacity: 0.7,
            backgroundColor: theme.palette.grey[800],
        },
        selectBlockActive: {
            opacity: 0.7,
            backgroundColor: theme.palette.primary.main,
        },
        checkbox: {
            position: 'absolute',
            top: `${-theme.spacing(2)}px`,
            left: theme.spacing(0),
            transitionProperty: 'top',
            transitionDuration: '0.5s',
            color: palette.common.white,
            '&:checked': {
                color: palette.common.white,
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            }
        },
        checkboxActive: {
            top: theme.spacing(0),
        },
        label: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            width: `calc(100% - ${theme.spacing(4)}px)`,
            padding: theme.spacing(1),
            borderRadius: theme.spacing(0.5),
            border: `1px solid ${palette.common.white}`,
            color: palette.common.white,
            textAlign: 'center',
            textTransform: 'capitalize',
            fontSize: '0.9em',
            fontWeight: 600,
            opacity: 0,
            transitionProperty: 'opacity',
            transitionDuration
        },
        labelActive: {
            opacity: 1
        },
        testLabel: {
            marginLeft: theme.spacing(0.5),
        },
        info: {
            flexGrow: 1,
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        },
        infoBox: {
            [theme.breakpoints.down('xs')]: {
                position: 'relative',
                paddingLeft: theme.spacing(1)
            }
        },
        infoErrors: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(1)
            }
        },
        error: {
            backgroundColor: `${theme.palette.error.light}`
        },
        name: {
            marginBottom: 0,
            fontSize: '0.8125rem',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        location: {
            color: theme.palette.text.secondary,

            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(1)
            }
        },
        iconWrap: {
            marginLeft: '-2px'
        },
        noImage: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            height: '100%',
            color: theme.palette.grey[500]
        },
        icon: {
            fontSize: '2.5rem'
        },
        title: {
            margin: 0,
            fontSize: '1rem',
            paddingTop: theme.spacing(0.5),
            textAlign: 'center',
            lineHeight: 1.2
        },
        statusLine: {
            position: 'absolute',
            left: 0,
            display: 'block',
            width: '4px',
            height: '100%',
            backgroundColor: statusColors[status],
            borderRadius: '24px'
        },
    })
);

const { useContext, useState, useRef } = React;

const TerminalsTilesItem = (props: {
    row: IEposListItemModel;
    selectedIds: Array<number>;
    handleRedirect: (id: number) => void;
    handleTirminalSelect: (event: React.MouseEvent<unknown>, id: number) => void;
    img: string;
    style?: any;
    classes?: any;
    isMobileView: boolean;
}) => {
    const { row, handleRedirect, selectedIds, handleTirminalSelect, img, classes, isMobileView } = props;
    const { translations, permissions }: GlobalContextModel = useContext(GlobalContext);

    const status = row.status !== EPOS_STATUS.OFFLINE && row.errors && row.errors.length ? EPOS_STATUS.WARNING : row.status;
    const tilesItemRef = useRef(null);

    const [isHovered, setIsHovered] = useState<boolean>(false);

    const { maintenancePermission } = permissions;
    const anyActionPermission = bulkActions.some(action => permissions[action.permission]) || maintenancePermission;

    const isSelected = selectedIds.indexOf(row.id) !== -1;

    return (
        <div ref={tilesItemRef} data-machineid={row.machineId} data-id={row.id}>
            <Paper
                className={clsx(
                    classes.wrap,
                    isSelected && classes.wrapActive
                )}
                elevation={isSelected || isHovered ? palette.elevation : 0}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => handleRedirect(row.id)}
            >
                <div className={classes.imageWrap}>
                    <div className={clsx(
                        classes.selectBlock,
                        isHovered && classes.selectBlockHover,
                        isSelected && classes.selectBlockActive
                    )}
                    >
                        {anyActionPermission &&
                            <Checkbox
                                className={clsx(classes.checkbox, (isSelected || isHovered) && classes.checkboxActive)}
                                checked={isSelected}
                                color="default"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleTirminalSelect(event, row.id);
                                }}
                            />
                        }
                        <Typography
                            className={clsx(classes.label, (isSelected || isHovered) && classes.labelActive)}
                            variant="body2"
                            onClick={(event) => {
                                if (isSelected) {
                                    event.stopPropagation();
                                    handleTirminalSelect(event, row.id);
                                }
                            }}
                        >
                            {isSelected ? translations['hm-tile-view-deselect'] : translations['hm-tile-view-open-terminal']}
                        </Typography>
                    </div>
                    <TerminalsTilesImg
                        img={img}
                    />
                </div>
                <div className={clsx(classes.info, status === EPOS_STATUS.WARNING && classes.error)}>
                    <div className={classes.infoBox}>
                        {
                            isMobileView
                                ? (
                                    <span
                                        className={classes.statusLine}
                                        style={{ backgroundColor: statusColors[status] }}
                                    />
                                )
                                : null
                        }
                        <Typography className={classes.name} gutterBottom variant="body2">
                            {row.name}
                            {row.isTest && <TestLabel className={classes.testLabel} />}
                        </Typography>
                        <TerminalStatus
                            statusName={row.statusName}
                            status={status}
                            transformDate={row.transformDate}
                            isMobileView={isMobileView}

                        />
                    </div>
                    <Typography className={clsx(classes.name, classes.location)} gutterBottom variant="body2">
                        {row.location}
                    </Typography>
                    <div className={classes.infoErrors}>
                        {
                            (status === EPOS_STATUS.WARNING
                                ? row.errors.map((error, i) => {
                                    return (
                                        <span
                                            key={`${row.id}-${i}`}
                                            className={classes.iconWrap}
                                        >
                                            <ErrorIcon
                                                color="error"
                                                id={error.errorId}
                                                fontSize="small"
                                            />
                                        </span>
                                    );
                                }) : ''
                            )}
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default withStyles(styles)(TerminalsTilesItem);
