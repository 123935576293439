import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IVouchersByIdPayload,
    IReportVouchersModel,
    IVoucherDetailsModel,
    IVouchersFullSearchPayload,
    IVouchersFullSearchResponse,
    IVoucherAutorizeReprintPayload,
    IRefreshVoucherDetailsPayload
} from 'api/models/reports';

import {
    IVouchersReportExportPayload,
    IReportsExportModel,
} from 'api/models/reportsExport';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class VouchersReportApi extends BaseApi {
    GetVouchersList(payload: IVouchersFullSearchPayload) {
        return this.QueryEmpBff<IVouchersFullSearchResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.VouchersReport.Search, this.QueryMethods.POST);
    }

    GetVouchersListById(payload: IVouchersByIdPayload) {
        return this.QueryEmpBff<Array<IReportVouchersModel>>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.VouchersReport.Search, this.QueryMethods.GET);
    }

    GetVouchersDetails(voucherId: number) {
        return this.QueryEmpBff<IVoucherDetailsModel>(handleCookieToPayload({}, 'showTestAccounts', 'IncludeTest'), `${EmpEndpoint.VouchersReport.Details}/${voucherId}`, this.QueryMethods.GET);
    }

    GetExport(payload: IVouchersReportExportPayload) {
        return this.QueryEmpBff<IReportsExportModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.VouchersReportExport.GetExport,
            this.QueryMethods.POST
        );
    }

    AutorizeReprint(payload: IVoucherAutorizeReprintPayload) {
        return this.QueryEmpBff<boolean>(payload, EmpEndpoint.VouchersReport.AutorizeReprint, this.QueryMethods.PATCH);
    }

    RefreshDetails(payload: IRefreshVoucherDetailsPayload) {
        return this.QueryEmpBff<IVoucherDetailsModel>(payload, EmpEndpoint.VouchersReport.RefreshDetails, this.QueryMethods.POST);
    }
}
