import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }: Theme) => ({
    notClickableRow: {
        cursor: 'default',
    },
    betshopName: {
        [breakpoints.down('xs')]: {
            color: palette.grey[700],
            whiteSpace: 'initial',
        }
    },
    inlineTitle: {
        [breakpoints.down('xs')]: {
            marginRight: spacing(4/8),
            fontWeight: typography.fontWeightMedium,
        }
    },
    documentId: {
        [breakpoints.down('xs')]: {
            color: palette.grey[700],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    userNameWrapper: {
        [breakpoints.down('xs')]: {
            marginRight: 'auto',
        },
    },
    userName: {
        [breakpoints.down('xs')]: {
            marginRight: spacing(1),
        }
    },
}));

export default useStyles;
