import * as React from 'react';

import {
    Menu,
    MenuList,
    MenuItem,
} from '@material-ui/core';

import { handleDownLoadBarcode } from 'pages/Users/business';

import { ResetPasswordData, ResetSupervisorBarcodeData, ResetSupervisorPinData } from 'data';

import ActionConfirm from 'pages/Users/components/ActionConfirm/ActionConfirm';

import Api from 'api/Api';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const AnchorMenuItem = (props) => {
    return (
        <MenuItem style={{ fontSize: '0.875rem' }}>
            {props.children}
        </MenuItem>
    );
};

const ActionsMenu = ({
    anchorEl,
    handleClose,
    userId,
    isResetPresent,
    isSupervisor,
    userEnabled,
    changeUserStatus,
    classes
}) => {
    const { translations, permissions }: GlobalContextModel = React.useContext(GlobalContext);

    const {
        usersResetPasswordPermission,
        usersBlockUnblockPermission,
        userResetSupervisorBarcodePermission,
        userResetSupervisorPinPermission,
        downloadSupervisorBarcodePermission
    } = permissions;

    const handleUserStatusChange = (e) => {
        changeUserStatus(userId);
        handleClose(e);
    };

    return (
        <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={(e) => e.stopPropagation()}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <MenuList className={classes.menu} onClick={handleClose}>
                {usersResetPasswordPermission && isResetPresent &&
                    <ActionConfirm
                        id={userId}
                        title={ResetPasswordData.title}
                        description={ResetPasswordData.description}
                        onSubmit={() => Api.User.ResetPassword({ id: userId })}
                        Anchor={() => <AnchorMenuItem>{translations[ResetPasswordData.button]}</AnchorMenuItem>}
                        dataA={'reset-password-button'}
                    />
                }
                {usersBlockUnblockPermission &&
                    <MenuItem
                        className={classes.menuItem}
                        onClick={handleUserStatusChange}
                        data-a={'block-user-button'}
                    >
                        {userEnabled
                            ? translations['users-actions-block']
                            : translations['users-actions-unblock']
                        }
                    </MenuItem>
                }
                {userResetSupervisorBarcodePermission && isSupervisor &&
                    <ActionConfirm
                        dataA={'user-reset-supervisor-barcode-button'}
                        id={userId}
                        title={ResetSupervisorBarcodeData.title}
                        description={ResetSupervisorBarcodeData.description}
                        onSubmit={() => Api.User.ResetSupervisorBarcode({ id: userId })}
                        Anchor={() => <AnchorMenuItem>{translations[ResetSupervisorBarcodeData.button]}</AnchorMenuItem>}
                    />
                }
                {downloadSupervisorBarcodePermission && isSupervisor &&
                    <MenuItem
                        className={classes.menuItem}
                        onClick={() => handleDownLoadBarcode(userId)}
                        data-a={'download-barcode-button'}
                    >
                        {translations['users-actions-download-barcode-button']}
                        <svg id={'download-barcode'} className={classes.barcodeSvg} />
                    </MenuItem>
                }
                {userResetSupervisorPinPermission && isSupervisor &&
                    <ActionConfirm
                        dataA={'user-reset-supervisor-pin-button'}
                        id={userId}
                        title={ResetSupervisorPinData.title}
                        description={ResetSupervisorPinData.description}
                        onSubmit={() => Api.User.ResetSupervisorPin({ id: userId })}
                        Anchor={() => <AnchorMenuItem>{translations[ResetSupervisorPinData.button]}</AnchorMenuItem>}
                    />
                }
            </MenuList>
        </Menu>
    );
};

export default ActionsMenu;
