import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SelectedQuantity from 'components/SelectedQuantity/SelectedQuantity';

import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';

import { IEposListItemModel } from 'api/models/epos';

import { IHeadRow, IEposData, headRowsDefault, bulkActions } from 'data';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import BulkActionsMenu from 'pages/Epos/components/BulkActionsMenu/BulkActionsMenu';

type Order = 'asc' | 'desc';

interface IEnhancedTableProps {
    rows: Array<IEposListItemModel>;
    classes: ReturnType<typeof useStyles>;
    selectedIds: Array<number>;
    activeColumns: Array<IHeadRow>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IEposData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClearSelectedRows: () => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        statusBar: {
            padding: `${theme.spacing(3)}px ${theme.spacing(6)}px 0`
        },
        paper: {
            padding: `${theme.spacing(3)}px ${theme.spacing(6)}px 0`
        },
        table: {
            minWidth: 750,
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '4px'
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        row: { cursor: 'pointer' },
        errorRow: {
            backgroundColor: `${theme.palette.error.light}`
        }
    }),
);

const useTableHeadStyles = makeStyles((theme: Theme) =>
    createStyles({
        headRow: {
            position: 'relative',
            height: '49px'
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 8px',
            flexGrow: 1
        },
        selected: {
            minWidth: '90px'
        },
        checkbox: {
            padding: `${(theme.spacing(1) / 2) + 1}px ${theme.spacing(1) + 1}px ${(theme.spacing(1) / 2) + 1}px ${theme.spacing(1) - 1}px`,
        },
        cell: {
            padding: theme.spacing(2, 0.5)
        },
        hideCellMobile: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        withoutCheck: {
            '& th:first-child': {
                paddingLeft: theme.spacing(2),

                [theme.breakpoints.down('md')]: {
                    paddingLeft: theme.spacing(1),
                },
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: 0,
                },
            }
        },
        hidden: {
            display: 'none'
        }
    }),
);

export default function TerminalsTableHead(props: IEnhancedTableProps) {
    const {
        rows,
        classes,
        onSelectAllClick,
        order,
        orderBy,
        selectedIds,
        rowCount,
        onRequestSort,
        activeColumns,
        handleClearSelectedRows
    } = props;

    const c = useTableHeadStyles({});

    const { translations, permissions }: GlobalContextModel = React.useContext(GlobalContext);

    const {
        restartTerminalPermission,
        restartShellPermission,
        restartPrinterPermission,
        reinitBillValPermission,
        maintenancePermission
    } = permissions;

    const actionPermissions =
        restartTerminalPermission ||
        restartShellPermission ||
        restartPrinterPermission ||
        reinitBillValPermission ||
        maintenancePermission;

    const createSortHandler = (property: keyof IEposData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const anyActionPermission = bulkActions.some(action => permissions[action.permission]) || maintenancePermission;

    return (
        <TableHead>
            <TableRow className={clsx(!actionPermissions && c.withoutCheck)} >
                {
                    actionPermissions &&
                        <TableCell padding="checkbox" >
                            {selectedIds && selectedIds.length > 0
                                ? (
                                    <div className={c.actions}>
                                        <SelectedQuantity
                                            selectedIds={selectedIds}
                                            rowsCount={rows.length}
                                            handleSelectAllClick={onSelectAllClick}
                                            roundBorder={anyActionPermission}
                                        />
                                        {anyActionPermission &&
                                            <BulkActionsMenu rows={rows} selectedIds={selectedIds} handleClearSelectedRows={handleClearSelectedRows} />}
                                    </div>
                                ) : (
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selectedIds && selectedIds.length > 0 && selectedIds.length < rowCount}
                                        checked={selectedIds && selectedIds.length === rowCount && rowCount > 0}
                                        onChange={onSelectAllClick}
                                        inputProps={{ 'aria-label': 'select all terminals' }}
                                    />
                                )
                            }
                        </TableCell>
                }
                {activeColumns.map((row) => (
                    <TableCell
                        key={row.id}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        variant="head"
                        className={c.cell}
                        classes={!headRowsDefault.some(e => e.id === row.id) && { root: c.hideCellMobile } || {}}
                        style={{ width: row.width || 'auto' }}
                    >
                        <div className={clsx(selectedIds && selectedIds.length > 0 && c.hidden)}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {translations[row.label]}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
