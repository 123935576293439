export function removeUndefinedEntries<T extends object>(object: T): Partial<T> {
    const result = { ...object };

    Object.keys(result).forEach(key => result[key] === undefined && delete result[key]);

    return result;
}

export const getIdFromObject = ({ id }) => id;

export function hasKeyWithTrueValue(object, keysArray) {
    for (const key of keysArray) {
        if (object.hasOwnProperty(key) && Boolean(object[key]) === true) {
            return true;
        }
    }

    return false;
}

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeepObjects(target, ...sources) {
    if (!sources.length) return target;

    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeepObjects(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeepObjects(target, ...sources);
}
