import * as React from 'react';
import {
    Box,
    Typography,
    withStyles,
    Theme,
} from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';

import useStyles from './styles';
import {
    ticketDetailsAuthorizeReprintReason,
} from 'data';

import { GlobalContext } from 'context/globalContext';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

import { GlobalContextModel } from 'api/models/general';
import { ITicketDetailsModel, IExpandedDetailsBlocks } from 'api/models/reports';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': { borderBottom: 0 },
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' }
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        minHeight: 68,
    },
    content: {
        margin: '0',
        alignItems: 'center',
        '&$expanded': {
            margin: '0'
        },

        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    expanded: {
        backgroundColor: theme.palette.grey[100]
    },
    expandIcon: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
        }
    },
    modalBox: {
        padding: `0 ${theme.spacing(3)}px`,
        marginBottom: `${theme.spacing(2)}px`,
    },
    inputWrap: {
        '& .Mui-focused': {
            color: `${theme.palette.primary.main}`,
        }
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
}))(MuiAccordionDetails);

const { useContext } = React;

interface ITicketDetailsPrintout {
    data: ITicketDetailsModel;
    isExpanded: boolean;
    onExpansionClick(name: keyof IExpandedDetailsBlocks): void;
}

export const TicketDetailsPrintout = (props) => {
    const { data, isExpanded, onExpansionClick }: ITicketDetailsPrintout = props;
    const { translations, dateTimeFormat, timeZone }: GlobalContextModel = useContext(GlobalContext);
    const classes = useStyles({});

    return (
        <Accordion expanded={isExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={() => onExpansionClick('printout')}
            >
                <Box className={classes.iconBox}>
                    <RefreshIcon color="primary" />
                </Box>
                <Box className={classes.reprintBox}>
                    <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                            {translations['emp-reports-ticket-authorize-reprint-details']}
                        </Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            {data?.printouts?.map((printout, id) => {
                return (
                    <AccordionDetails
                        key={`printout-${id}`}
                        id={`printout-${id}`}
                        className={classes.secondaryDetailsBox}
                    >
                        <Box padding={3} className={classes.blockDetailsFull}>
                            <div className={classes.mainBlockDetailsBox}>
                                <Typography
                                    variant="body2"
                                    className={classes.mainBlockDetailsName}
                                >
                                    {translations['emp-reports-ticket-reprint-authorization-date']}
                                </Typography>
                                <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                    {
                                        printout?.lastAuthorizedDate
                                            ? formatDateByPattern(
                                                new Date(formatToIsoDateString(printout.lastAuthorizedDate)),
                                                dateTimeFormat,
                                                timeZone
                                            )
                                            : '-'
                                    }
                                </Typography>
                            </div>

                            <div className={classes.mainBlockDetailsBox}>
                                <Typography
                                    variant="body2"
                                    className={classes.mainBlockDetailsName}
                                >
                                    {translations['emp-reports-ticket-authorized-by']}
                                </Typography>
                                <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                    {
                                        printout?.lastAuthorizedEntityName ?? '-'
                                    }
                                </Typography>
                            </div>
                            <div className={classes.mainBlockDetailsBox}>
                                <Typography
                                    variant="body2"
                                    className={classes.mainBlockDetailsName}
                                >
                                    {translations['emp-reports-ticket-reason']}
                                </Typography>
                                <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                    {
                                        printout?.authorizeReprintReason
                                            ? translations[
                                                ticketDetailsAuthorizeReprintReason.find(
                                                    reason => reason.id === printout.authorizeReprintReason
                                                )['label']
                                            ]
                                            : '-'
                                    }
                                </Typography>
                            </div>
                            {/* <div className={classes.mainBlockDetailsBox}>
                                <Typography
                                    variant="body2"
                                    className={classes.mainBlockDetailsName}
                                >
                                    {translations['emp-reports-ticket-comment']}
                                </Typography>
                                <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                    {
                                        printout?.authorizeReprintComment ?? '-'
                                    }
                                </Typography>
                            </div> */}

                            <div className={classes.mainBlockDetailsBox}>
                                <Typography
                                    variant="body2"
                                    className={classes.mainBlockDetailsName}
                                >
                                    {translations['emp-reports-ticket-reprinted-date']}
                                </Typography>
                                <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                    {
                                        printout?.lastPrintDate
                                            ? formatDateByPattern(
                                                new Date(formatToIsoDateString(printout.lastPrintDate)),
                                                dateTimeFormat,
                                                timeZone
                                            )
                                            : '-'
                                    }
                                </Typography>
                            </div>
                            <div className={classes.mainBlockDetailsBox}>
                                <Typography
                                    variant="body2"
                                    className={classes.mainBlockDetailsName}
                                >
                                    {translations['emp-reports-ticket-reprinted-by']}
                                </Typography>
                                <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                    {
                                        printout?.lastPrintEntityName ?? '-'
                                    }
                                </Typography>
                            </div>
                        </Box>
                    </AccordionDetails>);
            })
            }
        </Accordion>
    );
};

export default TicketDetailsPrintout;
