import React, { useState } from 'react';
import clsx from 'clsx';
import useBaseStyles from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableRow/styles';
import useStyles from './styles';

import { IReportSupervisorModel } from 'api/models/reports';
import { headSupervisorReportColumns } from 'data';
import {
    Box,
    TableRow,
    TableCell,
} from '@material-ui/core';
import SupervisorReportTableCellContent from 'pages/Reports/Supervisor/SupervisorReportTable/SupervisorReportTableCellContent/SupervisorReportTableCellContent';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { TRANSACTION_TYPE } from 'const';

const SupervisorReportTableRow = ({
    row,
    handleRedirect,
    isMobileView,
}: {
    row: IReportSupervisorModel;
    handleRedirect: (id: string, transactionType: TRANSACTION_TYPE) => void;
    isMobileView: boolean;
}) => {
    const baseClasses = useBaseStyles();
    const localClasses = useStyles();
    const [classes] = useState({ ...baseClasses, ...localClasses });

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <TableRow className={classes.row}
            hover
            onClick={() => handleRedirect(row.documentId, row.transactionType)}
            tabIndex={-1}
        >
            {!isMobileView
                ? headSupervisorReportColumns.map((column, index) => {
                    const { id } = column;
                    const className = clsx(
                        classes.cell,
                        classes.defaultCell,
                        classes[`${column.id}Cell`],
                    );

                    return (
                        <TableCell className={className}
                            scope={index === 0 ? 'row' : null}
                            key={id}
                        >
                            <SupervisorReportTableCellContent
                                id={id}
                                row={row}
                            />
                        </TableCell>
                    );
                }): (
                    <>
                        <TableCell className={clsx(classes.cell, classes.firstCell)}>
                            <SupervisorReportTableCellContent
                                id="approvalDate"
                                row={row}
                            />
                            <SupervisorReportTableCellContent className={classes.betshopName}
                                id="betshopName"
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <SupervisorReportTableCellContent className={classes.inlineTitle}
                                id="approvalTypes"
                                row={row}
                            />
                            <SupervisorReportTableCellContent className={classes.inlineTitle}
                                id="transactionType"
                                row={row}
                            />
                            <SupervisorReportTableCellContent className={classes.documentId}
                                id="documentId"
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={clsx(classes.cell, classes.lastCell)}>
                            <Box className={classes.userNameWrapper}>
                                <SupervisorReportTableCellContent className={classes.userName}
                                    id="supervisorName"
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-supervisor-amount']}
                                </span>
                                <SupervisorReportTableCellContent
                                    id="amount"
                                    row={row}
                                />
                            </Box>
                        </TableCell>
                    </>
                )
            }
        </TableRow>
    );
};

export default SupervisorReportTableRow;
