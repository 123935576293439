import BaseApi from './_BaseApi';
import { IMaintenanceImageModel } from 'api/models/image';
import { EmpEndpoint } from 'api/Endpoint';
import { IMAGE_TYPES } from 'const';
import { emulateOptions } from 'utils/emulateOptions';

export default class ImageApi extends BaseApi {
    // Do not remove! Used for testings big amount of terminals
    GetMaintenanceImages(): Promise<Array<IMaintenanceImageModel>> {
        return this.QueryEmpBff({}, EmpEndpoint.Image.GetMaintenanceImages, this.QueryMethods.GET);
    }

    GetTerminalImages(machineIds: Array<string>, types: Array<IMAGE_TYPES>): Promise<string> {
        let params = {
            machineIds,
            types
        };

        emulateOptions.useMock && (params['useCDN'] = emulateOptions.useCDN);

        const empEndPoint = emulateOptions.useMock ? EmpEndpoint.Image.GetTerminalImagesMock : EmpEndpoint.Image.GetTerminalImages;

        return this.QueryEmpBff(params, empEndPoint);
    }

    UploadMaintenanceImage(file: any): Promise<Array<IMaintenanceImageModel>> {
        let formData = new FormData();

        formData.append('file', file);

        return this.Submit(formData, EmpEndpoint.Image.UploadMaintenanceImage);
    }

    DeleteMaintenanceImage(name: string): Promise<Array<IMaintenanceImageModel>> {
        let params = {
            name
        };

        return this.QueryEmpBff(params, EmpEndpoint.Image.DeleteMaintenanceImage, this.QueryMethods.POST);
    }
}
