export const delayCancellablePromise = (cancelablePromise, delay = 0) => {
    const promise = new Promise((resolve, reject) => {
        cancelablePromise
            .then((val) => setTimeout(() => resolve(val), delay))
            .catch((error) => reject(error));
    });

    promise['cancel'] = cancelablePromise['cancel'];

    return promise;
};

export function getQueryParams(url) {
    let qparams = {},
        parts = (url || '').split('?'),
        qparts, qpart;

    if (parts.length <= 1) {
        return qparams;
    } else {
        qparts = parts[1].split('&');

        for (let i in qparts){

            qpart = qparts[i].split('=');
            qparams[decodeURIComponent(qpart[0])] =
                           decodeURIComponent(qpart[1] || '');
        }
    }

    return qparams;
}
