export type IFunctionWithAnyParams = (...args: any[]) => any;

interface IDebouncedFunction <F extends IFunctionWithAnyParams> {
    (...args: Parameters<F>): ReturnType<F>;
    clear: () => void;
    flush: () => void;
}

export default function debounce<F extends IFunctionWithAnyParams>(
    func: F,
    wait: number,
    immediate: boolean,
): IDebouncedFunction<F> {
    let timeout, args, context, timestamp, result;

    if (null == wait) wait = 100;

    function later() {
        let last = Date.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;

            if (!immediate) {
                result = func.apply(context, args);
                args = null;
                context = null;
            }
        }
    }

    const debounced = function() {
        context = this;
        args = arguments;

        timestamp = Date.now();

        let callNow = immediate && !timeout;

        if (!timeout) timeout = setTimeout(later, wait);

        if (callNow) {
            result = func.apply(context, args);
            args = null;
            context = null;
        }

        return result;
    };

    debounced.clear = function() {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    debounced.flush = function() {
        if (timeout) {
            result = func.apply(context, args);
            args = null;
            context = null;

            clearTimeout(timeout);
            timeout = null;
        }
    };

    return debounced;
}
