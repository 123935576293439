import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IBetshopListPayload,
    IBetshopListResult,
    INetworkBetshop,
    IBetshopAddPayload,
    IBetshopEditPayload,
    IBetshopDetailsResponse,
    IBetshopAddResponse,
} from 'api/models/network-management';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class NetworkManagementApi extends BaseApi {
    GetBetshops(payload: IBetshopListPayload) {
        return this.QueryEmpBff<IBetshopListResult>(handleCookieToPayload(payload, 'showTestAccounts', 'includeTestCustomer'), EmpEndpoint.NetworkManagement.GetBetshops);
    }

    GetBetshopsLite(params = {}) {
        return this.QueryEmpBff<Array<INetworkBetshop>>(handleCookieToPayload(params, 'showTestAccounts', 'includeTestCustomer'), EmpEndpoint.NetworkManagement.GetBetshopsLite, this.QueryMethods.GET);
    }

    GetSingleBetshop(id: number) {
        return this.QueryEmpBff<IBetshopDetailsResponse>(handleCookieToPayload({ betshopId: id }, 'showTestAccounts', 'includeTestCustomer'), EmpEndpoint.NetworkManagement.GetSingleBetshop, this.QueryMethods.GET);
    }

    AddBetshop(payload: IBetshopAddPayload) {
        return this.QueryEmpBff<IBetshopAddResponse>(payload, EmpEndpoint.NetworkManagement.AddBetshop);
    }

    EditBetshop(payload: IBetshopEditPayload) {
        return this.QueryEmpBff<IBetshopAddResponse>(payload, EmpEndpoint.NetworkManagement.EditBetshop);
    }
}
