import React from 'react';

import { Order, ORDER_BY_CARD_TRANSACTIONS_COLUMN, ORDER_BY_CARD_TRANSACTIONS_COLUMN_NAMES } from 'const';
import usePropStyles from 'pages/Reports/Tickets/TicketsReportTable/styles';
import useTableHeadStyles from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableHead/styles';

import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';

import {
    headCardTransactionsReportColumns,
} from 'data';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

export default function CardTransactionsReportTableHead({
    classes,
    hideSorting,
    onSort,
    order,
    orderByColumn,
}: {
    classes: ReturnType<typeof usePropStyles>;
    hideSorting?: boolean;
    onSort: (orderByColumn: ORDER_BY_CARD_TRANSACTIONS_COLUMN) => void;
    order: Order;
    orderByColumn: ORDER_BY_CARD_TRANSACTIONS_COLUMN;
}) {
    const localClasses = useTableHeadStyles({});

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <TableHead>
            <TableRow>
                {
                    headCardTransactionsReportColumns.map(({ id, label, disablePadding, width }) => {
                        const isColumnSortable = Object.keys(ORDER_BY_CARD_TRANSACTIONS_COLUMN_NAMES)
                            .some(orderByColumnVoucher => orderByColumnVoucher === id);

                        return (
                            <TableCell className={localClasses.cell}
                                key={id}
                                padding={disablePadding ? 'none' : 'default'}
                                sortDirection={isColumnSortable && order}
                                variant="head"
                                classes={!headCardTransactionsReportColumns.some(e => e.id === id) ? { root: localClasses.hideCellMobile } : {}}
                                scope="col"
                                style={{ width: width || 'auto' }}
                            >
                                {isColumnSortable && !hideSorting
                                    ? (
                                        <TableSortLabel
                                            active={orderByColumn === ORDER_BY_CARD_TRANSACTIONS_COLUMN_NAMES[id]}
                                            direction={order}
                                            hideSortIcon={!isColumnSortable}
                                            onClick={isColumnSortable ? () => onSort(ORDER_BY_CARD_TRANSACTIONS_COLUMN_NAMES[id]) : undefined}
                                        >
                                            {isColumnSortable && translations[label]}
                                            {isColumnSortable ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === Order.descending ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>)
                                    : translations[label]}
                            </TableCell>
                        );
                    })
                }
            </TableRow>
        </TableHead>
    );
}
