import BaseApi from './_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IUserList,
    IUserPayload,
    IEditUserPayload,
    IBlockUserPayload,
    IUserIdPayload,
    IUserDetails,
    ICheckUserPermission,
    IResetCredentialPayload,
    ICheckUserParams,
    IUserDownloadBarcode
} from 'api/models/user';
import { GeneralResponseResult } from '../models/general';

export default class UserApi extends BaseApi {
    TokenRefresh(): Promise<Object> {
        return this.QueryEmpBff({}, EmpEndpoint.User.TokenRefresh);
    }

    Add(payload: IUserPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, EmpEndpoint.User.Add);
    }

    List(): Promise<Array<IUserList>> {
        return this.QueryEmpBff({}, EmpEndpoint.User.List, this.QueryMethods.GET);
    }

    Edit(payload: IEditUserPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, EmpEndpoint.User.Edit);
    }

    Details(payload: IUserIdPayload): Promise<IUserDetails> {
        return this.QueryEmpBff(payload, EmpEndpoint.User.Details, this.QueryMethods.GET);
    }

    ResetPassword(payload: IResetCredentialPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, EmpEndpoint.User.ResetPassword);
    }

    ResetSupervisorBarcode(payload: IResetCredentialPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, EmpEndpoint.User.ResetSupervisorBarcode);
    }

    DownloadSupervisorBarcode(payload: IUserIdPayload): Promise<IUserDownloadBarcode> {
        return this.QueryEmpBff(payload, EmpEndpoint.User.DownloadSupervisorBarcode, this.QueryMethods.GET);
    }

    ResetSupervisorPin(payload: IResetCredentialPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, EmpEndpoint.User.ResetSupervisorPin);
    }

    Block(payload: IBlockUserPayload): Promise<GeneralResponseResult> {
        return this.QueryEmpBff(payload, !payload.enabled ? EmpEndpoint.User.Block : EmpEndpoint.User.Unblock);
    }

    CheckUserPermission(params: ICheckUserParams): Promise<ICheckUserPermission> {
        return this.QueryEmpBff(params, EmpEndpoint.User.CheckUserPermission, this.QueryMethods.GET);
    }
}
