import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) => ({
    root: {
        width: '100%',
    },
    table: {
        width: 'auto',
        minWidth: '100%',
        tableLayout: 'fixed',
        border: `1px solid ${palette.grey[300]}`,

        [breakpoints.down('xs')]: {
            display: 'block',
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& thead': {
            [breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        '& tbody': {
            [breakpoints.down('xs')]: {
                display: 'block',
            }
        },
    },
    tableWrapper: {
        overflow: 'auto',

        [breakpoints.down('xs')]: {
            flexGrow: 1
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    paginationCountProgress: {
        marginLeft: spacing(0.5),
    }
}));

export default useStyles;
