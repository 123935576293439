import React, { useState } from 'react';
import clsx from 'clsx';
import useBaseStyles from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableRow/styles';
import useSupervisorReportsStyles from 'pages/Reports/Supervisor/SupervisorReportTable/SupervisorReportTableRow/styles';
import useStyles from './styles';

import { IReportCardTransactionModel } from 'api/models/reports';
import { headCardTransactionsReportColumns } from 'data';
import {
    Box,
    TableRow,
    TableCell,
} from '@material-ui/core';
import CardTransactionsReportTableCellContent from 'pages/Reports/CardTransactions/CardTransactionsReportTable/CardTransactionsReportTableCellContent/CardTransactionsReportTableCellContent';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const CardTransactionsReportTableRow = ({
    row,
    handleRedirect,
    isMobileView,
}: {
    row: IReportCardTransactionModel;
    handleRedirect: (href: string) => void;
    isMobileView: boolean;
}) => {
    const baseClasses = useBaseStyles();
    const supervisorReportClasses = useSupervisorReportsStyles();
    const localClasses = useStyles();
    const [classes] = useState({ ...baseClasses, ...supervisorReportClasses, ...localClasses });

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <TableRow className={clsx(classes.row, classes.notClickableRow)}
            tabIndex={-1}
        >
            {!isMobileView
                ? headCardTransactionsReportColumns.map((column, index) => {
                    const { id } = column;
                    const className = clsx(
                        classes.cell,
                        classes.defaultCell,
                        classes[`${column.id}Cell`],
                    );

                    return (
                        <TableCell className={className}
                            scope={index === 0 ? 'row' : null}
                            key={id}
                        >
                            <CardTransactionsReportTableCellContent
                                id={id}
                                row={row}
                                handleRedirect={handleRedirect}
                            />
                        </TableCell>
                    );
                }): (
                    <>
                        <TableCell className={clsx(classes.cell, classes.firstCell)}>
                            <CardTransactionsReportTableCellContent
                                id={'creationDate'}
                                row={row}
                            />
                            <CardTransactionsReportTableCellContent className={classes.betshopName}
                                id={'betshopName'}
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <span className={classes.inlineTitle}>
                                {translations['emp-reports-transaction-receipt-id']}
                            </span>
                            <CardTransactionsReportTableCellContent className={classes.receiptId}
                                id={'receiptNumber'}
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <CardTransactionsReportTableCellContent
                                id={'issuerName'}
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={clsx(classes.cell)}>
                            <Box className={clsx(classes.boxWithTitle, classes.boxStart)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-transaction-rrn-id']}
                                </span>
                                <CardTransactionsReportTableCellContent
                                    id={'transactionRRNId'}
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-transaction-id']}
                                </span>
                                <CardTransactionsReportTableCellContent
                                    id={'transactionId'}
                                    row={row}
                                />
                            </Box>
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <Box className={clsx(classes.boxWithTitle, classes.boxStart)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-transaction-amount']}
                                </span>
                                <CardTransactionsReportTableCellContent
                                    id={'amount'}
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-ticket-status']}
                                </span>
                                <CardTransactionsReportTableCellContent
                                    id={'status'}
                                    row={row}
                                />
                            </Box>
                        </TableCell>
                        <TableCell className={clsx(classes.cell, classes.dropdownCell)}>
                            <CardTransactionsReportTableCellContent
                                id="itemIds"
                                row={row}
                                handleRedirect={handleRedirect}
                                isMobileView
                            />
                        </TableCell>
                    </>
                )
            }
        </TableRow>
    );
};

export default CardTransactionsReportTableRow;
