import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        position: 'relative',

        [breakpoints.down('xs')]: {
            display: 'block',
        }
    },
    header: {
        display: 'flex',
        width: '100%',
        padding: spacing(6, 6, 3),

        [breakpoints.down('md')]: {
            padding: spacing(6, 2, 2),
        },
        [breakpoints.down('xs')]: {
            display: 'block',
            padding: spacing(2),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',

        [breakpoints.down('xs')]: {
            marginBottom: spacing(2),
            minHeight: '36px',
        },
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%',
    },
    actions: {
        display: 'flex',
        marginLeft: 'auto',
    },
    options: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'stretch',
        minHeight: spacing(4),
        marginBottom: spacing(1),

        [breakpoints.down('sm')]: {
            marginTop: spacing(1)
        },
    },
    searchWrap: {
        width: '322px',
        flexShrink: 0,
        order: 1,

        [breakpoints.down('xs')]: {
            width: '100%',
            padding: 0
        }
    },
    divider: {
        [breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: spacing(3, 6, 0),
        backgroundColor: palette.common.white,

        [breakpoints.down('md')]: {
            padding: spacing(2),
        },
        [breakpoints.down('xs')]: {
            padding: spacing(0, 0, 2),
        }
    },
    alertWrapper: {
        marginBottom: spacing(2)
    },
    resultsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing(1),
        minHeight: spacing(4),

        [breakpoints.down('xs')]: {
            marginBottom: spacing(2),
            padding: spacing(0, 2),
        }
    },
    resultsWrapper: {
        flex: 1,
    },
    columnChooser: {
        [breakpoints.down('md')]: {
            display: 'none'
        }
    }
}));

export default useStyles;
