import React from 'react';
import useStyles from 'pages/Reports/Tickets/TicketsReportTable/styles';
import { ORDER_BY_CARD_TRANSACTIONS_COLUMN, Order, rowsPerPageOptions } from 'const';
import {
    Box,
    Table,
    TableBody,
    TablePagination,
} from '@material-ui/core';

import { IReportCardTransactionModel } from 'api/models/reports';

import CardTransactionsReportTableHead from 'pages/Reports/CardTransactions/CardTransactionsReportTable/CardTransactionsReportTableHead/CardTransactionsReportTableHead';
import CardTransactionsReportTableRow from 'pages/Reports/CardTransactions/CardTransactionsReportTable/CardTransactionsReportTableRow/CardTransactionsReportTableRow';
import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const CardTransactionsReportTable = ({
    order,
    orderByColumn,
    page,
    rows,
    rowsCount,
    hideSorting,
    rowsPerPage,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRedirect,
    isMobileView
}: {
    order: Order;
    orderByColumn: ORDER_BY_CARD_TRANSACTIONS_COLUMN;
    page: number;
    rows: Array<IReportCardTransactionModel>;
    rowsCount: number;
    hideSorting?: boolean;
    rowsPerPage: number;
    handleSort: (orderByColumn: ORDER_BY_CARD_TRANSACTIONS_COLUMN) => void;
    handleChangePage: (event, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRedirect: (href: string) => void;
    isMobileView: boolean;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const classes = useStyles({});

    return (
        <React.Fragment>
            <Box className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                >
                    <CardTransactionsReportTableHead
                        classes={classes}
                        order={order}
                        orderByColumn={orderByColumn}
                        onSort={handleSort}
                        hideSorting={hideSorting}
                    />
                    <TableBody>
                        {rows.map((row) => (
                            <CardTransactionsReportTableRow
                                key={row.transactionId}
                                row={row}
                                handleRedirect={handleRedirect}
                                isMobileView={isMobileView}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rowsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={translations['hm-table-rows-per-page']}
                ActionsComponent={TablePaginationActions}
            />
        </React.Fragment>
    );
};

export default CardTransactionsReportTable;
