import React, { useState } from 'react';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import { Filters } from 'components/Filters/FiltersBox/FiltersBox';
import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import FiltersWithSearch from 'components/Filters/FiltersWithSearch/FiltersWithSearch';
import { toggleItemInArray } from 'utils/arrayMethods';
import { addYears } from 'utils/formatDate';
import {
    AUDIT_LOG_FILTERS,
    AUDIT_LOG_FILTERS_TITLES,
    AUDIT_ACTION_KEYS,
    IFilterListItem
} from 'const';

const AuditLogFiltersList = ({
    setApplyDisabled,
    initFilters,
    appliedFilters,
    setFilters,
    scrollElement,
    handleSearchInFilters,
    searchInFiltersList
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const { byAction, byDate } = AUDIT_LOG_FILTERS;
    const [ isExpanded, setExpanded ] = useState({ byAction: initFilters.byAction.length > 0, byDate: false });
    const filtersList = Object.keys(AUDIT_ACTION_KEYS)
        .map(key => ({ id: Number(key), name: translations[AUDIT_ACTION_KEYS[key]] }));

    const handleExpanded = (key: string) => {
        setExpanded({ ...isExpanded, [key]: !isExpanded[key] });
    };

    const toggleFilters = (item: IFilterListItem, arr: Array<IFilterListItem>) => {
        const selectedIndex = arr.findIndex(arrItem => arrItem.id === item.id);

        return toggleItemInArray(item, selectedIndex, arr);
    };

    const handleFilterClick = (label: string, item: IFilterListItem) => {
        setFilters({ ...initFilters, [label]: toggleFilters(item, initFilters[label]) });
    };

    const changeDates = (dates) => {
        setFilters({ ...initFilters, ...dates });
    };

    return (
        <>
            <Filters
                label={byDate}
                title={AUDIT_LOG_FILTERS_TITLES.byDate}
                expanded={isExpanded.byDate}
                handleExpanded={handleExpanded}
            >
                <DateTimePicker
                    setApplyDisabled={setApplyDisabled}
                    fromDate={initFilters.fromDate}
                    toDate={initFilters.toDate}
                    minDate={addYears(new Date(), -1)}
                    changeDates={changeDates}
                />
            </Filters>
            <FiltersWithSearch
                label={byAction}
                title={AUDIT_LOG_FILTERS_TITLES.byAction}
                data={filtersList}
                expanded={isExpanded.byAction}
                activeFilterIds={initFilters.byAction}
                appliedActiveFilters={appliedFilters.byAction}
                handleFilterClick={handleFilterClick}
                handleExpanded={handleExpanded}
                scrollElement={scrollElement}
                handleSearchInFilters={handleSearchInFilters}
                searchText={searchInFiltersList[byAction].text}
                isSearchFieldShown={searchInFiltersList[byAction].isFieldOpen}
            />
        </>
    );
};

export default AuditLogFiltersList;
