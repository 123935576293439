import * as React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface IBackdropSpinnerProps {
    open: boolean;
    fullScreen?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: ({ fullScreen }: Partial<IBackdropSpinnerProps>) => ({
            position: fullScreen ? 'fixed' : 'absolute',
            zIndex: theme.zIndex.drawer - 1,
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }),
    }),
);

const BackdropSpinner = ({ open, fullScreen }: IBackdropSpinnerProps) => {
    const classes = useStyles({ fullScreen });

    return (
        <Backdrop open={open} className={classes.backdrop}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default BackdropSpinner;
