import React from 'react';

import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';

const { useState } = React;

const ActionConfirm = (props) => {
    const { id, onSubmit, title, description, Anchor, dataA } = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        onSubmit();
        handleClose();
    };

    return (
        <React.Fragment>
            <span onClick={handleClickOpen} data-a={dataA}>
                <Anchor />
            </span>

            <ConfirmModal
                id={id}
                isOpen={open}
                title={title}
                description={description}
                onClose={handleClose}
                onConfirm={handleSubmit}
            />
        </React.Fragment>
    );
};

export default React.memo(ActionConfirm);
