import * as React from 'react';
import clsx from 'clsx';

import { IEposListItemModel } from 'api/models/epos';
import { eposStatuses } from 'components/StatusBar/StatusBar';
import ErrorsMainButton from './ErrorsMainButton';
import ErrorsFilterButton from './ErrorsFilterButton';

import IFiltersState from './types';
import useStyles from './styles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const ERROR_TYPE_ID = {
    SHELL_ERROR: 1,
    MONEY_ERROR: 2,
    CARD_ERROR: 3,
    PRINTER_ERROR: 4,
    NETWORK_ERROR: 8,
    SHELL_UPDATE_ERROR: 15,
    1: 'SHELL_ERROR',
    2: 'MONEY_ERROR',
    3: 'CARD_ERROR',
    4: 'PRINTER_ERROR',
    8: 'NETWORK_ERROR',
    15: 'SHELL_UPDATE_ERROR'
};

const NO_ERRORS = {
    SHELL_ERROR: false,
    MONEY_ERROR: false,
    CARD_ERROR: false,
    PRINTER_ERROR: false,
    NETWORK_ERROR: false,
    SHELL_UPDATE_ERROR: false,
};

const initialState: IFiltersState = {
    shellErrors: 0,
    moneyErrors: 0,
    printerErrors: 0,
    networkErrors: 0,
    shellUpdateErrors: 0,
    selectedErrors: NO_ERRORS
};

const buttonHeight = 48;

function reducer(state, action) {
    switch (action.type) {
        case 'shellErrors':
            return { ...state, shellErrors: action.payload };
        case 'moneyErrors':
            return { ...state, moneyErrors: action.payload };
        case 'printerErrors':
            return { ...state, printerErrors: action.payload };
        case 'networkErrors':
            return { ...state, networkErrors: action.payload };
        case 'shellUpdateErrors':
            return { ...state, shellUpdateErrors: action.payload };
        case 'selectedErrors':
            return { ...state, selectedErrors: action.payload };
        default:
            return { ...state };
    }
}

const ErrorsFilter = (props: { data: Array<IEposListItemModel>; onErrorFiltering(errorsArr): void; filterStatusId: number; isMobileView: boolean }) => {
    const classes = useStyles({});
    const { isMobileView } = props;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [wrapHeight, setWrapHeight] = React.useState<number>(buttonHeight);
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    React.useEffect(() => {
        if (props.data.length) {
            countErrors();
            checkToClear();
        }
    }, [props]);

    function countErrors() {
        let shell = 0;
        let money = 0;
        let printer = 0;
        let network = 0;
        let shellUpdate = 0;

        props.data.map((e) => {
            e?.errors?.map((error) => {
                switch (error.errorId) {
                    case ERROR_TYPE_ID.SHELL_ERROR:
                        shell++;
                        break;
                    case ERROR_TYPE_ID.MONEY_ERROR:
                    case ERROR_TYPE_ID.CARD_ERROR:
                        money++;
                        break;
                    case ERROR_TYPE_ID.PRINTER_ERROR:
                        printer++;
                        break;
                    case ERROR_TYPE_ID.NETWORK_ERROR:
                        network++;
                        break;
                    case ERROR_TYPE_ID.SHELL_UPDATE_ERROR:
                        shellUpdate++;
                        break;
                    default:
                        break;
                }
            });
        });

        dispatch({ type: 'shellErrors', payload: shell });
        dispatch({ type: 'moneyErrors', payload: money });
        dispatch({ type: 'printerErrors', payload: printer });
        dispatch({ type: 'networkErrors', payload: network });
        dispatch({ type: 'shellUpdateErrors', payload: shellUpdate });
    }

    function checkToClear() {
        if (props.filterStatusId !== eposStatuses.errors) {
            for (let error in state.selectedErrors) {
                if (state.selectedErrors.hasOwnProperty(error)) {
                    state.selectedErrors[error] = false;
                }
            }

            dispatch({ type: 'selectedErrors', payload: state.selectedErrors });
        }
    }

    function onErrorHandler(errors: Array<string>) {
        errors.forEach((err) => {
            state.selectedErrors[err] = !state.selectedErrors[err];
            dispatch({ type: 'selectedErrors', payload: state.selectedErrors });
        });
        props.onErrorFiltering(
            Object.keys(state.selectedErrors).map(
                (err) => state.selectedErrors[err] && ERROR_TYPE_ID[err]
            )
        );
    }

    function getActiveClass(errors: Array<string>) {
        const isActive: Boolean = errors.some((err) => state.selectedErrors?.[err]);

        return isActive ? classes.active : null;
    }

    let erorsTypesCount = 0;

    Object.values(state).forEach(e => typeof e !== 'object' && !!e && erorsTypesCount++);

    const allCountWarnings = Object.values(state).reduce((prevVal: any, curVal: number) => {
        if (typeof curVal !== 'object') {
            return prevVal + curVal;
        }

        return prevVal;
    });

    function handleIsOpen() {
        isOpen ? setWrapHeight(buttonHeight) : setWrapHeight((buttonHeight + 4) * (erorsTypesCount + 1));
        setIsOpen(!isOpen);
    }

    return (
        props.data.length && Object.values(state).some(e => typeof e !== 'object' && !!e)
            ? (
                <div className={clsx(classes.wrap, isMobileView && classes.wrapMobile)} style={isMobileView && erorsTypesCount ? { height: wrapHeight } : {}}>
                    {
                        isMobileView &&
                            <ErrorsMainButton
                                count={allCountWarnings}
                                isOpen={isOpen}
                                handleIsOpen={handleIsOpen}
                                selectedErrors={state.selectedErrors}
                            />
                    }
                    <div className={clsx(classes.wrapButtons, !isOpen && isMobileView && classes.hidden)}>
                        {state.shellErrors
                            ? (
                                <ErrorsFilterButton
                                    errorsArr={['SHELL_ERROR']}
                                    isMobileView={isMobileView}
                                    activeClass={getActiveClass(['SHELL_ERROR'])}
                                    iconName="web"
                                    errorsCount={state.shellErrors}
                                    translation={translations['hm-filter-shell']}
                                    onErrorHandler={onErrorHandler}
                                />)
                            : null
                        }
                        {state.moneyErrors
                            ? (
                                <ErrorsFilterButton
                                    errorsArr={['MONEY_ERROR']}
                                    isMobileView={isMobileView}
                                    activeClass={getActiveClass(['MONEY_ERROR'])}
                                    iconName="monetization_on"
                                    errorsCount={state.moneyErrors}
                                    translation={translations['hm-filter-money']}
                                    onErrorHandler={onErrorHandler}
                                />)
                            : null
                        }
                        {state.printerErrors
                            ? (
                                <ErrorsFilterButton
                                    errorsArr={['PRINTER_ERROR']}
                                    isMobileView={isMobileView}
                                    activeClass={getActiveClass(['PRINTER_ERROR'])}
                                    iconName="print"
                                    errorsCount={state.printerErrors}
                                    translation={translations['hm-filter-printer']}
                                    onErrorHandler={onErrorHandler}
                                />)
                            : null
                        }
                        {state.networkErrors
                            ? (
                                <ErrorsFilterButton
                                    errorsArr={['NETWORK_ERROR']}
                                    isMobileView={isMobileView}
                                    activeClass={getActiveClass(['NETWORK_ERROR'])}
                                    iconName="network_check"
                                    errorsCount={state.networkErrors}
                                    translation={translations['hm-filter-network']}
                                    onErrorHandler={onErrorHandler}
                                />)
                            : null
                        }
                        {state.shellUpdateErrors
                            ? (
                                <ErrorsFilterButton
                                    errorsArr={['SHELL_UPDATE_ERROR']}
                                    isMobileView={isMobileView}
                                    activeClass={getActiveClass(['SHELL_UPDATE_ERROR'])}
                                    iconName="get_app"
                                    errorsCount={state.shellUpdateErrors}
                                    translation={translations['hmd-shell-update']}
                                    onErrorHandler={onErrorHandler}
                                />)
                            : null
                        }
                    </div>
                </div>)
            : null
    );
};

export default ErrorsFilter;
