import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    buttonLabel: {
        padding: '0 0.5rem',
        fontSize: '0.9rem',
        lineHeight: 1.75,
        fontWeight: 600,
        textTransform: 'none',
    },
    menu: {
        outline: 'none',
        display: 'flex',
        flexDirection: 'column'
    }
});

export default useStyles;
