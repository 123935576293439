import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { Checkbox, TableRow, TableCell } from '@material-ui/core';
import TestLabel from 'components/TestLabel/TestLabel';
import TerminalsTableCell from '../TerminalsTableCell/TerminalsTableCell';
import ErrorIcon from 'components/ErrorIcon/ErrorIcon';
import { EPOS_STATUS, statusColors } from 'const';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        name: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',

            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(1.5),
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }
        },
        nameTest: {
            maxWidth: `calc(100% - ${theme.spacing(3)}px)`,
            marginRight: theme.spacing(0.5),
        },
        row: {
            cursor: 'pointer',

            [theme.breakpoints.down('xs')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, auto)',
                padding: theme.spacing(1, 2),
                borderBottom: '1px solid #e0e0e0',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }
        },
        errorRow: {
            backgroundColor: `${theme.palette.error.light}`
        },
        selectedRow: {
            '&.Mui-selected': {
                backgroundColor: `${theme.palette.primary.light}`,
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.light}`
                }
            }
        },
        cell: {
            padding: theme.spacing(2, 0.5)
        },
        cellEllipsis: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

            [theme.breakpoints.down('xs')]: {
                padding: 0
            }
        },
        defaultCell: {
            [theme.breakpoints.down('xs')]: {
                position: 'relative',
                minWidth: '150px',
                border: 'none',
                padding: 0
            }
        },
        checkboxCell: {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        nameCell: {
            [theme.breakpoints.down('xs')]: {
                padding: 0,
                fontWeight: 500,
            }
        },
        firstCell: {
            paddingLeft: theme.spacing(2),

            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing(1),
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
            },
        },
        nameWrap: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
        },
        locationCell: {
            [theme.breakpoints.down('xs')]: {
                alignSelf: 'center',
                textAlign: 'right'
            }
        },
        statusCellName: {
            [theme.breakpoints.down('xs')]: {
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1.5),
            }
        },
        timeCell: {
            [theme.breakpoints.down('xs')]: {
                visibility: 'hidden',
                padding: 0
            }
        },
        errorsCell: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                padding: `${theme.spacing(1)}px 0 0 ${theme.spacing(1)}px`,
                gridColumnStart: 1,
                gridColumnEnd: 3
            }
        },
        breakWordCell: {
            wordBreak: 'break-word'
        },
        hideCellTablet: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobileShow: {
            display: 'none',
            [theme.breakpoints.down('xs')]: {
                display: 'inline-block'
            }
        },
        statusLine: {
            position: 'absolute',
            left: 0,
            display: 'block',
            width: '4px',
            height: '100%',
            backgroundColor: statusColors[status],
        },
        statusLineTop: {
            borderRadius: '24px 24px 0 0'
        },
        statusLineBottom: {
            borderRadius: '0 0 24px 24px'
        },
    }),
);

const TerminalsTableRow = (props) => {
    const {
        isItemSelected,
        labelId,
        activeColumns,
        row,
        handleRedirect,
        activeColumnsHasErrors,
        handleTirminalSelect,
        isMobileView
    } = props;
    const status = row.status !== EPOS_STATUS.OFFLINE && row.errors && row.errors.length ? EPOS_STATUS.WARNING : row.status;
    const classes = useStyles({ status });
    const { translations, permissions }: GlobalContextModel = React.useContext(GlobalContext);

    const {
        restartTerminalPermission,
        restartShellPermission,
        restartPrinterPermission,
        reinitBillValPermission,
        maintenancePermission
    } = permissions;

    const actionPermissions =
        restartTerminalPermission ||
        restartShellPermission ||
        restartPrinterPermission ||
        reinitBillValPermission ||
        maintenancePermission;

    return (
        <TableRow
            hover
            onClick={() => handleRedirect(row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            className={clsx(
                classes.row,
                row.status !== EPOS_STATUS.OFFLINE &&
                row.errors &&
                row.errors.length &&
                activeColumnsHasErrors() &&
                classes.errorRow,
                isItemSelected && classes.selectedRow)
            }
            data-a={`epos-table-row-${row.id}`}
        >
            {
                actionPermissions &&
                    <TableCell
                        className={classes.checkboxCell}
                        padding="checkbox"
                        variant="body"
                    >
                        <Checkbox
                            color="primary"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleTirminalSelect(event, row.id);
                            }}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </TableCell>
            }
            <TableCell
                className={clsx(classes.cell, classes.cellEllipsis, classes.defaultCell, classes.nameCell, !actionPermissions && classes.firstCell)}
                component="td" id={labelId}
                scope="row"
                padding="none"
                variant="body"
            >
                <div className={classes.nameWrap}>
                    {
                        isMobileView
                            ? <span className={clsx(classes.statusLine, classes.statusLineTop)} style={{ backgroundColor: statusColors[status] }} /> : null
                    }
                    <span
                        className={clsx(classes.name, row.isTest && classes.nameTest)}
                        data-id={row.id}
                        data-machineid={row.machineId}
                    >
                        {row.name}
                    </span>
                    {row.isTest &&
                        <TestLabel />
                    }
                </div>
            </TableCell>
            <TerminalsTableCell
                className={clsx(classes.cell, classes.cellEllipsis, classes.defaultCell, classes.locationCell)}
                name="location"
                row={row}
                activeColumns={activeColumns}
            />
            {isMobileView
                ? (
                    <TableCell className={clsx(classes.cell, classes.cellEllipsis, classes.defaultCell)} variant="body">
                        <span className={clsx(classes.statusLine, classes.statusLineBottom)} style={{ backgroundColor: statusColors[status] }} />
                        <span className={classes.statusCellName}>
                            {row.statusName}
                        </span>
                        <span className={classes.mobileShow}>
                            {row.transformDate}
                        </span>
                    </TableCell>
                )
                : (
                    activeColumns.some(col => col.id === 'statusName')
                        ? (
                            <TableCell className={clsx(classes.cell, classes.cellEllipsis, classes.defaultCell)} variant="body">
                                <span className={classes.statusCellName}>
                                    {row.statusName}
                                </span>
                            </TableCell>)
                        : null
                )
            }
            <TerminalsTableCell
                className={clsx(classes.cell, classes.timeCell, classes.defaultCell)}
                name="deliveryTime"
                row={row}
                activeColumns={activeColumns}
            />
            {
                activeColumnsHasErrors() &&
                    <TableCell className={clsx(classes.cell, classes.defaultCell, classes.errorsCell)} variant="body">
                        {
                            (row.status !== EPOS_STATUS.OFFLINE && row.errors && row.errors.length)
                                ? row.errors.map((error, i) => {
                                    return (
                                        <ErrorIcon
                                            color="error"
                                            key={`${row.id}-${i}`}
                                            id={error.errorId}
                                            fontSize="default"
                                        />
                                    );
                                }) : ''
                        }
                    </TableCell>
            }
            <TerminalsTableCell
                className={clsx(classes.cell, classes.cellEllipsis, classes.hideCellTablet)}
                name="os"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={clsx(classes.cell, classes.cellEllipsis, classes.hideCellTablet)}
                name="linuxPatchVersion"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={clsx(classes.cell, classes.hideCellTablet)}
                name="shell"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={clsx(classes.cell, classes.cellEllipsis, classes.hideCellTablet)}
                name="monitoringAgentVersion"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={clsx(classes.cell, classes.hideCellTablet)}
                name="ip"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={clsx(classes.cell, classes.cellEllipsis, classes.hideCellTablet)}
                name="dataSetVersion"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={classes.hideCellTablet}
                name="minStake"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={clsx(classes.cell, classes.cellEllipsis, classes.hideCellTablet)}
                name="sportsBookConnectionType"
                row={row}
                activeColumns={activeColumns}
            />
            <TerminalsTableCell
                className={clsx(classes.cell, classes.hideCellTablet)}
                name="sportsBookLeaderIP"
                row={row}
                activeColumns={activeColumns}
            />
            {
                activeColumns.some(item => item.id === 'updateVersion')
                    ? (
                        <TableCell className={clsx(classes.cell, classes.hideCellTablet)} variant="body">
                            {row['updateVersion'] ? row['updateVersion'] : ''}
                        </TableCell>
                    )
                    : null
            }
            {
                activeColumns.some(item => item.id === 'updateStatus')
                    ? (
                        <TableCell className={clsx(classes.cell, classes.hideCellTablet, classes.breakWordCell)} variant="body">
                            {row['updateStatus'] ? translations[`hm-update-status-${row['updateStatus']}`] : ''}
                        </TableCell>
                    )
                    : null
            }
            <TerminalsTableCell
                className={clsx(classes.cell, classes.hideCellTablet)}
                name="environmentType"
                row={row}
                activeColumns={activeColumns}
            />
        </TableRow>
    );
};

export default TerminalsTableRow;
