import { makeStyles, createStyles } from '@material-ui/core';
import defaultTheme from 'theme';

const useStyles = makeStyles((theme) =>
    createStyles({
        absoluteLoader: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-20px',
            marginTop: '-20px'
        },
        imageBox: {
            position: 'relative',
            borderRadius: '4px',
            border: 'solid 1px #e0e0e0',
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
                '& > button': { display: 'none' }
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: '0 auto',
                maxWidth: '420px',
                '&::before': {
                    content: 'none',
                    display: 'initial',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.8)',
                },
                '& > button': {
                    display: 'inline-flex',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',
                    textTransform: 'none',
                    color: `${theme.palette.grey[100]}`,
                    borderColor: `${theme.palette.grey[100]}`,
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: theme.palette.primary.main,
                        borderColor: 'transparent'
                    }
                },
            },
            background: 'none',
            '&:hover': {
                '&::before': {
                    content: 'none',
                    display: 'initial',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.8)',
                },
                '& > button': {
                    display: 'inline-flex',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',
                    textTransform: 'none',
                    color: `${theme.palette.grey[100]}`,
                    borderColor: `${theme.palette.grey[100]}`,
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: theme.palette.primary.main,
                        borderColor: 'transparent'
                    }
                }
            }
        },
        imageBoxLink: {
            '&:hover': {
                '&::before': {
                    content: '\'\'',
                },
            }
        },
        imageBoxVncLink: {
            [theme.breakpoints.down('sm')]: {
                '&::before': {
                    content: '\'\'',
                },
            },
        },
        imageBoxScreenNotAvailable: {
            background: `${theme.palette.grey[100]}`,
        },
        noImageBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            minHeight: '50%',
        },
        screen: {
            minHeight: '175px'
        },
        noImage: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(3, 0),
            color: defaultTheme.palette.grey[500]
        },
        img: {
            width: '100%'
        },
        title: {
            paddingTop: defaultTheme.spacing(2),
            fontSize: '1.7rem',
            fontWeight: 500
        },
        icon: {
            fontSize: '4rem'
        },
        errorScreen: {
            height: '100%',
            background: defaultTheme.palette.common.white
        }
    })
);

export default useStyles;
