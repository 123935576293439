import { LanguageListItemModel } from 'api/models/general';

const languageIdCookie = 'languageId';
const languageCodeCookie = 'languageId';

export default function getCookieValue(a: string) {
    const b = document.cookie.toLocaleLowerCase().match(`(^|;)\\s*${a.toLocaleLowerCase()}\\s*=\\s*([^;]+)`);

    return b ? b[b.length - 1] : '';
}

export const handleCookieToPayload = (payload, cookie: string, cookieForPayload: string = '') => {
    if (getCookieValue(cookie)) payload[cookieForPayload] = getCookieValue(cookie);

    return payload;
};

export const setLanguageCookie = (payload, languages: Array<LanguageListItemModel>, cookie: 'languageId' | 'languageCode' = languageIdCookie) => {
    const value = getCookieValue(languageIdCookie);
    const defaultLanguageId = languages.find(lang => lang.IsDefault).Id;

    if (value && languages.some(language => language[cookie] === value)) {
        if (cookie === languageIdCookie) {
            payload[languageIdCookie] = value;
        } else {
            payload[languageCodeCookie] = languages.find(lang => String(lang.Id) === value).Code;
        }
    } else {
        if (cookie === languageIdCookie) {
            payload[languageIdCookie] = defaultLanguageId;
        } else {
            payload[languageCodeCookie] = languages.find(lang => lang.Id === defaultLanguageId).Code;
        }
    }

    return payload;
};
