import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import { AccordionSummary } from '@material-ui/core';

const summaryStyles = ((theme: Theme) =>
    createStyles({
        root: {
            minHeight: `${theme.spacing(8)}px`,
            padding: `0 ${theme.spacing(2)}px`,
            '&$expanded': {
                minHeight: `${theme.spacing(8)}px`
            },
            '&$focused': {
                backgroundColor: theme.palette.common.white
            }
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            minHeight: `${theme.spacing(4)}px`,
            margin: '4px 0',
            '&$expanded': {
                minHeight: `${theme.spacing(5)}px`,
                margin: '4px 0'
            },
            '&$focused': {
                backgroundColor: theme.palette.common.white
            }
        },
        expandIcon: {
            padding: 3
        },
        expanded: {}
    })
);

const StyledAccordionSummary = withStyles(summaryStyles)(
    AccordionSummary
);

export default StyledAccordionSummary;
