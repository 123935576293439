import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    fullWidth?: boolean;
    ['data-a']?: string;
};

const Modal: React.FunctionComponent<ModalProps> = props => {
    const { isOpen, onClose, children, fullWidth, ['data-a']: dataA = '' } = props;

    return ReactDOM.createPortal(
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={fullWidth}
                data-a={dataA.length ? dataA : undefined}
            >
                {children}
            </Dialog>
        </div>,
        document.getElementById('modal')
    );
};

export default Modal;
