import { transformDate, lastErrorDate } from './formatDate';
import { EPOS_STATUS } from 'const';

type Order = 'asc' | 'desc';

function defaultSorting(data, direction: Order) {
    if (data?.length) {
        const withErrors = [];
        const withoutErrors = [];

        data.forEach(el => {
            el.transformDate = transformDate(el.deliveryTime);

            if (el.status !== EPOS_STATUS.OFFLINE && el.errors && el.errors.length) {
                withErrors.push(el);
            } else {
                el.errors = [];
                withoutErrors.push(el);
            }
        });

        const withErrorsSorted = withErrors.sort((a, b) => lastErrorDate(a.errors) - lastErrorDate(b.errors));

        if (direction === 'desc') {
            const withoutErrorsSorted = withoutErrors
                .sort((a, b) => {

                    return a.status - b.status ||
                        a.location.localeCompare(b.location) ||
                        a.name.localeCompare(b.name);
                });

            return withErrorsSorted.concat(withoutErrorsSorted);
        } else {
            const withoutErrorsSorted = withoutErrors
                .sort(
                    (a, b) => b.status - a.status ||
                        a.location.localeCompare(b.location) ||
                        a.name.localeCompare(b.name)
                );

            return withoutErrorsSorted.concat(withErrorsSorted);
        }
    }

    return [];
}

export default defaultSorting;
