/* global JSON */
import React from 'react';

import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import defaultTheme from 'theme';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CustomPrompt = ({ message, cleanUp }) => {
    const parsedMessage = JSON.parse(message);
    const cancel = () => cleanUp(false);
    const ok = () => cleanUp(true);

    return (
        <>
            <CssBaseline />
            <MuiThemeProvider theme={defaultTheme}>
                <Dialog open onClose={cancel}>
                    <DialogTitle id="alert-dialog-title">{parsedMessage.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {parsedMessage.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button data-a="custom-prompt-button-yes" onClick={ok} color="primary" autoFocus>
                            {parsedMessage.yes}
                        </Button>
                        <Button data-a="custom-prompt-button-no" onClick={cancel} color="primary">
                            {parsedMessage.no}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        </>
    );
};

export default CustomPrompt;
