import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: spacing(2.5),
    },
}));

export default useStyles;
