import { CancelablePromise } from 'api/services/_BaseApi';
import axios from 'axios';

export const cancelRequests = (apiRequests: Record<string, CancelablePromise<any>>) => {
    for (const key in apiRequests) {
        apiRequests[key]?.cancel();
    }
};

export const isCanceled = (value: any) => {
    return axios.isCancel(value);
};

export function throwNetworkError(error) {
    if (!isCanceled(error)) throw error;
}
