import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useContext
} from 'react';

import {
    Box,
    Drawer,
    Divider,
} from '@material-ui/core';

import {
    IFilterListItem,
} from 'const';

import {
    vouchersStatusData,
    vouchersTypeData,
    entityTypeData
} from 'data';

import {
    IVouchersReportBaseFilterList,
    IVouchersReportFilterList,
    checkOpenExtension,
    countFilterQty,
    toggleFilters,
} from 'pages/Reports/business';

import { FilterButton, Header, ControlPanel } from 'components/Filters/Filters';
import { FiltersBox } from 'components/Filters/FiltersBox/FiltersBox';
import FiltersWithSearch from 'components/Filters/FiltersWithSearch/FiltersWithSearch';
import DateTimePicker from 'pages/Users/AuditLogs/components/DateTimePicker/DateTimePicker';
import ThresholdFilter from 'components/ThresholdFilter/ThresholdFilter';

import useStyles from './styles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const initOpenExpansions: Partial<Record<keyof IVouchersReportBaseFilterList, boolean>> = {};

const terminalFiltersBoxes = [
    { label: 'issuedByEntityIds', title: 'emp-reports-vouchers-issued-by' },
    { label: 'issuedBetshopIds', title: 'emp-reports-vouchers-issue-sportsbook' },
    { label: 'cashedOutByEntityIds', title: 'emp-reports-vouchers-cashed-out-by' },
    { label: 'cashedOutBetshopIds', title: 'emp-reports-vouchers-cash-out-sportsbook' },
];
const searchListInitial = {};

terminalFiltersBoxes.forEach(box => searchListInitial[box.label] = { text: '', isFieldOpen: false });

const thresholdFilters = [
    { label: 'minVoucherCost', title: 'emp-reports-vouchers-voucher-cost' }
];
const initialValidityState = {
    timeRange: true,
    ...thresholdFilters
        .reduce((accumulator, { label }) => ({ ... accumulator, [label]: true }), {}),
};
const checkValidity = (validityState) => Object.values(validityState).every(isValid => isValid);

const VouchersReportFilters = function ({
    filtersLists,
    appliedFilters,
    setAppliedFilters,
    filterListInitialWithDate
}: {
    readonly filtersLists: IVouchersReportBaseFilterList;
    readonly appliedFilters: IVouchersReportFilterList;
    readonly setAppliedFilters: (filters: IVouchersReportFilterList) => void;
    filterListInitialWithDate: IVouchersReportFilterList;
}) {
    const classes = useStyles({});

    const { globalSettings }: GlobalContextModel = useContext(GlobalContext);

    const [filters, setFilters] = useState(filterListInitialWithDate);
    const [searchInFiltersList, setSearchInFiltersList] = useState(searchListInitial);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openExpansions, setOpenExpansions] = useState(initOpenExpansions);

    const filtersWrapperRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setFilters({ ...appliedFilters });
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
    }, Object.values(appliedFilters));

    const [validityState, setValidityState] = useState({ ...initialValidityState });
    const isApplyDisabled = !checkValidity(validityState);
    const setTimeRangeInvalid = useCallback((isInvalid) => {
        setValidityState(validityState => ({ ...validityState, timeRange: !isInvalid }));
    }, []);

    const handleOpenDrawer = () => {
        setFilters({ ...appliedFilters });
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
    };

    const handleFilterClick = useCallback((key: string, item: IFilterListItem) => {
        setFilters(filters => {
            const filterArr = toggleFilters(item, filters[key]);

            return {
                ...filters,
                [key]: filterArr,
            };
        });
    }, []);

    const handleApplyFilters = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
        setAppliedFilters({ ...filters });
        setSearchInFiltersList({ ...searchListInitial });
    };

    const onClearFilters = () => {
        setOpenExpansions({ ...initOpenExpansions });
        setFilters({ ...filterListInitialWithDate });
        setAppliedFilters({ ...filterListInitialWithDate });
        setSearchInFiltersList({ ...searchListInitial });
    };

    const handleExpanded = useCallback((key: string) => {
        setOpenExpansions(openExpansions => (
            {
                ...openExpansions,
                [key]: !openExpansions[key],
            }
        ));
    }, []);

    const changeDates = useCallback((dates) => {
        setFilters(filters => ({ ...filters, ...dates }));
    }, []);

    const thresholdFilterValidityHandler: (key: string, isValid: boolean) => void =
    useCallback((key, isValid) => {
        setValidityState(validityState => ({ ...validityState, [key]: isValid }));
    }, []);

    const thresholdFilterChangeHandler: (key: string, value: number | Array<number>) => void =
    useCallback((key, value) => {
        setFilters(filters => ({ ...filters, [key]: value }));
    }, []);

    const handleSearchInFilters = (key: string, text: string, isFieldOpen: boolean) => {
        const newValue = { text, isFieldOpen };

        setSearchInFiltersList({ ...searchInFiltersList, [key]: newValue });
    };

    return (
        <>
            <FilterButton
                filterQty={countFilterQty(appliedFilters)}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
                data-a="vouchers-report-filters"
            >
                <div className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                        data-a="vouchers-filter-wrap"
                    >
                        <Divider />
                        <Box className={classes.dateFiltersContainer}>
                            <FiltersBox
                                label="voucherStatusIds"
                                title="emp-reports-vouchers-voucher-status"
                                data={vouchersStatusData}
                                activeFilterIds={{ ['voucherStatusIds']: filters['voucherStatusIds'] }}
                                handleFilterClick={handleFilterClick}
                                expanded={openExpansions['voucherStatusIds']}
                                handleExpanded={handleExpanded}
                                translationKey={'emp-reports-vouchers'}
                            />
                            <FiltersBox
                                label="voucherTypeIds"
                                title="emp-reports-vouchers-voucher-type"
                                data={vouchersTypeData}
                                activeFilterIds={{ ['voucherTypeIds']: filters['voucherTypeIds'] }}
                                handleFilterClick={handleFilterClick}
                                expanded={openExpansions['voucherTypeIds']}
                                handleExpanded={handleExpanded}
                                translationKey={'emp-reports-vouchers'}
                            />
                            <FiltersBox
                                label="issuedByEntityTypeIds"
                                title="emp-reports-vouchers-entity-type"
                                data={entityTypeData}
                                activeFilterIds={{ ['issuedByEntityTypeIds']: filters['issuedByEntityTypeIds'] }}
                                handleFilterClick={handleFilterClick}
                                expanded={openExpansions['issuedByEntityTypeIds']}
                                handleExpanded={handleExpanded}
                                translationKey={'emp-reports-vouchers'}
                            />
                            <DateTimePicker
                                setApplyDisabled={setTimeRangeInvalid}
                                fromDate={filters.fromDate}
                                toDate={filters.toDate}
                                changeDates={changeDates}
                            />
                        </Box>
                        <Divider />
                        {terminalFiltersBoxes.map(({ label, title }) => (
                            <FiltersWithSearch
                                key={label}
                                label={label}
                                title={title}
                                data={filtersLists[label]}
                                activeFilterIds={filters?.[label]}
                                appliedActiveFilters={appliedFilters?.[label]}
                                handleFilterClick={handleFilterClick}
                                expanded={openExpansions?.[label]}
                                handleExpanded={handleExpanded}
                                handleSearchInFilters={handleSearchInFilters}
                                searchText={searchInFiltersList[label].text}
                                isSearchFieldShown={searchInFiltersList[label].isFieldOpen}
                                scrollElement={filtersWrapperRef.current}
                            />)
                        )}
                        {thresholdFilters.map(({ label, title }) => (
                            <ThresholdFilter
                                key={label}
                                label={label}
                                title={title}
                                data={filters[label]}
                                inputComparisonLabel=">"
                                inputPrefix={globalSettings.settings.CurrencySign}
                                formatValue
                                onChange={thresholdFilterChangeHandler}
                                validityHandler={thresholdFilterValidityHandler}
                                expanded={openExpansions[label]}
                                handleExpanded={handleExpanded}
                                translationKey={''}
                            />)
                        )}
                    </div>
                    <ControlPanel
                        disabled={isApplyDisabled}
                        onApplyFilters={handleApplyFilters}
                        onClearFilters={onClearFilters}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default VouchersReportFilters;
