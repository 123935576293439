import { ApexOptions } from 'apexcharts';
import { revertToRealValue } from './business';
import { IChartDataItem } from './types';

export const getOptions = (color: string, minValueToDisplay: number, realData: Array<IChartDataItem>): ApexOptions => {
    return {
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: [color, '#000'],
        markers: {
            size: 4,
            fillOpacity: 0.9,
            colors: [color],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 7
            }
        },
        grid: {
            borderColor: '#e0e0e0',
            strokeDashArray: 3,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                show: true,
                format: 'dd MMM',
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
            tickPlacement: 'on',
            crosshairs: {
                show: true,
                width: 2,
                position: 'back',
                opacity: 0.9,
                stroke: {
                    color: '#b6b6b6',
                    width: 0,
                    dashArray: 0
                },
                fill: {
                    type: 'solid',
                    color: color
                }
            },
            tooltip: {
                enabled: false
            }
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function(value, { dataPointIndex }) {
                    return value === realData[dataPointIndex].axisYValue
                        ? value.toString()
                        : revertToRealValue(value, minValueToDisplay).toString();
                }
            }
        },
        yaxis: {
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            }
        }
    };
};
