import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
    Box,
    Table,
    TableBody,
    TablePagination,
} from '@material-ui/core';

import { IEposData, IHeadRow } from 'data';
import { IEposListItemModel } from 'api/models/epos';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import TerminalsTableRow from './TerminalsTableRow/TerminalsTableRow';
import TerminalsTableHead from './TerminalsTableHead/TerminalsTableHead';
import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import formatStr from 'utils/formatStr';
import { rowsPerPageOptions } from 'const';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        statusBar: {
            padding: `${theme.spacing(3)}px ${theme.spacing(6)}px 0`
        },
        paper: {
            padding: `${theme.spacing(3)}px ${theme.spacing(6)}px 0`
        },
        table: {
            tableLayout: 'fixed',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '4px',

            [theme.breakpoints.down('xs')]: {
                borderLeft: 'none',
                borderRight: 'none'
            },
            '& thead': {
                [theme.breakpoints.down('xs')]: {
                    display: 'none'
                }
            }
        },
        tableWrapper: {
            [theme.breakpoints.down('xs')]: {
                flexGrow: 1
            }
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        row: {
            cursor: 'pointer',

            [theme.breakpoints.down('xs')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                padding: theme.spacing(1),
                borderBottom: '1px solid #e0e0e0',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }
        },
        errorRow: {
            backgroundColor: `${theme.palette.error.light}`
        },
    }),
);

const TerminalsTable = (props: {
    rows: Array<IEposListItemModel>;
    selectedIds: Array<number>;
    order: 'asc' | 'desc';
    orderBy: string;
    page: number;
    activeColumns: Array<IHeadRow>;
    rowsPerPage: number;
    handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRequestSort: (event: React.MouseEvent<HTMLElement>, property: keyof IEposData) => void;
    handleTirminalSelect: (event: React.MouseEvent<unknown>, id: number) => void;
    handleChangePage: (event: React.MouseEvent<HTMLElement>, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClearSelectedRows: () => void;
    handleRedirect: (id: number) => void;
    isMobileView: boolean;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const classes = useStyles({});
    const {
        rows,
        activeColumns,
        selectedIds,
        order,
        orderBy,
        rowsPerPage,
        handleSelectAllClick,
        handleRequestSort,
        handleTirminalSelect,
        handleChangePage,
        handleChangeRowsPerPage,
        handleClearSelectedRows,
        handleRedirect,
        isMobileView
    } = props;

    const page = Math.min(Math.ceil(rows.length / rowsPerPage), props.page);

    function activeColumnsHasErrors() {
        return activeColumns.some(item => item.id === 'errors');
    }

    const isSelected = (id: number) => selectedIds.indexOf(id) !== -1;

    return (
        <React.Fragment>
            <Box className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                >
                    <TerminalsTableHead
                        rows={rows}
                        classes={classes}
                        activeColumns={activeColumns}
                        selectedIds={selectedIds}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        handleClearSelectedRows={handleClearSelectedRows}
                    />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {

                                return (
                                    <TerminalsTableRow
                                        key={row.id}
                                        isItemSelected={isSelected(row.id)}
                                        labelId={`enhanced-table-checkbox-${index}`}
                                        row={row}
                                        handleRedirect={handleRedirect}
                                        activeColumnsHasErrors={activeColumnsHasErrors}
                                        handleTirminalSelect={handleTirminalSelect}
                                        activeColumns={activeColumns}
                                        isMobileView={isMobileView}
                                    />
                                );
                            })}
                    </TableBody>
                </Table>
            </Box>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={translations['hm-table-rows-per-page']}
                labelDisplayedRows={(paginationInfo) => {
                    return `${formatStr(translations['hm-table-pagination-displayed-rows'], { 'from': paginationInfo.from, 'to': paginationInfo.to, 'count': paginationInfo.count })}`;
                }}
                ActionsComponent={(props) => <TablePaginationActions {...props} onChangePage={handleChangePage} />}
            />
        </React.Fragment>
    );
};

export default TerminalsTable;
