import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter, useLocation } from 'react-router-dom';
import Api from 'api/Api';
import {
    Button,
    Chip,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    withWidth
} from '@material-ui/core';

import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { rowsPerPageOptions } from 'const';
import { headRoles } from 'data';
import { cancelRequests } from 'utils/requestCancelation';
import { IRoleList } from 'api/models/role';

const nameSorting = (a, b) => a.name.localeCompare(b.name);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(6, 6, 3),

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(6, 2, 2),
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            padding: theme.spacing(2),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    addButton: {
        minWidth: 120,
        textTransform: 'none',
        whiteSpace: 'nowrap'
    },
    divider: {
        margin: theme.spacing(0)
    },
    tableWrap: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1
        },
    },
    table: {
        tableLayout: 'fixed',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '4px',

        [theme.breakpoints.down('xs')]: {
            marginTop: `-${theme.spacing(2)}px`,
            border: 'none',
        },

        '& thead': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        '& tr': {
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(1, 2),
                borderBottom: '1px solid #e0e0e0'
            },
            '&:last-child': {
                [theme.breakpoints.down('xs')]: {
                    borderBottom: 'none'
                }
            }
        },
        '& td': {
            [theme.breakpoints.down('xs')]: {
                padding: 0,
                borderBottom: 'none'
            }
        },
        '& td:first-child': {
            width: '33%',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            }
        }
    },
    nameHead: {
        width: '33%'
    },
    nameBody: {
        width: '33'
    },
    name: {
        margin: 0,
        fontWeight: 'bold',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize'
    },
    cell: {
        margin: 0,
        wordBreak: 'break-word'
    },
    tableRow: {
        cursor: 'pointer',
    },
    titleLabel: {
        height: '21px',
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: '#808080',
        fontSize: '12px'
    }
}));

const apiRequests = {
    roleList: null
};

interface IInitState {
    Name: string;
    Description: string;
    id: string;
    page: number;
    rolesPerPage: number;
}

const RoleList = ({ history }) => {
    const classes = useStyles({});
    const { translations, permissions }: GlobalContextModel = React.useContext(GlobalContext);

    const { state: initState } = useLocation<IInitState>();

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const [page, setPage] = React.useState(initState?.page || 0);
    const [rolesPerPage, setRolesPerPage] = React.useState(initState?.rolesPerPage || 20);

    React.useEffect(() => {
        loadData();

        return () => cancelRequests(apiRequests);
    }, []);

    const loadData = async () => {
        try {
            apiRequests.roleList = Api.Role.List();

            const result: Array<IRoleList> = await apiRequests.roleList;

            if (result) {
                setRoles(result);
                setIsLoaded(true);
            }
        } catch (error) {
            // handle fetch errors here
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRolesPerPage = event => {
        setRolesPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onRoleRowClick = (event: React.MouseEvent<HTMLElement>) => {
        const role = roles.find(role => role.id == event.currentTarget.id);

        permissions.rolesViewPermission && role && history.push({
            pathname: '/role-edit',
            state: {
                Name: role.name,
                Description: role.description,
                id: role.id,
                page,
                rolesPerPage
            }
        });
        localStorage.setItem('roleId', role.id);
        localStorage.setItem('roleName', role.name);
        localStorage.setItem('roleDescription', role.description);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleWrap}>
                    <h1 className={classes.title}>
                        {translations['gen-roles']}
                    </h1>
                    {permissions.rolesAddPermission &&
                        <Button
                            data-a="role-add-button"
                            component={Link}
                            to={'/role-add'}
                            className={classes.addButton}
                            variant="contained"
                            color="primary"
                        >
                            {translations['role-add-role'] || ''}
                        </Button>
                    }
                </div>
            </div>
            <Divider className={classes.divider} />
            <PageLayoutWrapper>
                <BackdropSpinner open={!isLoaded} />
                {!!roles.length &&
                    <div>
                        <div className={classes.tableWrap}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                            >
                                <TableHead >
                                    <TableRow>
                                        {headRoles.map((headRole) => (
                                            <TableCell key={headRole.id} className={classes[`${headRole.id}Head`]}>
                                                {translations[headRole.label]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {roles
                                        .sort(nameSorting)
                                        .slice(page * rolesPerPage, page * rolesPerPage + rolesPerPage).map(role => (
                                            <TableRow
                                                key={role.id}
                                                id={role.id}
                                                hover
                                                className={clsx(permissions.rolesViewPermission && classes.tableRow)}
                                                onClick={onRoleRowClick}
                                                data-a="roles-table-row"
                                            >
                                                <TableCell className={classes.nameBody}>
                                                    <p className={classes.name}>
                                                        <span>{role.name}</span>
                                                        {role.isInternal &&
                                                            <Chip
                                                                data-a="role-internal-label"
                                                                className={classes.titleLabel}
                                                                label={translations['role-internal']}
                                                                size="small"
                                                                component={'span'}
                                                            />}
                                                    </p>
                                                </TableCell>
                                                <TableCell>
                                                    <p className={classes.cell}>{role.description}</p>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={roles.length}
                            rowsPerPage={rolesPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRolesPerPage}
                            labelRowsPerPage={translations['hm-table-rows-per-page']}
                            ActionsComponent={(props) => <TablePaginationActions {...props} onChangePage={handleChangePage} />}
                        />
                    </div>
                }
            </PageLayoutWrapper>
        </div>
    );
};

export default withWidth()(withRouter(RoleList));
