import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { pageWrapContainer } from 'const';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bodyWrapContainer: {
            width: '100%',
            maxWidth: pageWrapContainer.maxWidth
        },
        title: {
            marginTop: theme.spacing(1),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        statusContainer: {
            marginBottom: theme.spacing(0)
        },
        iconButton: {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        iconLabel: {
            fontSize: '0.9rem',
            fontWeight: 600
        },

        actionWrap: {
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(2)
            }
        },
        content: {
            width: '100%',
            padding: theme.spacing(2, 0),
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0)
            }
        },
        terminalInfoWrap: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                order: 1
            }
        },
        metrics: {
            [theme.breakpoints.down('sm')]: {
                order: 2
            }
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%'
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(6, 6, 1, 6),

            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(6, 2, 0),
            },
            [theme.breakpoints.down('xs')]: {
                display: 'block',
                padding: theme.spacing(2, 1.5, 0),
            }
        },
        betStatusBadge: {
            margin: theme.spacing(1, 1, 1, 0)
        },
        bodyWrap: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            padding: theme.spacing(0, 6),

            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(2),
            }
        },
        icon: {
            marginRight: theme.spacing(1),
            color: theme.palette.grey[500],
            fontSize: theme.typography.h5.fontSize,

            [theme.breakpoints.down('xs')]: {
                fontSize: `${24/16}rem`,
            }
        },

        // mainBlock
        mainBlockBetTypeCell: {
            '&:first-child:nth-child(2n + 1)': {
                [theme.breakpoints.down('sm')]: {
                    paddingRight: theme.spacing(36/8),
                }
            }
        },
        mainBlockHeadCell: {
            padding: theme.spacing(2),
            textAlign: 'right',
            '&:first-child': {
                paddingLeft: 0,
            },
            '&:last-child': {
                paddingRight: 0
            },
            [theme.breakpoints.down('sm')]: {
                width: '50%'
            },
            '&:nth-child(2n)': {
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: 0,
                    textAlign: 'left'
                }
            },
            '&:nth-child(2n + 1)': {
                [theme.breakpoints.down('sm')]: {
                    paddingRight: 0
                }
            },
        },
        mainBlockHeadCellWide: {
            flexGrow: 1,
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingLeft: 0
            }
        },
        mainBlockHeadCellName: {
            paddingBottom: theme.spacing(0.5),
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 500,
            textTransform: 'uppercase',

        },
        betTypeDescription: {
            display: 'flex',
            alignItems: 'flex-start',
            lineHeight: 1
        },
        badgeSGPx: {
            marginTop: '3px',
            marginRight: theme.spacing(4/8),
        },
        blockDetailsContainer: {
            columns: '2 auto',
            columnGap: theme.spacing(4),
            width: '100%',
            backgroundColor: '#fff',
            [theme.breakpoints.down('sm')]: {
                columns: '1 auto',
                padding: theme.spacing(2)
            }
        },
        blockDetailsFull: {
            width: '100%',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.common.white
        },
        mainBlockDetailsContainer: {
            padding: theme.spacing(2, 0)
        },
        mainBlockDetailsBox: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(2),

            '&:last-child': {
                marginBottom: 0
            }
        },
        mainBlockDetailsName: {
            fontWeight: 500,
            color: '#000',
        },
        mainBlockDetailsDesc: {
            fontWeight: 500,
            color: theme.palette.text.secondary,
            textAlign: 'right',
            wordWrap: 'break-word'
        },
        badgeImage: {
            padding: theme.spacing(1),
            marginRight: theme.spacing(1),
            borderRadius: '4px',
            border: `1px solid ${theme.palette['badge'].success.color}`
        },
        //print block
        secondaryDetailsBox: {
            padding: theme.spacing(0, 2, 2),
            backgroundColor: theme.palette.grey[100]
        },
        iconBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '16px',
            backgroundColor: theme.palette.primary.light
        },
        reprintBox: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: 'auto'
            }
        },
        //modal
        dialogContent: {
            minWidth: '400px',
            overflowY: 'initial'
        },
        modalBox: {
            padding: `0 ${theme.spacing(3)}px`,
            marginBottom: `${theme.spacing(2)}px`,
        },
        inputWrap: {
            '& .Mui-focused': {
                color: `${theme.palette.primary.main}`,
            }
        },
        listItem: {
            padding: theme.spacing(0.5, 0),
            cursor: 'pointer'
        },
        //notification

    })
);

export default useStyles;
