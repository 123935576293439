import React, {
    useContext,
    useRef,
    useState,
} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    useLocation,
    useParams,
    withRouter,
    Prompt,
} from 'react-router-dom';

import {
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    TextField,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';

import { ArrowBack } from '@material-ui/icons';
import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import UserActionPanel from './components/UserActionPanel/UserActionPanel';
import NotificationModal from 'components/Modal/NotificationModal/NotificationModal';
import ListModal from 'components/Modal/ListModal';
import UserOperators from './components/UserOperators';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { UserAddFields, IUserAdd, IUserDetails, IUserLocationState } from 'api/models/user';
import {
    generalInformationInputs,
    generalEditUserInformationInputs,
    CreateUserNotification,
    UserOperatorsModal,
    userView,
    userAdd,
    userEdit,
    userChangeBetshop
} from 'data';
import { OPERATORS_SPECIFIC_USER_KEYS } from 'const';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

import { emailValidate } from 'utils/validation';
import { pageWrapContainer } from 'const';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

import { deepEqualObjects } from 'utils/deepEqualObjects';

import Api from 'api/Api';

const firstNameKey = UserAddFields.firstName;
const lastNameKey = UserAddFields.lastName;
const emailKey = UserAddFields.email;
const userNameKey = UserAddFields.userName;
const positionKey = UserAddFields.position;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(0)}px`,

        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(6)}px ${theme.spacing(2)}px 0`,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px 0`,
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    titleLabel: {
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: '#808080'
    },
    iconButton: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    iconLabel: {
        fontSize: '0.9rem',
        fontWeight: 600
    },
    bodyWrapContainer: {
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth
    },
    form: {
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth,
        paddingBottom: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden'
        }
    },
    container: {
        '& > .MuiGrid-item': {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5)
        }
    },
    row: {
        padding: `${theme.spacing(3)}px 0`,
        marginTop: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2, 2)
        },

        ':last-child > &': {
            [theme.breakpoints.down('xs')]: {
                marginBottom: 0,
            }
        },
    },
    groupLabel: {
        marginTop: 0,
        marginBottom: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2)
        },
    },
    groupSubHeading: {
        marginTop: 0,
        color: theme.palette.text.hint,

        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2)
        },
    },
    input: {
        position: 'relative',
        width: '100%'
    },
    checkboxRow: {
        marginTop: theme.spacing(2)
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(2)}px`,
        }
    },
    actionButton: {
        textTransform: 'none',
        marginLeft: theme.spacing(2)
    },
    serverError: {
        margin: '8px 14px 0',
        padding: 0,
        fontSize: '0.75rem',
        color: theme.palette.error.main
    },
    formControl: {
        width: '100%'
    },
    selectLabel: {
        background: theme.palette.background.default
    },
}));

const apiRequests = {
    operatorsPermissions: null,
    operatorsRole: null,
    userDetails: null,
    operatorsGroups: null,
    operatorsBetshops: null,
    cashierRoles: null
};

const emptyOperatorWithEmptyState = {
    emp: {
        betshops: [],
        roleId: '',
    },
    supervisor: {
        betshops: [],
    },
    cashier: {
        roleId: '',
        betshopId: null,
        hideShopData: false
    }
};

const emptyOperator = {
    emp: null,
    supervisor: null,
    cashier: null
};

const UserAdd = ({ history }) => {
    const { state: initState } = useLocation<IUserLocationState>();
    const { id: pageId } = useParams<{ id?: string }>();
    const { globalSettings, translations, dateTimeFormat, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);
    const isAddPage = pageId === 'add';
    const isReadOnlyPage = !isAddPage && !permissions.usersEditPermission;

    const operators = globalSettings?.user?.DomainMappings || {};
    const isMultitenancyOperators = Object.keys(operators).length > 0;

    const informationInputs = isAddPage ? generalInformationInputs : generalEditUserInformationInputs;

    let requiredErrorsInit = {};
    let valuesInitial = {};
    let errorMessagesInit = {};

    const selectRoleInputName = 'Roles';
    const selectOperatorName = 'Operator';
    const betshopsName = 'betshops';
    const emptyFields = 'EmptyFields';
    const roleIdName = 'roleId';
    const betshopIdName = 'betshopId';

    const classes = useStyles({});

    const clientId = globalSettings?.user?.ClientId;

    informationInputs.forEach(item => {
        if (item.required) {
            requiredErrorsInit[item.name] = true;
            errorMessagesInit[item.name] = 'users-valid-msg-empty-field';
        }

        valuesInitial[item.name] = initState?.[item.name] || '';
    });

    const [valuesInit, setValuesInit] = useState(valuesInitial);
    const [values, setValues] = useState(valuesInitial);
    const [errorFields, setErrorFields] = useState(requiredErrorsInit);
    const [errorMessagesKeys, setErrorMessagesKeys] = useState(errorMessagesInit);
    const [firstSubmit, setFirstSubmit] = useState(true);
    const [loading, setLoading] = useState(false);
    const isInternalRef = useRef(initState?.isInternal || false);
    const isInternal = isInternalRef.current;
    const [isUserEnabled, setIsUserEnabled] = useState(true);
    const [serverErrorsKeys, setServerErrorsKeys] = useState([]);
    const [userDetailsError, setUserDetailsError] = useState(false);

    const [initOperatorsState, setInitOperatorsState] = useState({});
    const [operatorsState, setOperatorsState] = useState({});

    const [operatorsRole, setOperatorsRole] = useState({});
    const [cashierRoles, setCashierRoles] = useState({});
    const [operatorsBetshops, setOperatorsBetshops] = useState([]);
    const [operatorsList, setOperatorsList] = useState([]);
    const [availableOperatorsPermissions, setAvailableOperatorPermissions] = useState([]);

    const [initTwoFactorAuth, setInitTwoFactorAuth] = useState(false);
    const [passwordUpdateDate, setPasswordUpdateDate] = useState('');

    const [twoFactorAuth, setTwoFactorAuth] = useState(false);

    const [operatorsSpecificUserState, setOperatorsSpecificState] = useState({});
    const [operatorsSpecificUserErrors, setOperatorsSpecificUserErrors] = useState({});

    const [notificationModalView, setNotificationModalView] = useState(false);
    const [operatorsModalView, setOperatorsModalView] = useState(false);

    requiredErrorsInit[selectRoleInputName] = true;
    errorMessagesInit[selectRoleInputName] = 'users-valid-msg-empty-field';

    requiredErrorsInit[selectOperatorName] = true;
    errorMessagesInit[selectOperatorName] = 'users-valid-msg-empty-operators';

    requiredErrorsInit[betshopsName] = true;
    errorMessagesInit[betshopsName] = 'users-valid-msg-empty-field';

    const dominantUsersPermission = isReadOnlyPage
        ? [userView]
        : isAddPage
            ? [userAdd]
            : [userEdit, userChangeBetshop];

    React.useEffect(() => {
        loadData();

        return () => cancelRequests(apiRequests);
    }, []);

    const loadData = async () => {
        !isAddPage && await loadUserDetails();
        await isMultitenancyOperators ? loadOperatorsList() : loadOperatorDetails([{ [clientId]: '' }]);
    };

    const loadOperatorsList = async () => {
        try {
            apiRequests.operatorsPermissions = Api.User.CheckUserPermission({ permissions: dominantUsersPermission });

            const operatorsListPermissions = await apiRequests.operatorsPermissions;

            if (operatorsList) {
                const operatorsListAvailable = [];

                Object.keys(operatorsListPermissions).forEach(key => {
                    operatorsListPermissions[key].some(permission => permission === userAdd || permission === userEdit) &&
                    operatorsListAvailable.push(key);
                });

                setAvailableOperatorPermissions(operatorsListPermissions);
                setOperatorsList(operatorsListAvailable);

                if (document.location.href.includes('/users/add')) {
                    setOperatorsSpecificState(Object.keys(operatorsList).reduce((acc, key) => {
                        return {
                            ...acc,
                            [key]: {
                                [OPERATORS_SPECIFIC_USER_KEYS.emp]: false,
                                [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: false,
                                [OPERATORS_SPECIFIC_USER_KEYS.cashier]: false
                            }
                        };
                    }, {}));

                    setOperatorsSpecificUserErrors(Object.keys(operatorsList).reduce((acc, key) => {
                        return {
                            ...acc,
                            [key]: {
                                [OPERATORS_SPECIFIC_USER_KEYS.emp]: { [selectRoleInputName]: false, [betshopsName]: false },
                                [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: { [selectRoleInputName]: false },
                                [OPERATORS_SPECIFIC_USER_KEYS.cashier]: { [selectRoleInputName]: false, [betshopsName]: false },
                                [OPERATORS_SPECIFIC_USER_KEYS.general]: { [emptyFields]: false }
                            }
                        };
                    }, {}));
                }
            }
        } catch (error) {
            throwNetworkError(error);
        }
    };

    const loadOperatorDetails = async (operatorClientIds) => {
        try {
            setLoading(true);

            apiRequests.operatorsBetshops = Api.NetworkManagement.GetBetshopsLite({ operatorClientIds });
            apiRequests.cashierRoles = Api.Role.ListIdName({
                operatorClientIds,
                application: 'cashier'
            });

            const operatorsBetshops = await apiRequests.operatorsBetshops;
            const cashierRoles = await apiRequests.cashierRoles;

            if (!isInternalRef.current) {
                apiRequests.operatorsRole = Api.Role.ListIdName({ operatorClientIds, application: 'emp' });

                const operatorsRole = await apiRequests.operatorsRole;

                operatorsRole && setOperatorsRole(operatorsRole);
            }

            cashierRoles && setCashierRoles(cashierRoles);
            operatorsBetshops && setOperatorsBetshops(operatorsBetshops);

            if (!isMultitenancyOperators && isAddPage) {
                setOperatorsState({ [clientId]: { ...emptyOperator } });
                setInitOperatorsState({ [clientId]: { ...emptyOperator } });

                setOperatorsSpecificState({
                    [clientId]: {
                        [OPERATORS_SPECIFIC_USER_KEYS.emp]: false,
                        [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: false,
                        [OPERATORS_SPECIFIC_USER_KEYS.cashier]: false,
                    }
                });
                setOperatorsSpecificUserErrors({
                    [clientId]: {
                        [OPERATORS_SPECIFIC_USER_KEYS.emp]: { [selectRoleInputName]: false, [betshopsName]: false },
                        [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: { [selectRoleInputName]: false, [betshopsName]: false },
                        [OPERATORS_SPECIFIC_USER_KEYS.cashier]: { [selectRoleInputName]: false, [betshopsName]: false },
                        [OPERATORS_SPECIFIC_USER_KEYS.general]: { [emptyFields]: false }
                    }
                });
            }
        } catch (error) {
            throwNetworkError(error);
        } finally {
            setLoading(false);
        }
    };

    const loadUserDetails = async () => {
        try {
            setLoading(true);
            apiRequests.userDetails = Api.User.Details({ id: pageId });

            const userDetailsResponse: IUserDetails = await apiRequests.userDetails;

            if (userDetailsResponse?.userName?.length) {
                isInternalRef.current = userDetailsResponse.isInternal;
                setIsUserEnabled(userDetailsResponse.enabled);

                const { operatorSpecificProperties } = userDetailsResponse;
                const newOperatorsSpecificUserState = {};

                loadOperatorDetails(Object.keys(operatorSpecificProperties));
                setInitOperatorsState(JSON.parse(JSON.stringify(operatorSpecificProperties)));
                setOperatorsState(JSON.parse(JSON.stringify(operatorSpecificProperties)));

                informationInputs.forEach(item => {
                    valuesInitial[item.name] = userDetailsResponse?.[item.name] || '';

                    if (item.required) {
                        requiredErrorsInit[item.name] = !valuesInitial[item.name].length;
                    }
                });

                setErrorFields({ ...errorFields, ...requiredErrorsInit });
                setValuesInit({ ...valuesInit, ...valuesInitial });
                setValues({ ...values, ...valuesInitial });

                userDetailsResponse?.passwordUpdateDate && setPasswordUpdateDate(userDetailsResponse.passwordUpdateDate);

                if (isInternalRef.current) {
                    const roleList = {};

                    Object.keys(operatorSpecificProperties).forEach(operator => {
                        roleList[operator] = [{
                            id: operatorSpecificProperties[operator]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.[roleIdName],
                            name: operatorSpecificProperties[operator]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.roleName
                        }];
                    });

                    setOperatorsRole(roleList);
                }

                Object.keys(operatorSpecificProperties).forEach(operator => {
                    newOperatorsSpecificUserState[operator] = {
                        [OPERATORS_SPECIFIC_USER_KEYS.emp]: false,
                        [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: false,
                        [OPERATORS_SPECIFIC_USER_KEYS.cashier]: false,
                    };

                    if (operatorSpecificProperties[operator][OPERATORS_SPECIFIC_USER_KEYS.emp]?.[roleIdName]) {
                        newOperatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.emp] = true;
                    }

                    if (operatorSpecificProperties[operator][OPERATORS_SPECIFIC_USER_KEYS.supervisor]) {
                        newOperatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.supervisor] = true;
                    }

                    if (operatorSpecificProperties[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[roleIdName]) {
                        newOperatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier] = true;
                    }
                });

                setInitTwoFactorAuth(userDetailsResponse.isTwoFactorEnabled);
                setTwoFactorAuth(userDetailsResponse.isTwoFactorEnabled);

                setOperatorsSpecificState(newOperatorsSpecificUserState);
                setOperatorsSpecificUserErrors(Object.keys(operatorSpecificProperties).reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: {
                            [OPERATORS_SPECIFIC_USER_KEYS.emp]: { [selectRoleInputName]: false, [betshopsName]: false },
                            [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: { [selectRoleInputName]: false },
                            [OPERATORS_SPECIFIC_USER_KEYS.cashier]: { [selectRoleInputName]: false, [betshopsName]: false },
                            [OPERATORS_SPECIFIC_USER_KEYS.general]: { [emptyFields]: false }
                        }
                    };
                }, {}));
            } else {
                setUserDetailsError(true);
            }
        } catch (error) {
            setUserDetailsError(true);
            throwNetworkError(error);
        }
    };

    const handleChange = (name, required) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = name === userNameKey ? event.target.value.toLowerCase() : event.target.value;

        setValues({ ...values, [name]: value });
        !firstSubmit && setErrorFields({ ...errorFields, [name]: required && !event.target.value });
    };

    const handleFocus = (name) => () => {
        if (name === emailKey && valuesInit[name] === values[name]) {
            setValues({ ...values, [emailKey]: '' });
        }
    };

    const handleFocusOut = (name) => () => {
        if (name === emailKey && values[name] == '') {
            setValues({ ...values, [emailKey]: valuesInit[emailKey] });
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let trimValues = values;
        let newErrors = errorFields;
        let newErrorMessagesKeys = errorMessagesKeys;
        let newOperatorsSpecificUserErrors = JSON.parse(JSON.stringify(operatorsSpecificUserErrors));
        let anyOperatorsSpecificError = false;

        firstSubmit && setFirstSubmit(false);

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                trimValues[key] = values[key].trim();
            }
        }

        for (const key in errorFields) {
            if (errorFields.hasOwnProperty(key)) {
                const isRequired = informationInputs.find(el => el.name === key)?.required;

                newErrors[key] = isRequired && !trimValues[key];
            }
        }

        newErrors[selectOperatorName] = isMultitenancyOperators && !Object.keys(operatorsState).length;

        Object.keys(operatorsState).forEach(operator => {
            if (operatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.emp]) {
                if (!operatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.emp]?.[roleIdName]) {
                    newOperatorsSpecificUserErrors[operator][OPERATORS_SPECIFIC_USER_KEYS.emp][selectRoleInputName] = true;
                }
            }

            if (operatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier]) {
                if (!operatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[roleIdName]) {
                    newOperatorsSpecificUserErrors[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier][selectRoleInputName] = true;
                }

                if (!operatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[betshopIdName]) {
                    newOperatorsSpecificUserErrors[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier][betshopsName] = true;
                }
            }

            if (!(operatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.emp]?.[roleIdName] ||
                operatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.supervisor] ||
                operatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[roleIdName])) {
                newOperatorsSpecificUserErrors[operator][OPERATORS_SPECIFIC_USER_KEYS.general][emptyFields] = true;
            }
        });

        anyOperatorsSpecificError = Object.values(newOperatorsSpecificUserErrors).some(component => {
            return Object.values(component).some(err => Object.values(err).some(e => e));
        });

        const allFieldsFilled = Object.values(newErrors).every(field => !field) && !anyOperatorsSpecificError;

        if (allFieldsFilled) {
            if (values[emailKey] && valuesInit[emailKey] !== values[emailKey] && !emailValidate(values[emailKey])) {
                newErrors[emailKey] = true;
                newErrorMessagesKeys[emailKey] = 'users-valid-msg-email';
            } else {
                submitForm();

                return;
            }
        }

        setValues({ ...trimValues });
        setErrorFields({ ...newErrors });
        setErrorMessagesKeys({ ...newErrorMessagesKeys });
        setOperatorsSpecificUserErrors({ ...newOperatorsSpecificUserErrors });
    };

    const apiRequest = () => {
        const operatorSpecificProperties = JSON.parse(JSON.stringify(operatorsState));

        Object.keys(operatorsState).forEach(operator => {
            if (Object.values(operatorsSpecificUserState[operator]).some(e => e)) {
                if (!operatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.emp]) {
                    operatorSpecificProperties[operator][OPERATORS_SPECIFIC_USER_KEYS.emp] = null;
                }

                if (!operatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.supervisor]) {
                    operatorSpecificProperties[operator][OPERATORS_SPECIFIC_USER_KEYS.supervisor] = null;
                }

                if (!operatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier]) {
                    operatorSpecificProperties[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier] = null;
                }
            } else {
                delete operatorSpecificProperties[operator];
            }

            // remove readonly operator if edit existing user
            if (!isAddPage && isMultitenancyOperators && !operatorsList.includes(operator)) {
                delete operatorSpecificProperties[operator];
            }
        });

        if (!isAddPage) {
            const editUserPayload = {
                id: pageId,
                firstName: values[firstNameKey],
                lastName: values[lastNameKey],
                position: values[positionKey],
                isTwoFactorEnabled: twoFactorAuth,
                operatorSpecificProperties
            };

            if (values[emailKey] !== valuesInit[emailKey]) {
                editUserPayload[emailKey] = values[emailKey];
            }

            return Api.User.Edit(editUserPayload);
        } else {
            const addUserPayload = {
                firstName: values[firstNameKey],
                lastName: values[lastNameKey],
                userName: values[userNameKey],
                position: values[positionKey],
                email: values[emailKey],
                isTwoFactorEnabled: twoFactorAuth,
                operatorSpecificProperties
            };

            return Api.User.Add(addUserPayload);
        }

    };

    const closeNotificationModal = () => {
        setNotificationModalView(false);
        history.push({ pathname: '/users', state: initState });
    };

    const submitForm = () => {
        setLoading(true);
        apiRequest()
            .then(res => {
                if (res.succeeded) {
                    clearValues();

                    if (isAddPage) {
                        setNotificationModalView(true);
                    } else {
                        history.push({ pathname: '/users', state: initState });
                    }
                } else {
                    let newErrorFields = errorFields;
                    let newErrorMessagesKeys = errorMessagesKeys;
                    let newServerErrorsKeys = [];
                    let newOperatorsSpecificUserErrors = { ...operatorsSpecificUserErrors };

                    res.errors.forEach(error => {
                        const { description } = error;
                        const fieldName = description[0].toLowerCase() + description.slice(1);

                        const clientId = error?.clientId;

                        if (clientId) {
                            if (fieldName === betshopsName) {
                                newOperatorsSpecificUserErrors[clientId][OPERATORS_SPECIFIC_USER_KEYS.emp][betshopsName] = true;
                                newErrorMessagesKeys[fieldName] = error.code;
                            }
                        } else {
                            if (error.code && errorFields.hasOwnProperty(fieldName)) {
                                newErrorFields[fieldName] = true;
                                newErrorMessagesKeys[fieldName] = error.code;
                            } else {
                                newServerErrorsKeys.push(error.code);
                            }
                        }
                    });

                    setLoading(false);
                    setErrorFields(newErrorFields);
                    setErrorMessagesKeys(newErrorMessagesKeys);
                    setServerErrorsKeys(newServerErrorsKeys);
                    setOperatorsSpecificUserErrors(newOperatorsSpecificUserErrors);
                }
            })
            .catch(error => console.warn(error));
    };

    const clearValues = () => {
        setValues(valuesInit);
        setOperatorsState(initOperatorsState);
        setTwoFactorAuth(initTwoFactorAuth);
    };

    const isValueChanged = () => {
        return Object.entries(values).some(([key, value]) => valuesInit[key] !== value) ||
            !deepEqualObjects(initOperatorsState, operatorsState) ||
            initTwoFactorAuth != twoFactorAuth;
    };

    const handleCancel = () => {
        history.push({ pathname: '/users', state: initState });
    };

    const promptMessage = JSON.stringify({
        title: translations['gen-discard-changes-title'],
        msg: translations['gen-discard-changes-msg'],
        yes: translations['gen-yes'],
        no: translations['gen-no'],
    });

    const handleTwoFactor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTwoFactorAuth(event.target.checked);
    };

    const handleRoleChange = (event: React.ChangeEvent<{name?: string; value: string}>) => {
        const copyOperatorsState = { ...operatorsState };
        const copyOperatorsSpecificUserErrors = { ...operatorsSpecificUserErrors };

        if (copyOperatorsState[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.emp]) {
            copyOperatorsState[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.emp][roleIdName] = event.target.value;
        } else {
            copyOperatorsState[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.emp] = {
                [roleIdName]: event.target.value
            };
        }

        if (copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.emp]) {
            copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.emp][selectRoleInputName] = false;
        } else {
            copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.emp] = {
                [selectRoleInputName]: false
            };
        }

        copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.general] = {
            [emptyFields]: false
        };

        setOperatorsState(copyOperatorsState);
        !firstSubmit && isMultitenancyOperators && setOperatorsSpecificUserErrors({ ...copyOperatorsSpecificUserErrors });
    };

    const handleCashierRoleChange = (event: React.ChangeEvent<{name?: string; value: string}>) => {
        const copyOperatorsState = { ...operatorsState };
        const copyOperatorsSpecificUserErrors = { ...operatorsSpecificUserErrors };

        if (copyOperatorsState[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.cashier]) {
            copyOperatorsState[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.cashier][roleIdName] = event.target.value;
        } else {
            copyOperatorsState[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.cashier] = {
                [roleIdName]: event.target.value
            };
        }

        if (copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.cashier]) {
            copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.cashier][selectRoleInputName] = false;
        } else {
            copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.cashier] = {
                [selectRoleInputName]: false
            };
        }

        copyOperatorsSpecificUserErrors[event.target.name][OPERATORS_SPECIFIC_USER_KEYS.general] = {
            [emptyFields]: false
        };

        setOperatorsState(copyOperatorsState);
        !firstSubmit && isMultitenancyOperators && setOperatorsSpecificUserErrors({ ...copyOperatorsSpecificUserErrors });
    };

    const onCloseOperatorsModal = () => {
        setOperatorsModalView(false);
    };

    const onConfirmOperatorsModal = (operators: Array<string>) => {
        const copyOperatorsState = { ...operatorsState };
        const copyOperatorsSpecificUserState = { ...operatorsSpecificUserState };
        const copyOperatorsSpecificUserErrors = { ...operatorsSpecificUserErrors };

        operators.forEach(operator => {
            if (!operatorsState.hasOwnProperty(operator)) {
                if (initOperatorsState.hasOwnProperty(operator)) {
                    copyOperatorsState[operator] =JSON.parse(JSON.stringify(initOperatorsState[operator]));
                } else {
                    copyOperatorsState[operator] = JSON.parse(JSON.stringify(emptyOperator));
                }
            }

            if (!copyOperatorsSpecificUserState.hasOwnProperty(operator)) {
                copyOperatorsSpecificUserState[operator] = {
                    [OPERATORS_SPECIFIC_USER_KEYS.emp]: false,
                    [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: false,
                    [OPERATORS_SPECIFIC_USER_KEYS.cashier]: false,
                };

                if (copyOperatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.emp]?.[roleIdName]) {
                    copyOperatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.emp] = true;
                }

                if (copyOperatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.supervisor]) {
                    copyOperatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.supervisor] = true;
                }

                if (copyOperatorsState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[roleIdName]) {
                    copyOperatorsSpecificUserState[operator][OPERATORS_SPECIFIC_USER_KEYS.cashier] = true;
                }
            }

            if (!copyOperatorsSpecificUserErrors.hasOwnProperty(operator)) {
                copyOperatorsSpecificUserErrors[operator] = {
                    [OPERATORS_SPECIFIC_USER_KEYS.emp]: { [selectRoleInputName]: false, [betshopsName]: false },
                    [OPERATORS_SPECIFIC_USER_KEYS.supervisor]: { [selectRoleInputName]: false },
                    [OPERATORS_SPECIFIC_USER_KEYS.cashier]: { [selectRoleInputName]: false, [betshopsName]: false },
                    [OPERATORS_SPECIFIC_USER_KEYS.general]: { [emptyFields]: false }
                };
            }

        });

        loadOperatorDetails(Object.keys(copyOperatorsState));
        setOperatorsState(copyOperatorsState);
        setOperatorsSpecificState(copyOperatorsSpecificUserState);
        setOperatorsSpecificUserErrors(copyOperatorsSpecificUserErrors);
        setOperatorsModalView(false);
        !firstSubmit && setErrorFields({ ...errorFields, [selectOperatorName]: !Object.keys(copyOperatorsState).length });
    };

    const handleOperatorRemove = (operator) => {
        const copyOperatorsState = { ...operatorsState };
        const copyOperatorsSpecificState = { ...operatorsSpecificUserState };
        const copyOperatorsSpecificUserErrros = { ...operatorsSpecificUserErrors };

        delete copyOperatorsState[operator];
        delete copyOperatorsSpecificState[operator];
        delete copyOperatorsSpecificUserErrros[operator];

        setOperatorsState(copyOperatorsState);
        setOperatorsSpecificState(copyOperatorsSpecificState);
        setOperatorsSpecificUserErrors(copyOperatorsSpecificUserErrros);
    };

    const handleOperatorModalView = () => {
        setOperatorsModalView(true);
    };

    const handleEmpBetshopsChange = (betshopsSelected: Array<number>, operatorName: string) => {
        const copyOperatorsState = { ...operatorsState };
        const copyOperatorsSpecificUserErrors = { ...operatorsSpecificUserErrors };

        if (copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.emp]) {
            copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.emp][betshopsName] = betshopsSelected;
        } else {
            copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.emp] = {
                [betshopsName]: betshopsSelected
            };
        }

        if (copyOperatorsSpecificUserErrors[operatorName][OPERATORS_SPECIFIC_USER_KEYS.emp]) {
            copyOperatorsSpecificUserErrors[operatorName][OPERATORS_SPECIFIC_USER_KEYS.emp][betshopsName] = false;
        } else {
            copyOperatorsSpecificUserErrors[operatorName][OPERATORS_SPECIFIC_USER_KEYS.emp] = {
                [betshopsName]: false
            };
        }

        if (copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.supervisor]) {
            copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.supervisor][betshopsName] = betshopsSelected;
        } else {
            copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.supervisor] = {
                [betshopsName]: betshopsSelected
            };
        }

        setOperatorsState(copyOperatorsState);
        setOperatorsSpecificUserErrors(copyOperatorsSpecificUserErrors);
    };

    const handleCashierBetshopsChange = (betshopId: number, operatorName: string) => {
        const copyOperatorsState = { ...operatorsState };
        const copyOperatorsSpecificUserErrors = { ...operatorsSpecificUserErrors };

        if (copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier]) {
            copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier][betshopIdName] = betshopId;
        } else {
            copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier] = {
                [betshopIdName]: betshopId
            };
        }

        if (copyOperatorsSpecificUserErrors[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier]) {
            copyOperatorsSpecificUserErrors[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier][betshopsName] = false;
        } else {
            copyOperatorsSpecificUserErrors[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier] = {
                [betshopsName]: false
            };
        }

        copyOperatorsSpecificUserErrors[operatorName][OPERATORS_SPECIFIC_USER_KEYS.general] = {
            [emptyFields]: false
        };

        setOperatorsState(copyOperatorsState);
        setOperatorsSpecificUserErrors(copyOperatorsSpecificUserErrors);
    };

    const handleHideShopData = (operatorName: string) => {
        const copyOperatorsState = { ...operatorsState };

        copyOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier]['hideShopData'] = !operatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier]['hideShopData'];
        setOperatorsState(copyOperatorsState);
    };

    const handleOperatorsSpecificUserState = (operatorName: string, propertyName: string) => {
        const newOperatorsState = { ...operatorsState };
        const newOperatorsSpecificUserState = { ...operatorsSpecificUserState };
        const newOperatorsSpecificErrorState = { ...operatorsSpecificUserErrors };

        newOperatorsSpecificUserState[operatorName][propertyName] = !operatorsSpecificUserState[operatorName][propertyName];

        if (propertyName === OPERATORS_SPECIFIC_USER_KEYS.emp) {
            if (newOperatorsSpecificUserState[operatorName][propertyName]) {
                initOperatorsState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]
                    ? newOperatorsState[operatorName][propertyName] = { ...initOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.emp] }
                    : newOperatorsState[operatorName][propertyName] = { ...emptyOperatorWithEmptyState.emp };
            } else {
                newOperatorsState[operatorName][propertyName] = null;
            }
        }

        if (propertyName === OPERATORS_SPECIFIC_USER_KEYS.supervisor) {
            newOperatorsSpecificUserState[operatorName][propertyName]
                ? newOperatorsState[operatorName][propertyName] = { ...emptyOperatorWithEmptyState.supervisor }
                : newOperatorsState[operatorName][propertyName] = null;

            newOperatorsSpecificErrorState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.general][emptyFields] = false;
        }

        if (propertyName === OPERATORS_SPECIFIC_USER_KEYS.cashier) {
            if (newOperatorsSpecificUserState[operatorName][propertyName]) {
                initOperatorsState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]
                    ? newOperatorsState[operatorName][propertyName] = { ...initOperatorsState[operatorName][OPERATORS_SPECIFIC_USER_KEYS.cashier] }
                    : newOperatorsState[operatorName][propertyName] = { ...emptyOperatorWithEmptyState.cashier };
            } else {
                newOperatorsState[operatorName][propertyName] = null;
            }
        }

        newOperatorsSpecificErrorState[operatorName][propertyName] = {
            [selectRoleInputName]: false
        };

        setOperatorsState(newOperatorsState);
        setOperatorsSpecificState(newOperatorsSpecificUserState);
        setOperatorsSpecificUserErrors(newOperatorsSpecificErrorState);
    };

    return !userDetailsError && (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.bodyWrapContainer}>
                    <IconButton
                        data-a="user-add-button-back"
                        className={classes.iconButton}
                        onClick={handleCancel}
                        edge="start"
                        color="inherit"
                        size="small"
                        disableRipple
                    >
                        <ArrowBack htmlColor="black" />
                        &nbsp;
                        <Typography variant="button" className={classes.iconLabel}>{translations['gen-users']}</Typography>
                    </IconButton>
                    <h1 className={classes.title}>
                        <span>{isAddPage
                            ? translations['users-add-user']
                            : `${valuesInit['firstName'] || ''} ${valuesInit['lastName'] || ''}`}
                        </span>
                        {isInternal &&
                            <Chip
                                data-a="internal-label"
                                className={classes.titleLabel}
                                label={translations['users-internal-label']}
                                size="small"
                                component={'span'}
                            />}
                    </h1>
                </div>
            </div>
            <Divider />
            <PageLayoutWrapper>
                <BackdropSpinner open={loading} />
                <form className={classes.form} onSubmit={handleSubmit}>
                    {!isAddPage && (
                        <UserActionPanel
                            user={{
                                id: pageId,
                                enabled: isUserEnabled,
                            }}
                            isResetPresent={
                                Object.keys(initOperatorsState).some(operator =>
                                    initOperatorsState[operator].emp ||
                                    initOperatorsState[operator].cashier
                                ) &&
                                !isInternal
                            }
                            isSupervisor={Object.keys(initOperatorsState).some(operator => initOperatorsState[operator].supervisor)}
                            isLoading={loading}
                        />
                    )}
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item md={4} sm={12}>
                            <h2 className={clsx(classes.groupLabel, isInternal || false && classes.groupSubHeading)}>{translations['users-general-information']}</h2>
                        </Grid>
                        <Grid item md={8} sm={12}>
                            <Grid container spacing={3} className={classes.container}>
                                {informationInputs.map((
                                    {
                                        id,
                                        name,
                                        label,
                                        helperTextKey = '',
                                        required = true,
                                        disabled = false,
                                        maxLength,
                                        type = 'text',
                                        fullSize = false
                                    }: IUserAdd) => {
                                    const helperText = !firstSubmit && errorFields[name] && translations[errorMessagesKeys[name]] ||
                                        (helperTextKey ? `${translations[helperTextKey]}` : ' ');

                                    return (
                                        <Grid item sm={fullSize ? 12 : 6} xs={12} key={id}>
                                            <TextField
                                                error={!firstSubmit && errorFields[name]}
                                                helperText={helperText}
                                                className={classes.input}
                                                value={values[name] || ''}
                                                label={translations[label]}
                                                id={name}
                                                type={type}
                                                inputProps={{ maxLength }}
                                                onChange={handleChange(name, required)}
                                                onFocus={handleFocus(name)}
                                                onBlur={handleFocusOut(name)}
                                                variant="outlined"
                                                color="primary"
                                                disabled={disabled || isInternal || isReadOnlyPage}
                                            />
                                        </Grid>);
                                }
                                )}
                            </Grid>
                            <FormControlLabel
                                disabled={isInternal || isReadOnlyPage}
                                classes={{ root: classes.checkboxRow }}
                                control={
                                    <Checkbox
                                        checked={twoFactorAuth}
                                        onChange={handleTwoFactor}
                                        name="two-factor"
                                        color="primary"
                                    />
                                }
                                label={translations['users-two-factor-auth']}
                            />
                            {
                                passwordUpdateDate &&
                                    <p>
                                        {`${translations['users-password-update']}: `}
                                        <span data-a="password-update-date">
                                            {formatDateByPattern(new Date(formatToIsoDateString(passwordUpdateDate)), dateTimeFormat, timeZone)}
                                        </span>
                                    </p>
                            }
                        </Grid>
                    </Grid>
                    <Divider />
                    <UserOperators
                        firstSubmit={firstSubmit}
                        isMultitenancyOperators={isMultitenancyOperators}
                        clientId={clientId}
                        selectRoleInputName={selectRoleInputName}
                        selectOperatorName={selectOperatorName}
                        availableOperatorsPermissions={availableOperatorsPermissions}
                        initOperatorsState={initOperatorsState}
                        operatorsState={operatorsState}
                        operatorsList={operatorsList}
                        operatorsRole={operatorsRole}
                        cashierRoles={cashierRoles}
                        operatorsBetshops={operatorsBetshops}
                        errorFields={errorFields}
                        errorMessagesKeys={errorMessagesKeys}
                        operatorsSpecificUserErrors={operatorsSpecificUserErrors}
                        isInternal={isInternal}
                        readOnly={isReadOnlyPage}
                        operatorsSpecificUserState={operatorsSpecificUserState}
                        handleRoleChange={handleRoleChange}
                        handleCashierRoleChange={handleCashierRoleChange}
                        handleEmpBetshopsChange={handleEmpBetshopsChange}
                        handleCashierBetshopsChange={handleCashierBetshopsChange}
                        handleHideShopData={handleHideShopData}
                        handleOperatorRemove={handleOperatorRemove}
                        handleOperatorModalView={handleOperatorModalView}
                        handleOperatorsSpecificUserState={handleOperatorsSpecificUserState}
                    />
                    {serverErrorsKeys.map(error => (
                        <p key={error} className={classes.serverError}>{translations[error]}</p>
                    ))}
                    {(isValueChanged() || isAddPage) && !isReadOnlyPage &&
                        <footer className={classes.footer}>
                            <Button
                                data-a="user-add-button-cancel"
                                className={classes.actionButton}
                                variant="outlined"
                                onClick={handleCancel}
                            >
                                {translations['gen-cancel'] || ''}
                            </Button>
                            <Button
                                data-a="user-add-button-add"
                                className={classes.actionButton}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {isAddPage ? translations['users-add'] : translations['users-edit'] || ''}
                            </Button>
                        </footer>
                    }
                </form>
            </PageLayoutWrapper>
            <Prompt
                when={isValueChanged()}
                message={promptMessage}
            />
            <NotificationModal
                title={CreateUserNotification.title}
                description={UserOperatorsModal.description}
                isOpen={notificationModalView}
                onClose={closeNotificationModal}
            />
            <ListModal
                data={operatorsList}
                dataChecked={Object.keys(operatorsState)}
                keyToTranslations="operator"
                title={UserOperatorsModal.title}
                confirmTextKey={UserOperatorsModal.add}
                cancelTextKey={UserOperatorsModal.cancel}
                onClose={onCloseOperatorsModal}
                onConfirm={onConfirmOperatorsModal}
                isOpen={operatorsModalView}
            />
        </div>
    );
};

export default withRouter(UserAdd);
