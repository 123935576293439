import {
    IBetshopSupervisorDefaultFieldsSettings,
    SettingsModel,
} from 'api/models/general';
import {
    IBetshopDetailsBase,
    IBetshopDetailsResponse,
} from 'api/models/network-management';
import {
    betshopPayoutPurchaseLimitsNames,
    betshopSupervisorThresholdNames,
    voucherExpirationSettingNames,
    voucherExpirationTimeUnit,
} from 'const';
import {
    betshopDetailInputs,
    bethopPayoutPurchaseLimitsInputs,
    supervisorSettingsInputs,
    voucherExpirationInputs,
} from 'data';

import { capitalize } from 'utils/formatStr';
import {
    getDetailsFieldErrorData,
    getNumberFieldErrorData,
} from 'utils/validation';

import { isSearchValueAcceptable } from 'components/SearchField/business';

export type IBetshopValuesState = Required<IBetshopDetailsBase> & {
    [key in keyof typeof betshopPayoutPurchaseLimitsNames]: string;
} & {
    [key in keyof typeof betshopSupervisorThresholdNames]: string;
} & {
    [key in keyof typeof voucherExpirationSettingNames]: string;
}
export type IBetshopErrorsState = {[key in keyof IBetshopValuesState]: boolean}
export type IBetshopErrorMessagesState = {[key in keyof IBetshopValuesState]: string}

export type IBetshopFieldsData = {
    valuesInit: IBetshopValuesState;
    errorsInit: IBetshopErrorsState;
    errorMessagesInit: IBetshopErrorMessagesState;
}

const settingToDefaultNameMapper: {
    [key: string]: keyof IBetshopSupervisorDefaultFieldsSettings;
} = {
    [betshopSupervisorThresholdNames.mtlCashin]: 'MTLCashin',
    [betshopSupervisorThresholdNames.mtlCashout]: 'MTLCashout',
    [betshopSupervisorThresholdNames.ctrCashin]: 'CTRCashin',
    [betshopSupervisorThresholdNames.ctrCashout]: 'CTRCashout',
} as const;

export const errorDescriptionToFieldNameMapper: {
    [key: string]: keyof IBetshopValuesState;
} = {
    'RFC': 'rfc',
    'MTLCashin': 'mtlCashin',
    'MTLCashout': 'mtlCashout',
    'CTRCashin': 'ctrCashin',
    'CTRCashout': 'ctrCashout',
} as const;

export function initBetshopDetailInputs (
    translations: Record<string, string>,
    { valuesInit, errorsInit, errorMessagesInit }: IBetshopFieldsData,
    betshopDetailsResponse: IBetshopDetailsResponse = {} as IBetshopDetailsResponse
) {
    const betshop = betshopDetailsResponse?.betshop;
    const betshopDetailInputsData: Array<{
        name: Extract<keyof IBetshopValuesState, string>;
        required: boolean;
    }> = [
        ...betshopDetailInputs,
        {
            name: 'isTest',
            required: false,
        }
    ];

    betshopDetailInputsData
        .forEach(({ name, required }) => {
            let initValue;

            if (name === 'isTest') {
                initValue = betshop?.isTest ?? false;
            } else {
                initValue = betshop?.[name] ?? '';
            }

            const { error, errorMessage } = getDetailsFieldErrorData({ translations }, initValue, required);

            valuesInit[name as string] = initValue;
            errorsInit[name as string] = error;
            errorMessagesInit[name as string] = errorMessage;
        });
}

export function initPayoutPurchaseLimitsInputs (
    translations: Record<string, string>,
    { valuesInit, errorsInit, errorMessagesInit }: IBetshopFieldsData,
    betshopDetailsResponse: IBetshopDetailsResponse = {} as IBetshopDetailsResponse,
    globalSettings: SettingsModel
) {
    const settings = betshopDetailsResponse?.settings;

    bethopPayoutPurchaseLimitsInputs
        .forEach(({ name, required }) => {
            const initValue = `${(!settings
                ? globalSettings.settings.BetshopDefaultSettings.PayoutPurchaseLimits[settingToDefaultNameMapper[name] ?? capitalize(name)]
                : settings?.payoutPurchaseLimits?.[name]) ?? '0'}`;

            const { error, errorMessage } = getNumberFieldErrorData({ translations }, initValue, required);

            valuesInit[name] = initValue;
            errorsInit[name] = error;
            errorMessagesInit[name] = errorMessage;
        });
}

export function initSupervisorInputs (
    translations: Record<string, string>,
    { valuesInit, errorsInit, errorMessagesInit }: IBetshopFieldsData,
    betshopDetailsResponse: IBetshopDetailsResponse = {} as IBetshopDetailsResponse,
    globalSettings: SettingsModel
) {
    const settings = betshopDetailsResponse?.settings;

    supervisorSettingsInputs
        .forEach(({ name, required }) => {
            const initValue = `${(!settings
                ? globalSettings.settings.BetshopDefaultSettings.SupervisorSettings[settingToDefaultNameMapper[name] ?? capitalize(name)]
                : settings?.supervisorThresholds?.[name]) ?? '0'}`;

            const { error, errorMessage } = getNumberFieldErrorData({ translations }, initValue, required);

            errorsInit[name] = error;
            errorMessagesInit[name] = errorMessage;
            valuesInit[name] = initValue;
        });
}

export function initVoucherExpirationInputs (
    translations: Record<string, string>,
    { valuesInit, errorsInit, errorMessagesInit }: IBetshopFieldsData,
    betshopDetailsResponse: IBetshopDetailsResponse = {} as IBetshopDetailsResponse,
    globalSettings: SettingsModel
) {
    const settings = betshopDetailsResponse?.settings;

    voucherExpirationInputs
        .forEach(({ name, required, isInteger }) => {
            const isTimeUnit = name === voucherExpirationSettingNames.timeUnit;

            const initValue = `${(!settings
                ? globalSettings.settings.BetshopDefaultSettings.VoucherExpiration[settingToDefaultNameMapper[name] ?? capitalize(name)]
                : settings?.voucherExpiration?.[name]) ?? (isTimeUnit ? voucherExpirationTimeUnit.day : '0')}`;

            valuesInit[name] = initValue;

            // timeUnit dropdown will always have valid value (because of valid default value)
            if (!isTimeUnit) {
                const { error, errorMessage } = getNumberFieldErrorData({ translations }, initValue, required, { isInteger });

                errorsInit[name] = error;
                errorMessagesInit[name] = errorMessage;
            }
        });
}

export const filterBySearch = (data, text) => {
    return data.filter(({ id, name }) => {
        return isSearchValueAcceptable([id, name], text);
    });
};
