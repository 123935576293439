export function overrideStyles(classes, externalStyles) {
    if (externalStyles && Boolean(Object.keys(externalStyles))) {
        Object.keys(externalStyles).forEach(key => {
            if (classes[key]) {
                classes[key] = externalStyles[key];
            }
        });
    }

    return classes;
}
