import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tableWrap: {
        zIndex: 0,
        position: 'relative',
        display: 'flex',
        alignSelf: 'normal',
        flexDirection: 'column',
        flexGrow: 1
    },
    table: {
        tableLayout: 'fixed',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '4px',

        [theme.breakpoints.down('xs')]: {
            border: 'none'
        },
        '& thead': {
            textTransform: 'capitalize',

            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        '& tr': {
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(1, 2),
                borderBottom: '1px solid #e0e0e0'
            },
            '&:last-child': {
                [theme.breakpoints.down('xs')]: {
                    borderBottom: 'none'
                }
            }
        },
        '& td': {
            [theme.breakpoints.down('xs')]: {
                padding: 0,
                borderBottom: 'none'
            }
        },
    },
    tableRowEdit: {
        cursor: 'pointer',
    },
    headerCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    nameWrap: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center'
    },
    nameCell: {
        fontWeight: 500
    },
    testLabel: {
        marginLeft: theme.spacing(0.5),
    },
    balanceCell: {
        [theme.breakpoints.down('xs')]: {
            color: theme.palette.text.secondary
        },
    },
    controlsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'normal',
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(0),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        }
    },
    searchIcon: {
        position: 'absolute',
        right: '0',
        top: '-122px'
    },
    wrapOpen: {
        height: theme.spacing(4.5),
        marginBottom: theme.spacing(2),

        '& fieldset': {
            [theme.breakpoints.down('xs')]: {
                height: theme.spacing(5),
                opacity: 1
            }
        }
    },
}));

export default useStyles;
