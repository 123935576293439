import * as React from 'react';
import Api from 'api/Api';

import { IKpiPayload } from 'api/models/kpi';
import { KPI_TYPES } from '../../const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

export const KpiContext = React.createContext(null);

const kpiPayload: IKpiPayload = {
    FromDate: null,
    KpiTypes: [
        KPI_TYPES.SportGGR,
        KPI_TYPES.SportTurnover,
        KPI_TYPES.Margin,
        KPI_TYPES.BetCountAll
    ]
};

let kpiData, setKpiData;

const loadKpiData = async () => {
    try {
        const result = await Api.Kpi.GetAllData(kpiPayload);

        setKpiData(result);
    } catch (error) {
        // handle fetch errors here
    }
};

export const KpiContextProvider = (props) => {
    const { permissions }: GlobalContextModel = React.useContext(GlobalContext);

    [kpiData, setKpiData] = React.useState([]);

    React.useEffect(() => {
        permissions?.dashboardPermission && loadKpiData();
    }, [permissions]);

    return (
        <KpiContext.Provider value={kpiData}>
            {props.children}
        </KpiContext.Provider>
    );
};

export { loadKpiData };
