import React, { useContext, useState } from 'react';
import useStyles from './styles';

import {
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    withWidth,
    Button,
} from '@material-ui/core';
import { headOddsSheets } from 'data';
import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';
import { GlobalContextModel, LanguageListItemModel } from 'api/models/general';
import { sortBy } from 'utils/arrayMethods';
import OddsSheetsApi from 'api/services/OddsSheetsApi';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';
import { throwNetworkError } from 'utils/requestCancelation';
import { GlobalContext } from 'context/globalContext';
import { setLanguageCookie } from 'utils/cookieMethods';

const Api = {
    OddsSheets: new OddsSheetsApi(),
};

const apiRequests = {
    betShopIds: null
};

const OddsSheets = () => {
    const languagePdfCode = 'language';
    const languageIdCookie = 'languageId';

    const classes = useStyles({});
    const { globalSettings, translations }: GlobalContextModel = useContext(GlobalContext);

    const [isLoading, setIsLoading] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    const orderedHeadOddsSheets = headOddsSheets && sortBy('position', headOddsSheets);

    let orderedPdfTypes = globalSettings?.settings?.PdfReportSettings?.SportsbookProgramTypes && sortBy('Order', globalSettings?.settings?.PdfReportSettings?.SportsbookProgramTypes);

    const GetLanguagePdfCode = (payload: any, languages: Array<LanguageListItemModel>) => {
        const languageId = parseInt(payload[languageIdCookie]);
        const language = languages.find(lang => lang.Id === languageId);

        if (language){
            payload[languagePdfCode] = language.FullCode;
        } else {
            const defaultLanguageId = languages.find(lang => lang.IsDefault).Id;
            const defaultLanguage = languages.find(lang => lang.Id === defaultLanguageId);

            payload[languagePdfCode] = defaultLanguage.FullCode;
        }

        return payload;
    };

    const handleLoadingPdf = async (modelType: string) => {
        setIsLoading(true);
        setNetworkError(false);

        try {
            let payload = setLanguageCookie({ ...apiRequests }, globalSettings.languages, 'languageId');
            let payloadWithPdfCode = GetLanguagePdfCode({ ...payload }, globalSettings.languages);
            let link = await Api.OddsSheets.GetPdf(payloadWithPdfCode, modelType).then(res => res.link);
            let dataUrl = await fetch(link).then(pdf => pdf.blob()).then(blob => URL.createObjectURL(blob));

            if (link && dataUrl) {
                const newWindow = window.open();

                if (newWindow) {
                    newWindow.document.write(`<object width="100%" height="100%" type="application/pdf" 
                            data="${link}">
                            <center>${translations['general-error']}</center>
                            </object>`);
                    newWindow.document.close();
                }

                setIsLoading(false);
            } else {
                setIsLoading(false);
                setNetworkError(true);
            }
        } catch (error) {
            setIsLoading(false);
            setNetworkError(true);
            throwNetworkError(error);
        }
    };

    const handleHideAlert = () => {
        setNetworkError(false);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleWrap}>
                    <h1 className={classes.title}>
                        {translations['emp-odds-sheets-title'] || ''}
                    </h1>
                </div>
            </div>
            <Divider />
            <PageLayoutWrapper>
                <div className={classes.alertWrapper}>
                    <AlertMessageFullWidth
                        label={`${translations['general-error']}`
                        }
                        show={networkError}
                        handleHideAlert={handleHideAlert}
                    >
                        {
                            translations['gen-dismiss']
                        }
                    </AlertMessageFullWidth>
                </div>
                <BackdropSpinner open={isLoading} />
                {!isLoading &&
                    <div className={classes.tableWrap}>
                        <Table className={classes.table}>
                            <TableHead >
                                <TableRow>
                                    {orderedHeadOddsSheets.map((headCell) => (
                                        <TableCell
                                            align={headCell.align as 'left' | 'right'}
                                            key={headCell.id}
                                            className={classes.headerCell}
                                        >
                                            {translations[headCell.label]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderedPdfTypes
                                    ?.map((item, index) => (
                                        <TableRow key={index} >
                                            <TableCell className={classes.cell}>
                                                <p>{translations[`emp-odds-sheets-column-${item.SportbookProgramName}`] || ''}</p>
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={classes.cell}
                                            >
                                                <Button
                                                    className={classes.button}
                                                    onClick={() => {
                                                        handleLoadingPdf(item.SportbookProgramName);
                                                    }}
                                                    color="primary"
                                                >
                                                    {translations['emp-odds-sheets-column-action-open'] || ''}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                }
            </PageLayoutWrapper>
        </div>
    );
};

export default withWidth()(OddsSheets);
