import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    popover: { pointerEvents: 'none' },
    paper: {
        padding: spacing(8/8),
        marginTop: spacing(8/8),
    },
    chip: {},
    chipLabelSmall: {
        paddingRight: spacing(12/8),
        paddingLeft: spacing(12/8)
    },
    dataItemWrap: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        '& + $chip': {
            marginLeft: spacing(16/8),
        }
    },
    dataItemInPopover: {
        padding: spacing(8/8),
    },
    dataLink: {
        color: 'inherit',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

export default useStyles;
