import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {

    return createStyles({
        widget: {
            padding: '1.3rem 1.1rem',
            boxShadow: 'none',
            border: '1px solid #e0e0e0',
            [theme.breakpoints.down('xs')]: {
                background: '#fafbfc',
                borderRadius: '0',
                paddingBottom: '0.8rem',
                borderTop: '0',
                ':nth-child(even) > &': {
                    borderLeft: 'none',
                },
                ':nth-child(-n + 2) > &': {
                    'border-top': `1px solid ${theme.palette.grey[300]}`,
                    'border-top-right-radius': theme.shape.borderRadius
                },
                ':nth-child(-n + 1) > &': {
                    'border-top-left-radius': theme.shape.borderRadius,
                    'border-top-right-radius': '0'
                },
                ':nth-last-child(-n + 2) > &': {
                    'border-bottom-left-radius': theme.shape.borderRadius
                },
                ':last-child > &': {
                    'border-bottom-right-radius': theme.shape.borderRadius,
                    'border-bottom-left-radius': '0'
                },
            },
        },
        title: {
            minHeight: theme.spacing(4)
        },
        heading: {
            fontSize: '1em',
            marginBottom: '0.3rem',
            marginTop: '-0.2em',
            color: '#4e4e4e',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1em',
                marginBottom: '0',
            }
        },
        dayValueItem: {
            textAlign: 'right'
        },
        relativeValWrap: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: '0.1rem'
        },
        relativeValue: {
            fontSize: '1rem',
            position: 'relative',
            '& $icon': {
                color: '#e0452f'
            }
        },
        relativeValue_up: {
            '& $icon': {
                color: '#3dc582',
                transform: 'rotate(180deg)'
            }
        },
        relativeValue_empty: {
            color: '#4e4e4e',
            '& $icon': {
                display: 'none'
            }
        },
        relativeTitle: {
            fontSize: '1em',
            color: '#4e4e4e',
            marginTop: '-0.2rem',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.9em'
            }
        },
        icon: {
            height: '0.9em',
            width: '0.9em',
            position: 'relative',
            top: '0.3rem',
            [theme.breakpoints.down('xs')]: {
                height: '0.7em',
                width: '0.7em',
            }
        },
    });
});

export default useStyles;
