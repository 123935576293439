import * as React from 'react';
import {
    Grid, Box,
    Typography, Divider,
    Button, withStyles,
    Theme
} from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import WebIcon from '@material-ui/icons/Web';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import { GetApp } from '@material-ui/icons';

import useStyles from './styles';

import { MetricModel } from 'api/models/metric';
import { METRIC_TYPES, statusColors, EPOS_STATUS } from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': { borderBottom: 0 },
        '&:nth-child(2)': { borderTop: 0 },
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' }
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        minHeight: 68,
        '&$expanded': {
            backgroundColor: theme.palette.grey[100],
            minHeight: 68
        }
    },
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0'
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme: Theme) => ({
    root: { backgroundColor: theme.palette.grey[100] }
}))(MuiAccordionDetails);

const hasErrors = (deviceMetrics): boolean => Boolean(
    deviceMetrics?.errors?.length
);

const hasProperties = (deviceMetrics): boolean => Boolean(
    deviceMetrics?.properties &&
    Object.keys(deviceMetrics.properties).length
);

export const HeaderBlock = ({ className, text }) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <Accordion className={className}>
            <AccordionSummary>
                <Typography variant="h6" display="block">{translations[text]}</Typography>
            </AccordionSummary>
        </Accordion>
    );
};

const MetricIcons = {
    [METRIC_TYPES.SHELL]: WebIcon,
    [METRIC_TYPES.BILL_ACCEPTOR]: MonetizationOnIcon,
    [METRIC_TYPES.COIN_ACCEPTOR]: MonetizationOnIcon,
    [METRIC_TYPES.PRINTER]: PrintRoundedIcon,
    [METRIC_TYPES.NETWORK]: NetworkCheckIcon,
    [METRIC_TYPES.SHELL_UPADATE]: GetApp,
};

export const MetricBlock = (
    { metric, buttons, eposStatus }: { metric: MetricModel; buttons?: Array<any>; eposStatus: EPOS_STATUS }
) => {
    const metricStatus = checkMetricStatus();

    const classes = useStyles({ metricStatus });
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const MetricIcon = MetricIcons[metric.metricType];
    const iconColor = statusColors[metricStatus];

    function translateWithDefault(key: string, defValue: string): string {
        let result = translations[key];

        return result !== key ? result : defValue;
    }

    function checkMetricStatus() {
        if (eposStatus && eposStatus === EPOS_STATUS.OFFLINE) {
            return EPOS_STATUS.OFFLINE;
        } else if (!metric.status && hasErrors(metric)) {
            return EPOS_STATUS.WARNING;
        } else {
            return eposStatus;
        }
    }

    return (
        <Accordion id={`metric_block_${metric.metricType}`}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Box className={classes.iconBox}>
                    <MetricIcon style={{ color: iconColor }} />
                </Box>

                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                        {translateWithDefault(`hmd-metrics-${metric.metricName.replace(/ /gi, '-').toLowerCase()}`, metric.metricName)}
                    </Typography>
                    {
                        eposStatus === EPOS_STATUS.WARNING
                            ? <Typography variant="body2" color="textSecondary">{translations['hmd-epos-has-issues']}</Typography>
                            : null
                    }
                </Box>
            </AccordionSummary>

            <AccordionDetails>
                <Box padding={3} className={classes.detailsContainer}>

                    {metric.errors?.map((error) => (
                        error?.code && error?.message
                            ? (
                                <Box marginBottom={2} key={error.code} display="flex" className={classes.errorMessage}>
                                    <ErrorOutlineIcon color="error" style={{ margin: '0 9px' }} />
                                    <Typography variant="subtitle2" style={{ lineHeight: '1.2' }}>
                                        {translateWithDefault(`hmd-error-msg-type-${metric.metricType}-code-${error.code.toLowerCase()}`, error.message)}
                                    </Typography>
                                </Box>)
                            : null
                    )
                    )}

                    {hasProperties(metric) ? (
                        <Grid container>
                            {Object.keys(metric.properties).map((property) => (
                                metric.properties[property] !== '' && (
                                    <Grid key={property} container justify="space-between">
                                        <Grid item lg={4} xs={12}>
                                            <Typography
                                                variant="body2"
                                                className={classes.propertyName}
                                            >
                                                {property}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={8} xs={12}>
                                            <Typography variant="body2" className={classes.propertyDesc}>
                                                {metric.properties[property]}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))
                            )}
                        </Grid>)
                        : null
                    }
                    {buttons?.some(button => button.permission) &&
                        <React.Fragment>
                            <Divider style={{ margin: '6px 0 18px' }} />
                            {buttons.map((action) => (
                                action.permission &&
                                    <Button
                                        key={action.label}
                                        variant="outlined"
                                        className={classes.normalCaseButton}
                                        onClick={action.onClick}
                                    >
                                        {translations[action.label]}
                                    </Button>
                            ))}
                        </React.Fragment>
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

interface IMonitorProps {
    title: string;
    metricBlocks: {
        [key: string]: {
            metric: MetricModel;
            buttons?: Array<{ label: string; onClick: Function }>;
        };
    };
    eposStatus: EPOS_STATUS;
}

const Monitor = ({ title, metricBlocks, eposStatus }: IMonitorProps) => {
    const classes = useStyles({});

    return (
        <React.Fragment>
            <HeaderBlock text={title} className={classes.header} />

            {Object.values(metricBlocks).map((block) => (
                block.metric
                    ? (
                        <MetricBlock
                            key={block.metric.metricType}
                            metric={block.metric}
                            buttons={block.buttons}
                            eposStatus={eposStatus}
                        />)
                    : null
            ))}
        </React.Fragment>
    );
};

export const GraphsBlock = ({ src }) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <Box paddingBottom={3}>
            <HeaderBlock text={translations['hmd-graphs']} className={classes.header} />
            <Box className={classes.graphsContainer}>
                <iframe
                    className={classes.iFrame}
                    src={src}
                    frameBorder="0"
                />
            </Box>
        </Box>
    );
};

export default Monitor;
