import { IFilterListItem, defaultTilesColumnsNum, IFilterList } from 'const';
import defaultTheme from 'theme';

export const sortingByClicked = (filteringData: Array<IFilterListItem>, activeItems: Array<IFilterListItem>) => {
    const sortedUncheckedItems = filteringData
        .filter(e => activeItems.every(activeItem => activeItem.id != e.id))
        .sort((a, b) => String(a.name).localeCompare(String(b.name)));

    return [...activeItems.sort((a, b) => String(a.name).localeCompare(String(b.name))), ...sortedUncheckedItems];
};

export const columnsInRow = (width: number) => {
    const xl4 = defaultTheme.breakpoints.width('xl'); // 1920
    const xxxl = 1800;
    const xxl = 1600;
    const xl = 1400;
    const lg = defaultTheme.breakpoints.width('lg'); // 1280
    const md = defaultTheme.breakpoints.width('md'); // 960
    const mdx = 780;
    const sm = defaultTheme.breakpoints.width('sm'); // 600
    let numColumns = defaultTilesColumnsNum;

    if (width < xl4 && width >= xxxl) {
        numColumns = 9;
    } else if (width < xxxl && width >= xxl) {
        numColumns = 8;
    } else if (width < xxl && width >= xl) {
        numColumns = 7;
    } else if (width < xl && width >= lg) {
        numColumns = 6;
    } else if (width < lg && width >= md) {
        numColumns = 5;
    } else if (width < md && width >= mdx) {
        numColumns = 4;
    } else if (width < mdx && width >= sm) {
        numColumns = 3;
    } else if (width < sm) {
        numColumns = 2;
    }

    return numColumns;
};

export const clearNotExistingData = (newData: IFilterList, oldData: IFilterList): IFilterList => {
    let result = { ...oldData };

    Object.keys(result).forEach(key => {
        const oldArrayByKey = [...result[key]];
        const newArrByKey = [];

        oldArrayByKey.forEach(oldDataItem => {
            if (newData[key].some(newDataItem => newDataItem.id === oldDataItem.id)) {
                newArrByKey.push(oldDataItem);
            }
        });
        result[key] = newArrByKey;
    });

    return result;
};
