import defaultTheme from 'theme';
import { IRequestInfo, IAlertMessage } from 'api/models/reports';

export const defaultTimezoneIANA = 'America/New_York';

export enum EPOS_STATUS {
    MAINTENANCE = 1,
    ONLINE = 2,
    OFFLINE = 3,
    WARNING = 4,
    PENDING_MAINTENANCE = 5
}

export enum EPOS_STATUS_NAMES {
    ONLINE = 'gen-status-online',
    OFFLINE = 'gen-status-offline',
    MAINTENANCE = 'gen-status-under-maintenance',
    WARNING = 'gen-status-warning',
    PENDING_MAINTENANCE = 'gen-status-pending-maintenance'
}

export const statusColors = {
    1: defaultTheme.palette.grey[600], // under maintenance
    2: defaultTheme.palette.secondary.main, // normal
    3: defaultTheme.palette.grey[700], // offline
    4: defaultTheme.palette.error.main, // warning
    5: '#B88A00',
};
export const statusBgColors = {
    1: defaultTheme.palette.grey[300], // under maintenance
    2: '#d0f6e8', // normal
    3: defaultTheme.palette.grey[300], // offline
    4: '#fad0cc', // warning
};

export enum METRIC_TYPES {
    SHELL = 1,
    BILL_ACCEPTOR = 2,
    COIN_ACCEPTOR = 3,
    PRINTER = 4,
    DOORS = 5,
    MINI_SWIPE_READER = 6,
    NFC = 7,
    NETWORK = 8,
    PC = 9,
    STATUS = 10,
    TOUCHSCREEN = 11,
    SHELL_UPADATE = 15
}

export enum GRID_COLUMNS {
    col1 = 1,
    col2 = 2,
    col3 = 3,
    col4 = 4,
    col5 = 5,
    col6 = 6,
    col7 = 7,
    col8 = 8,
    col9 = 9,
    col10 = 10,
    col11 = 11,
    col12 = 12
}

export const EPOS_PUSH_RESTART_TIMEOUT = 30000;
export const EPOS_PUSH_THROTTLING_INTERVAL = 3000;

export const EPOS_REQUEST_IMGS_INTERVAL = 1000 * 60 * 2;
export const ADDITIONAL_DATA_REQUEST_INTERVAL = 1000 * 60;
export const DETAILS_REQUEST_INTERVAL = 1000 * 60;

export const MAX_LOGS_TOTAL_COUNT = 10000;

export enum EPOS_ENVIRONMENT_TYPE {
    Production = 1,
    Canary = 2,
}

export enum COMMAND_TYPES {
    RestartPC = 1,
    RestartShell = 2,
    RestartPrinter = 3,
    ReInitBillAcceptor = 4,
    EnableMaintenanceMode = 5,
    DisableMaintenanceMode = 6,
    ShellUpdate = 14,
    ReloadShellSettings = 15,
    SwitchEnviroment = 16,
}

export const COMMAND_SUBTYPES = {
    SwitchEnviromentProduction: `${COMMAND_TYPES.SwitchEnviroment}_${EPOS_ENVIRONMENT_TYPE.Production}`,
    SwitchEnviromentCanary: `${COMMAND_TYPES.SwitchEnviroment}_${EPOS_ENVIRONMENT_TYPE.Canary}`,
};

export enum IMAGE_TYPES {
    Main = 1,
    Technician = 2,
    Second = 3
}

export const EPOS_FILTERS = {
    location: 'location',
    os: 'os',
    shell: 'shell',
    monitoringAgentVersion: 'monitoringAgentVersion',
    dataSetVersion: 'dataSetVersion',
    updateStatus: 'updateStatus',
    environmentType: 'environmentType',
};

export const AUDIT_LOG_FILTERS = {
    byAction: 'byAction',
    byDate: 'byDate'
};

export const EPOS_FILTERS_TITLES = {
    location: 'gen-betshop',
    os: 'gen-os',
    shell: 'hm-filter-shell-version',
    monitoringAgentVersion: 'hm-filter-monitoring-agent-version',
    dataSetVersion: 'hm-filter-bill-validator',
    updateStatus: 'hm-filter-update-status',
    environmentType: 'gen-environment',
};

export const EPOS_FILTERS_TRANLATION_KEY = {
    updateStatus: 'hm-update-status'
};

export const AUDIT_LOG_FILTERS_TITLES = {
    byAction: 'audit-logs-column-action',
    byDate: 'audit-logs-time-filter-title'
};

export interface IFilterListItem {
    id: string | number;
    name: string;
}

export interface IPointsOfSaleFilterListItem extends IFilterListItem {
    betShopId: number;
}

export interface IFilterNumberIdListItem {
    id: number;
    name: string;
}

export interface IFilterListBase {
    [key: string]: Array<IFilterListItem>;
}

export interface IFilterList extends IFilterListBase {
    location: Array<IFilterListItem>;
    os: Array<IFilterListItem>;
    shell: Array<IFilterListItem>;
    monitoringAgentVersion: Array<IFilterListItem>;
    dataSetVersion: Array<IFilterListItem>;
    updateStatus: Array<IFilterListItem>;
}

export const filterListInitial = {
    location: [],
    os: [],
    shell: [],
    monitoringAgentVersion: [],
    dataSetVersion: [],
    updateStatus: [],
    environmentType: [],
};

export const filterListOpenExpansions = {
    location: false,
    os: false,
    shell: false,
    monitoringAgentVersion: false,
    dataSetVersion: false,
    environmentType: false,
};

export const noImage = 'noimage';
export const imageUnavailable = 'Image Unavailable';
export const terminalOffline = 'Terminal Offline';

export const LanguageIdCookie = 'LanguageId';

export const defaultTilesColumnsNum = 10;

export const ERROR_TYPES = {
    SHELL_ERROR: {
        id: 1,
        icon: 'web',
        key: 'hm-filter-shell'
    },
    MONEY_ERROR: {
        id: 2,
        icon: 'monetization_on',
        key: 'hm-filter-money'
    },
    CARD_ERROR: {
        id: 3,
        icon: 'monetization_on',
        key: 'hm-filter-money'
    },
    PRINTER_ERROR: {
        id: 4,
        icon: 'print',
        key: 'hm-filter-printer'
    },
    NETWORK_ERROR: {
        id: 8,
        icon: 'network_check',
        key: 'hm-filter-network'
    },
    SHELL_UPDATE_ERROR: {
        id: 15,
        icon: 'get_app',
        key: 'hmd-shell-update'
    },
};

export const KPI_TYPES = {
    SportGGR: 'SportGGR',
    SportTurnover: 'SportTO',
    BetCountAll: 'BetCountAll',
    Margin: 'Margin'
};

export enum AUDIT_ACTIONS {
    LOGIN = 1,
    LOGOUT = 2,
    RESTART_TERMINAL = 3,
    ENABLE_MAINTENANCE = 4,
    DISABLE_MAINTENANCE = 5,
    RESTART_PRINTER = 6,
    REINITIALIZE_BILL_ACCEPTOR = 7,
    RESTART_SHELL = 8,
    VNC_CONNECT = 9,
    SSH_CONNECT= 10,
    TECHNICIAN_CONNECT = 11,
    USER_ADD = 12,
    USER_CHANGE_PASSWORD = 13,
    USER_EDIT = 14,
    USER_BLOCK = 15,
    USER_UNBLOCK = 16,
    MAINTENANCE_UPLOAD_IMAGE = 19,
    MAINTENANCE_DELETE_IMAGE = 20,
    NETWORKMANAGEMENT_BETSHOP_CREATE = 21,
    NETWORKMANAGEMENT_BETSHOP_EDIT = 22,
    UPDATE_SHELL = 23,
    RESET_PASSWORD = 24,
    ROLE_ADD = 25,
    ROLE_EDIT = 26,
    USER_RESET_SUPERVISOR_BARCODE = 27,
    USER_CHANGE_SUPERVISOR_PIN = 28,
    USER_RESET_SUPERVISOR_PIN = 29,
    RELOAD_SHELL_SETTINGS = 30,
    SWITCH_TO_PRODUCTION = 31,
    SWITCH_TO_CANARY = 32,
    EXPORT_TO_EXCEL = 33,
    TICKET_REPRINT_AUTHORIZATION = 34,
    TICKET_VOID = 35,
    SUPERVISOR_BARCODE_DOWNLOAD = 36,
    VOUCHER_REPRINT_AUTHORIZATION = 37,
    ODD_SHEETS_PDF = 38,
}

export const AUDIT_ACTION_KEYS = {
    [AUDIT_ACTIONS.LOGIN]: 'audit-logs-action-login',
    [AUDIT_ACTIONS.LOGOUT]: 'audit-logs-action-logout',
    [AUDIT_ACTIONS.RESTART_TERMINAL]: 'audit-logs-action-restart-terminal',
    [AUDIT_ACTIONS.ENABLE_MAINTENANCE]: 'audit-logs-action-enable-maintenance',
    [AUDIT_ACTIONS.DISABLE_MAINTENANCE]: 'audit-logs-action-disable-maintenance',
    [AUDIT_ACTIONS.RESTART_PRINTER]: 'audit-logs-action-restart-printer',
    [AUDIT_ACTIONS.REINITIALIZE_BILL_ACCEPTOR]: 'audit-logs-action-reinitialize-bill-acceptor',
    [AUDIT_ACTIONS.RESTART_SHELL]: 'audit-logs-action-restart-shell',
    [AUDIT_ACTIONS.SSH_CONNECT]: 'audit-logs-action-ssh-connect',
    [AUDIT_ACTIONS.VNC_CONNECT]: 'audit-logs-action-vnc-connect',
    [AUDIT_ACTIONS.TECHNICIAN_CONNECT]: 'audit-logs-action-technician-connect',
    [AUDIT_ACTIONS.RELOAD_SHELL_SETTINGS]: 'audit-logs-action-health-reload-shell-settings',
    [AUDIT_ACTIONS.USER_ADD]: 'audit-logs-action-user-add',
    [AUDIT_ACTIONS.USER_CHANGE_PASSWORD]: 'audit-logs-action-user-change-password',
    [AUDIT_ACTIONS.USER_EDIT]: 'audit-logs-action-user-edit',
    [AUDIT_ACTIONS.USER_BLOCK]: 'audit-logs-action-user-block',
    [AUDIT_ACTIONS.USER_UNBLOCK]: 'audit-logs-action-user-unblock',
    [AUDIT_ACTIONS.NETWORKMANAGEMENT_BETSHOP_CREATE]: 'audit-logs-action-network-betshop-create',
    [AUDIT_ACTIONS.NETWORKMANAGEMENT_BETSHOP_EDIT]: 'audit-logs-action-network-betshop-edit',
    [AUDIT_ACTIONS.MAINTENANCE_UPLOAD_IMAGE]: 'audit-logs-action-maintenance-upload-image',
    [AUDIT_ACTIONS.MAINTENANCE_DELETE_IMAGE]: 'audit-logs-action-maintenance-delete-image',
    [AUDIT_ACTIONS.UPDATE_SHELL]: 'hmd-shell-update',
    [AUDIT_ACTIONS.RESET_PASSWORD]: 'audit-logs-action-reset-password',
    [AUDIT_ACTIONS.ROLE_ADD]: 'audit-logs-action-role-add',
    [AUDIT_ACTIONS.ROLE_EDIT]: 'audit-logs-action-role-edit',
    [AUDIT_ACTIONS.USER_RESET_SUPERVISOR_BARCODE]: 'audit-logs-action-user-reset-supervisor-barcode',
    [AUDIT_ACTIONS.USER_CHANGE_SUPERVISOR_PIN]: 'audit-logs-action-user-change-supervisor-pin',
    [AUDIT_ACTIONS.USER_RESET_SUPERVISOR_PIN]: 'audit-logs-action-user-reset-supervisor-pin',
    [AUDIT_ACTIONS.SWITCH_TO_PRODUCTION]: 'hm-bulk-action-switch-env-production',
    [AUDIT_ACTIONS.SWITCH_TO_CANARY]: 'hm-bulk-action-switch-env-canary',
    [AUDIT_ACTIONS.EXPORT_TO_EXCEL]: 'audit-logs-action-export-to-excel',
    [AUDIT_ACTIONS.TICKET_REPRINT_AUTHORIZATION]: 'audit-logs-action-ticket-reprint-authorization',
    [AUDIT_ACTIONS.TICKET_VOID]: 'audit-logs-action-ticket-void',
    [AUDIT_ACTIONS.SUPERVISOR_BARCODE_DOWNLOAD]: 'audit-logs-action-supervisor-barcode-download',
    [AUDIT_ACTIONS.VOUCHER_REPRINT_AUTHORIZATION]: 'audit-logs-action-voucher-reprint-authorization',
    [AUDIT_ACTIONS.ODD_SHEETS_PDF]: 'audit-logs-action-odds-sheets-pdf',
};

export const duplicateUserNameKey = 'users-valid-msg-dup-uname';

export const transitionDuration = '.3s';

export const mediaMdx = '@media (max-width:780px)';

export const pageWrapContainer = {
    maxWidth: '1760px'
};

export const executeTerminalActions = [
    'health-monitor:restart-terminal',
    'health-monitor:restart-printer',
    'health-monitor:reinit-bill-validator',
    'health-monitor:restart-shell',
    'health-monitor:maintenance-mode',
    'health-monitor:update-shell'
];

export const establishRemoteAccess = [
    'health-monitor:view-technician-page',
    'health-monitor:vnc-access',
    'health-monitor:ssh-access',
];

export enum ShellApplicationType {
    Terminal = 0,
    Cashier = 1,
    Tv = 2
}

export const rowsPerPageOptions = [20, 30, 40, 50, 100];
export const INPUT_DEBOUNCE_INTERVAL = 500;

export const resultsDateRangeFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
};

export enum Order {
    ascending = 'asc',
    descending = 'desc',
}

export interface IWithId {
    id: string | number;
}

export enum PAYOUT_STATUS {
    SOLD = 1,
    PAID,
    UNPAID,
}

export const PAYOUT_STATUS_NAMES: {[key in keyof typeof PAYOUT_STATUS]: string} = {
    SOLD: 'emp-payout-status-sold',
    PAID: 'emp-payout-status-paid',
    UNPAID: 'emp-payout-status-unpaid',
};

export enum BET_STATUS {
    OPENED,
    LOST,
    WON,
    DRAW,
    CANCELED,
    CASHOUT = 32,
    VOIDED = 1000,
}

export const BET_STATUS_NAMES: {[key in keyof typeof BET_STATUS]: string} = {
    OPENED: 'emp-bet-status-open',
    LOST: 'emp-bet-status-lost',
    WON: 'emp-bet-status-won',
    DRAW: 'emp-bet-status-draw',
    VOIDED: 'emp-bet-status-voided',
    CANCELED: 'emp-bet-status-canceled',
    CASHOUT: 'emp-bet-status-cash-out',
};

export enum BET_TYPE {
    SINGLE = 1,
    COMBO,
    TEASER = 4,
    QA = 7,
    SYSTEM = 13,
    YOUR_BET = 24,
    PROGRESSIVE_PARLAY = 51,
    MULTIWAY = 99,
    MULTIPLES = 1001,
    SGPX = 1002,
    SGPX_PARLAY = 1003,
    SGPX_ROBINS = 1004,
    SGPX_MULTIPLES = 1005,
    SINGLES = 1111,
}

export const BET_TYPE_NAMES: {[key in keyof typeof BET_TYPE]?: string} = {
    SINGLE: 'emp-ticket-bet-type-single',
    COMBO: 'emp-ticket-bet-type-combo',
    TEASER: 'emp-ticket-bet-type-teaser',
    QA: 'emp-ticket-bet-type-qa',
    SYSTEM: 'emp-ticket-bet-type-system',
    YOUR_BET: 'emp-ticket-bet-type-your-bet',
    PROGRESSIVE_PARLAY: 'emp-ticket-bet-type-progressive-parlay',
    MULTIWAY: 'emp-ticket-bet-type-multiway',
    MULTIPLES: 'emp-ticket-bet-type-multiples',
    SGPX_PARLAY: 'emp-ticket-bet-type-sgpx-parlay',
    SGPX_ROBINS: 'emp-ticket-bet-type-sgpx-robins',
    SGPX_MULTIPLES: 'emp-ticket-bet-type-sgpx-multiples',
    SINGLES: 'emp-ticket-bet-type-singles',
};

export const betTypesSeparator = ', ';

export enum TICKETS_SEARCH_BY_DATES {
    creationDate = 1,
    settledDate,
    cancelationDate,
    paidDate,
    expirationDate,
    voidedDate
}
export const VOUCHER_NUMBER_LENGTH = 7;

export const TICKETS_SEARCH_BY_DATES_SORTING_COLUMNS: {[key in keyof typeof TICKETS_SEARCH_BY_DATES]} = {
    creationDate: 'creationDate',
    settledDate: 'settledDate',
    cancelationDate: 'settledDate',
    paidDate: 'paidDate',
    expirationDate: 'expirationDate',
    voidedDate: 'settledDate',
};

export enum ORDER_BY_VOUCHERS_COLUMN {
    issueDate = 0,
    cashoutDate = 1,
    cost = 2
}

export const ORDER_BY_VOUCHERS_COLUMN_NAMES: {[key in keyof typeof ORDER_BY_VOUCHERS_COLUMN]} = {
    issueDate: 'issueDate',
    cashoutDate: 'cashoutDate',
    cost: 'cost'
};

export const ORDER_BY_VOUCHERS_COLUMN_VALUES: {[key in keyof typeof ORDER_BY_VOUCHERS_COLUMN]} = {
    issueDate: 0,
    cashoutDate: 1,
    cost: 2
};

export enum ORDER_BY_SUPERVISOR_APPROVALS_COLUMN {
    approvalDate = 0,
    amount = 1
}

export const ORDER_BY_SUPERVISOR_APPROVALS_COLUMN_NAMES: {[key in keyof typeof ORDER_BY_SUPERVISOR_APPROVALS_COLUMN]} = {
    approvalDate: 'approvalDate',
    amount: 'amount',
};

export const ORDER_BY_SUPERVISOR_APPROVALS_COLUMN_VALUES: {[key in keyof typeof ORDER_BY_SUPERVISOR_APPROVALS_COLUMN]} = {
    approvalDate: 0,
    amount: 1
};

export enum ORDER_BY_CARD_TRANSACTIONS_COLUMN {
    creationDate = 0,
    amount = 1
}

export const ORDER_BY_CARD_TRANSACTIONS_COLUMN_NAMES: {[key in keyof typeof ORDER_BY_CARD_TRANSACTIONS_COLUMN]} = {
    creationDate: 'creationDate',
    amount: 'amount',
};

export const ORDER_BY_CARD_TRANSACTIONS_COLUMN_VALUES: {[key in keyof typeof ORDER_BY_CARD_TRANSACTIONS_COLUMN]} = {
    creationDate: 0,
    amount: 1
};

export enum VOUCHERS_STATUS {
    issued = 1,
    cashed = 2
}

export const VOUCHERS_STATUS_NAMES: {[key in keyof typeof VOUCHERS_STATUS]} = {
    issued: 'issued',
    cashed: 'cashed'
};

export enum VOUCHERS_TYPE {
    regular,
    collectedMoney
}

export const VOUCHERS_TYPE_NAMES: {[key in keyof typeof VOUCHERS_TYPE]} = {
    regular: 'regular',
    collectedMoney: 'collectedMoney'
};

export enum ENTITY_TYPE {
    cashier = 31,
    terminal
}

export const ENTITY_TYPE_NAMES: {[key in keyof typeof ENTITY_TYPE]} = {
    cashier: 'cashier',
    terminal: 'terminal'
};

export const SHOW_PAGINATION_INFO_RANGE_MONTH = 6;

export enum TRANSACTION_STATUS {
    NONE,
    CREATED,
    AUTHORIZED,
    PENDING,
    APPROVED,
    CANCELLED,
    DECLINED,
    FAILED,
    REVERSED,
    VOIDED,
}

export enum DETAILS_REFRESH_REQUEST_TYPE {
    REPRINT_AUTHORIZATION,
    TICKET_VOID
}

export const TRANSACTION_STATUS_NAMES: {[key in keyof typeof TRANSACTION_STATUS]: string} = {
    NONE: 'emp-transaction-status-none',
    CREATED: 'emp-transaction-status-created',
    AUTHORIZED: 'emp-transaction-status-authorized',
    PENDING: 'emp-transaction-status-pending',
    APPROVED: 'emp-transaction-status-approved',
    CANCELLED: 'emp-transaction-status-cancelled',
    DECLINED: 'emp-transaction-status-declined',
    FAILED: 'emp-transaction-status-failed',
    REVERSED: 'emp-transaction-status-reversed',
    VOIDED: 'emp-transaction-status-voided',
};

export enum APPROVAL_STATUS {
    UNDEFINED,
    STARTED,
    COMPLETED,
}

export enum APPROVAL_TYPE {
    MTL = 1,
    CTR,
    W2G,
}

export const APPROVAL_TYPE_NAMES: {[key in keyof typeof APPROVAL_TYPE]: string} = {
    MTL: 'emp-supervisor-approval-mtl',
    CTR: 'emp-supervisor-approval-ctr',
    W2G: 'emp-supervisor-approval-w2g',
};

export const licenseTypes = ['FB', 'FC', 'FD', 'FE'];

export enum betshopPayoutPurchaseLimitsNames {
    limitWinningsAmount = 'limitWinningsAmount',
    limitPurchaseAmount = 'limitPurchaseAmount',
}

export enum betshopSupervisorThresholdNames {
    w2GPayoutLimit = 'w2GPayoutLimit',
    w2GWagerMultiple = 'w2GWagerMultiple',
    mtlCashin = 'mtlCashin',
    mtlCashout = 'mtlCashout',
    ctrCashin = 'ctrCashin',
    ctrCashout = 'ctrCashout',
}

export enum voucherExpirationSettingNames {
    expPeriod = 'expPeriod',
    timeUnit = 'timeUnit',
}

export enum voucherExpirationTimeUnit {
    day = 0,
    month = 1,
}

export const voucherExpirationTimeUnitNames: {[key in keyof typeof voucherExpirationTimeUnit]: string} = {
    day: 'emp-settings-unit-day',
    month: 'emp-settings-unit-month',
};

export const approvalTypesSeparator = '+';

export enum TRANSACTION_TYPE {
    cashierBetPlacement = 1595,
    ticketPayWin = 1596,
    voucherIssue = 1598,
    voucherRedeem = 1599,
    retailPlayerDeposit = 14786,
    retailPlayerWithdraw = 14787,
}

export const TRANSACTION_TYPE_NAMES: {[key in keyof typeof TRANSACTION_TYPE]: string} = {
    cashierBetPlacement: 'emp-supervisor-transaction-cashierBetPlacement',
    ticketPayWin: 'emp-supervisor-transaction-ticketPayWin',
    voucherIssue: 'emp-supervisor-transaction-voucherIssue',
    voucherRedeem: 'emp-supervisor-transaction-voucherRedeem',
    retailPlayerDeposit: 'emp-supervisor-transaction-retailPlayerDeposit',
    retailPlayerWithdraw: 'emp-supervisor-transaction-retailPlayerWithdraw',
};

export const TRANSACTION_TYPE_DOCUMENT_URLS: {[key in TRANSACTION_TYPE]?: string} = {
    [TRANSACTION_TYPE.cashierBetPlacement]: '/reports/tickets',
    [TRANSACTION_TYPE.ticketPayWin]: '/reports/tickets',
};

export const OPERATORS_SPECIFIC_USER_KEYS = {
    emp: 'emp',
    supervisor: 'supervisor',
    cashier: 'cashier',
    general: 'general'
};

export enum EXCEL_EXPORT_ERROR_ENUM {
    NoData = 1,
	GenerationError = 2
}

export enum REPORTS_NAMES {
    cardTransactions = 'cardTransactions',
    supervisor = 'supervisor',
    tickets = 'tickets',
    vouchers = 'vouchers',
    playerStatement = 'playerStatement'
}

export const initRequestInfo: IRequestInfo = {
    status: 'success',
    message: ''
};

export enum APPLICATION_TYPE {
    emp = 1,
}

export const initialAlertMessage: IAlertMessage = {
    show: false,
    message: ''
};

export enum CANCEL_REASONS {
    CanceledByLBM,
    CustomerRequested,
    WriterRequested,
    KioskMalfunction,
    CashierMalfunction,
    SystemMalfunction,
    TestBet,
    HouseRules,
    DebitCardIssue,
    EventCanceled,
}
