import { IFilterDublicateEmailItem } from 'api/models/user';

export function toggleItemInArray<T = any>(item, selectedIndex, dataArr: Array<T>) {
    let newArr: Array<T> = [];

    if (selectedIndex === -1) {
        newArr = newArr.concat(dataArr, item);
    } else if (selectedIndex === 0) {
        newArr = newArr.concat(dataArr.slice(1));
    } else if (selectedIndex === dataArr.length - 1) {
        newArr = newArr.concat(dataArr.slice(0, -1));
    } else if (selectedIndex > 0) {
        newArr = newArr.concat(
            dataArr.slice(0, selectedIndex),
            dataArr.slice(selectedIndex + 1),
        );
    }

    return newArr;
}

export function everyChecked(data, key) {
    return data[key].every(e => e.checked);
}

export function arrayToFlatObject(array) {
    // [{ a: 1},{ b: 2},{ c: 3}] ---> {a: 1, b: 2, c: 3}
    return array.reduce(function(result, item) {
        let key = Object.keys(item)[0];

        result[key] = item[key];

        return result;
    }, {});
}

export const sortBy = <T>(key: string, arr: Array<T>) => arr.sort((a, b) => a[key] - b[key]);

export function sortByValuesThenNames(emailArray: Array<IFilterDublicateEmailItem>) {
    return emailArray.sort((a, b) => {
        if (a.count !== b.count) {
            return b.count - a.count;
        } else {
            return a.email.localeCompare(b.email);
        }
    });
}
