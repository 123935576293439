import { makeStyles } from '@material-ui/core/styles';
import { pageWrapContainer } from 'const';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(6, 6, 0),

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(6, 2, 0),
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: theme.spacing(2, 1.5, 0),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    testLabelLong: {
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(2)
    },
    testLabel: {
        marginLeft: theme.spacing(0.5),
    },
    subTitle: {
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(0.5)
    },
    iconButton: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    iconLabel: {
        fontSize: '0.9rem',
        fontWeight: 600
    },
    bodyWrapContainer: {
        overflow: 'hidden',
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth
    },
    form: {
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth,

        paddingBottom: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(-2),
            overflow: 'hidden'
        }
    },
    container: {
        '& > .MuiGrid-item': {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5)
        }
    },
    row: {
        padding: theme.spacing(3, 0),
        marginTop: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2, 2)
        },

        ':last-child > &': {
            [theme.breakpoints.down('xs')]: {
                marginBottom: 0,
            }
        },
    },
    groupLabel: {
        marginTop: 0,
        marginBottom: theme.spacing(2),
        fontSize: '1.5rem',
        fontWeight: 500,

        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2)
        },
    },
    groupSubHeading: {
        marginTop: 0,
        color: theme.palette.text.hint,

        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2)
        },
    },
    input: {
        position: 'relative',
        width: '100%',

        '& * .MuiOutlinedInput-notchedOutline': { minWidth: 0 }
    },
    licenseType: {
        width: theme.spacing(11),
        minWidth: theme.spacing(11),
        whiteSpace: 'nowrap',

        '&.MuiGrid-item': { paddingRight: 0 }
    },
    licenseId: {
        maxWidth: '100%',
        flexBasis: 0,
        flexGrow: 1
    },
    checkboxRow: {
        marginTop: theme.spacing(0.5),

        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2),
        }
    },
    actionButton: {
        textTransform: 'none',
        marginLeft: theme.spacing(2)
    },
    serverError: {
        margin: theme.spacing(1, 1.75, 0),
        padding: 0,
        fontSize: '0.75rem',
        color: theme.palette.error.main
    },
    selectLabel: {
        background: theme.palette.background.default
    },
    groupsButton: {
        height: theme.spacing(7),
        width: 'fit-content'
    },
    expPeriod: {
        width: theme.spacing(7)
    }
}));
