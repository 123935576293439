import * as React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, withRouter } from 'react-router-dom';
import Root from 'components/Root';

import CustomPrompt from 'components/CustomPrompt/CustomPrompt';

import { menuItems } from 'data';

import { EposContextProvider } from 'context/epos';
import { KpiContextProvider } from 'context/kpi';
import { ExportToExcelProvider } from 'context/exportToExcel';
import { GlobalContextProvider } from 'context/globalContext';

function BaseLogger(props) {
    let currentPath = props.history.location.pathname;

    React.useEffect(() => {
        props.history.listen((location) => {
            const currentItem = menuItems.find(item => item.path === location.pathname);

            if (currentPath !== location.pathname && currentItem?.hasOwnProperty('loadData')) {
                currentPath = location.pathname;
                currentItem.loadData();
            }
        });
    }, []);

    return (null);
}

const HistoryLogger = withRouter(BaseLogger);

function App() {
    const userConfirmation = React.useCallback((message, callback) => {
        const node = document.getElementById('custom-prompt');

        const cleanUp = answer => {
            ReactDOM.unmountComponentAtNode(node);
            callback(answer);
        };

        ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
    }, []);

    return (
        <GlobalContextProvider>
            <EposContextProvider>
                <KpiContextProvider>
                    <ExportToExcelProvider>
                        <BrowserRouter getUserConfirmation={userConfirmation}>
                            <HistoryLogger />
                            <Root />
                        </BrowserRouter>
                    </ExportToExcelProvider>
                </KpiContextProvider>
            </EposContextProvider>
        </GlobalContextProvider>
    );
}

export default App;
