import * as React from 'react';
import { CircularProgress } from '@material-ui/core';

const Spinner = ({ className }) => (
    <div className={className}>
        <CircularProgress color="primary" />
    </div>
);

export default Spinner;
