import * as React from 'react';

import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowDropDown, ViewColumnOutlined } from '@material-ui/icons';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        button: {
            padding: spacing(5/8),
            borderRadius: spacing(0.5),
            textTransform: 'none',
            backgroundColor: `${palette.grey[100]}`,
        }
    })
);

export default function ColumnChooserButton (props: { disabled: boolean; handleClick: (event: React.MouseEvent<unknown>) => void}) {
    const { disabled, handleClick } = props;
    const classes = useStyles({});

    return (
        <Button className={classes.button}
            disabled={disabled}
            variant="outlined"
            onClick={handleClick}
            data-a="column-chooser-button"
        >
            <ViewColumnOutlined />
            <ArrowDropDown />
        </Button>
    );
}
