export const EmpEndpoint = {
    General: {
        GetLoginUri: '/api/keycloak/login-uri',
        GetChangePasswordUri: '/api/keycloak/change-password-uri',
        GetChangeSupervisorPinUri: '/api/keycloak/change-supervisor-pin-uri',
        GetToken: '/api/keycloak/token',
        GetTranslationList: '/translation/list',
        GetGlobalSettings: '/setting/list'
    },
    Epos: {
        GetList: '/epos/list',
        GetEposAdditionalData: '/epos/additional-data'
    },
    Image: {
        GetTerminalImages: '/image/terminal',
        GetTerminalImagesMock: '/image/terminal-mock',

        GetMaintenanceImages: '/image/maintenance',
        DeleteMaintenanceImage: '/image/maintenance/delete',
        UploadMaintenanceImage: '/image/maintenance/upload',
    },
    Command: {
        RestartPC: '/epos/command/restart-terminal',
        RestartShell: '/epos/command/restart-shell',
        RestartPrinter: '/epos/command/restart-printer',
        ReInitBillAcceptor: '/epos/command/reinit-bill-acceptor',
        EnableMaintenanceMode: '/epos/command/maintenance-mode/enable',
        DisableMaintenanceMode: '/epos/command/maintenance-mode/disable',
        UpdateShell: '/epos/command/update-shell',
        ReloadShellSettings: '/epos/command/reload-shell-settings',
        SwitchToProduction: '/epos/command/switch-environment/production',
        SwitchToCanary: '/epos/command/switch-environment/canary',
    },
    Kpi: {
        GetAllData: '/kpi/all'
    },
    Metric: {
        GetList: '/metric/list'
    },
    User: {
        Login: '/user/login',
        List: '/user/list',
        Add: '/user/add',
        Edit: '/user/edit',
        ResetPassword: '/user/reset-password',
        UserChangePassword: '/user/change-password',
        Block: '/user/block',
        Unblock: '/user/unblock',
        Details: '/user/details',
        LoginAudit: '/user/login-audit',
        CheckUserPermission: '/user/check-permission',
        TokenRefresh: '/user/token-refresh',
        ResetSupervisorBarcode: '/user/reset-supervisor-barcode',
        DownloadSupervisorBarcode: '/user/download-supervisor-barcode',
        UserChangeSupervisorPin: '/user/change-supervisor-pin',
        ResetSupervisorPin: '/user/reset-supervisor-pin'
    },
    Role: {
        List: '/role/list',
        ListIdName: '/role/list/id-name',
        Add: '/role/add',
        Edit: '/role/edit',
        Details: '/role/details'
    },
    Resource: {
        List: '/resource/list'
    },
    AuditLog: {
        SaveLog: '/auditlog/save',
        GetList: '/auditlog/list'
    },
    NetworkManagement: {
        GetBetshops: '/betshop/list',
        GetBetshopsLite: '/betshop/list-lite',
        GetSingleBetshop: '/betshop/details',
        AddBetshop: '/betshop/add',
        EditBetshop: '/betshop/edit'
    },
    ReleaseServer: {
        GetShellVersions: '/release-server/shell-version/list'
    },
    CardPaymentTransactionsReport: {
        Search: '/card-payment-transactions-report/search',
        SearchByTransactionId: '/card-payment-transactions-report/search-by-transaction-id',
        CashiersList: '/card-payment-transactions-report/cashiers-list',
    },
    SupervisorAuditReport: {
        Search: '/supervisor-audit-report/search',
        SearchByDocumentId: '/supervisor-audit-report/search-by-document-id',
        UsersList: '/supervisor-audit-report/users-list',
    },
    TicketsReport: {
        Details: '/tickets-report/details',
        Search: '/tickets-report/search',
        SearchByTicketId: '/tickets-report/search-by-ticket-id',
        Count: '/tickets-report/tickets-count',
        PointsOfSaleFilter: '/tickets-report/points-of-sale-filter',
        AutorizeReprint: '/tickets-report/authorize-reprint',
        RefreshDetails: '/tickets-report/refresh-details',
        TicketVoid: '/tickets-report/cancel-ticket',
    },
    CardTransactionReportExport: {
        GetExport: '/reports-export/card-transactions-report',
    },
    SupervisorReportExport: {
        GetExport: '/reports-export/supervisor-audit-report',
    },
    TicketsReportExport: {
        GetExport: '/reports-export/tickets-report',
    },
    ReportsExport: {
        GetFile: 'reports-export/get-report'
    },
    VouchersReport: {
        Search: '/vouchers-report/search',
        Details: '/vouchers-report',
        AutorizeReprint: '/vouchers-report/authorize-reprint',
        RefreshDetails: '/vouchers-report/refresh-details'
    },
    VouchersReportExport: {
        GetExport: '/reports-export/vouchers-report',
    },
    PlayerStatementReportExport: {
        GetExport: '/reports-export/player-statement-report'
    },
    OddsSheetsPdf: {
        GetPdf: '/odds-sheets-report/get-pdf-link'
    }
};
