const storageName = 'emulateOptions';
const useMockName = `${storageName}.useMock`;
const useCDNName = `${storageName}.useCDN`;
const mockCountName = `${storageName}.mockCount`;

let _useMock = Boolean(window.localStorage.getItem(useMockName) || false);
let _useCDN = Boolean(window.localStorage.getItem(useCDNName) || true);
let _mockCount = Number.parseInt(window.localStorage.getItem(mockCountName) || '1000');

let emulateOptions = {
    get useMock() {
        return _useMock;
    },
    set useMock(value) {
        _useMock = value;
        window.localStorage.setItem(useMockName, _useMock.toString());
    },

    get useCDN() {
        return _useCDN;
    },
    set useCDN(value) {
        _useCDN = value;
        window.localStorage.setItem(useCDNName, _useCDN.toString());
    },

    get mockCount() {
        return _mockCount;
    },
    set mockCount(value) {
        _mockCount = value;
        window.localStorage.setItem(mockCountName, _mockCount.toString());
    }
};

window['emulateOptions'] = emulateOptions;

export { emulateOptions };
