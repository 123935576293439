import * as React from 'react';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Typography,
    TextField,
} from '@material-ui/core';

import Api from 'api/Api';

import { MaintenanceModeEnable } from 'data';
import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { cancelRequests } from 'utils/requestCancelation';

import formatStr from 'utils/formatStr';

const { useState, useEffect, useContext } = React;

const dotSize = '10px';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalBox: {
            padding: `0 ${theme.spacing(3)}px`,
            marginBottom: `${theme.spacing(2)}px`,
        },
        dialogContent: {
            paddingTop: 0,
            overflowY: 'initial',
        },
        imgTitleWrap: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        title: {
            margin: 0,
            paddingBottom: theme.spacing(1),
            fontSize: '1rem',
            lineHeight: 1,
            fontWeight: 500
        },
        titleDescription: {
            margin: 0,
            color: theme.palette.text.secondary,
            fontWeight: 400,
        },
        button: {
            minWidth: 'auto',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
            lineHeight: 1,

            '&:hover': {
                backgroundColor: 'transparent',
                shadow: 'none'
            }
        },
        uploadButton: {
            padding: theme.spacing(1, 2)
        },
        removeButton: {
            padding: theme.spacing(1, 0)
        },
        imagesWrap: {
            width: '100%',
            display: 'grid',
            gridColumnGap: theme.spacing(1),
            gridRowGap: theme.spacing(1),
            gridTemplateColumns: 'repeat(4, 1fr)',
            marginTop: theme.spacing(2)
        },
        imageWrap: {
            width: '100%',
            paddingTop: '80%',
            display: 'flex',
            alignItems: 'center',
            backgroundSize: 'contain',
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.down('xs')]: {
                backgroundSize: 'cover'
            }
        },
        image: {
            width: '100%',
            cursor: 'pointer',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '2px'
        },
        loadingImg: {
            position: 'relative',
            paddingTop: '80%',
            backgroundColor: `${theme.palette.grey[100]}`,
            border: 'none',
        },
        loadingDots: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: dotSize,
            height: dotSize,
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[200],
            animation: 'dot-flashing 1s infinite linear alternate',
            animationDelay: '.5s',
            '&::before': {
                content: '\'\'',
                display: 'block',
                position: 'absolute',
                left: '-15px',
                top: 0,
                width: dotSize,
                height: dotSize,
                borderRadius: '50%',
                backgroundColor: theme.palette.grey[200],
                animation: 'dot-flashing 1s infinite linear alternate',
                animationDelay: '0s',
            },
            '&::after': {
                content: '\'\'',
                display: 'block',
                position: 'absolute',
                right: '-15px',
                top: 0,
                width: dotSize,
                height: dotSize,
                borderRadius: '50%',
                backgroundColor: theme.palette.grey[200],
                animation: 'dot-flashing 1s infinite linear alternate',
                animationDelay: '1s',
            },
        },
        loadingText: {
            margin: 0,
            padding: theme.spacing(1, 0),
            color: theme.palette.text.secondary,
            fontSize: '0.8125rem',
            fontWeight: 500
        },
        imageActive: {
            borderColor: `${theme.palette.primary.main}`
        },
        inputWrap: {
            '& .Mui-focused': {
                color: `${theme.palette.primary.main}`,
            }
        },
        errrorBox: {
            minHeight: `${theme.spacing(2) + 2}px`,
        },
        errorMessage: {
            padding: theme.spacing(1, 0),
            fontSize: '0.75rem'
        },
        checkboxRow: {
            marginTop: theme.spacing(2)
        },
        inputWrapTimeout: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2)
        },
        timeoutInput: {
            width: '55px',
            marginRight: theme.spacing(2),
            '& input': {
                color: theme.palette.common.black,
                textAlign: 'right',
            }
        }
    })
);

const apiRequests = {
    maintenanceImages: null
};

export default (
    props: {
        id: number;
        isOpen: boolean;
        onClose: () => void;
        onSubmit: (id: number, params: Array<string>) => void;
    }) => {
    const { isOpen, onClose, onSubmit } = props;

    const classes = useStyles({});
    const { globalSettings, translations }: GlobalContextModel = useContext(GlobalContext);

    const imageSize = globalSettings.maintenance.ImageSize;
    const imageCount = globalSettings.maintenance.ImageCount;
    const timeoutDefault = globalSettings.maintenance.DefaultTimeout;
    const delayedMaintenanceEnabled = globalSettings.maintenance.DelayedMaintenanceEnabled;

    const [images, setImages] = useState([]);
    const [activeImage, setActiveImage] = useState(-10);
    const [inputValue, setInputValue] = useState('');
    const [mainFormValid, setMainFormValid] = useState(true);
    const [notificationToPlayer, setNotificationToPlayer] = useState(true);
    const [maintenanceTimeout, setMaintenanceTimeout] = useState(timeoutDefault ? `${timeoutDefault}` : '');
    const [timeoutValid, setTimeoutValid] = useState(true);
    const [fileSizeError, setFileSizeError] = useState(false);

    useEffect(() => {
        apiRequests.maintenanceImages = Api.Image.GetMaintenanceImages();
        apiRequests.maintenanceImages
            .then(res => res && setImages(setLoadedStatus(res)))
            .catch(() => {
                // handle fetch errors here
            });

        return () => cancelRequests(apiRequests);
    }, []);

    function setLoadedStatus(res) {
        return res.map(image => {
            image.loaded = true;

            return image;
        });
    }

    function toggleImage(id) {
        if (activeImage === id) {
            setActiveImage(-1);
        } else {
            setActiveImage(id);
            !mainFormValid && setMainFormValid(!!id);
        }
    }

    function handleInputChange(event) {
        setInputValue(event.target.value);
        !mainFormValid && setMainFormValid(!!event.target.value);
    }

    function handleClose() {
        setInputValue('');
        setMainFormValid(true);
        setTimeoutValid(true);
        setActiveImage(-1);
        onClose();
    }

    function handleSubmit() {
        const isMainFormValid = !!(activeImage >= 0 && activeImage < imageCount || inputValue);
        const isTimeoutValid = Number(maintenanceTimeout) >= Number(timeoutDefault);

        if (isMainFormValid && isTimeoutValid) {
            const imgs = `${images[activeImage]?.url
                ? images[activeImage].url
                : ''
            }`;
            const params = [imgs, inputValue];

            notificationToPlayer && params.push(maintenanceTimeout);

            setInputValue('');
            onSubmit(props.id, params);
            onClose();
        }

        setMainFormValid(isMainFormValid);
        setTimeoutValid(isTimeoutValid);
    }

    function updateImageArray(newImages: any[], newActive: number) {
        setImages(newImages);

        if (newActive >= 0) {
            setActiveImage(newActive);
        }
    }

    function onChangeUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024;

        if (fileSize > imageSize) {
            setFileSizeError(true);
        } else {
            const reader = new FileReader();
            const uploadedImagesArr = [...images];

            setFileSizeError(false);
            setMainFormValid(true);

            reader.readAsDataURL(file);

            uploadedImagesArr.push();

            reader.onloadend = () => {
                const newUploadedImgArr = [...uploadedImagesArr];

                newUploadedImgArr.push({
                    name: `image${newUploadedImgArr.length + 1}.png`,
                    url: reader.result,
                    loaded: false
                });

                setImages(newUploadedImgArr);
                Api.Image.UploadMaintenanceImage(file).then(res => res && updateImageArray(setLoadedStatus(res), res.length - 1));
            };

        }

        event.target.value = null;
    }

    function onRemoveImage(name) {
        setFileSizeError(false);
        setMainFormValid(true);
        Api.Image.DeleteMaintenanceImage(name).then(res => res && updateImageArray(setLoadedStatus(res), res.length >= 0 ? 0 : -1));
    }

    function handleTimeoutChange(event) {
        const { value } = event.target;

        setMaintenanceTimeout(value.replace(/[^0-9]/g, ''));
        !timeoutValid && setTimeoutValid(value >= +timeoutDefault);
    }

    return ReactDOM.createPortal(
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{translations[MaintenanceModeEnable.title]}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText id="alert-dialog-description">
                        {translations[MaintenanceModeEnable.description]}
                    </DialogContentText>
                </DialogContent>
                <Box className={classes.modalBox}>
                    <div className={classes.imgTitleWrap}>
                        <div>
                            <h2 className={classes.title}>{translations[MaintenanceModeEnable.imagesDescription]}</h2>
                            <p className={classes.titleDescription}>
                                {
                                    `${formatStr(
                                        translations['hm-bulk-action-enable-maintenance-mode-size-desc'],
                                        { 'imageSize': imageSize, 'imageCount': imageCount }
                                    )}`
                                }
                            </p>
                        </div>
                        <div>
                            <input
                                color="primary"
                                type="file"
                                onChange={onChangeUpload}
                                id="button-file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                disabled={images.length >= imageCount}
                            />
                            <label htmlFor="button-file">
                                <Button
                                    component="span"
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    className={clsx(classes.button, classes.uploadButton)}
                                    disabled={images.length >= imageCount}
                                >
                                    {translations[MaintenanceModeEnable.uploadImage]}
                                </Button>
                            </label>
                        </div>
                    </div>
                    <div className={classes.imagesWrap}>
                        {
                            !!(images?.length) && images.map((image, i) => {
                                return (
                                    !!image.loaded
                                        ? (
                                            <div key={i}>
                                                <div
                                                    className={clsx(
                                                        classes.imageWrap,
                                                        classes.image,
                                                        activeImage === i && classes.imageActive
                                                    )}
                                                    style={{ backgroundImage: `url("${image.url}")` }}
                                                    onClick={() => toggleImage(i)}
                                                />
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    className={clsx(classes.button, classes.removeButton)}
                                                    onClick={() => onRemoveImage(image.name)}
                                                >
                                                    {translations[MaintenanceModeEnable.deleteImage]}
                                                </Button>
                                            </div>)
                                        : (
                                            <div key={i}>
                                                <div className={clsx(classes.loadingImg)}>
                                                    <div className={classes.loadingDots} />
                                                </div>
                                                <p className={classes.loadingText}>
                                                    {translations[MaintenanceModeEnable.uploading]}
                                                </p>
                                            </div>)
                                );
                            })
                        }
                    </div>
                    {
                        fileSizeError &&
                            <Typography
                                className={classes.errorMessage}
                                color="error"
                                variant="subtitle2"
                            >
                                {`${formatStr(
                                    translations['hm-bulk-action-enable-maintenance-mode-size-error'],
                                    { 'imageSize': imageSize }
                                )}`}
                            </Typography>
                    }
                </Box>
                <Box className={classes.modalBox} mb={1}>
                    <Box className={classes.inputWrap}>
                        <TextField
                            label={translations[MaintenanceModeEnable.textDisplayPlaceholder]}
                            multiline
                            variant="outlined"
                            rows="4"
                            fullWidth
                            value={inputValue}
                            onChange={handleInputChange}
                            error={!mainFormValid}
                        />
                    </Box>
                    {
                        !mainFormValid &&
                            <Typography
                                className={classes.errorMessage}
                                color="error"
                                variant="subtitle2"
                            >
                                {translations[MaintenanceModeEnable.textError]}
                            </Typography>
                    }
                </Box>
                {
                    delayedMaintenanceEnabled &&
                        <Box className={classes.modalBox} mb={1}>
                            <Box className={classes.inputWrap}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={notificationToPlayer}
                                            onChange={() => setNotificationToPlayer(!notificationToPlayer)}
                                            name="two-factor"
                                            color="primary"
                                        />
                                    }
                                    label={translations[MaintenanceModeEnable.notificationToPlayer]}
                                />
                            </Box>
                            <Box className={classes.inputWrap}>
                                <p className={classes.titleDescription}>
                                    {translations[MaintenanceModeEnable.timerDescription]}
                                </p>
                            </Box>
                            <Box className={clsx(classes.inputWrap, classes.inputWrapTimeout)}>
                                <TextField
                                    className={classes.timeoutInput}
                                    size="small"
                                    variant="outlined"
                                    value={maintenanceTimeout}
                                    onChange={handleTimeoutChange}
                                    error={!timeoutValid}
                                    disabled={!notificationToPlayer}
                                    inputProps={{
                                        maxLength: '3'
                                    }}
                                />
                                <span>{translations[MaintenanceModeEnable.minutes]}</span>
                            </Box>
                            {
                                !timeoutValid &&
                                    <Typography
                                        className={classes.errorMessage}
                                        color="error"
                                        variant="subtitle2"
                                    >
                                        {`${translations[MaintenanceModeEnable.minutesError]} ${timeoutDefault} ${translations[MaintenanceModeEnable.minutes]}`}
                                    </Typography>
                            }
                        </Box>
                }
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {translations['gen-cancel']}
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {translations['gen-apply']}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>,
        document.getElementById('modal') || document.createElement('div') // for testing purposes
    );
};
