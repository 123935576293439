import { ITicketDetailsModel } from 'api/models/reports';
import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';
import {
    BET_TYPE,
    betTypesSeparator
} from 'const';

const emptyValueLabel = '-';

export const getPropertyValue = (property, data, dateTimeFormat, timeZone, globalSettings) => {
    return property?.date && data[property.label]
        ? new Date(data[property.label]).getTime()
            ? formatDateByPattern(new Date(formatToIsoDateString(data[property.label])), dateTimeFormat, timeZone)
            : emptyValueLabel
        : property?.currency
            ? data[property.label] != 0
                ? formatNumberToCurrencyString(
                    data[property.label],
                    numberStyles(globalSettings?.settings?.Currency),
                    globalSettings?.settings?.CurrencySign,
                    globalSettings?.settings?.CurrencyFormat
                )
                : emptyValueLabel
            : (data[property.label] || emptyValueLabel);
};

export const getBetDescriptionsString: (
    betTypeDescriptions: ITicketDetailsModel['betTypeDescriptions'],
    translations: any,
    separator?: string,
) => string = (
    betTypeDescriptions = {},
    translations,
    separator = betTypesSeparator
) => Object.values(betTypeDescriptions)
    .map((descriptionArray) =>
        descriptionArray
            .flat()
            .map(str => `${translations[str] ?? str}`)
            .join(' ')
    )
    .join(separator);

export const getOddsTitle: (
    betTypeDescriptions: ITicketDetailsModel['betTypeDescriptions'],
    translations: any
) => string = (
    betTypeDescriptions = {},
    translations
) => {
    const isMaxOdds = Object.keys(betTypeDescriptions || {})
        .map((betType) => Number(betType))
        .some((betType) => [BET_TYPE.SYSTEM, BET_TYPE.MULTIWAY].includes(betType));

    return translations[isMaxOdds ? 'emp-reports-ticket-max-odds' : 'emp-reports-ticket-total-odds'] || '';
};
