import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) =>
    createStyles({
        table: {
            width: 'auto',
            minWidth: '100%',
            tableLayout: 'fixed',
            border: `1px solid ${palette.grey[300]}`,
            borderRadius: spacing(0.5),

            '& thead': {
                [breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            '& body': {
                [breakpoints.down('sm')]: {
                    position: 'relative'
                }
            },
            '& tr': {
                '&:last-child': {
                    [breakpoints.down('sm')]: {
                        borderBottom: 'none'
                    }
                },
                [breakpoints.down('sm')]: {
                    position: 'relative',
                    display: 'flex',
                    padding: spacing(1, 2),
                    flexWrap: 'wrap',
                    borderBottom: `1px solid ${palette.divider}`
                }
            },
            '& td, & th': {
                [breakpoints.down('sm')]: {
                    padding: spacing(0.5, 0),
                    borderBottom: 'none'
                }
            }
        },
        wideMobile: {
            [breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        cell: {
            padding: spacing(2, 1),
            '&:last-child': {
                paddingRight: spacing(2),
                [breakpoints.down('sm')]: {
                    paddingRight: spacing(0)
                }
            }
        },
        cellHalf: {
            [breakpoints.down('sm')]: {
                width: '50%',
            }
        },
        cellVerticalTop: {
            verticalAlign: 'top'
        },
        statusCell: {
            [breakpoints.down('sm')]: {
                position: 'absolute',
                top: spacing(1),
                right: spacing(1),
            }
        },
        title: {
            padding: 0,
            margin: 0
        },
        titleValue: {
            fontSize: '16px'
        },
        betStatusBadge: {},
        bold: {
            fontWeight: 500
        },
        secondary: {
            color: palette.text.secondary
        },
        labelLive: {
            display: 'inline-block',
            padding: spacing(0, 0.5),
            marginLeft: spacing(0.5),
            color: palette.common.white,
            backgroundColor: palette.error.dark,
            borderRadius: spacing(0.5),
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        odds: {
            fontWeight: 500,
            color: palette['badge'].success.color,
        },
        finalResult: {},
        siblingSelectionsTable: {
            padding: 0,
            position: 'relative',

            '&::before': {
                content: '""',
                position: 'absolute',
                left: spacing(5/8),
                bottom: 0,
                color: palette['badge'].success.color,
                height: `calc(100% - ${spacing(13/8)}px)`,
                border: 0,
                borderLeft: '2px solid currentColor',
                borderStyle: 'dashed',
            },

            '& tr': {
                [breakpoints.down('sm')]: {
                    flexWrap: 'nowrap',
                    padding: spacing(4/8, 0, 4/8, 16/8),
                    borderBottom: 'none',
                },
            },
            '& $siblingFinalResultCell': {
                paddingLeft: spacing(8/8),
            },
        },
        siblingSelectionRow: {
            '&:first-child': {
                '& $siblingSelectionCell': {
                    paddingTop: 0,

                    '&::before': {
                        top: spacing(3/8),

                        [breakpoints.down('sm')]: {
                            top: spacing(7/8),
                        },
                    }
                },
                '& $siblingFinalResultCell': {
                    paddingTop: spacing(12/8),

                    [breakpoints.down('sm')]: {
                        paddingTop: 0,
                    },
                },
            },
            '&:last-child': {
                '& $siblingSelectionCell': {
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: spacing(12/8),
                        height: `calc(100% - ${spacing(23/8)}px)`,
                        backgroundColor: palette.common.white,
                    },
                },
            },
        },
        siblingSelectionCell: {
            borderBottom: 'none',
            padding: 0,
            paddingLeft: spacing(16/8),
            paddingRight: spacing(8/8),
            paddingTop: spacing(8/8),
            position: 'relative',

            [breakpoints.down('sm')]: {
                position: 'static',
            },

            '&::before': {
                content: '""',
                position: 'absolute',
                top: spacing((3+8)/8),
                left: 0,
                width: spacing(12/8),
                aspectRatio: 1,
                color: palette['badge'].success.color,
                border: '2px solid currentColor',
                borderRadius: '50%',
                backgroundColor: palette.common.white,
            },
        },
        siblingFinalResultCell: {
            borderBottom: 'none',
            minWidth: spacing(50/8),
            padding: 0,
            paddingTop: spacing((12+8)/8),
            paddingLeft: spacing(8/8),
            textAlign: 'end',

            [breakpoints.down('sm')]: {
                marginLeft: 'auto',
            }
        },
        tableRowTopAlign: {
            '& td, & th': {
                verticalAlign: 'top',
            },

            '& $betStatusBadge': {
                marginTop: spacing(8/8),
            },

            '& $odds': {
                display: 'inline-flex',
                marginTop: spacing(12/8),
            },
        },
    })
);

export default useStyles;
