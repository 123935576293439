import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import {
    Box,
    Divider,
    Grid,
    Typography,
    withWidth,
} from '@material-ui/core';

import { useHistory, useLocation } from 'react-router-dom';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import NoResultsFound from 'components/NoResultsFound';
import SearchbyNumber from 'components/SearchByNumber';
import CardTransactionsReportFilters from 'pages/Reports/CardTransactions/CardTransactionsReportFilters/CardTransactionsReportFilters';
import CardTransactionsReportTable from 'pages/Reports/CardTransactions/CardTransactionsReportTable/CardTransactionsReportTable';
import DateRangeLegend from 'components/DateRangeLegend';
import ButtonWithLinearProgress from 'components/Buttons/ButtonWithLinearProgress';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';

import { GlobalContext } from 'context/globalContext';

import { INetworkBetshop } from 'api/models/network-management';

import {
    IReportCardTransactionModel,
    ICardTransactionsSearchResponse,
    IPointsOfSaleModel,
    IAlertMessage,
} from 'api/models/reports';

import Api from 'api/Api';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

import {
    Order,
    rowsPerPageOptions,
    EXCEL_EXPORT_ERROR_ENUM,
    REPORTS_NAMES,
    ORDER_BY_CARD_TRANSACTIONS_COLUMN,
    ORDER_BY_CARD_TRANSACTIONS_COLUMN_NAMES,
    initialAlertMessage
} from 'const';

import formatStr from 'utils/formatStr';
import {
    startOfDay,
    endOfDay,
} from 'utils/formatDate';

import { setLanguageCookie } from 'utils/cookieMethods';

import {
    ICardTransactionsReportBaseFilterList,
    ICardTransactionsReportFilterList,
    ICardTransactionsReportInitState,
    cardTransactionIdSearchValidator,
    cardTransactionsReportFilterListInitial as filterListInitial,
} from 'pages/Reports/business';

import {
    generateCardTransactionsSearchPayload,
    getAllBetshops,
    getCashiersList,
} from 'pages/Reports/networkHelpers';

import { initMqttConnection, subscribe as subscribeToMqtt } from 'api/mqttClient';
import { onMessageExcelHandler, ExportToExcelContext, state as mqttState, setState as setMqttState } from 'context/exportToExcel';

import useStyles from 'pages/Reports/Tickets/styles';
import { CancelablePromise } from 'api/services/_BaseApi';

import { EXPORT_REPORT_TYPE } from 'api/models/reportsExport';

import { GlobalContextModel } from 'api/models/general';

type IApiRequests = {
    getAllBetshops: any | CancelablePromise<Array<INetworkBetshop>>;
    getTransactionsPage: null | CancelablePromise<ICardTransactionsSearchResponse>;
    getApprovalsPageById: null | CancelablePromise<Array<IReportCardTransactionModel>>;
    getCashiersList: null | CancelablePromise<IPointsOfSaleModel>;
    getExport: null | CancelablePromise<any>;
}

const apiRequests: IApiRequests = {
    getAllBetshops: null,
    getTransactionsPage: null,
    getApprovalsPageById: null,
    getCashiersList: null,
    getExport: null
};

let pendingGetExport = false;
let lastSearchResultDateReportingApi;

const CardTransactionsReport = (props) => {
    const { pathname, state: initState } = useLocation<ICardTransactionsReportInitState>();
    const history = useHistory();

    const classes = useStyles({});
    const { globalSettings, translations, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);

    const isMobileView = props.width === 'xs';

    const filtersListsInitial: ICardTransactionsReportBaseFilterList = {
        betShops: [],
        cashiers: [],
    };

    const { reportCardTransactionsExportPermission } = permissions;

    const cardTransactionsReportMaxRangeInDays = globalSettings?.settings?.ExcelExportSettings?.CardTransactionsReportMaxRangeDays != null
        ? globalSettings?.settings?.ExcelExportSettings?.CardTransactionsReportMaxRangeDays
        : globalSettings?.settings?.ExcelExportSettings?.DefaultReportMaxRangeDays;
    const cardTransactionsReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * cardTransactionsReportMaxRangeInDays;

    const [filtersLists, setFiltersLists] = useState(initState?.filtersLists ?? filtersListsInitial);

    const dateInTimeZone = new Date(new Date().toLocaleString('en', { timeZone }));
    const fromDate = startOfDay(dateInTimeZone);
    const toDate = endOfDay(dateInTimeZone);
    const filterListInitialWithDate = { ...filterListInitial, fromDate, toDate };

    // applied filters (as reference values when closing filters drawer for resetting if it is not applied)
    const [appliedFilters, setAppliedFilters] = useState(initState?.appliedFilters ?? { ...filterListInitialWithDate });

    const [isAscendingOrder, setIsAscendingOrder] = useState(initState?.isAscendingOrder ?? false);

    const [areRowsLoaded, setRowsLoaded] = useState(false);
    const [areFiltersListsLoaded, setFiltersListsLoaded] = useState(false);
    const isLoaded = areRowsLoaded && areFiltersListsLoaded;

    const isReadyToShowRef = useRef(false);

    isLoaded && (isReadyToShowRef.current = true);

    const [rows, setRows] = useState<Array<IReportCardTransactionModel>>(initState?.rows ?? []);

    const [page, setPage] = useState(initState?.page ?? 0);
    const previousRowsPerPageRef= useRef(rowsPerPageOptions[0]);
    const [rowsPerPage, setRowsPerPage] = useState(initState?.rowsPerPage ?? previousRowsPerPageRef.current);
    const scrollDownRef = useRef(false);

    const [rowsCount, setRowsCount] = useState(initState?.rowsCount ?? -1);

    const [searchText, setSearchText] = useState<string>(initState?.searchText ?? '');
    const [searchResetKey, setSearchResetKey] = useState(Math.random());
    const [invalidSearchValue, setInvalidSearchValue] = useState(false);
    const [isSearchedByTransactionID, setIsSearchedByTransactionID] = useState(initState?.isSearchedByTransactionID ?? false);
    const [transactionRRNIdFromSearch, setTransactionRRNIdFromSearch] = useState<string>(initState?.searchText ?? null);

    const [orderByColumn, setOrderByColumn] = useState<ORDER_BY_CARD_TRANSACTIONS_COLUMN>(ORDER_BY_CARD_TRANSACTIONS_COLUMN_NAMES.creationDate);
    const [alertMessage, setAlertMessage] = useState<IAlertMessage>({ ...initialAlertMessage });

    const ExportToExcelState = useContext(ExportToExcelContext);
    const {
        percentage: valueExportToExcel,
        isLoading: isLoadingExportToExcel,
        isFailed: isFailedExportToExcel,
        errorCode: excelExportError
    } = ExportToExcelState[REPORTS_NAMES.cardTransactions];

    isFailedExportToExcel && setAlertMessage({
        show: true,
        message: excelExportError === EXCEL_EXPORT_ERROR_ENUM.NoData
            ? translations['emp-transaction-nodata-error']
            : translations['general-error']
    });

    const fullSearchHandler = async ({
        newFilters = appliedFilters,
        newRowsPerPage = rowsPerPage,
        newPage = newRowsPerPage === rowsPerPage ? page : 0,
        newIsAscendingOrder = isAscendingOrder,
        newOrderByColumn = orderByColumn,
    }: {
        newFilters?: ICardTransactionsReportFilterList;
        newRowsPerPage?: number;
        newPage?: number;
        newIsAscendingOrder?: boolean;
        newOrderByColumn?: ORDER_BY_CARD_TRANSACTIONS_COLUMN;
    } = {}) => {
        setSearchText('');
        setSearchResetKey(Math.random());
        setInvalidSearchValue(false);
        setRowsLoaded(false);

        const payload = generateCardTransactionsSearchPayload(
            newFilters,
            newPage,
            newRowsPerPage,
            newIsAscendingOrder,
            newOrderByColumn,
            timeZone
        );

        apiRequests.getTransactionsPage = Api.CardTransactionsReportApi.GetTransactionsPage(payload);

        try {
            const {
                searchResult: approvalsList,
                count,
                pageNumber,
                lastSearchResultDate
            } = await apiRequests.getTransactionsPage;

            setPage(pageNumber - 1);
            setRowsCount(count);
            setRows(approvalsList);
            setIsSearchedByTransactionID(false);
            setRowsLoaded(true);
            lastSearchResultDateReportingApi = lastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
        }
    };

    const searchByIdHandler = async (transactionRRNId: string) => {
        setRowsLoaded(false);
        cancelRequests(apiRequests);

        apiRequests.getApprovalsPageById = Api.CardTransactionsReportApi.GetTransactionsPageByTransactionRRNId({
            transactionRRNId,
            betShopIds: [],
        });

        try {
            const approvalsList = await apiRequests.getApprovalsPageById;

            setPage(0);
            setRowsCount(approvalsList.length);
            setRows(approvalsList);
            setIsSearchedByTransactionID(true);
            setRowsLoaded(true);
            setTransactionRRNIdFromSearch(transactionRRNId);
        } catch (error) {
            throwNetworkError(error);
        }
    };

    useLayoutEffect(() => {
        if (scrollDownRef.current) {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;

            scrollDownRef.current = false;
        }
    }, [rows]);

    useEffect(() => {
        if (initState?.rows?.length) {
            setFiltersListsLoaded(true);
            setRowsLoaded(true);
        } else {
            getAllBetshops(apiRequests, setFiltersLists, globalSettings?.user?.ClientId)
                .then(() =>
                    getCashiersList(apiRequests, setFiltersLists)
                )
                .then(() => {
                    setFiltersListsLoaded(true);
                    fullSearchHandler();
                })
                .catch(throwNetworkError);
        }

        return () => cancelRequests(apiRequests);
    }, []);

    useEffect(() => {
        scrollDownRef.current = previousRowsPerPageRef.current !== rowsPerPage;
        previousRowsPerPageRef.current = rowsPerPage;
    }, [rowsPerPage]);

    const handleChangePage = (event, newPage: number) => {
        cancelRequests(apiRequests);
        fullSearchHandler({ newPage });
    };

    const handleSort = (clickedOrderByColumn: ORDER_BY_CARD_TRANSACTIONS_COLUMN) => {
        let newAscendingOrder = isAscendingOrder;
        let newOrderByColumn = clickedOrderByColumn;

        if (clickedOrderByColumn === orderByColumn) {
            newAscendingOrder = !isAscendingOrder;
            setIsAscendingOrder(newAscendingOrder);
        } else {
            setOrderByColumn(newOrderByColumn);
        }

        cancelRequests(apiRequests);
        fullSearchHandler({ newIsAscendingOrder: newAscendingOrder, newOrderByColumn });
    };

    const searchTextSubmitHandler = (value: string) => {
        if (value.length) {
            setAppliedFilters({ ...filterListInitialWithDate });

            const isValid = cardTransactionIdSearchValidator(value);

            setInvalidSearchValue(!isValid);

            if (isValid) {
                setSearchText(value);
                searchByIdHandler(value);
            } else {
                setRows([]);
            }
        } else {
            setInvalidSearchValue(false);
            setIsSearchedByTransactionID(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        cancelRequests(apiRequests);

        fullSearchHandler({ newRowsPerPage: event.target.value });
    };

    const handleFiltersApply = (filters) => {
        cancelRequests(apiRequests);

        setAlertMessage({ ...alertMessage, show: false });
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.cardTransactions]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });
        setAppliedFilters(filters);

        fullSearchHandler({ newFilters: { ...filters }, newPage: 0 });
    };

    const resetToDefault = () => {
        handleFiltersApply({ ...filterListInitialWithDate });
        setTransactionRRNIdFromSearch(null);
    };

    const handleRedirect = (href: string) => {
        const state: ICardTransactionsReportInitState = {
            filtersLists,
            isSearchedByTransactionID,
            appliedFilters,
            isAscendingOrder,
            rowsPerPage,
            rowsCount,
            rows,
            page,
            searchText,
        };

        history.replace({
            pathname: '/reports/card-transactions',
            state
        });

        history.push({
            pathname: href,
            state: {
                pathFrom: pathname
            }
        });
    };

    const handleLoadingExcel = async () => {
        if (rows.length == 0){
            setAlertMessage({ show: true, message: translations['emp-transaction-nodata-error'] });
        } else if (appliedFilters.toDate.getTime() - appliedFilters.fromDate.getTime() > cardTransactionsReportMaxRangeInMilliseconds) {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-transaction-export-to-excel-error-days'],
                    { 'days': cardTransactionsReportMaxRangeInDays }
                )}`
            });
        } else {
            const payload = generateCardTransactionsSearchPayload(
                appliedFilters,
                rowsPerPage,
                page,
                isAscendingOrder,
                orderByColumn,
                timeZone,
                lastSearchResultDateReportingApi,
                transactionRRNIdFromSearch ?? transactionRRNIdFromSearch,
            );

            if (!pendingGetExport) {
                pendingGetExport = true;
                Api.CardTransactionsReportApi.GetExport(
                    setLanguageCookie({ ...payload, reportType: EXPORT_REPORT_TYPE.CardTransactions }, globalSettings.languages, 'languageId'))
                    .then(res => {
                        const {
                            jwtToken,
                            reportId
                        } = res;

                        if (jwtToken) {
                            initMqttConnection(jwtToken, globalSettings.settings.MqttSettigs.BrokerKongUrl);
                            subscribeToMqtt({
                                topic: globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                onMessageHandler: (response) => onMessageExcelHandler(
                                    response,
                                    reportId,
                                    globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                    REPORTS_NAMES.cardTransactions)
                            });
                        }
                    }).catch((error) => {
                        setMqttState({
                            ...mqttState,
                            [REPORTS_NAMES.cardTransactions]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: null
                            }
                        });
                        throwNetworkError(error);
                    }).finally(() => pendingGetExport = false);
            }
        }
    };

    const handleHideAlert = () => {
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.cardTransactions]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });

        setAlertMessage({ ...alertMessage, show: false });
    };

    const CardTransactionsReportFiltersWithParams = (
        <CardTransactionsReportFilters
            filtersLists={filtersLists}
            appliedFilters={appliedFilters}
            setAppliedFilters={handleFiltersApply}
            filterListInitialWithDate={filterListInitialWithDate}
        />
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.searchWrap}>
                    <SearchbyNumber
                        initValue={searchText}
                        searchResetKey={searchResetKey}
                        placeholder={translations['emp-reports-transaction-search']}
                        onSubmit={searchTextSubmitHandler}
                        onReset={resetToDefault}
                        searchValidator={cardTransactionIdSearchValidator}
                    />
                </div>
                <div className={classes.titleWrap}>
                    <Typography className={classes.title}
                        component="h1"
                        noWrap
                    >
                        {translations['emp-reports-card-transactions-title']}
                    </Typography>
                    {isMobileView &&
                        <div className={classes.actions}>
                            {CardTransactionsReportFiltersWithParams}
                            {reportCardTransactionsExportPermission &&
                                <div style={{ marginLeft: '8px' }}>
                                    <ButtonWithLinearProgress
                                        label={translations['emp-transaction-export-to-excel'] as string}
                                        value={valueExportToExcel}
                                        handleLoading={handleLoadingExcel}
                                        loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                {isMobileView && (
                    <DateRangeLegend
                        translationKey="emp-reports-results-range-title"
                        fromDate={appliedFilters.fromDate}
                        toDate={appliedFilters.toDate}
                    />)}
            </div>
            {<Divider className={classes.divider} />}
            <div className={classes.paper}>
                <BackdropSpinner open={!isLoaded} />
                {isReadyToShowRef.current && (
                    <>
                        {!isMobileView && (
                            <div className={classes.resultsHeader}>
                                {!isSearchedByTransactionID && (
                                    <DateRangeLegend
                                        translationKey="emp-reports-results-range-title"
                                        fromDate={appliedFilters.fromDate}
                                        toDate={appliedFilters.toDate}
                                    />)}
                                <div className={classes.options}>
                                    {CardTransactionsReportFiltersWithParams}
                                    {reportCardTransactionsExportPermission &&
                                        <div style={{ marginLeft: '8px' }}>
                                            <ButtonWithLinearProgress
                                                label={translations['emp-transaction-export-to-excel'] as string}
                                                value={valueExportToExcel}
                                                handleLoading={handleLoadingExcel}
                                                loading={isLoadingExportToExcel}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className={classes.alertWrapper}>
                            <AlertMessageFullWidth
                                label={alertMessage.message}
                                show={alertMessage.show}
                                handleHideAlert={handleHideAlert}
                            >
                                {
                                    translations['gen-dismiss']
                                }
                            </AlertMessageFullWidth>
                        </div>
                        <Box className={classes.resultsWrapper}>
                            {(rows.length && !invalidSearchValue)
                                ? (
                                    <CardTransactionsReportTable
                                        page={page}
                                        rows={rows}
                                        rowsCount={rowsCount}
                                        hideSorting={isSearchedByTransactionID}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        handleSort={handleSort}
                                        handleRedirect={handleRedirect}
                                        order={isAscendingOrder ? Order.ascending : Order.descending}
                                        orderByColumn={orderByColumn}
                                        isMobileView={isMobileView}
                                    />
                                )
                                : (
                                    <Box paddingTop={12}>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <NoResultsFound
                                                    onResetClick={resetToDefault}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default withWidth()(CardTransactionsReport);
