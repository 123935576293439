import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Api from 'api/Api';
import {
    Button,
    MenuItem,
    Typography,
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import { useStyles as useSelectedQuantityStyles } from 'components/SelectedQuantity/SelectedQuantity';
import MaintenanceModal from 'pages/Epos/components/MaintenanceModal/MaintenanceModal';
import UpdateShellModal from 'pages/Epos/components/UpdateShellModal/UpdateShellModal';

import { IEposListItemModel, IBulkActions } from 'api/models/epos';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { COMMAND_TYPES } from 'const';
import {
    MaintenanceModeEnable,
    MaintenanceModeDisable,
    bulkActions,
    UpdateShell,
    SwitchToCanaryBulkAction,
    SwitchToProductionBulkAction,
} from 'data';
import { palette } from 'theme';

const { useState, useContext } = React;

interface IBulkActionsMenuProps {
    rows: Array<IEposListItemModel>;
    selectedIds: Array<number>;
    handleClearSelectedRows: () => void;
}

const useMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            height: `${theme.spacing(7) - 2}px`
        },
        menuButton: {
            minWidth: '100px',
            marginRight: '8px',
            borderRadius: '0 4px 4px 0',
            textTransform: 'none',
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: `${theme.palette.grey[100]}`,
        },
    })
);

export default function BulkActionsMenu(props: IBulkActionsMenuProps) {
    const {
        rows,
        selectedIds,
        handleClearSelectedRows
    } = props;

    const c = useMenuStyles({});
    const selectedQuantityClasses = useSelectedQuantityStyles({});
    const { permissions, translations }: GlobalContextModel = useContext(GlobalContext);

    const { maintenancePermission, updateShellPermission } = permissions;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
    const [isOpenMaintenanceModal, setIsOpenMaintenanceModal] = useState<boolean>(false);
    const [isOpenUpdateShellModal, setIsOpenUpdateShellModal] = useState<boolean>(false);

    const [bulkActionItem, setBulkActionItem] = useState<IBulkActions | {}>({});

    function handleOpenMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleCloseMenu() {
        setAnchorEl(null);
    }

    function handleCloseModal() {
        setIsOpenConfirmModal(false);
        setIsOpenMaintenanceModal(false);
        setIsOpenUpdateShellModal(false);
    }

    function handleClose(event) {
        setAnchorEl(null);
        setIsOpenConfirmModal(true);

        if (event.target.id == MaintenanceModeDisable.commandTypeId) {
            setBulkActionItem(MaintenanceModeDisable);
        } else {
            setBulkActionItem([
                ...bulkActions,
                SwitchToProductionBulkAction,
                SwitchToCanaryBulkAction,
            ].find(action => action.commandTypeId == event.target.id));
        }
    }

    function machineIdData(idArr: Array<number>) {
        return idArr.map((id) => rows.find((epos) => epos.id === id).machineId);
    }

    function handleSubmitConfirm(id: number) {
        if (id === COMMAND_TYPES.DisableMaintenanceMode || id === COMMAND_TYPES.EnableMaintenanceMode) {
            Api.Epos.BulkAction(machineIdData(selectedIds), id, []);
        } else {
            Api.Epos.BulkAction(machineIdData(selectedIds), id);
        }

        setIsOpenConfirmModal(false);
        handleClearSelectedRows();
    }

    function openMaintanceModal() {
        handleCloseMenu();
        setIsOpenMaintenanceModal(true);
    }

    function openUpdateShellModal() {
        handleCloseMenu();
        setIsOpenUpdateShellModal(true);
    }

    function handleSubmitMaintenance(id: number, params: Array<string>) {
        Api.Epos.BulkAction(machineIdData(selectedIds), id, params);
        setIsOpenConfirmModal(false);
        handleClearSelectedRows();
    }

    function handleUpdateShell(shellVersion: string) {
        Api.Epos.BulkAction(machineIdData(selectedIds), COMMAND_TYPES.ShellUpdate, [shellVersion]);
        handleCloseModal();
        handleClearSelectedRows();
    }

    return (
        <React.Fragment>
            <Button
                className={c.menuButton}
                variant="outlined"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
            >
                <Typography variant="body1">
                    <span className={selectedQuantityClasses.label}>{translations['hm-actions']}</span>
                </Typography>
                <ArrowDropDownIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                elevation={palette.elevation}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    maintenancePermission &&
                        <div>
                            <MenuItem className={c.menuItem} onClick={openMaintanceModal}>
                                {translations[MaintenanceModeEnable.title]}
                            </MenuItem>
                            <MenuItem
                                className={c.menuItem}
                                id={`${MaintenanceModeDisable.commandTypeId}`}
                                onClick={handleClose}
                            >
                                {translations[MaintenanceModeDisable.title]}
                            </MenuItem>
                        </div>
                }
                {
                    bulkActions.map((menuItem) =>
                        permissions[menuItem.permission] &&
                            <MenuItem
                                className={c.menuItem}
                                id={`${menuItem.commandTypeId}`}
                                key={menuItem.commandTypeId}
                                onClick={handleClose}
                            >
                                {translations[menuItem.title]}
                            </MenuItem>)
                }
                {
                    updateShellPermission &&
                        <MenuItem
                            className={c.menuItem}
                            id={`${UpdateShell.commandTypeId}`}
                            onClick={openUpdateShellModal}
                        >
                            {translations['hmd-shell-update']}
                        </MenuItem>
                }
                {
                    [SwitchToCanaryBulkAction, SwitchToProductionBulkAction].map((menuItem) =>
                        permissions[menuItem.permission] &&
                            <MenuItem
                                className={c.menuItem}
                                id={`${menuItem.commandTypeId}`}
                                key={menuItem.commandTypeId}
                                onClick={handleClose}
                            >
                                {translations[menuItem.title]}
                            </MenuItem>)
                }
            </Menu>
            <ConfirmModal
                id={(bulkActionItem as IBulkActions).commandTypeId}
                isOpen={isOpenConfirmModal}
                title={(bulkActionItem as IBulkActions).title}
                description={
                    selectedIds && selectedIds.length > 1
                        ? (bulkActionItem as IBulkActions).descriptionPlural
                        : (bulkActionItem as IBulkActions).description
                }
                onClose={handleCloseModal}
                onConfirm={handleSubmitConfirm}
            />
            <MaintenanceModal
                id={COMMAND_TYPES.EnableMaintenanceMode}
                isOpen={isOpenMaintenanceModal}
                onClose={handleCloseModal}
                onSubmit={handleSubmitMaintenance}
            />
            <UpdateShellModal
                isOpen={isOpenUpdateShellModal}
                title={UpdateShell.title}
                onClose={handleCloseModal}
                onConfirm={handleUpdateShell}
            />

        </React.Fragment>
    );
}
