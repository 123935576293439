import React, {
    useEffect,
    useState,
} from 'react';

import { IFiltersBoxProps, Filters } from 'components/Filters/FiltersBox/FiltersBox';
import ThresholdInput, { IThresholdInputProps } from 'components/ThresholdInput/ThresholdInput';
import { useFunctionDebounce } from 'utils/customHooks';
import { checkValidity, checkZeroString } from './business';

import useStyles from './styles';

interface IThresholdFilterProps extends
    Pick<IThresholdInputProps, 'inputComparisonLabel' | 'formatValue' | 'inputPrefix'>,
    Omit<IFiltersBoxProps, 'data' | 'handleFilterClick' | 'activeFilterIds'> {
        data: Array<number>;
        onChange: (key: string, data: Array<number>) => void;
        validityHandler?: (key: string, isValid: boolean) => void;
}

const ThresholdFilter: React.FunctionComponent<IThresholdFilterProps> = (props) => {
    const {
        label: key,
        inputComparisonLabel = '',
        formatValue = false,
        inputPrefix = '',
        data,
        onChange,
        validityHandler = () => {},
    } = props;

    const styles = useStyles({ content: inputComparisonLabel });

    const [tempValue, setTempValue] = useState('');
    const isValid = checkValidity(tempValue);

    const debouncedOnChange = useFunctionDebounce(onChange);

    const applyHandler = (stringValue) => {
        const isZeroString = checkZeroString(stringValue);

        debouncedOnChange(key, isZeroString ? [] : [Number(stringValue)]);
    };

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value: stringValue } }) => {
        const trimmedStringValue = stringValue.trim();
        const isStringValueValid = checkValidity(trimmedStringValue);

        setTempValue(trimmedStringValue);
        isStringValueValid !== isValid && validityHandler(key, isStringValueValid);
        isStringValueValid && applyHandler(trimmedStringValue);
    };

    useEffect(() => {
        setTempValue(`${data[0] ?? ''}`);
    }, [props]);

    return (
        <Filters {...{ ...props, activeFilterIds: { [key]: data } }}>
            <ThresholdInput className={styles.formControl}
                name={key}
                formatValue={formatValue}
                inputComparisonLabel={inputComparisonLabel}
                inputPrefix={inputPrefix}
                error={!isValid}
                value={tempValue}
                onChange={changeHandler}
            />
        </Filters>
    );
};

export default React.memo(ThresholdFilter);
