import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { transitionDuration } from 'const';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrap: {
            display: 'flex',
            width: '100%',
        },
        wrapMobile: {
            display: 'block',
            transitionProperty: 'height',
            transitionDuration
        },
        main: {
            display: 'flex',
        },
        wrapButtons: {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        hidden: {
            display: 'none'
        },
        button: {
            display: 'flex',
            marginRight: `${theme.spacing(1)}px`,
            marginBottom: `${theme.spacing(1)}px`,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: `${theme.palette.grey[100]}`,
            border: 'none',
            '&:hover': {
                backgroundColor: `${theme.palette.primary.light}`,
            },
            [theme.breakpoints.down('md')]: {
                '&:hover': {
                    backgroundColor: `${theme.palette.grey[100]}`,
                }
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                justifyContent: 'flex-start',
                flexGrow: 1,
                marginRight: 0,
                marginBottom: theme.spacing(0.5),
                textAlign: 'left',
            }
        },
        label: {
            padding: '0 5px'
        },
        buttonWrap: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        },
        buttonData: {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1
        },
        mainButtonData: {
            padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
        },
        count: {
            minWidth: theme.spacing(5),
            marginRight: theme.spacing(1.5),
            borderRadius: theme.spacing(2),
            fontWeight: 500,
            backgroundColor: theme.palette.grey[300],
            textAlign: 'center'
        },
        countActive: {
            color: '#fff',
            backgroundColor: `${theme.palette.primary.main}`
        },
        toggleButton: {
            minWidth: theme.spacing(1.5),
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: `${theme.palette.grey[100]}`,
            border: 'none',
            marginLeft: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },
        active: {
            backgroundColor: `${theme.palette.primary.light}`,
            [theme.breakpoints.down('md')]: {
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.light}`,
                }
            },
        },
        checkbox: {
            marginRight: `-${theme.spacing(1.4)}px`,
        },
        mobileTitleIcon: {
            marginRight: theme.spacing(1)
        },
        filteredButton: {
            display: 'flex',
            alignItems: 'center'
        }
    })
);

export default useStyles;
