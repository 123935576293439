import React, { useState, useEffect, useContext } from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    Typography,
} from '@material-ui/core';
import TestLabel from 'components/TestLabel/TestLabel';

import { useHistory, useLocation } from 'react-router-dom';

import { cancelRequests, isCanceled } from 'utils/requestCancelation';
import { numberStyles, formatNumberToCurrencyString } from 'utils/formatNumber';

import useStyles from './styles';
import clsx from 'clsx';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { betshopListHeader } from 'data';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import Api from 'api/Api';
import {
    INetworkBetshopLocationState,
    IBetshopListPayload
} from 'api/models/network-management';
import { rowsPerPageOptions } from 'const';
import SearchFieldWithSuggest from 'components/SearchField/SearchFieldWithSuggest';
import { filterBySearch } from 'pages/Betshops/business';
import { useDebounce } from 'utils/customHooks';

const apiRequests = {
    betshopList: null,
};

const BetshopTable = ({
    isSuggestionFieldEnabled,
    suggestionFieldWidth,
}: {
  isSuggestionFieldEnabled?: boolean;
  suggestionFieldWidth?: any;
}) => {
    const classes = useStyles({});
    const { globalSettings, translations, permissions }: GlobalContextModel =
    useContext(GlobalContext);

    const history = useHistory();
    const { state: initState } = useLocation<INetworkBetshopLocationState>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [defaultBetshops, setDefaultBetshops] = useState([]);
    const [betshopList, setBetshopList] = useState([]);
    const [searchText, setSearchText] = useState<string>(
        initState?.searchText || ''
    );
    const debouncedSearchText = useDebounce(searchText);
    const [page, setPage] = useState(initState?.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(initState?.rowsPerPage || 20);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        permissions.networkManagementBetshopsView && loadData({
            includeTestCustomer: false,
            itemsPerPage: rowsPerPage,
            pageNumber: page + 1,
            searchText: debouncedSearchText || '',
            orderDirection: 0,
            searchBy: [1, 2, 3]
        });

        return () => cancelRequests(apiRequests);
    }, [debouncedSearchText]);

    useEffect(() => {
        setPage(0);
    }, [debouncedSearchText]);

    const loadData = async (payload: IBetshopListPayload) => {
        try {
            cancelRequests(apiRequests);
            setIsLoaded(false);

            apiRequests.betshopList = Api.NetworkManagement.GetBetshops(payload);

            const result = await apiRequests.betshopList;
            const betshopList = result?.betshops?.map((betshop) => ({
                ...betshop,
                balance: formatNumberToCurrencyString(
                    betshop.balance,
                    numberStyles(globalSettings?.settings?.Currency),
                    globalSettings?.settings?.CurrencySign,
                    globalSettings?.settings?.CurrencyFormat
                )
            }
            ));

            if (betshopList) {
                setBetshopList(betshopList);
                setDefaultBetshops(betshopList);
                setPage(result.currentPage - 1);
                setTotalCount(result.betshopCount);
            }

            setIsLoaded(true);
        } catch (error) {
            // handle fetch errors here
            !isCanceled(error) && setIsLoaded(true);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        permissions.networkManagementBetshopsView && loadData({
            includeTestCustomer: false,
            itemsPerPage: rowsPerPage,
            pageNumber: newPage + 1,
            searchText: debouncedSearchText || '',
            orderDirection: 0,
            searchBy: [1, 2, 3],
        });
    };

    const handleChangeItemsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        permissions.networkManagementBetshopsView && loadData({
            includeTestCustomer: false,
            itemsPerPage: parseInt(event.target.value, 10),
            pageNumber: 1,
            searchText: debouncedSearchText || '',
            orderDirection: 0,
            searchBy: [1, 2, 3],
        });
    };

    const onItemListClick = (event: React.MouseEvent<HTMLElement>) => {
        const betshop = betshopList.find(
            (item) => item.id == event.currentTarget.id
        );

        permissions.networkManagementBetshopsView && betshop && history.push({
            pathname: `/betshops/${betshop.id}`,
            state: {
                searchText,
                page,
                rowsPerPage
            }
        });
    };

    const textSearchChange = (newText: string) => {
        if (searchText !== newText) {
            setSearchText(newText);
            setBetshopList(filterBySearch(defaultBetshops, newText));
            setPage(0);
        }
    };

    return (
        <>
            {isSuggestionFieldEnabled && (
                <div className={classes.controlsContainer}>
                    <SearchFieldWithSuggest
                        data={defaultBetshops.map((group) => ({
                            id: `${group.id}`,
                            name: group.name,
                        }))}
                        onTextChange={textSearchChange}
                        width={suggestionFieldWidth}
                        externalStyles={{
                            searchIcon: classes.searchIcon,
                            wrapOpen: classes.wrapOpen,
                        }}
                        searchValue={searchText}
                    />
                </div>
            )}
            <div className={classes.tableWrap}>
                {betshopList.length ? (
                    <div>
                        <div className={classes.tableWrap}>
                            <BackdropSpinner open={!isLoaded} />
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {betshopListHeader.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                className={classes.headerCell}
                                                style={{ width: headCell.width || 'auto' }}
                                            >
                                                {translations[headCell.label]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {betshopList.map((betshop) => (
                                        <TableRow
                                            key={betshop.id}
                                            id={betshop.id}
                                            hover={permissions.networkManagementBetshopsView}
                                            className={clsx(
                                                permissions.networkManagementBetshopsView &&
                          classes.tableRowEdit
                                            )}
                                            onClick={onItemListClick}
                                            data-a="betshops-table-row"
                                        >
                                            <TableCell className={classes.headerCell}>
                                                <div className={classes.nameWrap}>
                                                    <Typography noWrap className={classes.nameCell}>
                                                        {betshop.name}
                                                    </Typography>
                                                    {betshop.isTest && (
                                                        <TestLabel className={classes.testLabel} />
                                                    )}
                                                </div>
                                                <Typography noWrap variant="body2">
                                                    {betshop.id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                className={clsx(
                                                    classes.headerCell,
                                                    classes.balanceCell
                                                )}
                                            >
                                                {betshop.balance}
                                            </TableCell>
                                            <TableCell className={classes.headerCell}>
                                                {betshop.address}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={totalCount ? page : 0}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeItemsPerPage}
                            labelRowsPerPage={translations['hm-table-rows-per-page']}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                ) : (
                    <BackdropSpinner open={!isLoaded} />
                )}
            </div>
        </>
    );
};

export default BetshopTable;
