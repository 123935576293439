import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import { IGetTokenResponce, IInitialSettingsModel, SettingsModel } from 'api/models/general';

import { getQueryParams } from 'utils/devUtils';

import { APPLICATION_TYPE } from 'const';

declare const initialSettings: IInitialSettingsModel;

export default class GeneralApi extends BaseApi {
    private GetRedirectUri(restorePath, cleanRestorePath = false) {
        return `${document.location.protocol}//${document.location.host}/login/?restorePath=${cleanRestorePath ? '' : restorePath}`;
    }

    private GetLoginUriPaylod(cleanRestorePath = false) {
        return {
            applicationType: APPLICATION_TYPE.emp,
            siteId: initialSettings.SiteId,
            redirectUri: this.GetRedirectUri(document.location.pathname, cleanRestorePath),
        };
    }

    GetLoginUri(cleanRestorePath = false) {
        const loginHint = getQueryParams(document.location.href)?.['login_hint'];
        const payload = { ...this.GetLoginUriPaylod(cleanRestorePath) };

        if (loginHint) {
            payload['loginHint'] = loginHint;
        }

        return this.QueryLogin<any>(
            payload,
            EmpEndpoint.General.GetLoginUri,
            this.QueryMethods.GET);
    }

    GetChangePasswordUri() {
        return this.QueryLogin<any>(
            this.GetLoginUriPaylod(),
            EmpEndpoint.General.GetChangePasswordUri,
            this.QueryMethods.GET);
    }

    GetChangeSupervisorPinUri() {
        return this.QueryLogin<any>(
            this.GetLoginUriPaylod(),
            EmpEndpoint.General.GetChangeSupervisorPinUri,
            this.QueryMethods.GET);
    }

    GetToken(type: 'POST' | 'PUT' | 'DELETE', restorePath = '') {
        switch (type) {
            case 'POST':
                return this.QueryLogin<IGetTokenResponce>({
                    applicationType: APPLICATION_TYPE.emp,
                    siteId: initialSettings.SiteId,
                    code: getQueryParams(document.location.href)?.['code'] || '',
                    state: getQueryParams(document.location.href)?.['state'] || '',
                    redirectUri: this.GetRedirectUri(restorePath)
                },
                EmpEndpoint.General.GetToken,
                this.QueryMethods.POST);
            case 'PUT':
                return this.QueryLogin<IGetTokenResponce>({
                    applicationType: APPLICATION_TYPE.emp,
                    siteId: initialSettings.SiteId
                },
                EmpEndpoint.General.GetToken,
                this.QueryMethods.PUT);
            case 'DELETE':
                return this.QueryLogin<IGetTokenResponce>({
                    applicationType: APPLICATION_TYPE.emp,
                    siteId: initialSettings.SiteId
                },
                EmpEndpoint.General.GetToken,
                this.QueryMethods.DELETE);
            default:
                return this.QueryLogin<IGetTokenResponce>({
                    applicationType: APPLICATION_TYPE.emp,
                    siteId: initialSettings.SiteId
                },
                EmpEndpoint.General.GetToken,
                this.QueryMethods.PUT);

        }
    }

    GetGlobalSettings() {
        return this.QueryEmpBff<SettingsModel>({}, EmpEndpoint.General.GetGlobalSettings, this.QueryMethods.GET);
    }

    GetTranslationList() {
        return this.QueryEmpBff<Record<string, string>>({}, EmpEndpoint.General.GetTranslationList, this.QueryMethods.GET);
    }
}
