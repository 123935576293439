import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        blockDetailsFull: {
            width: '100%',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.common.white
        },
        mainBlockDetailsBox: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(2),

            '&:last-child': {
                marginBottom: 0
            }
        },
        mainBlockDetailsName: {
            fontWeight: 500,
            color: '#000',
        },
        mainBlockDetailsDesc: {
            fontWeight: 500,
            color: theme.palette.text.secondary,
            textAlign: 'right',
            wordWrap: 'break-word'
        },
        secondaryDetailsBox: {
            padding: theme.spacing(0, 2, 2),
            backgroundColor: theme.palette.grey[100]
        },
        iconBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '16px',
            backgroundColor: theme.palette.primary.light
        },
        reprintBox: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: 'auto'
            }
        },
    })
);

export default useStyles;
