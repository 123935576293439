import React, { useContext } from 'react';
import { Link, Typography } from '@material-ui/core';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const NoResultsFound = ({ onResetClick }) => {
    const { translations }: GlobalContextModel = useContext(GlobalContext);

    return (
        <>
            <Typography variant="h6" align="center">{translations['audit-logs-no-results-message']}</Typography>
            <Link
                display="block"
                variant="h6"
                color="primary"
                component="button"
                underline="always"
                onClick={onResetClick}
                style={{ margin: 'auto' }}
            >
                {translations['audit-logs-clear-all-filters']}
            </Link>
        </>
    );
};

export default NoResultsFound;
