import * as React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import BlockIcon from '@material-ui/icons/Block';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import { EPOS_STATUS_NAMES, imageUnavailable, terminalOffline } from 'const';

const styles = (theme: Theme) => (
    createStyles({
        noImage: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            height: '100%',
            color: theme.palette.grey[500]
        },
        icon: {
            fontSize: '2.5rem'
        },
        title: {
            fontSize: '1rem',
            paddingTop: theme.spacing(0.5)
        },
        image: {
            width: '100%'
        }
    })
);

const TerminalsTilesItem = (props: {
    img: string;
    classes?: any;
}) => {
    const { img, classes } = props;
    const [ showImage, setShowImage ] = React.useState(true);

    React.useEffect(() => setShowImage(true), [img]);

    const handleError = () => {
        setShowImage(false);
    };

    return (
        img === EPOS_STATUS_NAMES.OFFLINE
            ? (
                <div className={classes.noImage}>
                    <BlockIcon color="disabled" classes={{ root: classes.icon }} />
                    <p className={classes.title}>{terminalOffline}</p>
                </div>)
            : (
                img && showImage
                    ? (
                        <img
                            className={classes.image}
                            src={img}
                            onError={handleError}
                        />)
                    : (
                        <div className={classes.noImage}>
                            <DesktopAccessDisabledIcon color="disabled" classes={{ root: classes.icon }} />
                            <p className={classes.title}>{imageUnavailable}</p>
                        </div>)
            )
    );
};

export default withStyles(styles)(TerminalsTilesItem);
