import * as React from 'react';
import clsx from 'clsx';
import useStyles from './styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { BetStatusBadge } from 'components/Badges/Badges';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { BET_TYPE } from 'const';

import { ITicketDetailsSelectionNode } from 'api/models/reports';

import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

const TicketDetailsTable = ({
    selectionNodes,
    betType,
    totalOdds
}: {
    selectionNodes: Array<ITicketDetailsSelectionNode>;
    betType: BET_TYPE;
    totalOdds: number;
}) => {
    const classes = useStyles({});

    const { translations, dateTimeFormat, timeZone }: GlobalContextModel = React.useContext(GlobalContext);
    const isProgressiveParlay = betType === BET_TYPE.PROGRESSIVE_PARLAY;

    return (
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell className={clsx(isProgressiveParlay && classes.cellVerticalTop)}> {translations['emp-reports-ticket-status']}</TableCell>
                    <TableCell className={clsx(classes.cell, isProgressiveParlay && classes.cellVerticalTop)}>{translations['emp-reports-ticket-event-date']}</TableCell>
                    <TableCell className={clsx(classes.cell, isProgressiveParlay && classes.cellVerticalTop)}>{translations['emp-reports-ticket-event-league']}</TableCell>
                    <TableCell className={clsx(classes.cell, isProgressiveParlay && classes.cellVerticalTop)} align="right">
                        {
                            isProgressiveParlay
                                ? (
                                    <>
                                        <p className={classes.title}>{translations['emp-reports-ticket-total-odds']}</p>
                                        <p className={clsx(classes.title, classes.titleValue)}>{totalOdds}</p>
                                    </>)
                                : translations['emp-reports-ticket-odds']
                        }
                    </TableCell>
                    <TableCell className={clsx(classes.cell, isProgressiveParlay && classes.cellVerticalTop)}>{translations['emp-reports-ticket-selection-market']}</TableCell>
                    <TableCell className={clsx(classes.cell, isProgressiveParlay && classes.cellVerticalTop)} align="right">{translations['emp-reports-ticket-final-score']}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {selectionNodes.map(({ parent, children=[] }, id) => {
                    const hasChildren = children?.length;

                    const selections = hasChildren
                        ? (
                            <Table className={classes.siblingSelectionsTable}>
                                {children.map((selection, index) => (
                                    <TableRow className={classes.siblingSelectionRow} key={index}>
                                        <TableCell className={classes.siblingSelectionCell} data-a="children-selection-and-market">
                                            <Typography variant="body2" className={classes.bold}>{selection.yourBet}</Typography>
                                            <Typography variant="body2" className={classes.secondary}>{selection.market}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.siblingFinalResultCell} data-a="children-selection-final-result">
                                            <Typography variant="body2" className={clsx(classes.bold, classes.finalResult)}>{selection.finalResult ?? '-'}</Typography>
                                        </TableCell>
                                    </TableRow>))
                                }
                            </Table>)
                        : (
                            <>
                                <Typography variant="body2" className={classes.bold}>{parent.yourBet}</Typography>
                                <Typography variant="body2" className={classes.secondary}>{parent.market}</Typography>
                            </>
                        );

                    return (
                        <TableRow key={id} className={hasChildren && classes.tableRowTopAlign}>
                            <TableCell className={classes.statusCell} component="th" scope="row">
                                <BetStatusBadge className={classes.betStatusBadge} betStatus={parent.status} />
                            </TableCell>

                            <TableCell className={clsx(classes.cell, classes.wideMobile)}>
                                <Typography variant="body2">
                                    <span className={classes.bold}>{parent.eventId}</span>
                                    {
                                        parent.isLive && <span className={classes.labelLive}>L</span>
                                    }
                                </Typography>
                                <Typography variant="body2" className={classes.secondary}>
                                    {formatDateByPattern(new Date(formatToIsoDateString(parent.eventDate)), dateTimeFormat, timeZone)}
                                </Typography>
                            </TableCell>

                            <TableCell className={clsx(classes.cell, classes.cellHalf)}>
                                <Typography variant="body2" className={classes.bold}>{`${parent.eventName} ${parent.score}`}</Typography>
                                <Typography variant="body2" className={classes.secondary}>{parent.leagueName}</Typography>
                            </TableCell>

                            <TableCell className={clsx(classes.cell, classes.cellHalf)} align="right">
                                {
                                    !isProgressiveParlay && <span className={classes.odds}>{parent.displayOdds}</span>
                                }
                            </TableCell>

                            <TableCell className={clsx(classes.cell, hasChildren ? classes.wideMobile : classes.cellHalf)} colSpan={hasChildren ? 2 : 1}>
                                {selections}
                            </TableCell>

                            {!hasChildren &&
                                <TableCell className={clsx(classes.cell, classes.cellHalf)} align="right">
                                    <Typography variant="body2" className={clsx(classes.bold, classes.finalResult)}>{parent.finalResult ?? '-'}</Typography>
                                </TableCell>
                            }
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default TicketDetailsTable;
