import * as React from 'react';
import TableGrid from 'pages/Epos/components/TableGrid/TableGrid';
import BackdropSpinner from 'components/Common/BackdropSpinner';

import { EposContext } from 'context/epos';

const Epos = () => {
    const { isLoaded: isEposListLoaded } = React.useContext(EposContext) || { isLoaded: false };

    return (
        !isEposListLoaded ? <BackdropSpinner open /> : <TableGrid />
    );
};

export default Epos;
