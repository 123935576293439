const locale = navigator.language;
const valuePositionSymbol = '{0}';

export const numberStyles = (currency) => new Intl.NumberFormat(locale, {
    maximumFractionDigits: 10,
    style: 'currency',
    currency
});

export const formatNumberToCurrencyString = (num, numberStyles, CurrencySign, CurrencyFormat) => {
    const numPositive = Number(num) >= 0;
    const formattedNum = formatNumToLocale(num, numberStyles);

    return isNaN(num)
        ? num
        : formatNumWithCurrencySign(formattedNum, numPositive, CurrencySign, CurrencyFormat);
};

export const formatNumToLocale = (num, numberStyles, ignoreMinus = true) => {
    return numberStyles
        .formatToParts(num)
        .filter(val => !(val.type === 'currency' || ignoreMinus && val.type === 'minusSign'))
        .map(val => val.type === 'fraction' ? val.value.slice(0, 2) : val.value)
        .join('')
        .trim();
};

export const formatNumWithCurrencySign = (num, numPositive, currencySign, currencyFormat) => {
    let result = '';

    if (currencyFormat.indexOf(valuePositionSymbol) === 0) {
        result = numPositive ? num + currencySign : `-${num + currencySign}`;
    } else {
        result = numPositive ? currencySign + num : `-${currencySign + num}`;
    }

    return result;
};
