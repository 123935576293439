import React, { useState, useEffect } from 'react';
import {
    createMuiTheme,
    Grid,
    IconButton,
    InputAdornment,
    MuiThemeProvider,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import DateFnsUtils from '@date-io/date-fns';
import { addMinutes, startOfDay, endOfDay, isValid } from 'utils/formatDate';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import defaultTheme, { palette } from 'theme';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const extendedDefaultMuiTheme = createMuiTheme(defaultTheme, {
    palette: {
        primary: {
            main: palette.primary.main
        }
    }
});

const useStyles = makeStyles((theme) =>
    createStyles({
        menuItem: {
            padding: `0 ${theme.spacing(2)}px`
        }
    }),
);

const TimePicker = ({ 'data-a': dataAttr, label, maxDate, minDate, value, onChange, onAccept }) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const { dateTimeFormat }: GlobalContextModel = React.useContext(GlobalContext);

    const ampm = dateTimeFormat.includes('a');

    return (
        <DateTimePicker
            hideTabs
            strictCompareDates
            ampm={ampm}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton>
                            <EventIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            inputVariant="outlined"
            format={dateTimeFormat}
            maxDate = {maxDate}
            minDate = {minDate}
            minDateMessage={translations['audit-logs-min-date-msg']}
            maxDateMessage={translations['audit-logs-max-date-msg']}
            invalidDateMessage={translations['audit-logs-invalid-date-msg']}
            margin="normal"
            label={label}
            cancelLabel={translations['gen-cancel']}
            okLabel={translations['gen-apply']}
            value={value}
            onChange={onChange}
            onAccept={onAccept}
            style={{ width: '100%' }}
            color="primary"
            data-a={dataAttr}
        />
    );
};

const DateTimePickers = ({
    setApplyDisabled,
    fromDate,
    toDate,
    minDate: minAvailableDateInCalendar,
    changeDates,
}: {
    setApplyDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    fromDate?: Date;
    toDate?: Date;
    minDate?: Date;
    changeDates: React.Dispatch<React.SetStateAction<{ fromDate: Date; toDate: Date }>>;
}) => {
    const classes = useStyles({});
    const [isFromDateChangedByUser, setFromDateChangedByUser] = useState<boolean>(false);

    useEffect(() => {
        setApplyDisabled(!validate());
    }, [fromDate, toDate]);

    const checkMinDateValidity = (date: Date) => minAvailableDateInCalendar ? date >= minAvailableDateInCalendar : true;

    const isFromDateValid = (date: Date) => {
        return isValid(date) && checkMinDateValidity(date) && date < toDate;
    };

    const isToDateValid = (date: Date) => {
        return isValid(date) && checkMinDateValidity(date) && date <= endOfDay(new Date());
    };

    const validate = () => (
        isFromDateValid(fromDate) &&
        isToDateValid(toDate) &&
        fromDate < toDate
    );

    const handleFromDateChange = (date: Date) => {
        changeDates({ fromDate: isValid(date) ? date : new Date('Invalid Date object'), toDate });
        setFromDateChangedByUser(true);
    };

    const handleToDateChange = (date: Date) => {
        const newFromDate = !isFromDateChangedByUser && isToDateValid(date)
            ? startOfDay(date)
            : fromDate;

        changeDates({ fromDate: newFromDate, toDate: isValid(date) ? date : new Date('Invalid Date object') });
    };

    return (
        <MuiThemeProvider theme={extendedDefaultMuiTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container direction="column" alignItems="center" className={classes.menuItem}>
                    <TimePicker
                        label="From"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        onAccept={handleFromDateChange}
                        minDate={minAvailableDateInCalendar}
                        maxDate={addMinutes(toDate, -1)}
                        data-a="date-picker-from"
                    />
                    <TimePicker
                        label="To"
                        value={toDate}
                        onChange={handleToDateChange}
                        onAccept={handleToDateChange}
                        minDate={isFromDateChangedByUser ? addMinutes(fromDate, 1) : minAvailableDateInCalendar}
                        maxDate={endOfDay(new Date())}
                        data-a="date-picker-to"
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(DateTimePickers);
