import * as React from 'react';

import { handleDownLoadBarcode } from 'pages/Users/business';

import { Grid, Button, Menu, MenuList, MenuItem, Typography, makeStyles } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import RefreshIcon from '@material-ui/icons/Refresh';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import Api from 'api/Api';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ActionConfirm from 'pages/Users/components/ActionConfirm/ActionConfirm';

import { ResetPasswordData, ResetSupervisorBarcodeData, ResetSupervisorPinData } from 'data';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const { useState, useContext, useEffect } = React;

const useStyles = makeStyles((theme) => ({
    containter: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
            padding: `0 ${theme.spacing(2)}px`,
        },
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        }
    },
    buttonLabel: {
        padding: '0 0.5rem',
        fontSize: '0.9rem',
        lineHeight: 1.75,
        fontWeight: 600,
        textTransform: 'none',
    },
    menu: {
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        '& button': {
            color: 'inherit'
        }
    },
    barcodeSvg: {
        display: 'none'
    }
}));

const ChangePasswordButton = () => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = useContext(GlobalContext);

    return (
        <Button className={classes.button}>
            <RefreshIcon />
            <Typography className={classes.buttonLabel}>
                {translations[ResetPasswordData.button]}
            </Typography>
        </Button>
    );
};

const ChangeCardButton = () => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = useContext(GlobalContext);

    return (
        <Button className={classes.button}>
            <CreditCardIcon />
            <Typography className={classes.buttonLabel}>
                {translations[ResetSupervisorBarcodeData.button]}
            </Typography>
        </Button>
    );
};

const ResetSupPinButton = () => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = useContext(GlobalContext);

    return (
        <Button className={classes.button}>
            <FiberPinIcon />
            <Typography className={classes.buttonLabel}>
                {translations[ResetSupervisorPinData.button]}
            </Typography>
        </Button>
    );
};

const MoreActionsMenu = ({
    user, isSupervisor, isResetPresent, resetPassword, resetSupervisorBarcode, resetSupervisorPin
}) => {
    const classes = useStyles({});
    const { translations, permissions }: GlobalContextModel = useContext(GlobalContext);
    const {
        usersResetPasswordPermission,
        userResetSupervisorBarcodePermission,
        userResetSupervisorPinPermission,
        downloadSupervisorBarcodePermission
    } = permissions;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button aria-controls="more-actions-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <Typography className={classes.buttonLabel}>{translations['hmd-more-actions']}</Typography>
                <ArrowDropDownIcon />
            </Button>

            <Menu
                id="more-actions-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MenuList className={classes.menu}>
                    {usersResetPasswordPermission && isResetPresent &&
                        <MenuItem >
                            <ActionConfirm
                                id={user.id}
                                title={ResetPasswordData.title}
                                description={ResetPasswordData.description}
                                onSubmit={resetPassword}
                                Anchor={ChangePasswordButton}
                            />
                        </MenuItem>
                    }
                    {userResetSupervisorBarcodePermission && isSupervisor &&
                        <MenuItem >
                            <ActionConfirm
                                id={user.id}
                                title={ResetSupervisorBarcodeData.title}
                                description={ResetSupervisorBarcodeData.description}
                                onSubmit={resetSupervisorBarcode}
                                Anchor={ChangeCardButton}
                            />
                        </MenuItem>
                    }
                    {downloadSupervisorBarcodePermission && isSupervisor &&
                        <MenuItem >
                            <Button onClick={() => handleDownLoadBarcode(user.id)} data-a={'download-barcode-button'}>
                                <SaveAltIcon />
                                <Typography className={classes.buttonLabel}>
                                    {translations['users-actions-download-barcode-button']}
                                </Typography>
                                <svg id={'download-barcode'} className={classes.barcodeSvg} />
                            </Button>
                        </MenuItem>
                    }
                    {userResetSupervisorPinPermission && isSupervisor &&
                        <MenuItem >
                            <ActionConfirm
                                id={user.id}
                                title={ResetSupervisorPinData.title}
                                description={ResetSupervisorPinData.description}
                                onSubmit={resetSupervisorPin}
                                Anchor={ResetSupPinButton}
                            />
                        </MenuItem>
                    }
                </MenuList>
            </Menu>
        </>
    );
};

const UserActionPanel = (props) => {
    const classes = useStyles({});
    const { translations, permissions }: GlobalContextModel = useContext(GlobalContext);

    const {
        usersResetPasswordPermission,
        usersBlockUnblockPermission,
        userResetSupervisorBarcodePermission,
        userResetSupervisorPinPermission,
        downloadSupervisorBarcodePermission
    } = permissions;
    const { user, isSupervisor, isResetPresent, isLoading } = props;

    const [userStatus, setUserStatus] = useState(user.enabled);

    useEffect(() => {
        setUserStatus(user.enabled);
    }, [user.enabled]);

    const showMoreActionsMenu = useMediaQuery(useTheme().breakpoints.down('sm'));

    const changeUserStatus = () => {
        Api.User.Block({
            id: user.id,
            enabled: !userStatus,
        })
            .then(res => {
                if (res.succeeded) {
                    setUserStatus(!userStatus);
                }
            });
    };

    const resetPassword = () => {
        Api.User.ResetPassword({ id: user.id });
    };

    const resetSupervisorBarcode = () => {
        Api.User.ResetSupervisorBarcode({ id: user.id });
    };

    const resetSupervisorPin = () => {
        Api.User.ResetSupervisorPin({ id: user.id });
    };

    const userHasActions =
        usersBlockUnblockPermission ||
        usersResetPasswordPermission ||
        userResetSupervisorBarcodePermission ||
        downloadSupervisorBarcodePermission ||
        userResetSupervisorPinPermission;

    return userHasActions && (
        <Grid data-a="user-action-wrap" container spacing={2} className={classes.containter}>
            {usersBlockUnblockPermission && !isLoading &&
                <Grid item>
                    <Button onClick={changeUserStatus} data-a={'block-user-button'}>
                        <BlockIcon />
                        <Typography className={classes.buttonLabel}>
                            {userStatus
                                ? translations['users-actions-block']
                                : translations['users-actions-unblock']
                            }
                        </Typography>
                    </Button>
                </Grid>
            }
            {showMoreActionsMenu
                ? (isResetPresent || isSupervisor) && (
                    <Grid item>
                        <MoreActionsMenu
                            user={user}
                            isSupervisor={isSupervisor}
                            isResetPresent={isResetPresent}
                            resetPassword={resetPassword}
                            resetSupervisorBarcode={resetSupervisorBarcode}
                            resetSupervisorPin={resetSupervisorPin}
                        />
                    </Grid>)
                : (
                    <>
                        {usersResetPasswordPermission && isResetPresent &&
                            <Grid item>
                                <ActionConfirm
                                    dataA={'reset-password-button'}
                                    id={user.id}
                                    title={ResetPasswordData.title}
                                    description={ResetPasswordData.description}
                                    onSubmit={resetPassword}
                                    Anchor={ChangePasswordButton}
                                />
                            </Grid>
                        }
                        {userResetSupervisorBarcodePermission && isSupervisor &&
                            <Grid item>
                                <ActionConfirm
                                    dataA={'user-reset-supervisor-barcode-button'}
                                    id={user.id}
                                    title={ResetSupervisorBarcodeData.title}
                                    description={ResetSupervisorBarcodeData.description}
                                    onSubmit={resetSupervisorBarcode}
                                    Anchor={ChangeCardButton}
                                />
                            </Grid>
                        }
                        {downloadSupervisorBarcodePermission && isSupervisor &&
                            <Grid item>
                                <Button onClick={() => handleDownLoadBarcode(user.id)} data-a={'download-barcode-button'}>
                                    <SaveAltIcon />
                                    <Typography className={classes.buttonLabel}>
                                        {translations['users-actions-download-barcode-button']}
                                    </Typography>
                                    <svg id={'download-barcode'} className={classes.barcodeSvg} />
                                </Button>
                            </Grid>
                        }
                        {userResetSupervisorPinPermission && isSupervisor &&
                            <Grid item>
                                <ActionConfirm
                                    dataA={'user-reset-supervisor-pin-button'}
                                    id={user.id}
                                    title={ResetSupervisorPinData.title}
                                    description={ResetSupervisorPinData.description}
                                    onSubmit={resetSupervisorPin}
                                    Anchor={ResetSupPinButton}
                                />
                            </Grid>
                        }
                    </>
                )}
        </Grid>
    );
};

export default UserActionPanel;
